var React=window.Snowball._React;
import ModuleBase from "../base/ModuleBase";
import moduleFactory from "../moduleFactory";

export default class Layout extends ModuleBase {
    onLoad() {
        this.model.observe(() => {
            this.updateSubModules();
        });
    }

    onComponentUpdate() {
        this.updateSubModules();
    }

    patchData(model, bizData) {
        model.set({
            bizData
        });
        this.updateSubModules();
    }

    updateSubModules() {
        const { model } = this;

        model.nextTick(() => {
            const {
                template,
                modules,
                templates,
                data: {
                    logicId
                }
            } = this.props;
            const attributes = model.attributes;

            // 避免重复渲染
            if (this._renderedAttributes === attributes && this._renderedModules === modules) {
                return;
            }
            this._renderedAttributes = attributes;
            this._renderedModules = modules;

            const { cols } = template.extProps;
            const { app_layout_cols } = this.bizData;
            const children = modules.filter(mod => (mod.bizData.app_layout_id == logicId));
            const layoutCols = (app_layout_cols && app_layout_cols.length) || cols || 1;

            const wrappers = this.layoutWrappers || (this.layoutWrappers = {});

            for (let i = 0; i < layoutCols; i++) {
                const container = model.$('[layout-column="' + i + '"]')[0];
                if (container) {
                    const wrapper = wrappers[i] || (wrappers[i] = document.createElement('div'));
                    const item = children.find(mod => mod.bizData.app_layout_col == i);
                    if (item) {
                        this.renderSubtreeIntoContainer(moduleFactory({
                            index: -1,
                            template: item.template,
                            data: item,
                            templates,
                            modules
                        }), wrapper);
                    } else {
                        this.renderSubtreeIntoContainer(<div></div>, wrapper);
                    }
                    for (let j = 0; j < layoutCols; j++) {
                        if (j != i && wrappers[j] && wrappers[j].parentNode == container) {
                            container.removeChild(wrappers[j]);
                        }
                    }
                    container.appendChild(wrapper);
                }
            }
        });
    }
}