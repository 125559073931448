var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;

function Instructions({
    instructions
}) {
    return (
        <div className="it_instructions it_details_con" style={{
            display: instructions && instructions.length ? 'block' : 'none'
        }}>
            <h4 className="it_details_hd">
                商品说明书
            </h4>
            <table
                className="it_instructions_table"
            >
                {
                    instructions && instructions.map((serv) => {
                        return <tr>
                            <th>{serv.name}</th>
                            <td>{serv.value}</td>
                        </tr>;
                    })
                }
            </table>
        </div>
    );
}

export default inject(() => {
    const itemViewModel = autowired('itemViewModel');
    const { instructions } = itemViewModel.item;
    return {
        instructions: (instructions || []).filter(spec => spec.value)
    };
})(Instructions);