var { observable, util }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
var { toast }=window.Snowball._widget;
import PromcenterApiService from "../../../shared/apis/PromcenterApiService";
import CustomEvent from "../../../shared/utils/customEvent";
import { COUPON_BUTTON_STATUS } from "../constants";


export default class CouponViewModel extends ViewModel {
    @observable
    coupons = [];

    @autowired
    _promcenterApiService: PromcenterApiService;

    async loadCoupons(spuIds) {
        this.spuIds = spuIds;
        const result = await this._promcenterApiService.queryAvailableAndReceivedSpecs(spuIds, 1);
        const coupons = result.data ? result.data.specs || [] : [];
        this.coupons = coupons.filter(coupon => coupon.type == 1)
            .concat(coupons.filter(coupon => coupon.type == 2))
            .map(formatCoupon);
    }

    @emitter
    onReceiveCoupon(specId) {
        this.receiveCoupon(specId);
    }

    async receiveCoupon(specId) {
        const res = await this._promcenterApiService.receiveCouponBySpecId(specId);
        if (res.success) {
            toast.showToast('领券成功！');
            window.dispatchEvent(new CustomEvent('jkmallReceiveCoupon', { detail: { receiveCouponNum: 1 } }));
        } else {
            toast.showToast(res.message || '领取失败');
        }
        this.loadCoupons(this.spuIds);
    }
}


const TIME_FORMAT = 'yyyy.MM.dd';

/**
 * 计算按钮状态
 */
const computeButtonStatus = ({
    type,
    isPatch,
    isReceiveSuccess,
    buttonStatus,
    isSupportRoundOrder = true,
}) => {
    let status;

    // 是否支持凑单，
    const supportRoundOrder = isPatch && isSupportRoundOrder;

    // 是否可领
    if (type == 1) {
        // 是否领取过
        if (isReceiveSuccess) {
            // 是否可凑单
            if (supportRoundOrder) {
                status = COUPON_BUTTON_STATUS.RoundOrder;
            } else {
                status = COUPON_BUTTON_STATUS.Received;
            }
        } else {
            // 是否有按钮状态，比如已失效 已抢光
            if (buttonStatus != undefined) {
                status = buttonStatus;
            } else {
                status = COUPON_BUTTON_STATUS.Avaible;
            }
        }
    } else {
        // 是否可凑单
        if (supportRoundOrder) {
            status = COUPON_BUTTON_STATUS.RoundOrder;
        } else {
            status = COUPON_BUTTON_STATUS.Null;
        }
    }

    return status;
};

export const formatCoupon = (coupon) => {
    const isSupportRoundOrder = false;

    if (!coupon) {
        return {};
    }

    const {
        type,
        specId,
        // name,
        discountPrice,
        thresholdPrice,
        // detailInfo,
        description,
        // redirectUrl,
        startTime,
        endTime,
        effectiveDays,
        isPatch,
        isReceiveSuccess,
        buttonStatus,
    } = coupon;

    const _discountPrice = discountPrice / 100;
    const subtitle = thresholdPrice ? `满${thresholdPrice / 100}元可用` : '立减';
    const _description = description;

    let effectiveTime = '';
    if (effectiveDays != -1) {
        if (effectiveDays == 0) {
            effectiveTime = `领取后当天内可用`;
        } else {
            effectiveTime = `领取后${effectiveDays}天内可用`;
        }
    } else {
        effectiveTime = `${util.formatDate(startTime, TIME_FORMAT)}至${util.formatDate(endTime, TIME_FORMAT)}`;
    }

    const status = computeButtonStatus({
        type,
        isPatch,
        isReceiveSuccess,
        isSupportRoundOrder,
        buttonStatus,
    });

    return {
        specId,
        discountPrice: _discountPrice,
        subtitle,
        description: _description,
        effectiveTime,
        status,
    };
};
