var { ViewModel, emitter }=window.Snowball._app;
var { observable, asObservable, util }=window.Snowball;

const scheme = {
    patientName: [{
        required: true,
        message: '请输入姓名'
    }, {
        pattern: /^[\u2E80-\u9FFF]{2,5}$/,
        message: '请输入正确的姓名'
    }],
    monthAge: [{
        required: true,
        message: '请输入年龄'
    }, {
        validator: (value, key) => {
            if (value) {
                const age = Number(value);
                if (age < 72 || age > 1440) {
                    return { field: key, message: '年龄不能小于6岁或大于120岁' };
                }
            }
        }
    }],
    mobile: [{
        required: true,
        message: '请输入手机号'
    }, {
        pattern: /^(1[3456789]\d{9})$/,
        message: '请输入正确的手机号码'
    }]
};

export default class PatientFormViewModel extends ViewModel {

    @observable
    formData = {};

    @observable
    errors = {};

    constructor() {
        super();

        asObservable(this.errors)
            .set(this.app.validate({}, util.pick(scheme, Object.keys({}))));
    }

    setFormData(formData) {
        this.formData = formData;
        this.errors = {};
    }

    clearForm() {
        this.formData = {
            patientName: '',
            monthAge: '',
            gender: '1',
            mobile: ''
        };
        this.errors = {};
    }

    @emitter
    onFieldsChange(data) {
        asObservable(this.errors)
            .set(this.app.validate(data, util.pick(scheme, Object.keys(data))));
        asObservable(this.formData)
            .set(data);
    }

    submit(onSuccess, onError?) {
        this.errors = this.app.validate(this.formData, scheme);
        if (Object.keys(this.errors).some((key) => this.errors[key])) {
            onError && onError(this.errors);
        } else {
            onSuccess(this.formData);
        }
    }
}