var React=window.Snowball._React;

function TradeOrderList({ orders }) {
    return (
        orders && orders.length &&
        <div class="opr_orders_wrapper">
            <p>订单信息</p>
            <table>
                <tr>
                    <th>订单号</th>
                    <th>支付订单号</th>
                    <th>收货人</th>
                    <th>收货地址</th>
                    <th>支付金额</th>
                </tr>
                {
                    orders.map((tradeOrder, index) => {
                        const {
                            tradeOrder: { tradeId, outerId, actualTotalFee },
                            address: { name, province, city, area, detail },
                        } = tradeOrder;

                        const wholeAddress = `${province}${city}${area}${detail}`;
                        const totalFee = `${(actualTotalFee / 100).toFixed(2)}元`;
                        return (
                            <tr>
                                <td>{tradeId}</td>
                                <td>{outerId}</td>
                                <td>{name}</td>
                                <td>{wholeAddress}</td>
                                <td>￥{totalFee}</td>
                            </tr>
                        );
                    })
                }
            </table>
        </div>
    );
}

export default TradeOrderList;