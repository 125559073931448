var React=window.Snowball._React;var { Component }=window.Snowball._React;
var { toast }=window.Snowball._widget;
// import { TFSImage, SmallTag, NumberModifier } from 'mall-core/components';
// import Checkbox from '../../../components/Checkbox';
import { CartItemProps } from '../constant/types';
// import { formatPrice } from '../utils/formatPrice';
import { CartTypes } from '../constant/CartTypes';
import Checkbox from '../../../shared/components/Checkbox';
// import getPublicImage from '../../../utils/getPublicImage';
// import { SHOP_ITEM } from '../constant/ProductType';
import TFSImage from '../../../shared/components/TFSImage';
import NumberModifier from '../../../shared/components/NumberModifier';

type CartInfoProps = {
    skuData: CartItemProps,
    // 是否是无效的数据
    isInvalid: boolean,
    // 是否处于编辑态下
    // isEditMode: boolean,
    // 点击选择单个item的选择框
    onCartItemCheck: (skuData: CartItemProps) => void,
    // 单个item修改数量
    changeItemNum: (
        num: number,
        skuId: number,
        storeId: number,
        orderQty: number
    ) => void,
    // 规格被改变触发的事件
    // onSpecChange: (
    //     isO2O: boolean,
    //     spuId: number,
    //     skuId: number,
    //     storeId: number,
    //     count: number
    // ) => void
};

const InvalidTypes = {
    Success: 0,
    Invalid: 1,
    UserAddressDelivered: 2,
    OutOfStock: 3,
    Stockless: 4,
    StockNotEnough: 5
};

const Mask = ({ skuData, isInvalid }) => {
    const orderQtyMin = skuData.orderQtyMin || 1;
    const minStock = 5;
    const InvalidTextMap = {
        [InvalidTypes.Invalid]: '·失效·',
        [InvalidTypes.UserAddressDelivered]: '本区域不售卖',
        [InvalidTypes.OutOfStock]: '·无货·',
        [InvalidTypes.Stockless]: '库存紧张',
        [InvalidTypes.StockNotEnough]: '库存不足'
    };
    const invalidType = isInvalid
        ? InvalidTypes.Invalid
        : !skuData.userAddressDelivered
            ? InvalidTypes.UserAddressDelivered
            : skuData.stock <= 0
                ? InvalidTypes.OutOfStock
                : skuData.stock < orderQtyMin
                    ? InvalidTypes.StockNotEnough
                    : skuData.stock < minStock
                        ? InvalidTypes.Stockless
                        : InvalidTypes.Success;

    const islowline = invalidType == InvalidTypes.UserAddressDelivered || invalidType == InvalidTypes.StockNotEnough || invalidType == InvalidTypes.Stockless;

    if (invalidType !== InvalidTypes.Success) {
        return (
            <div
                className={
                    'ta_c ps_a cl_fff mask ' +
                    (islowline ? 'low_l' : '')
                }
            >
                {InvalidTextMap[invalidType]}
            </div>
        );
    }
    return null;
};


export const CheckItem = ({
    isInvalid,
    skuData,
    onCartItemCheck,
    trackParam
}) => {
    const selected = skuData.selected;
    const disable = skuData.disabled || isInvalid;
    const logName = !selected ? 'choose_goods_click' : 'not_choose_goods_click';
    return (
        <div app-track={logName} app-track-params={trackParam} className="cart-column item_check">
            <Checkbox
                disabled={disable}
                checked={selected}
                onChange={() => {
                    !disable && onCartItemCheck(skuData);
                }}
            />
        </div>
    );
};

export class CartItem extends Component<CartInfoProps, {}> {
    static defaultProps = {
        isInvalid: false,
        isEditMode: false,
        hasMemberPrice: false
    };

    onNumberClick = (logTxt, value, params) => {
        // log.makeLog(logTxt, {
        //     ...params,
        //     num: value
        // });
    };

    renderSpec() {
        let {
            skuData: { spec }
        } = this.props;
        spec = JSON.parse(spec);
        return Array(spec.levels || 0)
            .fill(null)
            .map((it, idx) => {
                const keyName = idx + 1;
                return (
                    <p key={`spec${idx}`} className="cl_999 to_e2">
                        {spec[`level${keyName}Key`]}：
                        {spec[`level${keyName}Spec`]}
                    </p>
                );
            });
    }

    render() {
        const {
            cartType,
            sellerId,
            skuData,
            changeItemNum,
            isInvalid,
            // noBorder,
            // isEditMode,
            deleteItems,
            // hasMemberPrice,
            // isPreviousActivity,
            onCartItemCheck
        } = this.props;
        const {
            id,
            skuId,
            spuId,
            storeId,
            activityTagId = 0,
            img,
            detailTitle,
            price,
            tags,
            count,
            skuNum,
            stock,
            // savePrice = 0,
            orderQty,
            orderQtyMin,
            disabled,
            selected,
            // memberPrice,
            // dotInfoDTO
        } = skuData;
        const isHideNumber = disabled || isInvalid;
        // const invalidClass = isInvalid ? 'invalid' : '';
        const maxNum = Math.min(orderQty, stock);
        const tagObj = JSON.parse(tags || '{}');
        let imgTag = '';
        try {
            imgTag =
                tagObj.displayTag &&
                JSON.parse(tagObj.displayTag[0]).images.spuPrimary.src;
        } catch (error) {
            console.log(error);
        }
        const isO2O = cartType === CartTypes.O2O;
        const param = {
            spu_id: spuId,
            sku_id: skuId,
            store_id: storeId || 0,
            seller_id: sellerId || 0
        };
        const trackParam = JSON.stringify({
            ...param,
            isvalid: !isInvalid,
            activitylabelid: activityTagId
        });
        // const isVip = !!memberPrice && hasMemberPrice;

        return (
            <div
                className={`cart_item_box clearfix ${(selected && !isInvalid) ? 'checked' : ''}`}
                data-rowid={id}
            // {...log.createSpmProps('sku', {
            //     isSubModule: true,
            //     materialId: skuId,
            //     materialType: 'sku',
            //     materialTransferType: 'generate',
            //     pageTransferType: 'product_detail',
            //     materialSource: dotInfoDTO.spm,
            //     url: `/item/${spuId}/${storeId}`,
            //     isInvalid
            // })}
            >
                <CheckItem
                    trackParam={trackParam}
                    isInvalid={isInvalid}
                    skuData={skuData}
                    onCartItemCheck={onCartItemCheck}
                />
                <div
                    className="cart-column cart_item"
                    app-link={`/item/${spuId}`}
                    app-track="goods_click"
                    app-track-params={trackParam}
                >
                    <div className="cart-column img_box">
                        <TFSImage src={img} alt="" width={180} height={180} />
                        {imgTag ? (
                            <TFSImage
                                className="tag_icon"
                                src={imgTag}
                                alt=""
                            />
                        ) : null}
                        <Mask skuData={skuData} isInvalid={isInvalid} />
                    </div>
                    <div className="cart-column item_name to_e2">
                        {detailTitle}
                    </div>
                </div>
                <div
                    className={
                        `cart-column spec cl_999` +
                        (!isInvalid && !isO2O && skuNum > 1
                            ? ' on'
                            : '')
                    }
                >
                    {this.renderSpec()}
                </div>
                <div className="cart-column price">￥{price / 100}</div>
                <div className="cart-column mm">
                    <NumberModifier
                        className="cart_number_modifier"
                        value={count || 1}
                        disableMinus={isHideNumber}
                        disablePlus={isHideNumber}
                        min={orderQtyMin || 1}
                        max={maxNum}
                        onMinusClick={(value) => {
                            this.onNumberClick(
                                'minus_click',
                                value,
                                param
                            );
                        }}
                        onInputClick={(value) => {
                            this.onNumberClick(
                                'input_num_click',
                                value,
                                param
                            );
                        }}
                        onPlusClick={(value) => {
                            this.onNumberClick(
                                'plus_click',
                                value,
                                param
                            );
                        }}
                        onError={(type, min, max) => {
                            type === 1
                                ? toast.showToast(
                                    `最少购买${min}件!`
                                )
                                : toast.showToast(
                                    `最多添加${max}件`
                                );
                        }}
                        onChange={(num) => {
                            count !== num &&
                                changeItemNum({
                                    skuData,
                                    num,
                                    id,
                                    storeId
                                });
                        }}
                    />
                </div>
                <div className="cart-column priceTotal">￥{price * count / 100}</div>
                <div className="cart-column operate" onClick={() => deleteItems([id])}>删除</div>
            </div>
        );
    }
}

