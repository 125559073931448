var React=window.Snowball._React;
import { Header, Menu, Footer } from "jkmall-frame";
import Modules from "../../modules/Modules";
import Main from "../../../shared/components/Main";
var { inject, mapViewModelToProps }=window.Snowball._app;

function Marketing(props) {
    const { attrs = {} } = props.pageData;
    const { pageBgColor, pageBgImage } = attrs;

    return (
        <div className="app-wrapper-gray" style={{ backgroundColor: pageBgColor, backgroundImage: `url("${pageBgImage}")` }}>
            <Header />
            <Menu />
            <Main>
                <Modules />
            </Main>
            <Footer />
        </div>
    );
}

export default inject(mapViewModelToProps("decorationViewModel"))(Marketing);
