export const ORDER_STATUS = {
    "WAITING_PAY": "等待买家付款",
    "WAITING_ACCEPT": "待接单",
    "WAITING_DELIVERY": "待发货，仓库正在快马加鞭打包中…",
    "SHIPPING": ["商家已发货，等待买家确认", "请您确认收货，超时后订单会自动确认收货"],
    "CLOSED": "已关闭",
    "CANCEL": "已取消",
    "FINISH": "已完成",
    "REFUNDING": "退款中",
    "REFUNDED": "已退款",
    "ORDERED": "已下单",
    "PAID": "已支付",
    "WAITING_TO_APPROVE": "待审核",
    "APPROVE_FAIL": "审核失败",
    "APPROVE_SUCCESS": "审核成功",
    "ORDER_SUCCESS": "预订成功",
    "ORDER_CLOSED": "交易关闭",

    // 处方2.0增加的订单状态
    "WAITING_TO_APPROVE_HP": "待审核订单",

    // 拼团订单增加的订单状态
    "GROUPING": "已付款",
    "GROUPED_WAITING_DELIVERY": "拼团成功，待发货",
    "GROUPED_DELIVERED": "拼团成功，已发货",
    "GROUP_SUCCESS": "拼团成功，已完成",
    "GROUP_SUCCESS_REFUNDED": "拼团成功，已退款",
    "GROUP_FAIL_REFUNDED": "拼团失败，已退款",

    // 拼团二期70商品添加订单状态
    "CHARGING": "充值中",
    "CHARGE_SUCCESS": "充值成功",
};