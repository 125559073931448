var { jsonp }=window.Snowball.util;

export function getLocation(cb) {
    return {};
}

// 根据经纬度获得地址信息` longitude 经度   latitude纬度
// export function getAddressInfoByLonLat(longitude, latitude, output = "json", pois = 1, coordtype = 'bd09ll') {
//     return jsonp(`https://api.map.baidu.com/geocoder/v2/?`, {
//         ak: 'lVFQypEcfxcpQvdRipQGl3vkMfjSU7Ny',
//         location: `${latitude},${longitude}`,
//         output,
//         pois,
//         coordtype,
//     });
// }

var BAIDU_API_KEY = ['lVFQypEcfxcpQvdRipQGl3vkMfjSU7Ny'];

export async function getSuggestAddress(
    keywords,
    longitude,
    latitude,
    region = '全国'
) {
    var random = parseInt(Math.random() * BAIDU_API_KEY.length, 10);
    var ak = BAIDU_API_KEY[random];
    var params = {
        q: keywords,
        output: 'json',
        city_limit: true,
        region: region,
        ak: ak
    };
    if (longitude && latitude) {
        params.location = `${latitude},${longitude}`;
    }
    var data = await jsonp(
        '//api.map.baidu.com/place/v2/suggestion?',
        params
    );
    if (data && data.result) {
        return data.result;
    } else {
        return [];
    }
}