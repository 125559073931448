var { configuration }=window.Snowball._app;

import CheckoutViewModel from './view-models/CheckoutViewModel';
import BeforeCheckoutViewModel from './view-models/BeforeCheckoutViewModel';

export const CheckoutConfiguration = configuration({
    modules: {
        checkoutViewModel: CheckoutViewModel,
        beforeCheckoutViewModel: BeforeCheckoutViewModel,
    }
});