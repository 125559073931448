var { observable }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
var { toast }=window.Snowball._widget;
import AddressEditorModalViewModel from "./AddressEditorModalViewModel";
import AddressApiService from "../../../shared/apis/AddressApiService";

export default class AddressViewModel extends ViewModel {

    @autowired
    _addressApiService: AddressApiService;

    @autowired
    _addressEditorModalViewModel: AddressEditorModalViewModel;

    @observable
    addressList = [];

    constructor() {
        super();

        this._addressEditorModalViewModel.onDidSave(() => {
            this.loadAllAddresses();
        });
    }

    @emitter
    onAddAddress() {
        if (this.addressList.length >= 20) {
            toast.showToast('地址数量不可超过20条!');
            return;
        }
        this._addressEditorModalViewModel.show({});
    }

    async loadAllAddresses() {
        const result = await this._addressApiService.getAllAddressesByBiz('mall');
        let addressList = result.success ? result.data.value || [] : [];
        this.addressList = addressList;
    }

    async setDefaultAddress(address) {
        const data = Object.assign({}, address, { preferred: true });
        const result = await this._addressApiService.saveOrUpdateAddress(data);
        if (result.success) {
            toast.showToast('设置成功');
            this.loadAllAddresses();
        }
    }

    modifyAddress(address) {
        this._addressEditorModalViewModel.show(address);
    }

    async deleteAddress(address) {
        const result = await this._addressApiService.deleteAddressById(address.id);
        if (result.success) {
            toast.showToast('删除成功');
            this.loadAllAddresses();
        }
    }
}