var React=window.Snowball._React;var { Component }=window.Snowball._React;
import { CartItemProps } from '../constant/types';
var { inject }=window.Snowball._app;
import { CartItem } from './';

type CartListProps = {
    // 无效购物车列表
    list: CartItemProps[],
    onClear: () => void
};

export class InvalidCartList extends Component<CartListProps, {}> {
    render() {
        const {
            list,
            deleteItems,
            onClear
        } = this.props;
        if (!list.length) {
            return null;
        }
        return (
            <div className="invalid_list">
                <div className="invalid_line clearfix">
                    <span className="icons-invalid" />
                    <span className="fs_m cl_333 ml_s">失效商品</span>
                    <p
                        className="empty_btn"
                        onClick={onClear}
                    >
                        清空失效商品
                    </p>
                </div>
                <div className="of_h">
                    {list.map((item, i) =>
                        <CartItem
                            key={'' + item.id + item.skuId}
                            skuData={item}
                            deleteItems={deleteItems}
                            isInvalid
                        />)}
                </div>
            </div>
        );
    }
}

export const InvalidCartListInjector = inject(({ deleteItems, getInvalidList, clearInvalidItems }) => ({
    list: getInvalidList(),
    deleteItems,
    onClear: clearInvalidItems,
}))(InvalidCartList);
