var ReactDOM=window.Snowball._ReactDOM;
var React=window.Snowball._React;var { Component }=window.Snowball._React;
var { util }=window.Snowball;
var { PageContext }=window.Snowball._app;
import { Template } from "../../../snowball.template";

function toJSON(a, b, c, d, e, f) {
    return JSON.stringify(Object.assign(a, b, c, d, e, f));
}

type ModuleProps = {
    // 模块索引
    index: number,
    // 模块ID
    logicId: number,
    // 页面数据
    pageData: any,
    // 模版数据
    template: any,
    // 模块数据
    data: any,
    // 模块更新事件
    onModuleUpdate?: () => never,
    // 模块加载完成事件
    onModuleLoad: (e: { target: any, data: any }) => never,
};

/**
 * 模块继承接口，模块初始化执行方法的调用顺序为 initialize -> patchData -> onCreate -> onLoad
 * onLoad之后视图变更会触发onUpdate
 */
interface IModule {
    // 初始化时执行
    initialize?: () => never | Promise<any>,
    // 进行数据的处理，模块初始化和props.bizData变化后触发
    patchData?: () => never | Promise<any>,
    // 设置spm信息
    updateSpm?: () => never,
    // 模块初始化成功后执行（界面未渲染完成）
    onCreate?: () => never | Promise<any>,
    // 模块初始化并渲染完成后执行
    onLoad?: () => never,
    // 模块更新完成后触发
    onUpdate?: () => never,
    // 模块销毁时触发
    onDestroy?: () => never,
}

/**
 * 所有模块的基类，子类需要自己实现 `IModule` 接口中的方法
 */
export default class ModuleBase extends Component<ModuleProps, any> implements IModule {

    static contextType = PageContext;

    constructor(props: ModuleProps, context) {
        super(props, context);

        this.logicId = props.logicId;
        this.templetId = props.data.id;
        this.create(props);
    }

    async create(props) {
        var { data, template, address, templateHTML } = props;
        var { extProps, tempHtml, tempCss } = template;

        if (tempCss) {
            util.style(template.id + "_" + template.version, tempCss);
        }

        // 首屏模块和浮动模块不用image lazyload
        tempHtml = (templateHTML || tempHtml).replace(/\s+sn-src=/g,
            !props.enableImageLazyLoad || extProps.isFixed || extProps.isFixedBar
                ? ' sn-image='
                : ' data-src=');

        this.model = new Template({
            el: `<div sn-if="{isShow}" class="clearfix ${extProps.isFixed || extProps.overflow ? '' : 'of_h'}{bizData.topMargin?' bk_mt':''}{bizData.bottomMargin?' bk_mb':''}{bizData.sideMargin?' bk_mlr':''}{bizData.topPadding?' bk_pt':''}{bizData.bottomPadding?' bk_pb':''}{bizData.sidePadding?' bk_plr':''}">${tempHtml}</div>`,
            attributes: {
                isShow: true,
                dataId: data.id,
                propsVal: data.propsVal,
                env: this.context.app.env,
                extProps,
                address,
                toJSON,
                ...data.model
            }
        });
        this.model.delegate = this;

        this.bizData = data.bizData;
        this.patchPageData(props);

        await this.initialize(props);
        await this.patchData(this.model, this.bizData);

        this.model.nextTick(() => {
            this.updateSpm();
            if (this.container) {
                this.container.setAttribute('data-spm', data.logicId);
                this.container.setAttribute('data-spm-cnt', JSON.stringify({
                    moduleIndex: this.props.index
                }));
            }

            this.props.onModuleLoad && this.props.onModuleLoad({
                target: this,
                data: this.props.data
            });
            this.onLoad && this.onLoad();
            this.model.on('viewDidUpdate', () => {
                if (this.autoloadImage) {
                    this.props.detectLazyLoadImage();
                }
                this.emitUpdate();
                this.updateSpm();
            });
        });
        this.onCreate && await this.onCreate();
    }

    initialize() { }

    patchPageData(props) {
        const { pageData } = props;
        if (pageData) {
            this.model.set({
                pageData: this.pageData
            });
        }
    }

    updateSpm() { }

    patchData() { }

    setRef = (ref) => {
        this.container = ref;
        if (ref) {
            this.model.appendTo(ref);
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    async componentWillReceiveProps(nextProps) {
        this.container.setAttribute('data-spm', nextProps.data.logicId);
        this.container.setAttribute('data-spm-cnt', JSON.stringify({
            moduleIndex: nextProps.index
        }));

        if (nextProps.pageData !== this.props.pageData) {
            this.patchPageData(nextProps);
        }
        if (!util.equals(nextProps.data.bizData, this.props.data.bizData) || nextProps.data.extData !== this.props.data.extData) {
            this.bizData = nextProps.data.bizData;
            await this.patchData(this.model, this.bizData);
        }
        this.onComponentUpdate && this.onComponentUpdate();
    }

    componentWillUnmount() {
        this.model.$el.remove();
        this.model.destroy();
        try {
            this.onDestroy && this.onDestroy();
        } catch (error) {
            console.error(error);
        }
    }

    emitUpdate() {
        this.props.onModuleUpdate && this.props.onModuleUpdate({
            data: this.props.data,
            target: this
        });
        this.onUpdate && this.onUpdate();
    }

    renderSubtreeIntoContainer(element, container) {
        if (!ReactDOM.unstable_renderSubtreeIntoContainer) {
            const context = this.context;
            ReactDOM.render(<PageContext.Provider value={context}>{element}</PageContext.Provider>, container);
        } else {
            ReactDOM.unstable_renderSubtreeIntoContainer(this, element, container);
        }
    }

    render() {
        return (
            <div
                ref={this.setRef}
                app-anchor-name={"@" + this.props.data.logicId}
                className={this.props.template.extProps.className || ''}
            ></div>
        );
    }
}
