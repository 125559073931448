var React=window.Snowball._React;

export default ({
    disabled = false,
    checked = false,
    onChange,
    children,
    className = ''
}) => {
    // 无子节点的情况下使用更简洁的方式
    if (React.Children.toArray(children).length === 0) {
        return (
            <div className="cm_checkbox">
                <div
                    onClick={() => {
                        !disabled && onChange && onChange();
                    }}
                    className={`cm_checkbox__inner${checked ? ' is_checked' : ''}${
                        disabled ? ' is_disabled' : ''
                    } ${className || ''}`}
                />
            </div>
        );
    }

    return (
        <label className="cm_checkbox">
            <div className="cm_checkbox__input">
                <span
                    className={`cm_checkbox__inner${
                        checked ? ' is_checked' : ''
                    }${disabled ? ' is_disabled' : ''} ${className || ''}`}
                />
                <input
                    className="cm_checkbox__original"
                    disabled={disabled}
                    checked={checked}
                    onChange={onChange}
                    type="checkbox"
                />
            </div>
            <div className="cm_checkbox__label">{children}</div>
        </label>
    );
};
