/* eslint-disable react-hooks/exhaustive-deps */
var React=window.Snowball._React;var { useEffect, useCallback, useState }=window.Snowball._React;
var { throttle }=window.Snowball.util;
import "../../sass/shared/anchor.scss";

export default function Anchor(props) {
    const { bgColor = "rgba(0,0,0,0.6)", textColor = "#FFFFFF", activeColor = "#FF6F00", items = [] } = props;

    const [hoverIndex, setHoverIndex] = useState(-1);
    const [curIndex, setCurIndex] = useState(0);
    const [showBackTop, setShowBackTop] = useState(false);

    const backTop = useCallback(() => {
        window.scrollTo(0, 0);
    }, []);

    const jumpItem = useCallback((item) => {
        if (item.link) {
            this.app.navigation.forward(item.link);
        } else {
            const selector = `[${item.tag.name}='${item.tag.value}']`;
            const elem = document.querySelector(selector);
            if (elem) {
                const rect = elem.getBoundingClientRect();
                document.documentElement.scrollTop += (rect.top + 2);
                document.body.scrollTop += (rect.top + 2);
            }
        }
    });

    useEffect(() => {
        const onScroll = throttle((e) => {
            let minScrollTop = 0;
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

            const pageHeight = document.documentElement.offsetHeight;
            setShowBackTop(scrollTop > pageHeight / 2);

            for (const [index, item] of Object.entries(items)) {
                if (item.tag) {
                    const selector = `[${item.tag.name}='${item.tag.value}']`;
                    const elem = document.querySelector(selector);
                    if (elem && elem.getBoundingClientRect) {
                        const { top } = elem.getBoundingClientRect();
                        if (top <= 0) {
                            if (minScrollTop == 0) {
                                minScrollTop = top;
                                setCurIndex(index);
                            } else {
                                if (top > minScrollTop) {
                                    setCurIndex(index);
                                }
                            }
                        }
                    }
                }
            }
            if (minScrollTop == 0) {
                setCurIndex(0);
            }
        }, 100);

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <div className="cm_anchor" style={{ backgroundColor: bgColor }}>
            <ul>
                {items.map((item, idx) => (
                    <li
                        className="ps_r"
                        onClick={() => jumpItem(item)}
                        style={{
                            color: textColor,
                            backgroundColor: idx == hoverIndex || idx == curIndex ? activeColor : "",
                        }}
                        onMouseEnter={() => setHoverIndex(idx)}
                        onMouseLeave={() => setHoverIndex(-1)}
                    >
                        {curIndex == idx ? <i className="iconfont icon-location1"></i> : null}
                        {item.text}
                    </li>
                ))}
            </ul>
            {showBackTop ? (
                <div className="backTop" style={{ color: textColor }} onClick={backTop}>
                    <i className="icon-up iconfont"></i>
                    <p>返回顶部</p>
                </div>
            ) : null}
        </div>
    );
}
