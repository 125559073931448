/* eslint-disable */
export function parseOrderJSONParams(paramStr) {
    if (!paramStr) return [];
    let params = JSON.parse(paramStr);
    params.map(seller => {
        seller.skus = seller.skus || [];
        seller.bundleItems = seller.bundleItems || [];
        return seller;
    });
    return params;
}