var React=window.Snowball._React;
import { transformTFSImageUrl } from '../../../core/tfs';

const CategoryNav = (props) => {
    const {
        activedNavCategoryId,
        firstCategoryList = [],
        onNavItemMouseEnter: onHover
    } = props;

    return (
        <ul className="mall_common__category_first_list">
            {
                firstCategoryList.map((item) => {
                    return (
                        <li
                            key={item.id}
                            onMouseEnter={() => { onHover && onHover(item.id); }}
                            className={activedNavCategoryId === item.id ? 'mall_common__category_first_list_item_active' : ''}
                        >
                            <span
                                className="mall_common__category_first_list_item_icon"
                            >
                                <img alt="" src={transformTFSImageUrl(item.img)} />
                            </span>
                            <span
                                className="mall_common__category_first_list_item_title"
                            >{item.name}</span>
                            <span className="mall_common__category_first_list_item_next"></span>
                        </li>
                    );
                })
            }
        </ul>
    );
};

export default CategoryNav;