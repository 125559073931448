var { observable }=window.Snowball;
var { ViewModel, autowired }=window.Snowball._app;
import OrderAppResourceService from "../services/OrderAppResourceService";
import UnicornApiService from "../../../shared/apis/UnicornApiService";
var { toast }=window.Snowball._widget;
import OrderOperationHandlers from "./OrderOperationHandlers";

export default class OrderInfoViewModel extends ViewModel {
    @observable
    address = {};

    @observable
    invoice = {};

    @observable
    tradeOrder = {};

    @observable
    buttonStatus = {};

    @observable
    orderOutBizInfo = {};

    @observable
    lgOrder = {};

    @observable
    isCod = false;

    @observable
    postFeeName = '';

    @observable
    isTopTipsVisible = true;

    get topTips() {
        return this._orderAppResourceService.topTips;
    }
    onCloseTopTips = this.ctx.createEmitter();

    @observable
    showSelfService = this.ctx.app.env.APP_ENV != 'HFL' && this.ctx.app.env.APP_ENV != 'HRX';

    onLoad = this.ctx.createEmitter();

    @autowired
    _orderAppResourceService: OrderAppResourceService;

    @autowired
    _unicornApiService: UnicornApiService;

    @autowired
    _orderOperationHandlers: OrderOperationHandlers;

    get documents() {
        return this._orderAppResourceService.documents;
    }

    get deliveryTime() {
        let occurTime = 0;
        const lgOrderList = this.lgOrder || {};
        if (lgOrderList.lgTraces && Array.isArray(lgOrderList.lgTraces) && lgOrderList.lgTraces.length >= 0) {
            lgOrderList.lgTraces.forEach(function (item) {
                if (item.content.indexOf('已发货') != -1) {
                    occurTime = item.occurTime;
                    return;
                }
            });
        }
        return occurTime;
    }

    constructor() {
        super();

        this.onCloseTopTips(() => {
            this.isTopTipsVisible = false;
        });
        this._orderOperationHandlers.onDidDeleteOrder(() => {
            this.app.navigation.replace('/order-list/all');
        });
        this._orderOperationHandlers.onDidConfirmReceipt(() => {
            this.reloadOrder();
        });
        this._orderOperationHandlers.onOrderStatusChange(() => {
            this.reloadOrder();
        });
    }

    async init(orderId) {
        this.orderId = orderId;
        this._orderAppResourceService.loadTopTips();

        Promise.all([
            this._orderAppResourceService.loadCommonAppResources(),

            this._unicornApiService.queryOrderForBuyer(orderId)
                .then(res => {
                    if (!res.success) {
                        toast.showToast(res.message);
                    } else {
                        this._setData(res.data);
                        this.onLoad.emit(res.data);
                    }
                })
        ])
            .then(() => {
                let { source } = this.tradeOrder;
                // 会员限时兑换，19年12月份以后产生的订单进行运费文案调整
                if (source == 'bbdjqe' && this.tradeOrder.createTime >= 1575129600) {
                    let freightInfo;
                    const freightText = (this.documents.find(doc => doc.keyName == 'duojin-exchange-freight') || {}).val;
                    if (freightText) {
                        try {
                            freightInfo = JSON.parse(freightText);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                    this.postFeeName = freightInfo && freightInfo.text ? freightInfo.text : '运费';
                } else {
                    this.postFeeName = '运费';
                }
            });
    }

    reloadOrder() {
        this.orderId && this.init(this.orderId);
    }

    _setData(data) {
        this.invoice = data.invoice;
        this.address = data.address;
        this.tradeOrder = data.tradeOrder;
        this.buttonStatus = {
            ...data.buttonStatus,
            buyAgainButton: false,
            statusListButton: false,
            evaluableButton: false
        };

        const { tradeExtMaps } = data.tradeOrder;
        if (tradeExtMaps) {
            for (var i = 0; i < tradeExtMaps.length; i++) {
                const tradeExtMap = tradeExtMaps[i];
                if (tradeExtMap.key == 'outBizInfo') {
                    this.orderOutBizInfo = JSON.parse(tradeExtMap.value);
                } else if (isCod(tradeExtMap)) {
                    this.isCod = true;
                }
            }
        }
    }
}

function isCod(tradeExtMap) {
    return tradeExtMap.key == 'orderTag' && tradeExtMap.value == 'CodRx';
}