
var React=window.Snowball._React;var { useState, useEffect }=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
var { util }=window.Snowball;

const DistrictSelector = inject(mapViewModelToProps('areaSelectorViewModel'))(
    ({
        value = [],
        onChange,
        onProvinceChange,
        onCityChange,
        provinces,
        cities,
        districts,
    }) => {
        const [initProvinceCode, initCityCode, initDistrictCode] = value;
        const [currentTab, setTab] = useState(initDistrictCode ? 2 : initCityCode ? 1 : 0);

        const [currentProvinceCode, setProvinceCode] = useState(initProvinceCode);
        const [currentCityCode, setCityCode] = useState(initCityCode);
        const [currentDistrictCode, setDistrictCode] = useState(initDistrictCode);

        // 受控组件，同步value
        useEffect(() => {
            setProvinceCode(initProvinceCode);
            setCityCode(initCityCode);
            setDistrictCode(initDistrictCode);
            setTab(initDistrictCode ? 2 : initCityCode ? 1 : 0);
        }, [initProvinceCode, initCityCode, initDistrictCode]);

        const [currentList, currentAreaCode, onSelect] = currentTab == 0
            ? [provinces, currentProvinceCode, (area) => {
                setProvinceCode(area.code);
                setCityCode('');
                setDistrictCode('');
                setTab(1);
                onProvinceChange(area.code);
            }]
            : currentTab == 1
                ? [cities, currentCityCode, (area) => {
                    setCityCode(area.code);
                    setDistrictCode('');
                    setTab(2);
                    onCityChange(area.code);
                }]
                : [districts, currentDistrictCode, (area) => {
                    setCityCode(area.code);
                    setDistrictCode('');
                    onChange([currentProvinceCode, currentCityCode, area.code]);
                }];

        const province = currentProvinceCode && util.get(provinces.find(item => item.code == currentProvinceCode), 'name');
        const city = currentCityCode && util.get(cities.find(item => item.code == currentCityCode), 'name');
        const district = currentDistrictCode && util.get(districts.find(item => item.code == currentDistrictCode), 'name');

        return (
            <div className="app-district-selector">
                <ul className="tab flex">
                    <li onClick={() => setTab(0)} className={currentTab == 0 ? 'curr' : ''}>{province || '省'}</li>
                    <li onClick={() => setTab(1)} className={currentTab == 1 ? 'curr' : ''}>{city || '市'}</li>
                    <li onClick={() => setTab(2)} className={currentTab == 2 ? 'curr' : ''}>{district || '区'}</li>
                </ul>
                <ul className="list clearfix">
                    {
                        currentList.map((area, index) => {
                            return <li
                                key={`area${index}`}
                                className={"fl_l to_e " + (currentAreaCode == area.code ? 'curr' : '')}
                                onClick={() => onSelect(area)}
                            >{area.name}</li>;
                        })
                    }
                </ul>
            </div>
        );
    }
);

export default DistrictSelector;