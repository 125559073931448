var { util }=window.Snowball;
import * as env from '../../../env';


export function getNormalSkus(seller, excludeInvalidItems, urlParams) {
    return (
        seller.items
            // 过滤掉非正常状态的商品
            .filter(item =>
                excludeInvalidItems
                    ? !item.status || item.status == 0
                    : true
            )
            .map(item => {
                let trace = '';
                const urlSeller = urlParams.find(
                    urlSeller => urlSeller.sellerId == seller.sellerId
                );
                if (urlSeller) {
                    const urlItem = urlSeller.skus.find(
                        prd => prd.skuId == item.skuId
                    );
                    trace = urlItem ? urlItem.trace : '';
                }
                return {
                    skuId: item.skuId,
                    amount: item.piece,
                    trace,
                    isGive: item.isGive,
                    cartKey: item.cartKey || '',
                    spm: item.spm
                };
            })
    );
}

export function getNormalBundleItems(seller, excludeInvalidItems, urlParams) {
    return (
        seller.bundleItems && seller.bundleItems
            // 过滤掉非正常状态的商品
            .filter(item =>
                excludeInvalidItems
                    ? !item.status || item.status == 0
                    : true
            )
            .map(item => {
                let trace = '';
                const urlSeller = urlParams.find(
                    urlSeller => urlSeller.sellerId == seller.sellerId
                );
                if (urlSeller) {
                    const urlItem = urlSeller.bundleItems.find(
                        bundle => bundle.comboId == item.comboId
                    );
                    trace = urlItem ? urlItem.trace : '';
                }
                return {
                    comboId: item.comboId,
                    count: item.count,
                    spm: item.spm,
                    trace,
                    cartKey: item.cartKey || '',
                    skus: item.items.map((sku) => ({
                        skuId: sku.skuId,
                        amount: sku.piece,
                        freightInsurance: sku.freightInsurance,
                        isGive: sku.isGive
                    }))
                };
            })
    );
}

export function getSubSellerSkus(subSellers, excludeInvalidItems, urlParams) {
    if (subSellers && subSellers.length) {
        // 可能存在多个分仓信息，出现一个Seller对象中有多条subSellers
        let skus = [];
        subSellers.forEach(sub => {
            skus.push(...getNormalSkus(sub, excludeInvalidItems, urlParams));
        });
        return skus;
    }
    return [];
}

export function getSkus(seller, excludeInvalidItems, urlParams) {
    if (seller.subSellers && seller.subSellers.length) {
        return getSubSellerSkus(seller.subSellers, excludeInvalidItems, urlParams);
    }
    return getNormalSkus(seller, excludeInvalidItems, urlParams);
}

export function getSubSellerBBS(subSellers) {
    let bbsList = [];
    if (subSellers && subSellers.length) {
        subSellers.forEach(sub => {
            if (sub.bbs) {
                bbsList.push({
                    wareHouseId: sub.wareHouseId,
                    bbs: sub.bbs
                });
            }
        });
    }
    return bbsList;
}

export function getFund(params) {
    const { fundAttrs, withCoupon, enableInvoice, invoiceAttrs } = params;
    let fund = {
        healthGoldSwitch: fundAttrs.healthGold.healthGoldSwitch,
        healthStarSwitch: fundAttrs.healthStar.healthStarSwitch,
        healthLifeSwitch: fundAttrs.healthLife.healthLifeSwitch,
        paymentType: fundAttrs.paymentType,
        cash: fundAttrs.cash
    };

    if (withCoupon) {
        fundAttrs.couponCode != null &&
            (fund.couponCode = fundAttrs.couponCode);
        fundAttrs.redCouponCode != null &&
            (fund.redCouponCode = fundAttrs.redCouponCode);
    }

    if (enableInvoice) {
        fund.invoice = {
            ...invoiceAttrs,
            isNeed: !!invoiceAttrs.title
        };
        // 纸质发票 不传手机号
        if (invoiceAttrs.mode == 0) {
            delete fund.invoice.receiverMobile;
        }
    }

    return fund;
}

export function getBiz(params) {
    const {
        orderAttrs,
        groupId,
        callbackUrl,
        isFirst,
        from,
        orderPrescriptionAttrs,
        jkCode,
        tcode,
        isDirectPay,
        flowLimited,
    } = params;
    const biz = {
        tel: orderAttrs.tel,
        codRxImg: orderAttrs.codRxImg,
        frType: from,
        groupId,
        callBackUrl: callbackUrl || '',
        outBizInfo: util.queryString('outBizInfo') || '',
        isFirst,
        rechargeTel: orderAttrs.rechargeTel || '',
        jkCode,
        tcode,
        isDirectPay,
        isWX: env.IS_WX,
        flowLimited,
    };

    let proxyCode;

    // 代理人小店分佣金统一使用url上proxyCode参数
    if (env.OUT_BIZ_TYPE === 'shzs' && (proxyCode = util.queryString('proxyCode'))) {
        biz.hCode = proxyCode;
    } else {
        const hCode =
            util.queryString('userHcode') ||
            sessionStorage.getItem('cbwUserHcode');
        if (hCode) {
            biz.hCode = hCode;
        }

        if (env.IS_SHOUXIAN) {
            // 生活助手
            if (util.queryString('subChannel') == 'shzs') {
                const securityKey = localStorage.getItem('shzs.securityKey');
                const securityParam = localStorage.getItem(
                    'shzs.securityParam'
                );
                if (securityKey) {
                    biz.securityKey = securityKey;
                }
                if (securityParam) {
                    biz.securityParam = securityParam;
                }
            }
            // 购健康
            else {
                const hCode = localStorage.getItem('agentCode');
                if (hCode) {
                    biz.hCode = hCode;
                }
            }
        }
    }

    // 新处方单
    if (orderAttrs.isNewPrescriptionModel === true) {
        const { havePrescription: isCodRxImg, isDiagnosed: isCodRxCase, disease, medicalRecordImgs, prescriptionImgs } = orderPrescriptionAttrs;
        const userCodRxcases = disease.filter((item) => {
            return item.isSelect === true;
        }).map((item) => {
            return item.name;
        });

        let codRxCaseImg = '';
        if (medicalRecordImgs.length > 0) {
            codRxCaseImg = medicalRecordImgs.map((item) => {
                return item.key;
            });
            codRxCaseImg = codRxCaseImg.join(',');
        }

        let codRxImg = '';
        if (prescriptionImgs.length > 0) {
            codRxImg = prescriptionImgs.map((item) => {
                return item.key;
            });
            codRxImg = codRxImg.join(',');
        }

        Object.assign(biz, {
            isCodRxImg,
            isCodRxCase,
            userCodRxcases,
            codRxCaseImg,
            codRxImg
        });
    }
    return biz;
}

export function getSeller(params) {
    const { sellerCollection, excludeInvalidItems, withCoupon, urlParams } = params;
    return sellerCollection
        .map(seller => {
            const sellerData = {
                sellerId: seller.sellerId,
                bbs: seller.bbs,
                skus: getSkus(seller, excludeInvalidItems, urlParams),
                warehouses: getSubSellerBBS(seller.subSellers),
                bundleItems: getNormalBundleItems(seller, excludeInvalidItems, urlParams),
            };
            if (withCoupon && seller.couponCode != null) {
                sellerData.couponCode = seller.couponCode;
            }
            return sellerData;
        })
        // 过滤掉无商品的商户
        .filter(seller =>
            excludeInvalidItems ? (seller.skus.length || seller.bundleItems.length) : true
        );
}

export function initSellers(sellers, sellerCollection) {
    return sellers.map(({ subSellers, items = [], bundleItems = [], ...item }, index) => {
        return ({
            ...item,
            items,
            bundleItems,
            subSellers: subSellers && subSellers.length
                ? subSellers.map((sub, subIndex) => {
                    const currentSubSellers = sellerCollection[index] && sellerCollection[index].subSellers
                        ? sellerCollection[index].subSellers
                        : [];
                    let id = 100 + subIndex;
                    const info = currentSubSellers && currentSubSellers.length
                        ? currentSubSellers.find(seller => seller.id === id)
                        : {};
                    return {
                        ...sub,
                        bbs: info.bbs || '',
                        id: id,
                    };
                })
                : [],
            bbs: util.get(sellerCollection.find(localSeller => localSeller.id == (index + 1)), ['bbs']) || '',
            id: index + 1
        });
    });
}

export function getCurrentSeller(sellerCollection, id) {
    let currentSeller = sellerCollection.find((seller) => seller.id == id);
    if (!currentSeller) {
        sellerCollection.each((seller) => {
            currentSeller = seller.subSellers.find((subSeller) => subSeller.id == id);
            if (currentSeller) {
                return;
            }
        });
    }
    return currentSeller;
}