import { request } from './request';
import { OUT_BIZ_TYPE } from '../../env';

export function getCartNum() {
    return request("piggy.getBizCartNum", true, {
        outBizType: OUT_BIZ_TYPE
    }, {
        isShowLoading: false,
        autoLogin: false
    });
}