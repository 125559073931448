var { Service }=window.Snowball._app;

export default class HeadlineApiService extends Service {
    getHeadinfoDetail(infoId) {
        return this.app.gateway.request('headline.getHeadinfoDetail', true, {
            infoId
        });
    }

    addPraiseToInfo(infoId) {
        return this.app.gateway.request('headline.addPraiseToInfo', true, {
            infoId
        });
    }
}