var React=window.Snowball._React;
import { forward } from '../../../core/navigation';

const MyPajk = (props) => {
    const { couponCount } = props;

    return (
        <div className="mall_common__header__my_pajk">
            <div className="mall_common__header__my_pajk_shop">
                <span>
                    我的
                    <i className="mall_common__header__my_pajk_shop_arrow_icon"></i>
                </span>
                <ul className="mall_common__header__my_pajk_dropdown_list">
                    <li onClick={() => { forward('/my-coupon'); }}>
                        <i className="mall_common__header__my_pajk_shop_coupon_icon"></i>
                        我的优惠券{couponCount ? '' : ''}
                    </li>
                    <li onClick={() => { forward('/order-list/all'); }}>
                        <i className="mall_common__header__my_pajk_shop_order_icon"></i>
                        我的订单
                    </li>
                    <li onClick={() => { forward('/address'); }}>
                        <i className="mall_common__header__my_pajk_shop_address_icon"></i>
                        我的地址
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default MyPajk;