var React=window.Snowball._React;
import { forward } from '../../../core/navigation';

const LoginInfo = (props) => {
    const { isLogin, nickName, logout } = props;

    return (
        <div className="mall_common__header__login_info">
            <div className="mall_common__header__welcome">欢迎来到平安健康互联网网上药店!</div>
            {
                isLogin
                    ? <div className="mall_common__header__user">
                        <div className="mall_common__header__user_nick_name">
                            {nickName}
                            <i className="mall_common__header__user_nick_name_arrow_icon"></i>
                        </div>
                        <ul className="mall_common__header__user_dropdown_list">
                            <li onClick={() => { forward('/person-info'); }}>
                                <i className="mall_common__header__user_info_icon"></i>
                                个人信息
                            </li>
                            <li onClick={() => { logout && logout(); }}>
                                <i className="mall_common__header__user_logout_icon"></i>
                                退出
                            </li>
                        </ul>
                    </div>
                    : <React.Fragment>
                        <div
                            onClick={() => { forward('/login'); }}
                            className="mall_common__header__login"
                        >登录</div>
                        <div
                            onClick={() => { forward('/login'); }}
                            className="mall_common__header__register"
                        >免费注册</div>
                    </React.Fragment>
            }
        </div>
    );
};

export default LoginInfo;