import { request } from './request';

export function getFrontCategory(params) {
    return request("unicorn.getFrontCategory", false, params, {
        isShowLoading: false,
        autoLogin: false
    });
}

export function getFirstLevelFrontCategory(params) {
    return request("unicorn.getFirstLevelFrontCategory", false, params, {
        isShowLoading: false,
        autoLogin: false
    });
}