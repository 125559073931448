var React=window.Snowball._React;
var { autowired, inject }=window.Snowball._app;
import PayPlatform from './PayPlatform';
import HealthCard from './HealthCard';
import { TAB_TPYE } from '../constants';

function Tab({
    title,
    selected,
    onClick,
}) {
    return <div className={`tab fl_l cs_p ${selected ? 'selected' : ''}`} onClick={onClick}>{title}</div>;
}

function Tabs({
    tabs,
    selectedTab,
    onTabClick,
}) {
    if (tabs.length == 0) {
        return null;
    }

    return (
        <div className="checkout_tabs">
            <div className="tab_header clearfix">
                {tabs.map((tab, index) => {
                    if (tab.payMethods.length == 0) {
                        return null;
                    }
                    return <Tab key={index} title={tab.title} selected={tab.selected} onClick={() => onTabClick(tab)} />;
                })}
            </div>
            <div className="tab_body">
                {
                    selectedTab && selectedTab.tabType == TAB_TPYE.PAY_PLATFORM
                        ? <PayPlatform />
                        : selectedTab && selectedTab.tabType == TAB_TPYE.HEALTH_CARD
                            ? <HealthCard />
                            : null
                }
            </div>
        </div>
    );
}

export default inject(() => {
    const {
        tabs,
        onTabClick,
    } = autowired('checkoutViewModel');

    const selectedTab = tabs.find(({ selected }) => !!selected);

    return {
        tabs,
        selectedTab,
        onTabClick,
    };
})(Tabs);
