var React=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
var ReactDOM=window.Snowball._ReactDOM;

function Consult({
    status,
    frameName,
    setRef
}) {
    const { visible, width, height } = status;
    if (!visible) {
        return null;
    }
    return ReactDOM.createPortal(
        <div className="consult_box">
            <iframe
                title="consult"
                name={frameName}
                ref={setRef}
                frameborder="0"
                width={width}
                height={height}
            />
        </div>,
        document.body
    );
}

export default inject(mapViewModelToProps('consultViewModel'))(Consult);