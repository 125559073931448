var { configuration }=window.Snowball._app;

import CartViewModel from './view-models/CartViewModel';
import CartRequester from './services/CartRequester';
import PatientViewModel from './view-models/PatientViewModel';
import PatientFormViewModel from './view-models/PatientFormViewModel';
import ConsultViewModel from '../consult/view-models/ConsultViewModel';

export const CartConfiguration = configuration({
    modules: {
        cartViewModel: CartViewModel,
        cartRequester: CartRequester,
        patientViewModel: PatientViewModel,
        patientFormViewModel: PatientFormViewModel,
        consultViewModel: ConsultViewModel,
    }
});