var React=window.Snowball._React;
var { inject, mapViewModelToProps, autowired }=window.Snowball._app;
import { BUY_TYPES } from "../constants";

export default inject(() => {
    const itemViewModel = autowired('itemViewModel');
    return {
        pres: itemViewModel.pres,
        ...mapViewModelToProps('skuBuyViewModel')
    };
})(function BuyButton({
    pres,
    buyType,
    className,
    onAddToCart,
}) {
    const hasStock = pres.buttonReasonId == 0;
    const button = buyType == BUY_TYPES.ADD_TO_CART
        ? (
            <button
                className={className}
                disabled={!hasStock}
                onClick={onAddToCart}
            >加入购物车</button>
        )
        : buyType == BUY_TYPES.DEMAND_LIST
            ? (
                <button
                    className={className}
                    disabled={!hasStock}
                    onClick={onAddToCart}
                >提交购药需求</button>
            )
            : null;

    return button;
});