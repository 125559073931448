var { controller, autowired }=window.Snowball._app;
import Cart from "../containers/Cart";
import { CartConfiguration } from "../configuration";
import CartController from './CartController';
import { SellerTypes } from "../constant/SellerTypes";
var { toast }=window.Snowball._widget;

@controller({
    component: Cart,
    configuration: CartConfiguration
})
export default class DemandCartController extends CartController {

    cartType = 3;

    emptyLink = "/";

    @autowired
    _cartRequester;

    @autowired
    _patientViewModel;

    onInit() {
        this.request = this._cartRequester.demand;
        this.ctx.logger.makeLog('H5web_pc_demand_cart', 'onload');
        super.onInit();
    }

    async checkOut() {
        const groups = await this.checkoutValid();
        if (!groups.length) {
            toast.showToast(
                `请先选择需要预订的商品`
            );
            return;
        }
        const seller = groups[0].sellers[0] || {};
        if (this.validHasPrescription(groups)) {
            if (groups.length > 1 || groups[0].sellers.length > 1 || (seller.skus && seller.skus.length > 5)) {
                toast.showToast('最多只能选中一个商户下的五种商品进行提交');
                return;
            }
            // log.makeLog('settle_click');
            // this.app.navigation.forward(this.getInquiryUrl(seller));
            this._patientViewModel.setCheoutInfo(seller);
            return;
        }
        this.checkoutbase(groups);
    }

    getInquiryUrl(seller) {
        const { IS_PAJK, IS_SHOUXIAN, IS_WAP, IS_HFL, OUT_BIZ_TYPE } = this.app.env;
        const outBizType = IS_PAJK
            ? 'WZKYJK_HEALTH_PRES'
            : IS_SHOUXIAN
                ? 'WZKYSX_HEALTH_PRES'
                : IS_WAP
                    ? 'WZKYW_HEALTH_PRES'
                    : IS_HFL
                        ? "WZKYHFL_HEALTH_PRES"
                        : OUT_BIZ_TYPE;
        const entrance = `SKU.CART@${seller.storeId}.3.${outBizType}`;
        const isSupportPreIDName = IS_PAJK || IS_SHOUXIAN || IS_WAP || IS_HFL;
        const preSuit = isSupportPreIDName ? '医生您好，我（｛gender｝，｛age｝岁）想开' : '医生您好，我想开';
        const mainSuit = preSuit + seller.skus.map(item => item.title).join('，');
        const prehref = isSupportPreIDName ? `${location.origin}/prescribe/#/choosePatient?biztype=12` : `${location.origin}/cable/#/guide?biztype=12`;
        const href = prehref + `&entrance=${encodeURIComponent(entrance)}&mainsuit=${encodeURIComponent(mainSuit)}`;
        return href;
    }

    validHasPrescription(groups) {
        return groups.some(group =>
            group.sellers.some(seller => {
                let { cartSellerDTO } = this._cartViewModel.getSellerById(seller.sellerId);
                return cartSellerDTO && cartSellerDTO.isSellPrescr === SellerTypes.CLINICPRESCRIPTIONS;
            })
        );
    }
}