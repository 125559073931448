var { controller, autowired, param }=window.Snowball._app;
import Home from '../containers/Home';
import { HomeConfiguration } from '../configuration';
import DecorationViewModel from '../../modules/DecorationViewModel';
import { IM_CONSULT_URL } from '@/env';

@controller({
    component: Home,
    configuration: HomeConfiguration,
})
class HomeController {
    @param('pageId')
    pageId: number;

    @param('preview')
    isPreview;

    @autowired
    _decorationViewModel: DecorationViewModel;

    @autowired
    _userService;

    constructor(props, ctx) {
        console.log(props, ctx);
    }

    async onInit() {
        // 预览
        if (this.app.env.IS_PREVIEW && this.pageId) {
            await this._decorationViewModel.previewHomePageById(this.pageId);
            while (!this._decorationViewModel.isNoMoreData) {
                await this._decorationViewModel.previewHomeMore();
            }
        } else {
            // this._decorationViewModel.loadCmsPage(28664);
            this.ctx.logger.makeLog('H5web_pc_mall_home', 'onload');
            // 正式首页
            await this._decorationViewModel.loadHomePage(14);
            while (!this._decorationViewModel.isNoMoreData) {
                await this._decorationViewModel.loadHomeMore();
            }
        }
        this.notify();
    }

    notify() {
        var subDomain = /(?:\?|&)subdomain=([^=&]+)/.test(location.search) ? RegExp.$1 : 'www';
        var parentHost = /^\w+\.((?:pre\.)?jk\.cn|test\.pajk\.cn|(?:test|dev)\.pajkdc\.com)/.test(
            location.host
        )
            ? RegExp.$1
            : 'jk.cn';
        var parentOrigin =
            window.parent === window ? location.origin : 'https://' + subDomain + '.' + parentHost;

        console.log('%c======>>>>>>', 'color: #f20', parentOrigin);
        const data = {
            action: 'resize',
            data: {
                width: 500,
                height: 500,
            },
        };
        parent.postMessage(JSON.stringify(data), parentOrigin);
    }

    async goConsult() {
        if (!await this._userService.ensureLogin()) {
            return;
        }
        window.open(IM_CONSULT_URL + '&channel=' + this.app.env.APP_ENV);
    }

}

export default HomeController;
