var React=window.Snowball._React;var { Component }=window.Snowball._React;
const CPaymentItem = ({
    total = 0,
    cashPrice = 0,
    title,
    saleText = '',
    isOversea,
    onSaleTextClick
}) => {
    return (
        <li className="of_h">
            <div className="items_info fs_m of_h pb_mr pt_mr">
                <p className={`cl_333 to_e2 ${isOversea ? '' : 'common'}`}>
                    {
                    // isOversea && (
                    //     <SmallTag
                    //         className="cl_fff pl_s pr_s mr_s title_tag dp_ib"
                    //         text={'海外购'}
                    //     />
                    // )
                    }{title}</p>
                <p className="cl_999 sub_line">
                    共<span>{total}</span>件商品，合计
                    <span>￥{cashPrice / 100}</span>
                </p>
            </div>
            <button
                onClick={() => {
                    onSaleTextClick && onSaleTextClick();
                }}
            >
                {`去${saleText}`}
            </button>
        </li>
    );
};

const CPaymentList = ({ groups = [], saleText, onComplete }) => {
    return (
        <ul className="list">
            {groups.map((item, i) => (
                <CPaymentItem
                    key={`CPaymentItem-${i}`}
                    total={item.total}
                    isOversea={item.isOverseaSeller}
                    title={item.title}
                    cashPrice={item.cashPrice}
                    saleText={saleText}
                    onSaleTextClick={() => {
                        onComplete && onComplete(item.sellers, i);
                    }}
                />
            ))}
        </ul>
    );
};

export class CPaymentPopup extends Component<any, {}> {
    componentDidMount() {
        // log.makeLog('combine_pay_onload');
    }
    render() {
        const { groups, onComplete, onHide } = this.props;
        return (
            <div className="cart_accountpop">
                <div className="title">
                    <p>
                        {`请分开结算以下商品`}
                    </p>
                    <i
                        className="iconfont icon-close cl_999 fs_l"
                        onClick={onHide}
                    />
                </div>
                <CPaymentList
                    groups={groups}
                    saleText='结算'
                    onComplete={onComplete}
                />
            </div>
        );
    }
}
