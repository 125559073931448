var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;

function Announcement({
    announcements
}) {
    if (!announcements || !announcements.length) return null;
    return (
        <div className="it_announcements">
            <h3 className="hd">公告</h3>
            <ul className="cl_666 fs_m">
                {Array.isArray(announcements) &&
                    announcements.map((announcement, i) => (
                        <li
                            key={i}
                            className="lh_xs"
                        >
                            {announcement}
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default inject(() => {
    const itemViewModel = autowired('itemViewModel');
    const { announcement = '' } = itemViewModel.pres;
    return {
        announcements: announcement
            .split(/\\r\\n|\\r|\\n|\r\n|\r|\n/)
            .filter(str => !!str)
    };
})(Announcement);