var { controller, autowired }=window.Snowball._app;
import Checkout from "../containers/Checkout";
import { CheckoutConfiguration } from "../configuration";

@controller({
    component: Checkout,
    configuration: [CheckoutConfiguration]
})
export default class CheckoutController {

    @autowired
    _beforeCheckoutViewModel;

    onInit() {
        const { location } = this.ctx;
        const {
            tradeIds,
            callBackUrl,
            goToOrderInfo,
        } = location.query;
        this._beforeCheckoutViewModel.load({
            tradeIds: tradeIds.split('__'),
            callBackUrl,
            goToOrderInfo,
        });
    }
}