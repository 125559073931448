var React=window.Snowball._React;var { useRef, useState }=window.Snowball._React;
import useSearchTags, { useThinkingList } from '../hooks/useSearchTags';
import { forward } from '../../../core/navigation';

const SearchBar = React.memo(({ showHot = true }) => {
    const inputEle = useRef(null);
    const [inputValue, updateInputValue] = useState('');
    const [thinkingListVisible, updateThinkingListVisible] = useState(false);
    const { hotWords, searchTips } = useSearchTags();
    const thinkingList = useThinkingList(inputValue);
    const [{ data: defaultValue, showContent: placeholder }] = searchTips || [{}];

    return (
        <div className="mall_common__header__search">
            <div className="mall_common__header__search_bar">
                <div className="mall_common__header__search_input">
                    <span className="mall_common__header__search_input_icon"></span>
                    <input
                        ref={inputEle}
                        placeholder={placeholder}
                        value={inputValue}
                        onChange={(e) => { updateInputValue(e.target.value); }}
                        onFocus={() => { updateThinkingListVisible(true); }}
                        onBlur={() => { setTimeout(() => { updateThinkingListVisible(false); }, 300); }}
                    />
                    { renderThinkingList(thinkingList, thinkingListVisible, updateInputValue) }
                </div>
                <div
                    className="mall_common__header__search_btn"
                    onClick={() => { forward('/search', { keyword: inputEle.current.value || defaultValue }); }}
                >搜索</div>
            </div>
            {
                hotWords && hotWords.length > 0 && showHot ? renderHotTagList(hotWords) : null
            }
        </div>
    );
});

function renderHotTagList(hotTagList) {
    return (
        <div className="mall_common__header__search_hot_tags">
            热门搜索:
            {
                hotTagList.map((tag, index) => {
                    return (
                        <span
                            onClick={() => { forward('/search', { keyword: tag.hotword }); }}
                            key={tag.hotword + index}
                        >{tag.hotword}</span>
                    );
                })
            }
        </div>
    );
};

function renderThinkingList(list = [], visible, updateInputValue) {
    return (
        <ul
            className="mall_common__header__search_input_thinking_list"
            style={{ display: visible && list.length > 0 ? 'block' : 'none' }}
        >
            { list.map((item) => {
                return (
                    <li onClick={() => { updateInputValue(item.keyword); }} dangerouslySetInnerHTML={{ __html: item.keyWordWithHtmlTag }}></li>
                );
            }) }
        </ul>
    );
}

export default SearchBar;