var React=window.Snowball._React;

function GoodGlass({ optometryInfo }) {
    return optometryInfo
        ? (
            <div
                className="goodglassmsg"
            >
                <h3><p>验光单信息</p></h3>
                <div className="msg">
                    {
                        Object.values(optometryInfo)
                            .map(function (item, index) {
                                return (<div key={index} className="detail">{item}</div>);
                            })
                    }
                </div>
                <div className="item item-divider"></div>
            </div>
        )
        : null;
}

export default GoodGlass;