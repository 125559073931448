var { autowired }=window.Snowball._app;
import checkout from "../actions/checkout";
import getOrderCreatePageLink from "../../../shared/utils/getOrderCreatePageLink";
var { popup, toast }=window.Snowball._widget;
import { CartTypes, CART_ROUTES } from "../constant/CartTypes";
import { INVALID_CART_NUM } from "../constant/CartNumbers";
export default class CartController {

    @autowired
    _cartViewModel;

    @autowired
    _userService;

    onInit() {
        this.initPage();
    }

    async initPage() {
        this.setCartProps();
        if (!await this._userService.ensureLogin()) {
            return;
        }
        const isFromTab = !!this.ctx.location.query.fromtab;
        const cartNumberRes = await this._cartViewModel.fetchCartNum();
        if (!this._verifyValidCart(cartNumberRes)) {
            this.app.navigation.replace('/cart');
        } else if (!isFromTab && cartNumberRes.latestCartType && cartNumberRes.latestCartType !== this.cartType) {
            this.app.navigation.replace(CART_ROUTES[cartNumberRes.latestCartType]);
        } else {
            this._cartViewModel.fetch();
        }
    }

    setCartProps() {
        this._cartViewModel.setProps({
            request: this.request,
            cartType: this.cartType
        });
    }

    getInvalidList() {
        return this._cartViewModel.getInvalidList();
    }

    async checkoutValid() {
        if (!await this._userService.ensureLogin()) {
            return;
        }
        const groups = this._cartViewModel.groupItemsByCPayment();
        return groups;
    }

    async checkoutbase(groups) {
        let computedList = groups;
        if (groups.length > 1) {
            if (this.isCheckingOut) return;
            this.isCheckingOut = true;
            computedList = await this._cartViewModel.calcCPaymentsPrice(groups);
            this.isCheckingOut = false;
        }

        if (typeof computedList == 'string') {
            toast.showToast(computedList);
            this.reloadList();
            return;
        }
        const toOrder = getOrderCreatePageLink;
        checkout(computedList, (data)=> {
            // 传递下单页需要带一个购物车类型参数，下单页和别人不统一，需要加1
            toOrder(data, this.cartType + 1);
        });
    }

    reloadList() {
        this._cartViewModel.reload();
    }

    clearInvalidItems() {
        // log.makeLog('clean_expired_goods_click');
        const ids = this.getInvalidList().map((item) => item.id);
        popup.confirm({
            title: '确认要清空失效商品吗？',
            content: '清空后将无法恢复',
            okText: '清空',
            onOk: () => {
                // log.makeLog('confirm_clean_expired_goods_click');
                this._cartViewModel.deleteItems(ids);
            },
            onCancel() {
                // log.makeLog('cancel_clean_expired_goods_click');
            }
        });
    };

    deleteCheckedItems() {
        const ids = this._cartViewModel.getCheckedIds();
        if (!ids.length) {
            return alert('请先选择商品');
        }
        this.deleteItems(ids);
    }

    changeItemNum = ({ skuData, num, id, storeId }) => {
        this._cartViewModel
            .updateItemNumber({ sku: skuData, num, id, storeId, type: this.cartType });
    };

    deleteItems(ids) {
        popup.confirm({
            title: '确定要删除所选商品吗？',
            content: '删除后将无法恢复，您要重新手动添加至购物车',
            okText: '删除',
            onOk: () => {
                this._cartViewModel.deleteItems(ids);
            },
            onCancel() {
                // log.makeLog('cancel_clean_expired_goods_click');
            }
        });
    }

    _verifyValidCart(res = {}) {
        if (
            res.success &&
            this.cartType !== CartTypes.B2C &&
            res.rxCartNum === INVALID_CART_NUM
        ) {
            return false;
        }
        return true;
    }
}