var { util, observable }=window.Snowball;
var { Service, autowired }=window.Snowball._app;

export default class UserService extends Service {
    timeStamp = 0;

    @observable user = {};

    @autowired
    _loginService;

    getUserId() {
        return this.user.id || 0;
    }

    setUser(data) {
        this.user.withMutations((userModel) => {
            userModel.set(data);
        });
    }

    getUserInfo() {
        return this.app.gateway.request('user.getUserInfo', true, null, {
            isShowLoading: false,
            autoLogin: true,
            showErrorToast: false
        });
    }

    updateUserBasicInfo(userInfo) {
        return this.app.gateway.request('user.updateUserBasicInfo', true, userInfo, {
            isShowLoading: true,
            autoLogin: false,
            showErrorToast: false
        });
    }

    updateNickname(nickname) {
        return this.app.gateway.request('user.updateNickname', true, { nickname }, {
            isShowLoading: true,
            autoLogin: false,
            showErrorToast: false
        });
    }

    restore() {
        this.user = {};
    }

    async fetchUser() {
        this.isTaken = true;

        var res = await (this.promise || (this.promise = this.getUserInfo()));
        this._isLogin = res.code === -1 ? undefined : res.success || false;
        this.timeStamp = Date.now();

        if (res.success) {
            this.setUser(res.data);
            this.setUser({ isLogin: true });
            localStorage.setItem('USER_ID', this.getUserId());
        } else {
            this.restore();
            localStorage.removeItem('USER_ID');
        }
        this.promise = null;
        return res;
    }

    async isLogin() {
        if (!util.cookie('_wtk')) {
            return false;
        }
        // TODO: remove later
        // if (util.cookie('v_aid') != this.app.env.APP_ID) {
        //     return false;
        // }
        if (this.getUserId()) return true;
        // 是否登录的状态缓存5秒钟，防止同时多次请求
        if (this._isLogin !== undefined && Date.now() - this.timeStamp < 5000)
            return this._isLogin;

        var res = await this.fetchUser();

        return res.success;
    }

    /**
     * 确认用户是否登录，未登录的弹出登录框
     * @returns 登录状态，1: 已登陆, 2: 新登陆, 0: 未登录
     */
    async ensureLogin() {
        if (await this.isLogin()) {
            return 1;
        }
        if (await this._loginService.login()) {
            return 2;
        }
        return 0;
    }
}
