var { observable, util }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
var { toast, popup }=window.Snowball._widget;
import UnicornApiService from '@/shared/apis/UnicornApiService';
import AppResourceService from "@/shared/services/AppResourceService";
import RefundExpressFormViewModel from '../view-models/RefundExpressFormViewModel';
import {
    AFTER_SALE_STATUS_TEXT_LIST,
    AFTER_SALE_TOP_TEXT_MAP,
    REFUND_TYPE_KEY_MAP,
    REFUND_TYPE_VALUE_MAP,
    REFUND_TYPE_DEFAULT_KEY,
    REFUND_SUCCESS_STATUS_LIST
} from '../constants';

export default class AfterSaleViewModel extends ViewModel {
    @observable
    topTip = '';

    @observable
    isTopTipClickable = false;

    @observable
    topSetpList = [];

    @observable
    isRefundSuccess = false;

    @observable
    needUserSendProduct = false;

    @observable
    canCancelRefund = false;

    @observable
    refundAddress = '';

    @observable
    refundOrder = null;

    @observable
    refundRecordList = [];

    _requestNo = '';

    @autowired
    _unicornApiService: UnicornApiService;

    @autowired
    _appResourceService: AppResourceService;

    @autowired
    _refundExpressFormViewModel: RefundExpressFormViewModel;

    _afterSaleConfig = {};

    @emitter
    onRefundExpressFormSubmit() {
        this._refundExpressFormViewModel.save(() => {
            this.refresh();
        });
    }

    async init(requestNo) {
        this._requestNo = requestNo;

        // 必须等待这两个接口调用完成之后才能走下面的流程
        await this.fetchAfterSaleConfig();
        await this.fetchAfterSaleDetail(requestNo);

        if (this.needUserSendProduct) {
            this._refundExpressFormViewModel.init(requestNo);
        }
    }

    refresh() {
        this.init(this._requestNo);
    }

    async fetchAfterSaleConfig() {
        const res = await this._appResourceService.getAppResource({
            system: 'plugin',
            channel: 'all',
            type: 'text',
            source: 'afterSaleProgress'
        });
        const { values = [] } = res;

        values.forEach((item) => {
            const { keyName, val = "{}" } = item;

            this._afterSaleConfig[keyName] = JSON.parse(val);
        });
    }

    async fetchAfterSaleDetail(requestNo) {
        const res = await this._unicornApiService.getAfterSalesOrderDetail(requestNo);

        if (res.success) {
            const {
                afterSalesOrder: orderInfo,
                refundProgressRecords: recordList
            } = res.data;
            const { insuranceButton, status, refundType } = orderInfo;

            this.topTip = AFTER_SALE_TOP_TEXT_MAP[insuranceButton];
            // insuranceButton: 保险跳转按钮，0.不支持，1.去申请，2.去修改，3.去查看，4.仅显示不可跳转
            this.isTopTipClickable = insuranceButton < 4;
            this.isRefundSuccess = REFUND_SUCCESS_STATUS_LIST.indexOf(status) > -1;
            this.needUserSendProduct = status === 1;
            this.canCancelRefund = refundType === (REFUND_TYPE_VALUE_MAP.REFUND || refundType === REFUND_TYPE_VALUE_MAP.REFUND_ANG_GOODS) && status === 0;
            this.topSetpList = this.transformTopStepList(orderInfo, recordList);
            this.refundOrder = this.transformRefundOrder(orderInfo);

            const { refundRecordList, refundAddress } = this.transformRefundRecordList(orderInfo, recordList);

            this.refundRecordList = refundRecordList;
            this.refundAddress = refundAddress;
        }
    }

    transformTopStepList(orderInfo, recordList) {
        const { refundType, status } = orderInfo;
        const isOnlyRefund = refundType === REFUND_TYPE_VALUE_MAP.REFUND;
        const isInRefundProcess = REFUND_SUCCESS_STATUS_LIST.indexOf(status) === -1;
        const stepList = [
            {
                desc: isOnlyRefund ? '卖家申请退款' : '卖家申请退货退款',
                actived: false
            },
            {
                desc: '售后进度',
                actived: isInRefundProcess
            },
            {
                desc: '退款成功',
                actived: !isInRefundProcess
            }
        ];

        return stepList;
    }

    transformRefundOrder(orderInfo) {
        const {
            applyTime,
            status,
            cashRefundAmount,
            healthGoldRefundAmount,
            shoppingCardRefundAmount,
            healthBeanRefundAmount,
            healthStarRefundCount
        } = orderInfo;
        const formatAmount = (amount = 0) => { return (amount / 100).toFixed(2); };
        const refundAmountTextList = [
            { desc: '现金:', unit: '￥', placeholder: '￥0', amount: cashRefundAmount, format: formatAmount },
            { desc: '健康金:', unit: '', placeholder: '', amount: healthGoldRefundAmount, format: formatAmount },
            { desc: '健康生活通:', unit: '￥', placeholder: '', amount: shoppingCardRefundAmount, format: formatAmount },
            { desc: '健康豆:', unit: '', placeholder: '', amount: healthBeanRefundAmount },
            { desc: '健康星:', unit: '', placeholder: '', amount: healthStarRefundCount, format: (amount = 0) => amount / 100 }
        ]
            .map((item) => {
                const { desc, unit, placeholder, amount, format } = item;

                return amount > 0 ? `${desc}${unit}${format ? format(amount) : amount}` : placeholder;
            })
            .filter((text) => text !== '');

        return {
            ...orderInfo,
            statusText: AFTER_SALE_STATUS_TEXT_LIST[status],
            applyTime: util.formatDate(applyTime * 1000, 'yyyy-MM-dd HH:mm:ss'),
            refundAmountTextList
        };
    }

    transformRefundRecordList(orderInfo, recordList) {
        const { refundType: refundTypeValue } = orderInfo;
        const refundTypeKeyName = REFUND_TYPE_KEY_MAP[refundTypeValue] || REFUND_TYPE_DEFAULT_KEY;
        const allConfig = this._afterSaleConfig[refundTypeKeyName];
        const isOnlyRefund = refundTypeValue === REFUND_TYPE_VALUE_MAP.REFUND;
        let refundAddress;

        recordList = recordList
            .map(record => {
                const { status } = record;
                const configString = allConfig[status] ? allConfig[status][1] : '';
                const configList = configString.split('|');
                let desc, additionalDescList = [], isShow = true;

                switch (status) {
                    case 1: {
                        if (isOnlyRefund) {
                            isShow = false;
                            break;
                        }
                        desc = configList[0];
                        additionalDescList = [
                            { title: configList[1], content: record.refundAddress },
                            { title: configList[2], content: record.contactPerson },
                            { title: configList[3], content: record.contactPhone },
                            { title: configList[4], content: record.zipCode },
                        ];
                        refundAddress = record.refundAddress;
                        break;
                    }
                    case 2: {
                        desc = `${configList[0]}: ${record.comment}`;
                        break;
                    }
                    case 3: {
                        desc = record.operatorType !== 6 ? configList[0] : configList[1];
                        break;
                    }
                    case 0:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9: {
                        if (isOnlyRefund && status === 4) {
                            isShow = false;
                            break;
                        }

                        const specFreeSkuIds = [3032963, 3036933];
                        if (/\.test\./.test(location.host)) {
                            specFreeSkuIds.push(911911888974741);
                        }

                        const onlyHasSpecFreeSku = /\bspecFreeSkuId=(\d+)/.test(location.hash)
                            ? specFreeSkuIds.indexOf(RegExp.$1) !== -1
                            : false;

                        desc = status === 0 && onlyHasSpecFreeSku ? '您已发起退款申请，预计退款金额于1-3天原路返回' : configList[0];
                        break;
                    }
                    case 10: {
                        if (isOnlyRefund) {
                            isShow = false;
                            break;
                        }
                        desc = configList[0];
                        additionalDescList = [
                            { title: record.logisticsInc, content: record.logisticsCode }
                        ];
                        break;
                    }
                    default: {
                        isShow = false;
                    }
                }

                return {
                    desc,
                    additionalDescList,
                    date: util.formatDate(record.operatedAt * 1000, 'yyyy-MM-dd HH:mm:ss'),
                    isShow,
                    _record: record
                };
            })
            .filter(record => record.isShow);

        return { refundRecordList: recordList, refundAddress };
    }

    getInsurancePageUrl() {
        const { origin, href } = window.location;
        const { orderId } = this.refundOrder;
        const qs = util.qs(href);
        const hashQs = util.hashQs(href);
        let insurancePageUrl = `${origin}/mall-arthur/index.html#/freightInsurance/input/${this._requestNo}/${orderId}`;

        qs.app = 'WAP';

        insurancePageUrl = util.qs(insurancePageUrl, qs);
        insurancePageUrl = util.hashQs(insurancePageUrl, hashQs);

        return insurancePageUrl;
    }

    @emitter
    onTopTipClick() {
        if (this.isTopTipClickable) {
            this.app.navigation.forward(this.getInsurancePageUrl());
        }
    }

    @emitter
    onCancelRefundBtnClick() {
        popup.confirm({
            title: '撤销申请',
            content: '你确定要撤销申请吗？',
            onOk: () => { this.cancelAfterSaleRequest(); }
        });
    }

    async cancelAfterSaleRequest() {
        const res = await this._unicornApiService.cancelAfterSalesOrder({
            afterSalesOrderId: this._requestNo
        });

        if (res.success) {
            this.refresh();
        }
        else {
            toast.showToast(res.message || '撤销失败');
        }
    }
}