var React=window.Snowball._React;
import MODULE_TYPE from "./const/MODULE_TYPE";

import Layout from "./layout/Layout";
import Images from "./image/Images";
import FloorTitle from "./floor/FloorTitle";
import FloorTopic from "./floor/FloorTopic";
import FloorGoods from "./floor/FloorGoods";
import FloorBrands from "./floor/FloorBrands";
import Category from "./home/Category";
import Carousel from "./home/Carousel";
import ProductsList from "./product/ProductsList";
import BannerImages from "./image/BannerImages";
import CouponImage from "./image/CouponImage";
import Anchor from "./fixed/Anchor";

export default function moduleFactory(props) {
    const { template } = props;

    let ComponentFactory = null;

    switch (template.type) {
        case MODULE_TYPE.IMAGE:
            ComponentFactory = Images;
            break;
        case MODULE_TYPE.LAYOUT:
        case MODULE_TYPE.LAYOUT_TAB:
            ComponentFactory = Layout;
            break;
        case MODULE_TYPE.PC_FLOOR_TITLE:
            ComponentFactory = FloorTitle;
            break;
        case MODULE_TYPE.PC_FLOOR_TOPIC:
            ComponentFactory = FloorTopic;
            break;
        case MODULE_TYPE.PC_FLOOR_GOODS:
            ComponentFactory = FloorGoods;
            break;
        case MODULE_TYPE.PC_FLOOR_BRANDS:
            ComponentFactory = FloorBrands;
            break;
        case MODULE_TYPE.PC_CATEGORY:
            ComponentFactory = Category;
            break;
        case MODULE_TYPE.PC_CAROUSEL:
            ComponentFactory = Carousel;
            break;
        case MODULE_TYPE.PC_PRODUCT:
            ComponentFactory = ProductsList;
            break;
        case MODULE_TYPE.PC_BANNER_IMAGE:
            ComponentFactory = BannerImages;
            break;
        case MODULE_TYPE.PC_COUPON_IMAGE:
            ComponentFactory = CouponImage;
            break;
        case MODULE_TYPE.PC_ANCHOR:
            ComponentFactory = Anchor;
            break;

    }

    return !ComponentFactory ? null : <ComponentFactory {...props} />;
}
