import './sass/style.scss';
import './sprity/sprite.scss';
import './sass/iconfonts/iconfont.scss';

var { createApplication, Page }=window.Snowball._app;
import router from "./app/router";
import * as env from "./env";

import { AppConfiguration } from './shared/configuration';

import { registerDirectives } from "./extentions/domDirectives";
import { Logger, startErrorReporter } from "./extentions/logger";
import createGateway from './extentions/gateway';
import * as tfs from './extentions/tfs';
import * as filegw from "./extentions/filegw";
import validate from "./extentions/validate";
import popupConfirm, { popupAlert } from "./extentions/popupConfirm";
var { popup }=window.Snowball._widget;

import PajkSkywalking from '@pajk-design/skywalking';

const symbolLogger = Symbol.for('jkmall#logger');

PajkSkywalking.register({
    allowedHost: location.host.replace(/(^[^.]*)(\..*)/,'//api$2'),
    service: 'jkmall',
    serviceVersion: '1.0.0',
    autoTracePerf: true,
    jsErrors: true,
    enableSPA: true
});

popup.confirm = popupConfirm;
popup.alert = popupAlert;

Page.extentions.ctx({
    get logger() {
        if (!this[symbolLogger]) {
            this[symbolLogger] = new Logger(this);
        }
        return this[symbolLogger];
    }
});
Page.extentions.lifecycle({
    onCreate() {
        window.scrollTo(0, 0);
    },
    onDestroy() {
        console.log(this.location);
    }
});
startErrorReporter();

createApplication({
    routes: router,
    autoStart: true,
    configuration: AppConfiguration,
    extend(app) {
        registerDirectives(app);

        return {
            env,
            tfs,
            filegw,
            gateway: createGateway({
                env,
                loginService: app.autowired('loginService')
            }),
            validate
        };
    },
    options: {
        disableTransition: true
    }
}, document.getElementById('root'), (app) => {

    console.log('application start!');
});