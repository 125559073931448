var React=window.Snowball._React;
import moduleFactory from "./moduleFactory";

export default function renderModules({ modules, templates }) {
    if (!modules) {
        return null;
    }

    const normalModules = [];
    const fixedModules = [];

    modules.forEach((item) => {
        const { template } = item;
        if (template.extProps && template.extProps.app_layout_id) {
            return;
        }

        const moduleProps = {
            data: item,
            template,
            templates,
            modules
        };

        // 筛掉布局子模块
        if (!item.bizData.app_layout_id) {
            if (template.extProps.isFixed) {
                fixedModules.push(moduleFactory({
                    ...moduleProps,
                    index: fixedModules.length,
                    key: item.id
                }));
            } else {
                normalModules.push(moduleFactory({
                    ...moduleProps,
                    index: normalModules.length,
                    key: item.id,
                }));
            }
        }
    });

    return (
        <>
            {normalModules}
            <div className="md_fixed_wrapper">{fixedModules}</div>
        </>
    );
}