var React=window.Snowball._React;

function Form({ className, onSubmit, children }) {
    return <form
        className={"app-form " + (className || '')}
        onSubmit={(e) => {
            e.preventDefault();
            onSubmit && onSubmit();
        }}
    >{children}</form>;
}

function FormItem({ className, required, label, children, error }) {
    return <div className={"app-form-item " + (className || '')}>
        <label className="app-form-label"><span className="app-form-required" style={required ? {} : { visibility: 'hidden' }}>*</span>{label ? label + ':' : ''}</label>
        <div className="app-form-input-wrap">{children}</div>
        {error && <div className="app-form-error"><i class="icon iconfont icon-warn mr_s"></i>{error}</div>}
    </div>;
}

Form.Item = FormItem;

export default Form;