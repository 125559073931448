var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;

function formatMoney(money) {
    return (Number(money) / 100 || 0).toFixed(2);
}

function Settlement({ tradeOrder, isCod, postFeeName }) {
    const { source: orderSource } = tradeOrder;
    let useHealthBean = tradeOrder.useHealthBean || 0;
    // 养老险控费电子处方，健康险控费电子处方隐藏支付方式
    // 养老险控费电子处方，健康险控费电子处方
    // 「优惠金额」文案改成「垫付金额」
    let discountFeeText = (orderSource === 'JKX_HEALTH_PRES' || orderSource === 'HFLKF_HEALTH_PRES') ? '垫付金额' : '优惠金额';

    let showPayMode = (orderSource !== 'JKX_HEALTH_PRES' && orderSource !== 'HFLKF_HEALTH_PRES');
    // 付款方式-参数名: payMode; PAYONLINE
    let payModeName = tradeOrder.payMode == "CASHONDELIVERY"
        ? '货到付款'
        : '在线支付';

    if (tradeOrder.payMode === 'WITHHOLD') {
        payModeName = '续期代扣';
    } else if (tradeOrder.distributor === '3' && tradeOrder.payMode == "CASHONDELIVERY") {
        // 到店自取的订单，如果支付方式选的是到店现付，在订单详情页展示支付方式为到店现付
        payModeName = '到店现付';
    }

    // 是否展示税费
    let showOverseas = false;
    (tradeOrder.tradeExtMaps || []).forEach(ext => {
        if (ext.key === 'orderTag' && ext.value === 'OVERSEAS') {
            showOverseas = true;
        }
    });

    return (
        <div className="oi_settlement">
            <ul className="oi_settlement_list">
                {
                    showPayMode && (
                        <li className="oi_paymode">
                            <span className="label">支付方式:</span>
                            <span className="right">{payModeName}</span>
                        </li>
                    )
                }
                <li>
                    <span className="label">订单金额:</span>
                    <span className="right">
                        {useHealthBean > 0 && `${useHealthBean}豆`}
                        {useHealthBean > 0 && tradeOrder.tradeItemTotalFee > 0 && ` + `}
                        {tradeOrder.tradeItemTotalFee > 0 && `￥${formatMoney(tradeOrder.tradeItemTotalFee)}`}
                    </span>
                </li>
                <li style={{ display: tradeOrder.giftCardBalanceForOrder > 0 ? "" : "none" }}>
                    <span className="label">健康生活通抵扣订单:</span>
                    <span className="right">￥-{formatMoney(tradeOrder.giftCardBalanceForOrder > 0 ? tradeOrder.giftCardBalanceForOrder : 0)}</span>
                </li>
                <li style={{ display: tradeOrder.discountFee > 0 ? "" : "none" }}>
                    <span className="label">{discountFeeText}:</span>
                    <span className="right">￥-{formatMoney(tradeOrder.discountFee > 0 ? tradeOrder.discountFee : 0)}</span>
                </li>
                <li style={{ display: showOverseas ? "" : "none" }}>
                    <span className="label">订单税费:</span>
                    <span className="right">￥{formatMoney(tradeOrder.taxFee)}</span>
                </li>
                <li style={{ display: useHealthBean > 0 ? "" : "none" }}>
                    <span className="label">健康豆:</span>
                    <span className="right">{useHealthBean}</span>
                </li>
                <li>
                    <span className="label">
                        {postFeeName}
                        :</span>
                    <span className="right">￥{formatMoney(tradeOrder.postFee)}</span>
                </li>
                <li style={{ display: tradeOrder.giftCardBalanceForPost > 0 ? "" : "none" }}>
                    <span className="label">健康生活通抵扣运费:</span>
                    <span className="right">￥-{formatMoney(tradeOrder.giftCardBalanceForPost > 0 ? tradeOrder.giftCardBalanceForPost : 0)}</span>
                </li>
                <li>
                    <span className="label">{isCod ? '应付金额' : '总价'}:</span>
                    <span className="right total">
                        ￥{formatMoney(tradeOrder.actualTotalFee)}
                    </span>
                </li>
            </ul>
        </div>
    );
}

export default inject(() => {
    const orderInfoViewModel = autowired('orderInfoViewModel');
    return {
        tradeOrder: orderInfoViewModel.tradeOrder,
        isCod: orderInfoViewModel.isCod,
        postFeeName: orderInfoViewModel.postFeeName
    };
})(Settlement);