import { getFrontCategory, getFirstLevelFrontCategory } from '../../../core/api/unicorn';

const cachedCategoryData = {};
const categoryIns = {};

export default class CategoryService {
    constructor(rootId) {
        this.rootId = rootId;

        cachedCategoryData[rootId] = {};
    }

    static getInstance(rootId) {
        if (!categoryIns[rootId]) {
            categoryIns[rootId] = new CategoryService(rootId);
            return categoryIns[rootId];
        }

        return categoryIns[rootId];
    }

    getFirstCategory() {
        return new Promise((resolve, reject) => {
            const cachedList = this.objectToArray(
                cachedCategoryData[this.rootId],
                (prev, next) => prev.index - next.index
            );

            if (cachedList.length > 0) {
                resolve(cachedList);
            }
            else {
                this.requestFirstCategory().then((list) => {
                    resolve(list);
                });
            }
        });
    }

    getSecondAndThirdCategory(firstCategoryId) {
        return new Promise((resolve, reject) => {
            const cachedFirstCategory = cachedCategoryData[this.rootId][firstCategoryId];
            const { children = [] } = cachedFirstCategory;

            if (children.length > 0) {
                resolve(children);
            }
            else {
                this.requestSecondAndThirdCategory(firstCategoryId).then((list) => {
                    resolve(list);
                });
            }
        });
    }

    requestFirstCategory() {
        return getFrontCategory({
            id: this.rootId
        }).then((res) => {
            if (res.success) {
                const { children: list = [] } = res.data;

                this.cacheFirstCategory(list);

                return list;
            }
        });
    }

    requestSecondAndThirdCategory(firstCategoryId) {
        return getFirstLevelFrontCategory({
            id: firstCategoryId,
            rootId: this.rootId
        }).then((res) => {
            if (res.success) {
                const { children: list = [] } = res.data;

                this.cacheSecondAndThirdCategory(list, firstCategoryId);

                return list;
            }
        });
    }

    cacheFirstCategory(list) {
        for (let i = 0; i < list.length; i++) {
            const item = list[i];

            item.index = i;

            cachedCategoryData[this.rootId][item.id] = item;
        }
    }

    cacheSecondAndThirdCategory(list, firstCategoryId) {
        cachedCategoryData[this.rootId][firstCategoryId].children = list;
    }

    objectToArray(obj = {}, sortCompareFn) {
        const list = [];

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                list.push(obj[key]);
            }
        }

        sortCompareFn && list.sort && list.sort(sortCompareFn);

        return list;
    }
}