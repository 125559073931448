
var React=window.Snowball._React;
import { Header, Footer, Logo } from 'jkmall-frame';
var { inject, mapViewModelToProps }=window.Snowball._app;
import { CartTypes, CartTypeCNames } from '../constant/CartTypes';
import { INVALID_CART_NUM } from '../constant/CartNumbers';
import { CartListFrame, CartHead, CartTab, bottomBarFactory } from "../components";
import PatientModal from "../components/PatientModal";
import Consult from '../../consult/containers/Consult';
import ProgressList from '../../../shared/components/ProgressList';

function CartWrapper({
    list,
    cartType,
    cartInfo,
    statusInfo,
    checkOut,
    setAllChecked,
    deleteCheckedItems,
    emptyLink
}) {
    const {
        b2cCount,
        demandCount,
        cashPrice,
        skuCount,
        promPrice,
        disabled,
        isEmpty,
        isAllItemsChecked
    } = cartInfo;

    const { isError, isLoading } = statusInfo;
    const invalidDemandCount = cartType === CartTypes.DemandList ? 0 : -1;
    const isValidListEmpty = list.length === 0;
    const isCheckAllDisabled = isValidListEmpty || disabled;

    const isShowBottomBar = !isError && !isEmpty && !isLoading;

    const BottomBar = bottomBarFactory(cartType);

    let tabShows = [
        {
            type: CartTypes.B2C,
            href: '/cart?fromtab=1',
            name: CartTypeCNames[CartTypes.B2C],
            count: b2cCount || 0
        },
        {
            type: CartTypes.DemandList,
            href: '/cart/demandlist?fromtab=1',
            name: CartTypeCNames[CartTypes.DemandList],
            count: demandCount || invalidDemandCount
        }
    ].filter((item) => item.count !== INVALID_CART_NUM);

    return (
        <div className="full-white">
            <Header renderExtra={() => {
                return (
                    <div className="clearfix">
                        <Logo name="购物车" className="mt_8"/>
                        <ProgressList selectedIndex={0}/>
                    </div>
                );
            }} />
            <div className="w clearfix">
                <div className="cart-main">
                    <CartTab currentCartType={cartType} tabs={tabShows} />
                    <CartHead
                        disabled={isCheckAllDisabled}
                        checked={isAllItemsChecked}
                        setAllChecked={setAllChecked}
                    />
                    <CartListFrame showEmptyTip={isEmpty} link={emptyLink} />
                    {isShowBottomBar && <BottomBar
                        isChecked={isAllItemsChecked}
                        disabled={isCheckAllDisabled}
                        skuNumber={skuCount}
                        totalMoney={cashPrice}
                        promPrice={promPrice}
                        onCheckAll={setAllChecked}
                        onDelete={deleteCheckedItems}
                        onCheckout={checkOut}
                    />}
                    <PatientModal />
                    <Consult />
                </div>
            </div>
            <Footer type="grey" />
        </div>
    );
}

export default inject(({ cartType, checkOut, deleteCheckedItems, emptyLink }) => {
    return {
        ...mapViewModelToProps('CartViewModel'),
        cartType,
        checkOut,
        deleteCheckedItems,
        emptyLink
    };
})(CartWrapper);