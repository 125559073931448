var React=window.Snowball._React;
var { util }=window.Snowball;
// import TFSImage from '../../../shared/components/TFSImage';
import Radio from '../../../shared/components/Radio';

export default function Promotion({
    coupon = {},
    noUseBtnText,
    onClick,
    onNoUseBtnClick,
}) {
    return (
        <div className={`promotion clearfix ${coupon.selected ? 'selected' : ''}`}>
            <Radio selected={coupon.selected} onClick={onClick} />
            <span className="prom">
                {
                    coupon.thresholdPrice == 0
                        ? '立减'
                        : `满￥${(coupon.thresholdPrice / 100).toFixed(2)}元减`
                }
                ￥
                {(coupon.discountPrice / 100).toFixed(2)}
            </span>
            {/* <TFSImage src="tag" /> */}
            <span className="time cl_999">
                {util.formatDate(coupon.startTime, 'yyyy-MM-dd')}
                至
                {util.formatDate(coupon.endTime, 'yyyy-MM-dd')}
            </span>
            {coupon.selected && noUseBtnText
                ? (
                    <span
                        className="btn_no_use cl_999 cs_p"
                        onClick={(event) => {
                            event.stopPropagation();
                            onNoUseBtnClick();
                        }}
                    >{noUseBtnText}</span>
                ) : null
            }
        </div >
    );
}
