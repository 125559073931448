var React=window.Snowball._React;
import { Header, Footer } from "jkmall-frame";
import Main from "../../../shared/components/Main";
import empty from "../styles/images/empty.png";
export default function Error(props) {
    const errTxt = props.location.query.t || 'Opps, 404了哦';
    return (
        <div className="app-wrapper-gray">
            <Header />
            <Main>
                <div className="app-page-error">
                    <img src={empty} alt="" />
                    <p>{errTxt}</p>
                </div>
            </Main>
            <Footer />
        </div>
    );
}
