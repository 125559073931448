var React=window.Snowball._React;
import CategoryNav from './components/CategoryNav';
import CategoryDetail from './components/CategoryDetail';
import useCategory from './hooks/useCategory';

const Category = ({ categoryId = 0 }) => {
    const {
        isShowCategory,
        firstCategoryList,
        activedCategory,
        onCategoryMouseLeave,
        onCategoryNavItemMouseEnter
    } = useCategory(categoryId);

    return (
        <div
            className="mall_common__category"
            onMouseLeave={() => { onCategoryMouseLeave(); }}
        >
            <div className="mall_common__category_title">所有商品分类</div>
            <div className="mall_common__category_nav">
                <CategoryNav
                    activedNavCategoryId={activedCategory.id}
                    firstCategoryList={firstCategoryList}
                    onNavItemMouseEnter={onCategoryNavItemMouseEnter}
                />
                <CategoryDetail
                    isShow={isShowCategory}
                    secondCategoryList={activedCategory.children || []}
                />
            </div>
        </div>
    );
};

export const CategoryPlaceholder = () => {
    return (
        <div className="mall_common__category">
            <div className="mall_common__category_title" style={{ cursor: 'default' }}>所有商品分类</div>
        </div>
    );
};

export default Category;