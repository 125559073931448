var React=window.Snowball._React;var { useState, useEffect }=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import TFSImage from "../../../shared/components/TFSImage";

function Pictures({ className, pictures = [], video }) {
    const [current, setCurrent] = useState({});

    const [magnifier, setMagnifier] = useState({
        offsetLeft: 0,
        offsetTop: 0,
        visible: false
    });


    useEffect(() => {
        setCurrent({
            src: pictures[0],
            index: 0,
            left: 0
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, pictures);

    const magnifierTop = Math.max(0, Math.min(magnifier.offsetTop - 50, 300));
    const magnifierLeft = Math.max(0, Math.min(magnifier.offsetLeft - 50, 300));

    return (
        <div
            className={"it_pictures " + (className || '')}
            onMouseLeave={() => {
                setMagnifier({
                    ...magnifier,
                    visible: false
                });
            }}
        >
            <div className="it_pictures_curr"
                onMouseMove={(e) => {
                    let [offsetLeft, offsetTop] = [0, 0];
                    if (e.target.className.indexOf('it_pictures_magnifier') != -1) {
                        offsetLeft = e.target.offsetLeft;
                        offsetTop = e.target.offsetTop;
                    }
                    const x = offsetLeft + e.offsetX;
                    const y = offsetTop + e.offsetY;

                    setMagnifier({
                        offsetLeft: x,
                        offsetTop: y,
                        visible: x > 20 && y > 20 && x < 380 && y < 380
                    });
                }}
            >
                <TFSImage
                    className="img"
                    alt={current.src}
                    src={current.src}
                />
                {
                    magnifier.visible && <>
                        <div
                            className="it_pictures_magnifier"
                            style={{
                                top: magnifierTop,
                                left: magnifierLeft,
                            }}
                        ></div>
                        <div className="it_pictures_bigimg">
                            <TFSImage
                                className="bigimg"
                                alt={current.src}
                                src={current.src}
                                style={{
                                    top: magnifierTop * -4,
                                    left: magnifierLeft * -4,
                                }}
                            />
                        </div>
                    </>
                }
            </div>
            <div className="clearfix">
                <button
                    className="it_pictures_prev_btn fl_l"
                    style={current.left == 0 ? {
                        opacity: .5
                    }
                        : {
                            cursor: 'pointer'
                        }}
                    onClick={current.left == 0
                        ? undefined
                        : () => {
                            setCurrent({
                                ...current,
                                left: current.left - 1
                            });
                        }}
                ></button>
                <div className="it_pictures_con of_h fl_l">
                    <div
                        className="clearfix ps_r"
                        style={{
                            width: pictures.length * 70,
                            left: current.left * -64
                        }}
                    >
                        {
                            pictures && pictures.map((src, i) => {
                                return (
                                    <TFSImage
                                        className={"preview fl_l" + (src == current.src ? ' curr' : '')}
                                        alt={src}
                                        src={src}
                                        width={120}
                                        height={120}
                                        onClick={() => {
                                            setCurrent({
                                                src,
                                                index: i
                                            });
                                        }}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
                <button
                    className="it_pictures_next_btn fl_l"
                    style={current.left >= pictures.length - 5 ? {
                        opacity: .5
                    }
                        : {
                            cursor: 'pointer'
                        }}
                    onClick={current.left >= pictures.length - 5
                        ? undefined
                        : () => {
                            setCurrent({
                                ...current,
                                left: current.left + 1
                            });
                        }}
                ></button>
            </div>
        </div>
    );
}

export default inject(() => {
    const itemViewModel = autowired('itemViewModel');
    const { item } = itemViewModel;
    const pictures = item.images ? item.images.split('|') : [];
    if (item.platformImage) {
        pictures.unshift(item.platformImage);
    }

    const videoViewModel = autowired('videoViewModel');
    const video = videoViewModel.videos.find((video) => video.type == 1) || null;

    return {
        video,
        pictures
    };
})(Pictures);