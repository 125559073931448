var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import Title from './Title';
import Promotion from './Promotion';

function RedPackets({
    redPackets,
    onRedPacketChange,
}) {
    if (!redPackets.length) {
        return null;
    }

    return (
        <div className="co_cc">
            <Title title="红包" />
            {Array.isArray(redPackets) && redPackets.map((redPacket, i) => {
                return (
                    <Promotion
                        key={i}
                        coupon={redPacket}
                        noUseBtnText="不使用红包"
                        onClick={() => onRedPacketChange(redPacket.code)}
                        onNoUseBtnClick={() => onRedPacketChange('')}
                    />
                );
            })}
        </div>
    );
}

export default inject(() => {
    const {
        fundModel,
        onRedPacketChange,
    } = autowired('orderCreateViewModel');

    const { redCouponCode, redCoupons } = fundModel;

    const couponList = Array.isArray(redCoupons)
        ? redCoupons.reduce((result, coupon) => {
            result.push({
                ...coupon,
                selected: coupon.code == redCouponCode,
            });
            return result;
        }, [])
        : [];

    return {
        redPackets: couponList,
        onRedPacketChange,
    };
})(RedPackets);
