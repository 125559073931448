var React=window.Snowball._React;var { useEffect, useCallback, useState }=window.Snowball._React;
var { $ }=window.Snowball;
var { inject, autowired }=window.Snowball._app;
import DistrictSelector from './DistrictSelector';

export default inject((store, { value, onChange }) => {
    const areaSelectorViewModel = autowired('areaSelectorViewModel');
    const [isDropDownVisible, setDropDownVisible] = useState(false);
    const onDropDown = useCallback(() => {
        areaSelectorViewModel.initWithValue(value);
        setDropDownVisible(true);
    }, [areaSelectorViewModel, value]);

    useEffect(() => {
        return areaSelectorViewModel.onChange(() => {
            setDropDownVisible(false);
            onChange && onChange();
        });
    }, [areaSelectorViewModel, onChange]);

    return {
        area: areaSelectorViewModel.currentArea,
        isDropDownVisible,
        onDropDown,
        onCancel() {
            setDropDownVisible(false);
        }
    };
})(function DistrictDropDown({ area, isDropDownVisible, onDropDown, onCancel }) {
    useEffect(() => {
        const clickBody = (e) => {
            if (isDropDownVisible && $(e.target).closest('.app-district-dropdown-selector').length == 0) {
                onCancel();
                e.stopPropagation();
                e.preventDefault();
            }
        };
        document.body.addEventListener('click', clickBody, true);
        return () => {
            document.body.removeEventListener('click', clickBody, true);
        };
    }, [isDropDownVisible, onCancel]);

    return <div className="app-district-dropdown">
        <button
            className={"app-district-dropdown-main " + (isDropDownVisible ? "open" : "")}
            onClick={onDropDown}
        >{
                area.districtCode
                    ? <span>{area.province} {area.city} {area.district}</span>
                    : <span className="placeholder">请选择省市区</span>
            }</button>
        {
            !!isDropDownVisible && <div className="app-district-dropdown-selector">
                {/* <button
                    className="app-district-dropdown-close"
                    onClick={onCancel}
                >关闭</button> */}
                <DistrictSelector />
            </div>
        }
    </div>;
});