var { controller, autowired, param }=window.Snowball._app;
import PaySuccess from '../containers/PayResult';
import { PaySuccessConfiguration } from '../configuration';
import PayResultViewModel from '../view-models/PayResultViewModel';

@controller({
    component: PaySuccess,
    configuration: PaySuccessConfiguration,
})
export default class PaySuccessController {
    @param('orderId', { type: 'string' })
    orderId;

    @autowired
    _payResultViewModel: PayResultViewModel;

    onInit() {
        this.ctx.logger.makeLog('H5web_pc_pay_result', 'onload', {
            orderId: this.orderId || '',
        });
        if (this.orderId) {
            let orderIds =
                this.orderId.indexOf('__') !== -1 ? this.orderId.split('__') : [this.orderId];

            this.queryOrderTimer = setTimeout(() => {
                this._payResultViewModel.loadOrders(orderIds);

                clearTimeout(this.queryOrderTimer);
                this.queryOrderTimer = null;
            }, 3000);
        }
    }
}
