var React=window.Snowball._React;
import Checkbox from '../../../shared/components/Checkbox';
export function CartHead({ checked, disabled, setAllChecked }) {
    return (
        <div className="cart-thead">
            <div className="cart-column t-checkbox">
                <Checkbox
                    disabled={disabled}
                    checked={checked}
                    onChange={() => setAllChecked(!checked)}
                >全选</Checkbox>
            </div>
            <div className="cart-column t-goods">商品信息</div>
            <div className="cart-column t-props">属性</div>
            <div className="cart-column t-price">单价</div>
            <div className="cart-column t-quantity">数量</div>
            <div className="cart-column t-sum">小计</div>
            <div className="cart-column t-action">操作</div>
        </div>
    );
}