var React=window.Snowball._React;
var { autowired, inject }=window.Snowball._app;
import PayMethod from './PayMethod';
import { TAB_TPYE, PAY_METHOD_KEY } from '../constants';


function HealthCard({
    money,
    heathCardTab,
    onPayMethodClick,
    onImmediatelyPay,
}) {
    return (
        <div className="health_card_container">
            <div>
                {
                    heathCardTab.payMethods.map((payMethod, index) => {
                        switch (payMethod.key) {
                            case PAY_METHOD_KEY.HEALTH_CARD:
                                return (
                                    <PayMethod
                                        key={index}
                                        icon={<img className="va_m mr_m" src={require('../images/jkk.png')} alt="" />}
                                        text="健康卡支付"
                                        recommend={payMethod.recommend}
                                        selected={payMethod.selected}
                                        onClick={() => onPayMethodClick({ tabType: heathCardTab.tabType, payMethod })}
                                    />
                                );

                            case PAY_METHOD_KEY.MOCK:
                                return (
                                    <PayMethod
                                        key={index}
                                        icon={<img className="va_m mr_m" src={require('../images/jkk.png')} alt="" />}
                                        text="模拟支付"
                                        recommend={payMethod.recommend}
                                        selected={payMethod.selected}
                                        onClick={() => onPayMethodClick({ tabType: heathCardTab.tabType, payMethod })}
                                    />
                                );

                            default:
                                return null;

                        }
                    })
                }
            </div>
            <div className="bottom clearfix">
                <div className="btn_pay fl_l cs_p" onClick={onImmediatelyPay}>立即支付</div>
                <div className="fl_l total">
                    <span>应付金额：</span>
                    <span className="money">￥{money ? (money / 100).toFixed(2) : ''}</span>
                </div>
            </div>
        </div>
    );
}

export default inject(() => {
    const {
        money,
        tabs,
        onPayMethodClick,
        onImmediatelyPay,
    } = autowired('checkoutViewModel');

    const heathCardTab = tabs.find(({ tabType }) => tabType == TAB_TPYE.HEALTH_CARD);

    return {
        heathCardTab,
        money,
        onPayMethodClick,
        onImmediatelyPay,
    };
})(HealthCard);
