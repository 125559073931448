var { configuration }=window.Snowball._app;

import ItemViewModel from './view-models/ItemViewModel';
import SkuBuyViewModel from './view-models/SkuBuyViewModel';
import VideoViewModel from './view-models/VideoViewModel';
import CouponViewModel from './view-models/CouponViewModel';
import CommentViewModel from './view-models/CommentViewModel';
import RedPacketViewModel from './view-models/RedPacketViewModel';

export const ItemConfiguration = configuration({
    modules: {
        videoViewModel: VideoViewModel,
        itemViewModel: ItemViewModel,
        skuBuyViewModel: SkuBuyViewModel,
        couponViewModel: CouponViewModel,
        redPacketViewModel: RedPacketViewModel,
        commentViewModel: CommentViewModel
    }
});