var React=window.Snowball._React;
var { EventEmitter, Event, util }=window.Snowball;
var { Service, AppContextProvider, autowired }=window.Snowball._app;
var { toast, popup }=window.Snowball._widget;

const userEventEmitter = new EventEmitter();

let waitingForLogin;
let pmsLoginForWeb;

export default class LoginService extends Service {

    @autowired
    _userService;

    login(url) {
        var beforeLoginEvent = new Event('beforeLogin');
        userEventEmitter.trigger(beforeLoginEvent);
        if (
            beforeLoginEvent.isDefaultPrevented() ||
            beforeLoginEvent.isPropagationStopped()
        ) {
            return;
        }
        if (this.app.env.LOGIN_URL) {
            location.href = util.hashQs(this.app.env.LOGIN_URL, {
                returnUrl: location.href
            });
            return;
        }

        if (waitingForLogin) return waitingForLogin;
        waitingForLogin = loginPopup()
            .then((res) => {
                waitingForLogin = null;
                res && this._userService.fetchUser();
                this.app.emit('login');
                return res;
            })
            .catch((res) => {
                waitingForLogin = null;
                if (res) toast.showToast('登录失败');
                return false;
            });
        return waitingForLogin;
    }

    requestSmsVerifyCode(mobile) {
        return this.app.gateway.request("papaver.requestSmsVerifyCode", false, {
            mobile,
            referer: location.href
        });
    }

    requestCaptcha(params) {
        return this.app.gateway.request('rings.requestCaptcha', false, params, {
            isShowLoading: true,
            autoLogin: false,
        });
    }

    requestSmsVerifyCodeWithCaptcha(mobile, captchaKey, captchaValue) {
        return this.app.gateway.request("papaver.requestSmsVerifyCodeWithCaptcha", false, {
            mobile,
            captchaKey,
            captchaValue,
            referer: location.href
        });
    }
}

function loginPopup() {
    if (!pmsLoginForWeb) {
        pmsLoginForWeb = new Promise(function (resolve, reject) {
            import('../components/LoginPopup').then(
                ({ default: LoginPopup }) => {
                    popup.popup({
                        inQueue: false,
                        className: 'app-signin',
                        content: (
                            <AppContextProvider>
                                <LoginPopup
                                    onClose={(e, result) => {
                                        popup.hidePopup();
                                        pmsLoginForWeb = null;
                                        result ? resolve(result) : reject();
                                    }}
                                />
                            </AppContextProvider>
                        )
                    });
                }
            );
        });
    }
    return pmsLoginForWeb;
}