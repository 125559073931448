var React=window.Snowball._React;
var { popup }=window.Snowball._widget;

export default function popupServiceDescription({
    skuId,
    title,
    brief,
    url,
    onOk,
    onCancel,
}) {
    const modal = popup.confirm({
        title: title,
        content: (
            <div className="od_create__service_desc_popup">
                <div className="mb_m brief fs_m" dangerouslySetInnerHTML={{ __html: brief }} />
                <div
                    className="ta_c fs_m cl_666 td_u"
                    app-link={url}
                    app-track="service_description_popup_detail_click"
                    app-track-params={JSON.stringify({ skuId })}
                >点击查看详细说明</div>
            </div>
        ),
        okText: '确认，继续购买',
        onOk() {
            if (onOk) {
                onOk(modal);
            }
        },
        cancelText: '不符合，暂不购买',
        onCancel() {
            if (onCancel) {
                onCancel(modal);
            }
        },
    });
}