var { ViewModel, autowired }=window.Snowball._app;
var { observable, asObservable }=window.Snowball;
import HeadlineApiService from "../../../shared/apis/HeadlineApiService";
import UserService from "../../../shared/services/UserService";

export default class VideoViewModel extends ViewModel {
    @observable
    videos = [];

    @autowired
    _userService: UserService;

    @autowired
    _headlineApiService: HeadlineApiService;

    setVideos(videos) {
        this.videos = videos;
    }

    async loadIsLike(videoId) {
        const videos = asObservable(this.videos);
        const video = videos.find('id', videoId);
        if (!video) {
            throw new Error('不存在的视频ID' + videoId);
        }
        if (await this._userService.isLogin()) {
            const res = await this._headlineApiService.getHeadinfoDetail(videoId);
            if (res.success) {
                this._setVideoPraise(videoId, res.data.isPraised);
            }
        }
    }

    /**
     * 视频点赞
     *
     * @param {number} videoId 视频ID
     */
    async praise(videoId) {
        if (!await this._userService.ensureLogin()) {
            return;
        }

        const res = await this._headlineApiService.addPraiseToInfo(videoId);
        if (res.success) {
            if (res.data) {
                const video = asObservable(this.videos).find('id', videoId);
                video.set({
                    isPraised: true,
                    numOfAgree: video.get('numOfAgree') + 1
                });
            }
        } else {
            await this.loadIsLike(videoId);
        }
    }

    _setVideoPraise(videoId, isPraised) {
        asObservable(this.videos).updateBy('id', {
            id: videoId,
            isPraised
        });
    }
}