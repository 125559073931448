const MODULE_TYPE = {
    SHARE: -100,
    IMAGE: 1,
    PRODUCT: 2,
    PRODUCT_WATERFALL: 3,
    TOPIC: 4,
    TITLE: 5,
    HEADLINE: 6,
    FIXED_DIY: 7,
    FIXED_CART: 8,
    HEALTHY_LIFE: 9,
    O2O_DRUGSTORE: 10,
    FLOOR: 11,
    O2O_FLOOR: 12,
    CHOPHAND: 14,
    AD_CAROUSEL: 15,
    NAVIGATION: 16,
    O2O_TITLE: 17,
    BOTTOM_IMAGES: 18,
    O2O_CATEGORY: 19,
    O2O_IMAGE: 20,
    FLOATING_LAYER: 21,
    O2O_LOCATION_TITLE: 22,
    O2O_FLOATING_LAYER: 23,
    O2O_DRUGSTORE_COLUMN: 24,
    FLASH_SALE: 25,
    FIGURE_HEAD: 26,
    ANCHOR: 27,
    CMS_BOTTOM_BAR: 28,
    FLASH_SALE_IMAGE: 29,
    COUPON: 30,
    FLASH_SALE_ROUND: 31,
    FLASH_SALE_PRODUCT: 32,
    FLASH_SALE_BOTTOM_BAR: 34,
    FIXED_ACTIVITY: 35,
    HEALTH_GOLD: 36,
    GUIDE: 37,
    EXCHANGE_COUPON: 38,
    MYBEANS: 39,
    BEANS_PRODUCT: 40,
    SLIDE_NAV: 41,
    O2O_WATERFALL: 42,
    O2O_BOTTOM_IMAGES: 43,
    O2O_FIXED_DIY: 44,
    APP_GROUP: 45,
    COMMON_GROUP: 46,
    HEALTH_GOLD_BALANCE: 47,
    BARRAGE: 48,
    NO_HEAD_TOPIC: 49,
    CUSTOM_MEDIUM: 50,
    CMS_PICTAB: 51,
    TOPIC_FOR_NEW_USER: 52,
    TOPIC_OF_DAILY_SALE: 53,
    SECKILL: 54,
    MY_MEDICAL: 55,
    BEST_SELLERS: 56,
    PLAYER: 57,
    MARQUEE: 58,
    MULTIBARRAGE: 59,
    SIGN_IN: 60,
    CATEGORY_ITEM_AD: 61,
    O2O_LOCATION_STORE: 62,
    SMART_RECOMMEND_IMAGE_LIST: 63,
    SMART_RECOMMEND_IMAGE_CAROUSEL: 64,
    PRODUCT_WATERFALL4: 65,
    MULTI_COLS_PRODUCTS: 66,
    SMART_POPUP: 67,
    SEARCH_FILTER_WATERFALL: 68,
    FLASH_SALE_TODAY: 69,
    EQUITY_PACKAGE: 70,
    COST_GOLD_MULTI_COLS_PRODUCTS: 71,
    COST_GOLD_PRODUCT_WATERFALL: 72,
    TAB: 73,
    COST_GOLD_EXCHANGE: 74,
    AGENT_COMMISSION: 75,
    AGENT_PROMOTION_SPU: 76,
    PIC_COUNT_DOWN: 77,
    SMART_PRODUCTS: 78,
    SIGN: 79,
    AWESOME_PRODUCTS: 80,
    CMS_VIDEO: 81,
    CMS_HEADLINE: 82,
    SHARE_BANNER: 83,
    SHOUXIAN_FALL_STAR: 84,
    PUBLIC_DRAINAGE: 85,
    HOT_WORDS: 86,
    TEXT_MARQUEE: 87,
    DOCTOR_CONSULTS: 88,
    EPIDEMIC_SITUATION: 89,
    EPIDEMIC_SITUATION_DETAIL: 90,
    CHANNEL_IMAGES: 91,
    LAYOUT: 200,
    LAYOUT_TAB: 201,
    PC_FLOOR_TITLE: 202,
    PC_FLOOR_TOPIC: 203,
    PC_FLOOR_GOODS: 204,
    PC_FLOOR_BRANDS: 205,
    PC_CATEGORY: 207,
    PC_PRODUCT: 208,
    PC_CAROUSEL: 209,
    PC_BANNER_IMAGE: 210,
    PC_COUPON_IMAGE: 211,
    PC_ANCHOR: 212
};

export default MODULE_TYPE;
