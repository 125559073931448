var React=window.Snowball._React;
import { Header, Menu, Footer } from "jkmall-frame";
import { HomeFixedBar } from "../../../shared/components/HomeFixedBar";
import Modules from "../../modules/Modules";
var { inject, mapViewModelToProps }=window.Snowball._app;
import Main from "../../../shared/components/Main";

function Home(props) {
    const { attrs = {} } = props.pageData;
    const { pageBgColor, pageBgImage } = attrs;
    return (
        <div className="app-wrapper-gray" style={{ backgroundColor: pageBgColor, backgroundImage: `url("${pageBgImage}")` }}>
            <Header />
            <Menu categoryVisible={false} />
            <Main>
                <Modules />
            </Main>
            <HomeFixedBar goConsult={props.goConsult}/>
            <Footer />
        </div>
    );
}

export default inject(({ goConsult }) => {
    return {
        ...mapViewModelToProps('decorationViewModel'),
        goConsult
    };
})(Home);