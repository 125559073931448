var { Service }=window.Snowball._app;

export default class UnicornApiService extends Service {
    get request() {
        return this.app.gateway.request;
    }

    /**
     * 获取订单列表信息
     */
    pageQueryOrderForBuyer({ pageNo = 1, pageSize = 10, statusCode = "ALL" }) {
        return this.request(
            "unicorn.pageQueryOrderForBuyer",
            true,
            {
                pageNo,
                pageSize,
                appChannel: this.app.env.APP_ENV,
                statusCode,
            },
            {
                isShowLoading: false,
                autoLogin: true,
            }
        );
    }

    /**
     * 获取支付 url
     */
    getPayUrl({ orderIds, callBackUrl, outBizType }) {
        return this.request(
            "unicorn.getPayUrl",
            true,
            {
                orderIds,
                callBackUrl,
                outBizType,
            },
            {
                isShowLoading: true,
                autoLogin: false,
            }
        );
    }

    /**
     * 确认收货
     */
    confirmReceipt(bizOrderId = 0) {
        return this.request(
            "unicorn.confirmReceipt",
            true,
            {
                bizOrderId,
            },
            {
                isShowLoading: true,
                autoLogin: false,
            }
        );
    }

    queryOrderForBuyer(bizOrderId) {
        return this.request(
            "unicorn.queryOrderForBuyer",
            true,
            {
                bizOrderId,
                appChannel: this.app.env.APP_ENV,
                outBizType: this.app.env.OUT_BIZ_TYPE,
            },
            {
                isShowLoading: true,
            }
        );
    }

    getAppResource({ system, channel, type, source, keyName = "" }) {
        return this.request(
            "unicorn.getAppResource",
            false,
            {
                system,
                channel,
                type,
                source,
                keyName,
            },
            {
                isShowLoading: false,
                autoLogin: false,
            }
        );
    }

    getAfterSalesOrderApplicationPage({ amount, orderId, skuId }) {
        return this.request(
            "unicorn.getAfterSalesOrderApplicationPage",
            true,
            {
                amount,
                orderId,
                skuId,
            },
            {
                isShowLoading: false,
            }
        );
    }

    closeOrder(orderId) {
        return this.request(
            "unicorn.closeOrder",
            true,
            {
                orderId,
            },
            {
                isShowLoading: false,
            }
        );
    }

    cancelOrder(orderId, factor) {
        return this.request(
            "unicorn.cancelOrder",
            true,
            {
                orderId,
                factor,
            },
            {
                isShowLoading: false,
            }
        );
    }

    wholeOrderRefund(orderId, factor) {
        return this.request(
            "unicorn.wholeOrderRefund",
            true,
            {
                orderId,
                factor,
            },
            {
                isShowLoading: false,
            }
        );
    }

    /**
     * 创建售后单
     *
     * @param {object} data
     * @param {*} data.orderId
     * @param {*} data.orderStatus 申请售后时的订单状态
     * @param {*} data.refundType 后单类型，单商品仅退款:1/单商品退货退款:2
     * @param {*} data.factor 申请售后原因
     */
    createAfterSalesOrderAll({ orderId, orderStatus, refundType, factor }) {
        return this.request(
            "unicorn.createAfterSalesOrderAll",
            true,
            {
                orderId,
                afterSalesOrderArgs: {
                    orderStatus,
                    type: refundType,
                    factor,
                },
                afterSalesOrderExt: {
                    outBizType: this.app.env.OUT_BIZ_TYPE,
                },
            },
            {
                isShowLoading: false,
            }
        );
    }

    /**
     * 根据订单号获取售后单列表
     */
    getAfterSalesOrdersByTradeId(orderId) {
        return this.request("unicorn.getAfterSalesOrdersByTradeId", true, {
            orderId,
            afterSalesOrderExt: {
                outBizType: this.app.env.OUT_BIZ_TYPE,
            },
        });
    }

    /**
     * 根据用户获取售后列表
     */
    getAfterSalesOrdersByBuyerId({ pageNo, pageSize }) {
        return this.request("unicorn.pageQueryAfterSalesOrdersByBuyerId", true, {
            pageNo: pageNo,
            pageSize: pageSize,
        });
    }

    /**
     * 获取售后信息
     *
     * @param {*} requestNo
     */
    getAfterSalesOrderDetail(requestNo) {
        return this.request("unicorn.getAfterSalesOrderDetail", true, {
            requestNo,
        });
    }

    /**
     * 删除订单
     *
     * @param {*} tradeId
     */
    hideOrder(tradeId) {
        return this.request("unicorn.hideOrder", true, {
            tradeId,
            appChannel: this.app.env.APP_ENV,
        });
    }

    /**
     * 退货退款
     */
    createAfterSalesOrder({ amount, orderId, skuId, afterSalesOrderArgs }) {
        return this.request("unicorn.createAfterSalesOrder", true, {
            amount,
            orderId,
            skuId,
            afterSalesOrderArgs,
        });
    }

    /**
     * 退货时提交物流信息
     */
    submitAfterSalesLogisticsInfo({ afterSalesOrderId, afterSalesOrderLogisticsArgs }) {
        return this.request("unicorn.submitAfterSalesLogisticsInfo", true, {
            afterSalesOrderId,
            afterSalesOrderLogisticsArgs,
            afterSalesOrderExt: {
                outBizType: this.app.env.OUT_BIZ_TYPE,
            },
        });
    }

    /**
     * 取消退款/退货退款
     */
    cancelAfterSalesOrder({ afterSalesOrderId }) {
        return this.request("unicorn.cancelAfterSalesOrder", true, {
            afterSalesOrderId,
            afterSalesOrderExt: {
                outBizType: this.app.env.OUT_BIZ_TYPE,
            },
        });
    }

    /**
     * 前台类目
     */
    getFrontCategory(id) {
        return this.request("unicorn.getFrontCategory", false, {
            id,
        });
    }

    getFirstLevelFrontCategory(params) {
        return this.request("unicorn.getFirstLevelFrontCategory", false, params);
    }
}
