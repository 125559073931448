
var { $ }=window.Snowball;
var { isBoolean }=window.Snowball.util;

export function registerDirectives(app) {
    $(document.body)
        .on('click', '[app-link]', function (e) {
            var url = e.currentTarget.getAttribute('app-link');
            url && app.navigation.forward(url);
        })
        .on('click', 'a', function (e) {
            var href = e.currentTarget.getAttribute('href');
            var openTarget = e.currentTarget.getAttribute('target');

            // a标签设置了target _blank则不劫持
            if (openTarget === '_blank') { return; }

            if (!href) return false;

            if (!/^(javascript|mailto|tel):/.test(href)) {
                var element = e.currentTarget;
                (async () => {
                    if (!await checkLoginStatus(element)) return;
                    transitionTo(href, shouldDelayRedirect(element));
                })();
                return false;
            }
        });

    async function transitionTo(url, isDelay, pageSource) {
        if (isDelay !== undefined && !isBoolean(isDelay)) {
            pageSource = isDelay;
            isDelay = false;
        }

        if (/^(https?:)?\/\//.test(url)) {
            redirect(url, isDelay);
        } else {
            app.navigation.forward(url);
        }
    }

    async function checkLoginStatus(element) {
        if (element.getAttribute('app-should-login') !== null) {
            const userService = app.autowired('userService');
            const loginService = app.autowired('loginService');
            if (!await userService.isLogin()) {
                if (!await loginService.login(element.getAttribute('app-link'))) {
                    return false;
                }
            }
        }
        return true;
    }

    function shouldDelayRedirect(element) {
        return !!element.getAttribute('app-track');
    }

    function redirect(url, isDelay, pageSource) {
        isDelay ? delayRedirect(url, pageSource) : redirectImmediately(url, pageSource);
    }

    function delayRedirect(url, pageSource) {
        setTimeout(redirectImmediately.bind(null, url, pageSource), 300);
    }

    function redirectImmediately(url, pageSource) {
        if (!url) return;
        var currentPage = app.currentCtx.page;
        currentPage.storeCache();
        location.href = url;
    }
}
