var { configuration }=window.Snowball._app;

import PayResultViewModel from './view-models/PayResultViewModel';
import OrderOperationHandlers from '../order-info/view-models/OrderOperationHandlers';

export const PaySuccessConfiguration = configuration({
    modules: {
        payResultViewModel: PayResultViewModel,
        orderOperationHandlers: OrderOperationHandlers
    }
});
