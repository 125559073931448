var { observable, asObservable, util }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
var { toast }=window.Snowball._widget;
import AreaSelectorViewModel from "./AreaSelectorViewModel";
import AddressApiService from "../../../shared/apis/AddressApiService";
import * as GIS from '../../../utils/gis';

const scheme = {
    recipientName: [{
        required: true,
        message: '请输入收货人姓名'
    }, {
        max: 15,
        message: '姓名过长，请在15个汉字长度以下',
    }],
    recipientPhone: [{
        required: true,
        message: '请输入手机号'
    }, {
        pattern: /^(\+[\d]{2,4})?[0-9]{11}$/,
        message: '请输入正确的手机号码'
    }],
    streetAddress: [{
        required: true,
        message: '请输入详细地址'
    }, {
        max: 100,
        message: '收货地址过长，请在100个汉字长度以下',
    }],
    referAddr: [{
        required: true,
        message: '请输入楼层门牌'
    }, {
        max: 20,
        message: '门牌号码最大只能输入20个字符',
    }],
    addressLabel: []
};

export default class AddressFormViewModel extends ViewModel {
    @autowired
    _areaSelectorViewModel: AreaSelectorViewModel;

    @autowired
    _addressApiService: AddressApiService;

    @autowired
    _addressViewModel;

    @observable
    formData = {};

    @observable
    errors = {};

    @observable
    suggestAddresses = [];

    @observable
    isSuggestAddressesVisible = false;

    constructor() {
        super();

        this._areaSelectorViewModel.onChange(() => {
            this.onFieldsChange(this._areaSelectorViewModel.currentArea);

            asObservable(this.formData)
                .set({
                    streetAddress: '',
                    referAddr: ''
                });
        });
    }

    @emitter
    onSelectStreetAddress(item) {
        asObservable(this.formData).set({
            streetAddress: item.name,
            latitude: item.location ? item.location.lat : null,
            longitude: item.location ? item.location.lng : null,
        });
        asObservable(this.errors)
            .set(this.app.validate({ streetAddress: item.name }, util.pick(scheme, Object.keys({ streetAddress: item.name }))));
        this.isSuggestAddressesVisible = false;
    }

    @emitter
    onSave() {
        if (this.saving) return;
        this.saving = true;
        this.save((result) => {
            if (result.success) {
                toast.showToast('保存成功');
            }
            this.saving = false;
        });
    }

    @emitter
    onFieldsChange(data) {
        asObservable(this.errors)
            .set(this.app.validate(data, util.pick(scheme, Object.keys(data))));

        if ('streetAddress' in data) {
            data = {
                ...data,
                latitude: null,
                longitude: null,
                bizSubType: 'b2c'
            };
            if (data.streetAddress) {
                this._loadSuggestAddresses(data.streetAddress);
            } else {
                this.suggestAddresses = [];
                this.isSuggestAddressesVisible = false;
            }
        }

        asObservable(this.formData)
            .set(data);
    }

    _loadSuggestAddresses = util.debounce((streetAddress) => {
        GIS.getSuggestAddress(streetAddress, null, null, this.formData.city)
            .then(res => {
                this.suggestAddresses = res;
                this.isSuggestAddressesVisible = true;
            });
    }, 300)

    setFormData(formData) {
        this.formData = formData;
        this.errors = {};
        this._areaSelectorViewModel.initWithValue([formData.provinceCode, formData.cityCode, formData.districtCode]);
    }

    save(cb) {
        this.submit(async (data) => {
            if (!data.preferred) {
                const listResult = await this._addressApiService.getAllAddressesByBiz('mall');
                let addressList = listResult.success ? listResult.data.value || [] : [];
                if (!addressList.length) {
                    data.preferred = true;
                }
            }
            const result = await this._addressApiService.saveOrUpdateAddress(data);
            this._addressViewModel.loadAllAddresses();
            cb && cb(result);
        });
    }
    async submit(onSuccess, onError?) {
        this.errors = this.app.validate(this.formData, scheme);
        if (Object.keys(this.errors).some((key) => this.errors[key])) {
            onError && onError(this.errors);
        } else {
            onSuccess({ ...this.formData });
        }
    }
}