var { ViewModel, autowired }=window.Snowball._app;
var { observable, asObservable }=window.Snowball;

export default class ConsultViewModel extends ViewModel {

    @autowired
    _consultAuditApiService;

    @observable
    status = {
        visible: false,
        width: 0,
        height: 0
    };

    setRef(ref) {
        if (ref) {
            this.container = ref;
        }
    };

    async setConsultInfo(url, patientId) {
        this.removeListener();
        asObservable(this.status).set({
            visible: true
        });
        setTimeout(() => {
            this.initConsult(url);
        }, 0);
    }

    initConsult(url) {
        this.container.src = url;
        this.addListener();
    };

    addListener() {
        window.addEventListener('message', this.handleMessage);
    }

    removeListener() {
        window.removeEventListener('message', this.handleMessage);
    }

    handleMessage = (e) => {
        // todo 不是iframe这个src过来的，不接受
        // var origin = event.origin || event.originalEvent.origin;
        // if (origin.lastIndexOf('') === -1) {
        //     return;
        // }
        console.log('%c=收到消息了=====>>>>>>', 'color: #f20', e);

        if (
            e.data &&
            Object.prototype.toString.call(e.data) === '[object String]' &&
            e.data.charAt(0) === '{' && e.data.slice(-1) === '}'
        ) {
            var data = JSON.parse(e.data);
            switch (data.action) {
                case 'resize':
                    asObservable(this.status).set({
                        visible: true,
                        width: data.data.width || 300,
                        height: data.data.height || 300
                    });
                    break;
                case 'close':
                    asObservable(this.status).set({
                        visible: false
                    });
                    this.removeListener();
                    break;
                default:
                    break;
            }
        }
    }

    sendMessage(message) {
        message = typeof message === 'string'
            ? message
            : JSON.stringify(message);
        this.container && this.container.contentWindow.postMessage(message, '*');
    }
}