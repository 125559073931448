var { observable }=window.Snowball;
var { ViewModel, autowired }=window.Snowball._app;

export default class UserCidViewModel extends ViewModel {

    @observable
    userCid = {};

    @autowired
    _unicornApiService

    @autowired
    _masterApiService

    updateCid = async (addressId, cid) => {
        let res = await this._unicornApiService.updateUserInputInfo(addressId, cid);
        if (res.success) {
            this.setCid(cid);
            return true;
        }
        return false;
    };

    getModel() {
        return this.cidModel;
    }

    setCid(cid) {
        this.userCid = { cid };
    }

    restore() {
        this.cidModel = {};
    }
    userVerfiy(addressId, user) {
        return this._masterApiService.userVerify(addressId, user);
    }
}
