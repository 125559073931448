var React=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
import PersonInfomation from '../components/PersonInfomation';
import UserCenterMain from '../../../shared/components/UserCenterMain';
import UserCenterTitle from '../../../shared/components/UserCenterTitle';

function PersonInfo(props) {
    const {
        onSave,
        showNicknameError,
        onFieldsChange,
        onGenderClicked,
        inputError,
        personInfo,
        isSaving
    } = props;
    return (
        <UserCenterMain name="profile">
            <UserCenterTitle title="个人资料" />
            <PersonInfomation
                save={ onSave }
                showNicknameError={showNicknameError}
                onFieldsChange={onFieldsChange}
                onGenderClicked={onGenderClicked}
                error={inputError}
                personInfo={personInfo}
                isSaving={isSaving}
            />
        </UserCenterMain>
    );
}

export default inject(() => {
    return {
        ...mapViewModelToProps('personInfoViewModel'),
    };
})(PersonInfo);