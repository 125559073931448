import { request } from './request';

export function getUserInfo() {
    return request("user.getUserInfo", true, null, {
        isShowLoading: false,
        autoLogin: false
    });
}

export function logout() {
    return request("user.logout", true, null);
}