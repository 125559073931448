var { observable }=window.Snowball;
var { Service, autowired }=window.Snowball._app;

export default class OrderAppResourceService extends Service {
    @observable
    documents = [];

    @observable
    topTips = "";

    @autowired
    _appResourceService;

    loadCommonAppResources() {
        return this._appResourceService.getAppResource({
            system: 'plugin',
            channel: 'all',
            type: 'text',
            source: 'common'
        })
            .then(res => {
                this.documents = res.values;
                return res;
            });
    }

    loadTopTips() {
        this._appResourceService.getAppResource({
            system: 'plugin',
            channel: 'all',
            type: 'text',
            source: 'orderInfo'
        })
            .then(res => {
                this.topTips = res.values.find(item => item.keyName == 'confidence-game').val;
            });
    }
}