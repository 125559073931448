var { controller, autowired, param }=window.Snowball._app;
import { TradeStatusDetailConfiguration } from '../configuration';
import TradeStatusDetailService from '../services/TradeStatusDetailService';
import TradeStatusDetails from '../containers/TradeStatusDetails';

@controller({
    component: TradeStatusDetails,
    configuration: TradeStatusDetailConfiguration,
})
export default class TradeStatusDetailController {
    @param
    orderId;

    @autowired
    _tradeStatusDetailService: TradeStatusDetailService;

    get statusDetails() {
        return this._tradeStatusDetailService.statusDetails;
    }

    onInit() {
        this.ctx.logger.makeLog('H5web_pc_order_status', 'onload', { orderId: this.orderId });
        this._tradeStatusDetailService.loadTradeStatusDetails(this.orderId);
    }
}
