var React=window.Snowball._React;
import LoginInfo from './components/LoginInfo';
import MyPajk from './components/MyPajk';
import MobileApp from './components/MobileApp';
import Slogan from './components/Slogan';
import SearchBar from './components/SearchBar';
import MyCart from './components/MyCart';
import CustomContainer from './components/CustomContainer';
import useHeaderUser from './hooks/useHeaderUser';
import useAppConfig from '../../shared/hooks/useAppConfig';

const Header = ({
    showExtra,
    isLogin,
    className,
    renderExtra
}) => {
    const { user, logout } = useHeaderUser({
        isLogin,
    });
    const { imgs: appList = [] } = useAppConfig();

    return (
        <div className={`mall_common__header ${className || ''}`}>
            <div className="mall_common__header__nav">
                <div className="mall_common__header__nav_container">
                    <LoginInfo isLogin={user.isLogin} nickName={user.nickName} logout={logout} />
                    <div className="mall_common__header__quick_menu">
                        <MyPajk couponCount={user.couponNum} />
                        <MobileApp appList={appList} />
                    </div>
                </div>
            </div>
            <div className="mall_common__header__extra">
                <div className="mall_common__header__extra_container">
                    {renderExtraContent({
                        cartNum: user.cartNum,
                        appList,
                        showExtra,
                        renderExtra
                    })}
                </div>
            </div>
        </div>
    );
};

function renderExtraContent({
    showExtra = true,
    cartNum,
    renderExtra,
    appList
}) {
    // 给一个custom div 让第三方自己render里面内容
    if (!showExtra) {
        return (
            <React.Fragment>
                <Slogan />
                <CustomContainer />
            </React.Fragment>
        );
    }

    const originalExtraContent = (
        <React.Fragment>
            <Slogan />
            <SearchBar />
            <MyCart count={cartNum} />
            <MobileApp type="horizontal" appList={appList} />
        </React.Fragment>
    );

    return renderExtra ? renderExtra({ cartNum }) : originalExtraContent;
}

Header.getCustomContainer = (container) => {
    return (container || document).querySelector(CustomContainer.getId());
};

export default Header;
export { Slogan, SearchBar, MyCart, MobileApp };