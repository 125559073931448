var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import OrderInfoViewModel from '../view-models/OrderInfoViewModel';
import TFSImage from '../../../shared/components/TFSImage';

function Product({
    APP_ENV,
    isLastItem,
    item,
    tradeOrder,
    orderOutBizInfo,
    buttonStatus,
    onClickItem,
    onConsult,
    onUseCardService,
    onApplyAfterSale,
    onGoToAfterSaleList,
    onDzcfBtnClick
}) {
    const {
        orderStatus,
        // 订单来源，如果来自视频推药则显示处方药信息
        source: orderSource,
        tradeExtMaps = []
    } = tradeOrder;

    // 是否是电子处方
    let isHealthPres = orderSource.indexOf('HEALTH_PRES') !== -1;

    // 养老险控费电子处方，健康险控费电子处方不展示退款相关按钮
    let hideRefundBtn = (orderSource === 'JKX_HEALTH_PRES' || orderSource === 'HFLKF_HEALTH_PRES');
    // 待审核处方单状态，不展示退款相关按钮
    if (orderStatus === 'WAITING_TO_APPROVE_HP') {
        hideRefundBtn = true;
    }

    const isCod = tradeExtMaps.some((obj) => (obj.key == 'orderTag' && obj.value == 'CodRx'));

    let level1Key = item.level1Key || '';
    let level1Spec = item.level1Spec || '';
    let level2Spec = item.level2Spec || '';
    let level3Spec = item.level3Spec || '';
    let level4Spec = item.level4Spec || '';
    let level5Spec = item.level5Spec || '';
    let healthBeanPrice = item.healthBeanPrice || 0;

    const isChineseMedicine = orderSource == 'CHINESE_MEDICINE';
    const {
        showPrice,
        specText,
        // 默认图片
        defaultItemPic,
        totalCount
    } = isChineseMedicine
        ? {
            showPrice: false,
            defaultItemPic: 'images/nozy.jpg',
            specText: `规格 ${item.amount / (orderOutBizInfo.zyCount || 1)}g`,
            totalCount: orderOutBizInfo.zyCount || 1
        }
        : {
            showPrice: true,
            defaultItemPic: 'images/prescription.png',
            specText: `${level1Key ? level1Key + ":" + level1Spec : level1Spec} ${level2Spec} ${level3Spec} ${level4Spec} ${level5Spec}`,
            totalCount: item.amount
        };

    // 非电子处方处方药
    const isPrescription = !isCod && item.itemSubType === 'RECIPE' && orderSource != "VEDIO_MEDICINE" && !isHealthPres;
    const itemPic = (isChineseMedicine || !isPrescription) && item.itemPic
        ? item.itemPic
        : defaultItemPic;

    let showDzcfButton = false;
    // 电子处方按钮显示在最后一个上
    if (isLastItem && buttonStatus.healthPresButton && buttonStatus.healthPresButtonUrl) {
        showDzcfButton = true;
    }

    // 实体卡不展示服务详情按钮
    const showCardServiceButton = buttonStatus.viewCardsButton && !(item.itemType === 'CARD' && item.itemSubType === 'ENTITY');
    let cardButtonText = (APP_ENV.indexOf('ENTERPRISE') != -1 && tradeOrder.bizType === "HEALTH_VIRTUAL_CARD")
        ? "立即激活"
        : "服务详情";
    // 115和120商品，显示服务详情
    if (orderStatus === 'FINISH' && (item.itemType == 'COMM_SERVICE_ITEM' || item.itemType == 'SERVICE_ITEM')) {
        cardButtonText = '服务详情';
    }

    // 是否是赠品
    let present = item.tradeExtMaps.some(tradeExtMap => tradeExtMap.key === 'isGive');

    return (
        <tr className={
            "oi_item"
            + (
                showDzcfButton || showCardServiceButton || buttonStatus.applyOrderAfterSaleButton || buttonStatus.applyRefundForItemButton || buttonStatus.refundRecordForItemButton
                    ? " service"
                    : ""
            )
        }>
            <td>
                <a
                    href="javascript:;"
                    className="item"
                    onClick={() => !isPrescription && onClickItem(item)}
                >
                    <TFSImage
                        className="img fl_l"
                        src={itemPic}
                        width={120}
                        height={120}
                    />
                    <div className="fl_l ps_r ml_m">
                        <div className="title">
                            {
                                present && <span className='product_present'>赠品</span>
                            }{isPrescription ? '药品详情请咨询药师' : item.detailTitle}
                        </div>
                        {
                            isPrescription
                                ? null
                                : <p className="desc">{item.standard} {specText}</p>
                        }
                    </div>
                </a>
            </td>
            <td className="price ta_c">
                {
                    healthBeanPrice > 0 && (
                        <p className="price">{healthBeanPrice}豆</p>
                    )
                }
                {
                    (healthBeanPrice > 0) && (showPrice && item.actualPrice > 0) && (
                        <p>+</p>
                    )
                }
                {
                    showPrice && item.actualPrice > 0
                        ? <p className="price"><span className="f11">￥</span>{(item.actualPrice / 100)}</p>
                        : null
                }
            </td>
            <td className="count ta_c">× {totalCount}</td>
            <td className="ta_c">
                <button
                    title="客服咨询"
                    onClick={() => onConsult(tradeOrder)}
                    className={"oi_my_service"}
                >
                </button>
            </td>
            <td className="summary ta_c">
                {
                    showPrice && item.actualPrice > 0
                        ? <p className="price"><span className="f11">￥</span>{(item.actualPrice / 100) * item.amount}</p>
                        : null
                }
                <p>
                    {
                        tradeOrder.payMode == "CASHONDELIVERY"
                            ? '货到付款'
                            : '在线支付'
                    }
                </p>
            </td>
            <td className="oi_item_buttons">
                <button
                    className="show-service-button"
                    style={{ display: showCardServiceButton ? "block" : "none" }}
                    onClick={() => onUseCardService(item)}
                >{cardButtonText}</button>
                {
                    (buttonStatus.applyOrderAfterSaleButton && !hideRefundBtn)
                        ? (
                            <button
                                className='btn_solid'
                                onClick={() => onApplyAfterSale({
                                    tradeOrder,
                                    item,
                                    buttonStatus
                                })}
                            >申请售后</button>
                        )
                        : null
                }
                {
                    (buttonStatus.applyRefundForItemButton && !hideRefundBtn)
                        ? (
                            <button
                                className='btn_solid'
                                onClick={() => onApplyAfterSale({
                                    tradeOrder,
                                    item,
                                    buttonStatus
                                })}
                            >退款</button>
                        )
                        : null
                }
                {
                    (buttonStatus.refundRecordForItemButton && !hideRefundBtn)
                        ? (
                            <button className='btn_solid' onClick={() => onGoToAfterSaleList(tradeOrder.tradeId)}>退款详情</button>
                        )
                        : null
                }
                {
                    showDzcfButton
                        ? (
                            <button
                                className="show-service-button"
                                onClick={() => onDzcfBtnClick({
                                    item,
                                    buttonStatus
                                })}
                            >电子处方</button>
                        )
                        : null
                }
            </td>
        </tr>
    );
}

function mapOrderInfoToProps(orderInfoViewModel: OrderInfoViewModel, props) {
    return {
        tradeOrder: orderInfoViewModel.tradeOrder,
        orderOutBizInfo: orderInfoViewModel.orderOutBizInfo,
        buttonStatus: {
            ...orderInfoViewModel.buttonStatus,
            ...props.item.buttonStatus
        },
    };
}

export default inject(({ app }, props) => {
    const orderInfoViewModel = autowired('orderInfoViewModel');
    const orderOperationHandlers = autowired('orderOperationHandlers');
    return {
        ...mapOrderInfoToProps(orderInfoViewModel, props),
        onClickItem: (item) => app.navigation.forward('/item/' + item.itemId),
        onConsult: orderOperationHandlers.onConsult.emit,
        onApplyAfterSale: orderOperationHandlers.onApplyItemAfterSale.emit,
        onGoToAfterSaleList: orderOperationHandlers.onGoToAfterSaleList.emit,
        onDzcfBtnClick: orderOperationHandlers.onDzcfBtnClick.emit,
        APP_ENV: app.env.APP_ENV
    };
})(Product);