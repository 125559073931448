var { ViewModel, autowired, emitter }=window.Snowball._app;
var { observable }=window.Snowball;
import RefundFormViewModel from "./RefundFormViewModel";

export default class RefundModalViewModel extends ViewModel {
    @observable
    visible = false;

    @autowired
    _refundFormViewModel: RefundFormViewModel;

    // onInit() {
    //     this.refund({
    //         amount: 1,
    //         orderId: 121217990103,
    //         skuId: 911911888575707
    //     });
    // }

    refund({
        amount,
        orderId,
        skuId,
        refundType
    }) {
        this._refundFormViewModel.maxRefundNum = amount;
        this._refundFormViewModel.setFormData({})
            .loadRefundInfo({
                amount,
                orderId,
                skuId,
                refundType
            });
        this.showModal();
    }

    @emitter
    onCancel() {
        this.hideModal();
    }

    showModal() {
        this.visible = true;
        return this;
    }

    hideModal() {
        this.visible = false;
        return this;
    }
}