var React=window.Snowball._React;
export default function UserCenterTitle({ title, desc, toolbar }) {
    return (
        <>
            <div className="usercenter-title clearfix of_h">
                <h1>{title}</h1>
                <p className="desc">
                    {desc}
                </p>
                <div className="fl_r">
                    {toolbar}
                </div>
            </div>
            <div class="usercenter-title-seperator">
                <div className="orange">
                    <div className="triangle"></div>
                </div>
                <div className="line"></div>
            </div>
        </>
    );

}