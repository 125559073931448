var { observable, asObservable, util }=window.Snowball;
var { ViewModel, autowired }=window.Snowball._app;
var { toast }=window.Snowball._widget;

import MasterApiService from "../../../shared/apis/MasterApiService";
import GlobalAddressService from "../../../shared/services/GlobalAddressService";
import SkuBuyViewModel from "./SkuBuyViewModel";
import VideoViewModel from "./VideoViewModel";
import { BUY_TYPES } from "../constants";
import CommentViewModel from "./CommentViewModel";
import AppResourceService from "@/shared/services/AppResourceService";

export default class ItemViewModel extends ViewModel {
    @autowired
    _userService;

    @autowired
    _masterApiService: MasterApiService;

    @autowired
    _globalAddressService: GlobalAddressService;

    @autowired
    _skuBuyViewModel: SkuBuyViewModel;

    @autowired
    _videoViewModel: VideoViewModel;

    @autowired
    _commentViewModel: CommentViewModel;

    @autowired
    _appResourceService: AppResourceService

    // pc 站不使用lml
    // lml;

    @observable
    itemConfigs = {};

    // 商详商品域
    @observable
    item = {};

    // 商详商户域
    @observable
    seller = {};

    // 商详资金域
    @observable
    fund = {};

    // 商详用户域
    @observable
    user = {};

    @observable
    lg = {};

    // 商详展示信息
    @observable
    pres = {};

    // 商详页重定向标志，0：旧商详，1：拼团商详，-1：异常
    @observable
    control;

    // 商品评价信息
    @observable
    comment;

    // 拼团信息
    @observable
    group;

    // 限时抢购
    @observable
    flashSale;

    // 单买价信息
    @observable
    relatedItem;

    @observable
    newUserCoupon;

    @observable
    freightText = '';

    // 咨询客服
    @observable
    customerServiceUrl;

    @observable
    error;

    /**
     * 是否是处方药
     */
    get isPrescriptionDrug() {
        return this.item.spuType == 20 && this.item.spuSubType == 10;
    }

    onLoad = this.ctx.createEmitter();

    constructor() {
        super();

        this.ctx.autorun(() => {
            this.user = this._userService.user;
        });

        this.onLoad.once(() => {
            this.listenAddressChange();
        });

        // TODO:下架情形，哪些控件不展示
    }

    listenAddressChange() {
        this.ctx.autoDispose(
            asObservable(this._globalAddressService)
                .observe('address', (address) => {
                    this.loadByAddress(this.spuId, address);
                })
        );
    }

    async load(spuId) {
        const globalAddress = await this._globalAddressService.fetch();
        const result = await this.loadByAddress(spuId, globalAddress);
        this.onLoad.emit(result);
        return result;
    }

    async loadByAddress(spuId, address) {
        this.spuId = spuId;

        const { provinceCode, cityCode } = address;
        const [result, configs] = await Promise.all([
            this._masterApiService.getItemDetail({
                spuId,
                prCode: provinceCode,
                cyCode: cityCode,
                storeId: this.storeId,
                skuId: this.skuId,
                flowLimited: this.useFlowLimited
            }),
            this._appResourceService.getAppResource({
                system: 'jkmall',
                channel: 'all',
                type: 'text',
                source: 'common',
            })
        ]);

        if (!result.success) {
            toast.showToast(result.message);
            this.error = result;
            this.app.navigation.replace('/error?t=' + encodeURIComponent(result.message));
            return;
        }

        if (!result.data.item) {
            this.app.navigation.replace('/error?t=' + encodeURIComponent('商品不存在'));
            return;
        }

        const itemConfig = configs.values.find(cfg => cfg.keyName == 'item');
        if (itemConfig && itemConfig.val) {
            this.itemConfigs = JSON.parse(itemConfig.val);
        }

        const data = result.data;
        const { item } = data;
        data.user && asObservable(this.user).set(data.user);
        this.item = item;
        this.seller = data.seller;
        this.fund = data.fund;
        this.lg = data.lg;
        this.pres = data.pres;
        this.control = data.control;
        this.comment = data.comment;
        this.group = data.group;
        this.flashSale = data.flashSale;
        this.relatedItem = data.relatedItem;
        this.error = null;

        this._skuBuyViewModel.getSkus(spuId, data.seller.storeId, 0, {
            provinceCode,
            cityCode
        });

        // 处方药
        // https://yao.jk.cn/shop/#/item/59637
        // 若商品类型为2020-商城商品，2015-非处方药，则显示加入购物车按钮。
        // 若商品类型为2010-处方药，则显示提交需求清单按钮
        const buyType = item.spuType == 20 && (item.spuSubType == 20 || item.spuSubType == 15)
            ? BUY_TYPES.ADD_TO_CART
            : (item.spuType == 20 && item.spuSubType == 10)
                ? BUY_TYPES.DEMAND_LIST
                : BUY_TYPES.NONE;
        this._skuBuyViewModel.setBuyOptions({
            buyType,
            storeId: data.seller.storeId,
            item: this.item
        });

        this._videoViewModel.setVideos(this.pres.videos || []);
        this._loadNewUserCoupon();
        this._syncFreight();
        this._getCustomerServiceUrl({
            spuId: item.spuId,
            spuType: item.spuType,
            spuTitle: item.title,
            sellerId: data.seller.sellerId,
        });

        this._commentViewModel.setBaseParams(data.item.spuId, data.seller.sellerId);
    }

    async _getCustomerServiceUrl({
        spuId,
        spuType,
        spuTitle,
        sellerId
    }) {
        // 获取售前客服地址
        const result = await this._masterApiService.getCustomerServiceUrl(
            sellerId,
            spuType,
            'presale'
        );

        if (result.success && result.data.success && result.data.url) {
            const params = util.params({
                channel: this.app.env.APP_ENV,
                bizdata: {
                    source: this.app.env.APP_ENV,
                    serviceenter: 'product',
                    goodstid: spuId,
                    goodstype: spuType,
                    goodsName: spuTitle,
                    goodsUrl: location.href
                }
            });
            this.customerServiceUrl = `${result.data.url.replace('#/im/', '#/im-pc/')}&${params}`;
        }
    }

    goCustomService = async () => {
        if (!await this._userService.ensureLogin()) {
            return;
        }
        window.open(this.customerServiceUrl);
    }

    async _loadNewUserCoupon() {
        // 是否支持新人券
        if (this.fund.supportNewCoupon && await this._userService.isLogin()) {
            let res = await this._masterApiService.fetchNewCoupon({
                spuId: this.spuId,
                sellerId: this.seller.sellerId,
                skuIds: this.item.skus.map(sku => {
                    return sku.skuId;
                }),
                categoryId: this.item.categoryId
            });
            this.newUserCoupon = res.data;
        }
    }

    _syncFreight() {
        const {
            lgPrice,
            lgFreeThreshold,
            lgFreeRuleExist,
        } = this.fund;

        // 是否有包邮规则
        let type = lgPrice == 0 || (lgFreeRuleExist && lgFreeThreshold == 0)
            ? 'FREE'
            : lgFreeRuleExist
                ? 'NORMAL'
                : 'OTHER';

        const { lgTemplate = '{}' } = this.pres;
        const lgTemplateObj = JSON.parse(lgTemplate);
        const template = lgTemplateObj[type];

        if (!template) {
            this.freightText = '';
        } else {
            this.freightText = this._formatTemplate(template.barText);
        }
    }

    _formatTemplate(text) {
        const { HEALTH_GOLD_TEXT } = this.app.env;
        const {
            lgPrice,
            lgFreeThreshold,
        } = this.fund;

        return text
            .replace(/{{X}}/g, lgPrice / 100)
            .replace(/{{Y}}/g, lgFreeThreshold / 100)
            .replace(/{{ADDRESS}}/g, this._globalAddressService.address.fullAddress)
            .replace(/{{HEALTH_GOLD_TEXT}}/g, HEALTH_GOLD_TEXT);
    }
}