
var React=window.Snowball._React;var { Fragment }=window.Snowball._React;
import { Header, Footer, Logo } from 'jkmall-frame';
import Receivers from '../components/Receivers';
import ConfirmItems from '../components/ConfirmItems';
import Payment from '../components/Payment';
import CategoryCoupons from '../components/CategoryCoupons';
import RedPackets from '../components/RedPackets';
import Bottom from '../components/Bottom';
import AddressEditorModal from '../../address/components/AddressEditorModal';
import ProgressList from '../../../shared/components/ProgressList';


export default function OrderCreate() {
    return (
        <Fragment>
            <Header renderExtra={() => {
                return (
                    <div className="clearfix">
                        <Logo name="结算" className="mt_8" />
                        <ProgressList selectedIndex={1} />
                    </div>
                );
            }} />
            <div className="co">
                <div className="body">
                    <Receivers />
                    <ConfirmItems />
                    <Payment />
                    <CategoryCoupons />
                    <RedPackets />
                    <Bottom />
                    <AddressEditorModal />
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}