var React=window.Snowball._React;var { Component }=window.Snowball._React;

type NumberModifierProps = {
    value: number,
    min?: number,
    max?: number,
    disableMinus: boolean,
    disablePlus: boolean,
    onChange: (value: number) => void,
    onMinusClick?: (value: number) => void,
    onPlusClick?: (value: number) => void,
    onInputClick?: () => void,
    onError?: (type: number, min: number, max: number) => void
};

const ERROR_TYPE = {
    LESS_THAN_MIN: 1,
    MORE_THAN_MAX: 2
};

const BUTTON_TYPE = {
    MINUS: -1,
    PLUS: 1
};

export default class NumberModifier extends Component<NumberModifierProps> {
    static defaultProps = {
        min: 1
    };

    constructor(props) {
        super(props);
        this.state = {
            inputNumber: props.value
        };
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if (nextProps.value != prevState.value) {
    //         return {
    //             inputNumber: nextProps.value
    //         };
    //     }
    //     return null;
    // }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value != this.state.value) {
            this.setState({
                inputNumber: nextProps.value
            });
        }
    }

    changeValue = (value, type) => {
        const { min, max, onError, onChange } = this.props;
        const { inputNumber } = this.state;

        if (min !== undefined && value < min) {
            value = min;
            onError && onError(ERROR_TYPE.LESS_THAN_MIN, min, max);
            inputNumber != min && onChange && onChange(value);
        }
        else if (max !== undefined && value > max) {
            value = max;
            onError && onError(ERROR_TYPE.MORE_THAN_MAX, min, max);
            inputNumber != max && onChange && onChange(value);
        }
        else {
            onChange && onChange(value);
        }

        this.setState({
            inputNumber: value,
        });
    };

    onInputBlur = e => {
        const value = parseInt(e.target.value, 10) || this.props.min;
        this.changeValue(value);
    };

    onInputChange = e => {
        this.changeValue(parseInt(e.target.value, 10) || '');
    };

    render() {
        const {
            className = '',
            min,
            max,
            onPlusClick,
            onMinusClick,
            onInputClick,
            disableAll = false
        } = this.props;
        let {
            disableMinus = false,
            disablePlus = false
        } = this.props;
        const { inputNumber = min } = this.state;
        let inputDisabled = disableMinus && disablePlus;
        if (disableAll) {
            inputDisabled = true;
            disableMinus = true;
            disablePlus = true;
        }

        return (
            <div className={`cm_num__modifier ${className}`}>
                <button
                    className={`minus ${
                        inputNumber <= min ? 'disabled' : ''
                    }`}
                    onClick={() => {
                        this.changeValue(inputNumber - 1, BUTTON_TYPE.MINUS);
                        onMinusClick && onMinusClick(inputNumber - 1);
                    }}
                    disabled={disableMinus}
                >
                    －
                </button>
                <input
                    disabled={inputDisabled}
                    type="tel"
                    className={inputDisabled ? 'disabled' : ''}
                    value={inputNumber}
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    onClick={() => {
                        onInputClick && onInputClick();
                    }}
                />
                <button
                    className={`plus ${
                        inputNumber == max ? 'disabled' : ''
                    }`}
                    onClick={() => {
                        this.changeValue(inputNumber + 1, BUTTON_TYPE.PLUS);
                        onPlusClick && onPlusClick(inputNumber + 1);
                    }}
                    disabled={disablePlus}
                >
                    ＋
                </button>
            </div>
        );
    }
}
