var React=window.Snowball._React;
import Form from "../../../shared/components/Form";
import RefundItem from "./RefundItem";
var { Switch }=window.Snowball._components;
var { inject, mapViewModelToProps }=window.Snowball._app;
import TFSImage from "../../../shared/components/TFSImage";
import NumberModifier from '../../../shared/components/NumberModifier';

const FormItem = Form.Item;

export default inject(mapViewModelToProps('refundFormViewModel'))(function RefundForm({
    refundInfo = {},
    refundType,
    maxRefundNum,
    refundNum,
    onRefundNumChange,
    reasons,
    errors,
    formData = {},
    onFieldsChange,
    onPictureChange,
    onDeletePic,
    onCancel,
    onSubmit
}) {
    const {
        // 现金退款金额
        // refundCash,
        // 健康金退款金额
        // refundHealthGold,
        // 健康生活通退款金额
        // refundShopCard,
        // 运费金额
        logisticsFeeRefund,
        // 健康星退款数量
        // refundHealthStar,
        isKaolaOrder,
    } = refundInfo;

    const {
        refundCash,
        isLogisticFeeRefunded,
        description = '',
        factor = '',
        pics = []
    } = formData;

    const canChangeCash = !isKaolaOrder && refundType == 1;

    console.log(formData, canChangeCash);

    return <Form className="oi_refund_form">
        <FormItem label="退款商品"><RefundItem /></FormItem>
        <FormItem
            label="退款原因"
            required
            error={errors.factor}
        >
            <select
                value={factor}
                onChange={(e) => {
                    onFieldsChange({
                        factor: e.target.value
                    });
                }}
            >
                <option value="">请选择退款原因</option>
                {
                    reasons && reasons.map((reason) => {
                        return <option key={reason} value={reason}>{reason}</option>;
                    })
                }
            </select>
        </FormItem>
        {
            !canChangeCash && (
                <FormItem
                    label="退款数量"
                    error={errors.refundCash}
                    required
                >
                    <NumberModifier
                        min={1}
                        max={maxRefundNum}
                        value={refundNum}
                        onChange={(num) => onRefundNumChange(num)}
                    />
                </FormItem>
            )
        }
        <FormItem
            label="退款金额"
            error={errors.refundCash}
            required
        >
            <input
                disabled={!canChangeCash}
                value={refundCash}
                className="inputText"
                onChange={(e) => {
                    onFieldsChange({
                        refundCash: e.target.value
                    });
                }}
            />
        </FormItem>
        {
            !!logisticsFeeRefund && <FormItem label="退还运费" required>
                <span class="logisticsFeeRefund">￥{logisticsFeeRefund / 100}</span>
                <Switch
                    checked={isLogisticFeeRefunded}
                    onChange={(val) => {
                        onFieldsChange({
                            isLogisticFeeRefunded: val
                        });
                    }}
                />
            </FormItem>
        }
        <FormItem className="desc" label="退款说明">
            <textarea
                maxLength="200"
                placeholder="请输入文字描述，最长200个字，以便为您尽快处理"
                onChange={(e) => {
                    onFieldsChange({
                        description: e.target.value
                    });
                }}
                value={description}
            />
            <div className="cl_999 max">{200 - description.length >= 0 ? 200 - description.length : 0}字</div>
        </FormItem>
        <FormItem label="上传照片"
            error={errors.pics}
        >
            <div className="pic">
                <div className="file">
                    <input
                        type="file"
                        accept="image/*"
                        onChange={onPictureChange}
                    />
                </div>
                {
                    pics.map((pic) => {
                        return <div className="ps_r img">
                            <TFSImage
                                src={pic}
                            />
                            <button
                                className="close dock_tr"
                                onClick={() => onDeletePic(pic)}
                            ></button>
                        </div>;
                    })
                }
                <p className="note">最多4张</p>
            </div>
        </FormItem>
        <div className="oi_refund_form_btns">
            <button
                type="button"
                onClick={onCancel}
            >取消</button>
            <button
                type="button"
                className="btn_solid"
                onClick={onSubmit}
            >提交申请</button>
        </div>
    </Form>;
});