var { util }=window.Snowball;
var { ViewModel }=window.Snowball._app;

export default class BeforeOrderCreateViewModel extends ViewModel {
    async load({
        tradeIds,
        callBackUrl,
        goToOrderInfo,
    }) {
        if (goToOrderInfo == 1) {
            this.app.navigation.replace(
                tradeIds.length > 1
                    ? '/order-list/all'
                    : `/order/${tradeIds[0]}`
            );
        } else {
            this.app.navigation.replace(util.appendQueryString('#/checkout', {
                tradeIds: tradeIds.join('__'),
                callBackUrl,
                goToOrderInfo: 1
            }));
            this.app.navigation.forward('/pay?' + util.params({
                tradeIds: tradeIds.join('__'),
                callBackUrl,
            }));
        }
    }
}