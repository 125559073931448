
var React=window.Snowball._React;var { useState }=window.Snowball._React;
var { inject, mapViewModelToProps, autowired }=window.Snowball._app;
var { isNumber }=window.Snowball.util;
import SpecSelector from "./SpecSelector";
import BuyButtons from "./BuyButtons";
import Delivery from "./Delivery";
import Services from "./Services";
import CouponList from "./CouponList";
import RedPacketList from "./RedPacketList";
import DiscountList from "./DiscountList";

const SOLD_OUT_STATUS = 3;

export default inject(() => {
    const skuBuyViewModel = autowired('skuBuyViewModel');
    return {
        ...mapViewModelToProps('itemViewModel'),
        selectedSku: skuBuyViewModel.selectedSku
    };
})(function ItemBase({
    item,
    fund,
    control,
    pres,
    selectedSku
}) {
    let {
        couponSpecBrief = [],
        redPacketBrief = [],
        fullDiscountRules = [],
        fullCutRules = [],
    } = fund;
    const isCouponReceived = couponSpecBrief.length && couponSpecBrief[0].couponReceiveType == 2;
    const couponSpecBriefTexts = couponSpecBrief.map(getCouponBriefText);
    const [isCouponListVisible, setCouponListVisible] = useState(false);

    const isRedPacketReceived = redPacketBrief.length && redPacketBrief[0].couponReceiveType == 2;
    const redPacketBriefTexts = redPacketBrief.map(getCouponBriefText);
    const [isRedPacketListVisible, setRedPacketListVisible] = useState(false);

    const [isDiscountListVisible, setDiscountListVisible] = useState(false);

    const hasCoupon = couponSpecBrief && couponSpecBrief.length;
    const hasRedPacket = redPacketBrief && redPacketBrief.length;
    const hasDiscount = fullDiscountRules.length || fullCutRules.length;

    let priceElement;
    if (item.listStatus == SOLD_OUT_STATUS) {
        priceElement = <div className="soldOut">暂无报价</div>;
    } else {
        priceElement = <>
            <label>价&nbsp;&nbsp;&nbsp;&nbsp;格</label>
            <div className="priceSymbol dp_ib">￥</div>
            {
                selectedSku && selectedSku.price
                    ? <div className="price dp_ib">{selectedSku.price ? selectedSku.price / 100 : '0'}</div>
                    : <>
                        <div className="price dp_ib">{fund.minPrice ? fund.minPrice / 100 : '0'}</div>
                        {fund.minPrice != fund.maxPrice ? (
                            <span>
                                <span className="priceSymbol dp_ib" style={{
                                    verticalAlign: '3px',
                                    paddingLeft: 6,
                                    paddingRight: 6
                                }}>-</span>
                                <span className="priceSymbol dp_ib">￥</span>
                                <span className="price dp_ib">{fund.maxPrice ? fund.maxPrice / 100 : '0'}</span>
                            </span>
                        ) : null}
                    </>
            }
        </>;
    }

    return <div className="fl_r it_base">
        <h1 className="it_title to_e2">{item.detailTitle}</h1>
        <div className="it_prom_title to_e2">{item.promTitle}</div>
        <div className="it_price_wrapper">
            <div className="it_price_item clearfix">
                {priceElement}
                <div className="saleNum fl_r">{
                    !control || !control.rx
                        ? `已销售${formatSale(item.salesNum)}件`
                        : `预订量${formatSale(item.salesNum)}件`
                }</div>
            </div>
            <div
                className="it_price_item clearfix"
                style={{
                    display: hasCoupon || hasRedPacket || hasDiscount ? 'block' : 'none'
                }}
            >
                <label className="fl_l">促&nbsp;&nbsp;&nbsp;&nbsp;销</label>
                <ul className="fl_l sales">
                    {
                        hasCoupon
                            ? <li className="clearfix ps_r">
                                <em className="saleTag">优惠券</em>
                                <span className="saleCon to_e">{isCouponReceived ? '已领：' : ''}
                                    {couponSpecBriefTexts.join('、')}
                                </span>
                                <button
                                    className="saleBtn"
                                    onClick={() => setCouponListVisible(true)}
                                >立即领取</button>
                                {
                                    isCouponListVisible && <CouponList
                                        onClose={() => setCouponListVisible(false)}
                                        spuId={item.spuId}
                                    />
                                }
                            </li>
                            : null
                    }
                    {
                        hasRedPacket
                            ? <li className="clearfix ps_r">
                                <em className="saleTag">红包</em>
                                <span className="saleCon to_e">{isRedPacketReceived ? '已领：' : ''}
                                    {redPacketBriefTexts.join('、')}
                                </span>
                                <button
                                    className="saleBtn"
                                    onClick={() => setRedPacketListVisible(true)}
                                >立即领取</button>
                                {
                                    isRedPacketListVisible && <RedPacketList
                                        onClose={() => setRedPacketListVisible(false)}
                                        spuId={item.spuId}
                                    />
                                }
                            </li>
                            : null
                    }
                    {
                        hasDiscount
                            ? <li className="clearfix ps_r">
                                <em className="saleTag fl_l">满减</em>
                                <div className="saleCon fl_l">
                                    {fullDiscountRules.map((fullDiscountRule, i) => {
                                        const { fullDiscounts } = fullDiscountRule;
                                        return (
                                            <p className={`to_e desc`} key={i}>
                                                {
                                                    Array.isArray(fullDiscounts) ?
                                                        fullDiscounts.map(getFullDiscount).join('、')
                                                        : null
                                                }
                                            </p>
                                        );
                                    })}
                                    {fullCutRules.map((fullCutRule, i) => {
                                        const { fullCuts } = fullCutRule;
                                        return (
                                            <p className={`to_e desc`} key={i}>
                                                {
                                                    Array.isArray(fullCuts) ?
                                                        fullCuts.map(getFullCutBreif).join('、')
                                                        : null
                                                }
                                            </p>
                                        );
                                    })}
                                </div>
                                <button
                                    className="fl_l saleBtn"
                                    onClick={() => setDiscountListVisible(true)}
                                >立即查看</button>
                                {isDiscountListVisible &&
                                    <DiscountList
                                        fullDiscountRules={fullDiscountRules}
                                        fullCutRules={fullCutRules}
                                        onClose={() => setDiscountListVisible(false)}
                                    />
                                }
                            </li>
                            : null
                    }
                </ul>
            </div>
        </div>
        <Delivery />
        <SpecSelector />
        <BuyButtons />
        {!!pres.specialSpec && <div className="it_special_spec">{pres.specialSpec}</div>}
        <Services />
    </div>;
});

/**
 * 格式化销量
 * @param {number} num
 */
const formatSale = (num: number): string => {
    if (!isNumber(num)) {
        return '0';
    }
    switch (true) {
        case num < 0:
            num = '0';
            break;
        case num > 10000:
            num /= 1000;
            num = Math.round(num);
            num /= 10;
            num += '万';
            break;
        default:
            num += '';
            break;
    }
    return num;
};

const getCouponBriefText = ({
    thresholdPrice = 0,
    discountPrice = 0
}) => {
    return thresholdPrice > 0 ? `满${thresholdPrice / 100}元减${discountPrice / 100}元` : `立减${discountPrice / 100}元`;
};

const getFullDiscount = ({ amountThreshold, discount }) => {
    return `满${amountThreshold}件打${discount / 10}折`;
};

const getFullCutBreif = ({ priceThreshold, cut }) => {
    return priceThreshold > 0
        ? `满${convertCentToYuan(priceThreshold)}元减${convertCentToYuan(cut)}元`
        : `立减${convertCentToYuan(cut)}元`;
};

const convertCentToYuan = (price: number): number => isNumber(price) ? price / 100 : 0;
