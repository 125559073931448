var { util }=window.Snowball;
var { Service, autowired }=window.Snowball._app;
import UnicornApiService from "../apis/UnicornApiService";

const resourceCache = {};

export default class AppResourceService extends Service {
    @autowired
    _unicornApiService: UnicornApiService;

    async getAppResource(
        params
    ): Array<{
        keyName: string,
        val: string
    }> {
        const key = JSON.stringify(params);

        if (resourceCache[key]) {
            return resourceCache[key];
        }

        let res = await this._unicornApiService.getAppResource(params);
        let values = util.get(res, ['data', 'value']) || [];
        let result = {
            ...values[0],
            values
        };
        resourceCache[key] = result;
        return result;
    }
}