import ModuleBase from "../base/ModuleBase";

export default class BannerImages extends ModuleBase {
    patchData() {
        const { model, bizData } = this;
        const { images = [], col = 1, padding, margin } = bizData;
        const TFSImages = images.map((img) => ({
            ...img,
            src: this.context.app.tfs.transformTFSImageUrl(img.src),
        }));
        const containerClass = `col${col} ${padding ? "pd" : ""} ${margin ? "mg" : ""}`;

        model
            .set({
                containerClass,
                images: TFSImages,
                bizData,
            })
            .nextTick(() => {});
    }
}
