var { observable }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
import AddressFormViewModel from "./AddressFormViewModel";
var { toast }=window.Snowball._widget;


export default class AddressEditorModalViewModel extends ViewModel {
    @observable
    visible = false;

    @observable
    title;

    @autowired
    _addressFormViewModel: AddressFormViewModel;

    @emitter
    onCancel() {
        this.hide();
    }

    @emitter
    onSave() {
        this._addressFormViewModel.save((result) => {
            if (result.success) {
                toast.showToast('保存成功');
                this.hide();
            }
            else {
                toast.showToast(result.message);
            }
            this.onDidSave.emit(result);
        });
    }

    onDidSave = this.ctx.createEmitter();

    show(data) {
        this.visible = true;
        this.title = data.id ? '编辑地址' : '新增地址';
        this._addressFormViewModel.setFormData(data);
    }

    hide() {
        this.visible = false;
    }
}