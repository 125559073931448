var React=window.Snowball._React;
var { app }=window.Snowball._app;
export function CartTab({ currentCartType, tabs }) {
    return (
        <div className="tb_con clearfix">
            {tabs.map(cart => {
                return (
                    <div
                        key={`cartType${cart.type}`}
                        className={`tab_item ${
                            currentCartType === cart.type ? 'on' : ''
                        }`}
                        onClick={() => {
                            if (currentCartType !== cart.type) {
                                // log.makeLog(
                                // ['','switch_more_click','switch_yao_click','requirement_list_switch_click'][cart.type],{
                                //     carttype: cart.type
                                // });
                                app.navigation.replace(cart.href);
                            }
                        }}
                    >
                        {cart.name}
                        <span className="fs_m">
                            {cart.count > 99
                                ? '(99+)'
                                : cart.count > 0
                                    ? `(${cart.count})`
                                    : null}
                        </span>
                    </div>
                );
            })}
        </div>
    );
}