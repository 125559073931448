var { controller, autowired, param }=window.Snowball._app;
import AfterSale from "../containers/AfterSale";
import { AfterSaleConfiguration } from "../configuration";

@controller({
    component: AfterSale,
    configuration: [AfterSaleConfiguration]
})
export default class AfterSaleController {
    @param('requestNo')
    requestNo: number;

    @autowired
    _afterSaleViewModel;

    onInit() {
        this.ctx.logger.makeLog('H5web_pc_after_sale', 'onload');
        this._afterSaleViewModel.init(this.requestNo);
    }
}