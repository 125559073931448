var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
var { toast }=window.Snowball._widget;
import TFSImage from '../../../shared/components/TFSImage';
import getBlockStatus from '../../../shared/utils/getBlockStatus';
import NumberModifier from '../../../shared/components/NumberModifier';
import Title from './Title';
import { MAX_BBS_LENGTH } from '../constants';

const BLOCK_ID = 3040601;

const ERROR_TYPE = {
    LESS_THAN_MIN: 1,
    MORE_THAN_MAX: 2
};

function Actions({ onBackToCartClick }) {
    return (
        <div className="cs_p" onClick={onBackToCartClick}>
            <icon className="icon iconfont icon-back mr_s"></icon>
            <span>返回购物车</span>
        </div>
    );
}

const PRODUCT_STATUS = ['', '无货', '本区域不售卖', '已下架', '超出购买限制', '商品库存不足'];

class SellerBlock extends React.Component {

    state = {
        bbs: ''
    }

    onStoreBBSChange = ({ id, bbs }) => {
        if (bbs && bbs.length > MAX_BBS_LENGTH) {
            toast.showToast(`留言不能超过${MAX_BBS_LENGTH}字符`);
            this.setState({
                bbs: bbs.substr(0, MAX_BBS_LENGTH),
            });
            return;
        } else {
            this.setState({ bbs });
            this.props.onStoreBBSChange({
                id,
                bbs: bbs.substr(0, MAX_BBS_LENGTH),
            });
        }
    }

    onNumberInputError = (type, min, max) => {
        switch (type) {
            case ERROR_TYPE.LESS_THAN_MIN:
                toast.showToast(`至少购买${min}个`);
                break;
            case ERROR_TYPE.MORE_THAN_MAX:
                toast.showToast(`最多可购买 ${max}个`);
                break;
            default:
                break;
        }
    };

    render() {
        const {
            seller,
            blockStatus,
            onProductsCountChange,
        } = this.props;

        const { bbs } = this.state;

        const {
            id,
            cash,
            items = [],
            bundleItems = [],
            postPrice,
            freeDelivery,
            promotionPrice = 0
        } = seller;
        const skuAmount = items.reduce(
            (sum, cur) => Number(sum) + Number(cur.status ? 0 : cur.piece),
            0,
        );
        const bundleItemsAmount = bundleItems.reduce((total, bundle) => {
            let skuSum = bundle.items.reduce(
                (sum, cur) => Number(sum) + Number(cur.status ? 0 : cur.piece),
                0,
            );
            return Number(total) + Number(bundle.status ? 0 : bundle.count * skuSum);
        }, 0);
        return (
            <div className="seller_block fs_m">
                <div className="title cl_333">商家：{seller.sellerName}</div>
                <div className="items">
                    {Array.isArray(items) && items.map((item, itemIndex) => {
                        const {
                            buyLimit,
                            title,
                            specs,
                            singlePrice,
                            piece,
                            status,
                            images,
                            skuId,
                            // displayLabels,
                            // logicLabels,
                            minBuyLimit,
                        } = item;
                        return (
                            <div key={itemIndex} className="item cols clearfix">
                                <div className="info clearfix">
                                    <div className="img_wrap mr_m fl_l">
                                        <TFSImage className="img" src={images} />
                                        {
                                            status != 0
                                                ? (
                                                    <div className="mask">
                                                        <div
                                                            className={`mask_content ${[2, 4, 5].includes(status) ? 'oos' : ''}`}
                                                        >{PRODUCT_STATUS[status]}</div>
                                                    </div>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="title to_e3 fl_l">{title}</div>
                                </div>
                                <div className="spec">
                                    {Array.isArray(specs) && specs.map((spec, specIndex) => {
                                        return <p key={specIndex} className="line cl_999 fs_m">{spec.key}：{spec.value}</p>;
                                    })}
                                </div>
                                <div className="single_price cl_666">￥{singlePrice && (singlePrice / 100)}</div>
                                <div className="count">
                                    {
                                        blockStatus == 1
                                            ? (
                                                <NumberModifier
                                                    onChange={count => onProductsCountChange({ id, skuId, count })}
                                                    value={piece}
                                                    min={minBuyLimit}
                                                    max={buyLimit}
                                                    disableMinus={status && status != 0}
                                                    disablePlus={status && status != 0}
                                                    onError={this.onNumberInputError}
                                                />
                                            )
                                            : <div className="piece">{piece}</div>
                                    }
                                </div>
                                <div className="total cl_666">￥{singlePrice && (singlePrice * piece / 100)}</div>
                            </div>
                        );
                    })}
                </div>
                <div className="check fs_m">
                    <div className="bottom clearfix">
                        <div className="seller_remark fl_l">
                            <div className="cl_999 mb_m">给商家留言</div>
                            <div className="remark_wrap ps_r">
                                <textarea
                                    className="remark w_1x"
                                    placeholder="选填，请输入您与商家约定的留言"
                                    value={bbs}
                                    onChange={(event) => this.onStoreBBSChange({ id, bbs: event.target.value })}
                                ></textarea>
                                <div className="count">{bbs.length || 0}/{MAX_BBS_LENGTH}</div>
                            </div>
                        </div>
                        <div className="sum ta_r fl_r">
                            <div className="line">
                                <span className="left">运费：</span>
                                <span className="right">{freeDelivery ? '包邮' : '￥' + (postPrice / 100).toFixed(2)}</span>
                            </div>
                            <div className="line">
                                <span className="left">配送方式：</span>
                                <span className="right va_t">快递</span>
                            </div>
                            { promotionPrice > 0 && (
                                <div className="line">
                                    <span className="left">促销：</span>
                                    <span className="right">-￥{(promotionPrice / 100).toFixed(2)}</span>
                                </div>
                            ) }
                            <div className="total mt_m">
                                共
                                <span className="count">{skuAmount + bundleItemsAmount}</span>
                                件商品（含运费），小计：
                                <span className="symbol">￥</span>
                                <span className="money">{(cash / 100).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function ConfirmItems({
    hasInitialized,
    sellerCollection,
    blockStatus,
    onProductsCountChange,
    onStoreBBSChange,
    onBackToCartClick,
}) {
    return (
        <div className="co_ci">
            <Title
                title="确认商品信息"
                actions={<Actions onBackToCartClick={onBackToCartClick} />}
            />
            <div className="items_header cols cl_999 fs_m clearfix">
                <div className="info">商品信息</div>
                <div className="spec">属性</div>
                <div className="single_price">单价</div>
                <div className="count">数量</div>
                <div className="total">小计</div>
            </div>
            {hasInitialized && sellerCollection.map((seller, i) => {
                return (
                    <SellerBlock
                        key={i}
                        seller={seller}
                        blockStatus={blockStatus}
                        onProductsCountChange={onProductsCountChange}
                        onStoreBBSChange={onStoreBBSChange}
                    />
                );
            })}
        </div>
    );
}


export default inject(() => {
    const orderCreateViewModel = autowired('orderCreateViewModel');
    const {
        hasInitialized,
        sellerCollection,
        onProductsCountChange,
        onStoreBBSChange,
        onBackToCartClick,
    } = orderCreateViewModel;

    return {
        hasInitialized,
        sellerCollection,
        onProductsCountChange,
        blockStatus: getBlockStatus(orderCreateViewModel.lml, BLOCK_ID),
        onStoreBBSChange,
        onBackToCartClick,
    };
})(ConfirmItems);
