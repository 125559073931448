var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import DrugPermit from "./DrugPermit";

function ItemSpecs({
    isPrescriptionDrug,
    specs
}) {
    let permitNo,
        normalSpecs;
    if (isPrescriptionDrug) {
        const permitSpecName = '批准文号';
        const permitSpec = specs.find((spec) => {
            return spec.name == permitSpecName;
        });
        permitNo = permitSpec ? permitSpec.value : null;
        normalSpecs = specs.filter((spec) => {
            return spec.name != permitSpecName;
        });
    } else {
        normalSpecs = specs;
    }

    return (
        <div className="it_normal_specs it_details_con">
            <h4 className="it_details_hd">商品信息</h4>
            <div className="it_normal_specs_con">
                <table className="cl_666 fs_m">
                    {Array(Math.ceil(normalSpecs.length / 2)).fill(1)
                        .map((_, i) => (
                            <tr
                                key={i}
                                className="lh_xs"
                            >
                                <td>{normalSpecs[i * 2].name}:{normalSpecs[i * 2].value}</td>
                                <td>{
                                    normalSpecs[i * 2 + 1] ?
                                        `${normalSpecs[i * 2 + 1].name}:${normalSpecs[i * 2 + 1].value}`
                                        : ''
                                }</td>
                            </tr>
                        ))}
                </table>
                <DrugPermit permitNo={permitNo} />
            </div>
        </div>
    );
}

export default inject(() => {
    const itemViewModel = autowired('itemViewModel');
    const { specs } = itemViewModel.item;
    return {
        isPrescriptionDrug: itemViewModel.isPrescriptionDrug,
        specs: (specs || []).filter(spec => spec.value)
    };
})(ItemSpecs);