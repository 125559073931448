var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import { Header, Footer, Slogan, SearchBar, MyCart } from 'jkmall-frame';
import Main from '../../../shared/components/Main';
import Steps from '../../../shared/components/Steps';
import emptyImage from '../../../shared/images/empty.png';
import '../styles/Logistics.scss';

const Logistics = inject(() => {
    const logisticsViewModel = autowired('logisticsViewModel');

    return {
        orderInfo: logisticsViewModel.orderInfo,
        logisticsInfo: logisticsViewModel.logisticsInfo
    };
})((props) => {
    const {
        orderInfo,
        logisticsInfo
    } = props;

    return (<>
        <Header
            renderExtra={({ cartNum = 0 }) => {
                return <>
                    <Slogan />
                    <div className="usercenter-breadcrumb"><i className="triangle" style={{ left: '25px' }}></i>物流详情</div>
                    <SearchBar showHot={false} />
                    <MyCart count={cartNum} />
                </>;
            }}
        />
        <div className="app-wrapper-gray lg__logistics_wrapper">
            <Main className="app-wrapper-fff lg__logistics_main">
                <div className="lg__order_info">
                    <p className="title">订单信息</p>
                    <div className="main">
                        <div className="product">
                            <div className="image">
                                <img alt="" src={orderInfo.productImage} />
                                <div className="count">{orderInfo.productTotalCount}件商品</div>
                            </div>
                        </div>
                        <div className="express">
                            <p className="company">{logisticsInfo.expressCompany}</p>
                            <p className="orderId">运送单号：{logisticsInfo.expressOrderId}</p>
                        </div>
                        <div className="logistics_status">{logisticsInfo.currLogisticsStatusText}</div>
                    </div>
                </div>
                <div className="lg__logistics_process">
                    <p className="title">物流跟踪</p>
                    <div className="main">
                        {
                            logisticsInfo.traceList && logisticsInfo.traceList.length > 0
                                ? (
                                    <Steps direction="vertical" className="lg__logistics_process_steps">
                                        {
                                            logisticsInfo.traceList.map(item => {
                                                return (
                                                    <Steps.Step
                                                        icon={<i></i>}
                                                        actived={item.actived}
                                                    >
                                                        <p className="desc">{item.desc}</p>
                                                        <p className="date">{item.date}</p>
                                                    </Steps.Step>
                                                );
                                            })
                                        }
                                    </Steps>
                                )
                                : (
                                    <div className="empty_placeholder">
                                        <p><img alt="" src={emptyImage} /></p>
                                        <p>暂无数据</p>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </Main>
        </div>
        <Footer />
    </>);
});

export default Logistics;