var React=window.Snowball._React;var { useState }=window.Snowball._React;
import selectedImgSrc from '../../../shared/images/selected.png';

export default function Reasons({
    className,
    title,
    reasons,
    onCancel,
    onError,
    onSelect
}) {
    const [reason, setReason] = useState({});
    return (
        <div className={"oi_reason" + (className ? ' ' + className : '')}>
            <div className="oi_reason_title">
                <i className="oi_reason_icon"></i>
                <span>{title}</span>
            </div>
            <ul className="oi_reason_list clearfix">
                {
                    reasons.map((item) => {
                        return <li
                            className={"oi_reason_item ps_r fl_l " + (item.type == reason.type ? ' curr' : '') + (item.subTitle ? ' withSubTitle' : '')}
                            onClick={() => {
                                setReason(item);
                            }}
                        >
                            <p className="title">{item.title}</p>
                            {
                                !!item.subTitle && (
                                    <p className="subTitle">{item.subTitle}</p>
                                )
                            }
                            {item.type == reason.type && <img className="ftimg dock_br" alt="" src={selectedImgSrc} />}
                        </li>;
                    })
                }
            </ul>
            <div className="oi_reason_btns ta_r">
                <button onClick={onCancel}>取 消</button>
                <button
                    className="btn_solid"
                    onClick={() => {
                        if (!reason.type) {
                            return onError();
                        }
                        onSelect(reason);
                    }}
                >确 定</button>
            </div>
        </div>
    );
}