import ModuleBase from "../base/ModuleBase";
var { reactModule, autowired }=window.Snowball._app;

@reactModule
export default class FloorGoods extends ModuleBase {
    @autowired
    _gumihoApiService: GumihoApiService;

    async patchData() {
        const { model, bizData } = this;
        const spuIds = bizData.products.map((item) => item.id);
        const res = await this._gumihoApiService.getCmsSpus(spuIds);
        let products = res.success ? res.data.value || [] : [];

        products = products.slice(0, 7).map((prd, i) => {
            prd.picture = this.app.tfs.transformTFSImageUrl(prd.picture);
            prd.tags = [];

            if (prd.fullCutDTOs && prd.fullCutDTOs.length) {
                prd.tags.push(`满减`);
            }
            if (prd.couponDTOs && prd.couponDTOs.length) {
                const coupon = prd.couponDTOs[0];
                prd.tags.push(`领券满${coupon.thresholdPrice / 100}减${coupon.discountPrice / 100}`);
            }

            prd.price = prd.price / 100;
            return prd;
        });

        model.set({
            isShow: true,
            bizData: {
                ...bizData,
                products,
            },
        });
    }
}
