import ModuleBase from "../base/ModuleBase";

export default class FloorTitle extends ModuleBase {
    patchData() {
        const { model, bizData } = this;

        model.set({
            isShow: true,
            bizData
        }).nextTick(() => { });
    }
}