var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
var { util }=window.Snowball;

function BasicInfo({ address, tradeOrder, deliveryTime }) {
    let {
        displayStore,
        sellerNick = '',
    } = tradeOrder;

    displayStore = false;

    return (
        <>
            <div className="oi_title">订单信息</div>
            <ul className="oi_basic_info">
                <li>收货地址：{address.name}, {address.mobile}, {address.province}{address.city}{address.area}{address.detail}</li>
                <li>
                    <span>订单编号：</span>
                    <span className="right">{tradeOrder.tradeId}</span>
                </li>
                <li>
                    <span>下单时间：</span>
                    {tradeOrder.createTime ? util.formatDate(tradeOrder.createTime * 1000) : ''}
                </li>
                <li style={{ display: deliveryTime != 0 ? "block" : "none" }}>
                    <span className="left">发货时间：</span>
                    <span className="right">{deliveryTime != 0 ? util.formatDate(deliveryTime * 1000) : 'N/A'}</span>
                </li>
                {
                    !!displayStore && (
                        <li>
                            <span className="right">{sellerNick}</span>
                        </li>
                    )
                }
            </ul>
        </>
    );
}

export default inject(() => {
    const orderInfoViewModel: OrderInfoViewModel = autowired('orderInfoViewModel');
    return {
        address: orderInfoViewModel.address,
        tradeOrder: orderInfoViewModel.tradeOrder,
        deliveryTime: orderInfoViewModel.deliveryTime
    };
})(BasicInfo);