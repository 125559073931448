const NativeCustomEvent = window.CustomEvent;

function canUseNative() {
    try {
        const p = new NativeCustomEvent('cat', { detail: { foo: 'bar' } });

        return 'cat' === p.type && 'bar' === p.detail.foo;
    }
    catch (e) {
        console.log(e);
    }

    return false;
}

function CustomEventPolyfill(type, params) {
    const { bubbles = false, cancelable = false, detail = void 0 } = params;
    const e = document.createEvent ? document.createEvent('CustomEvent') : document.createEventObject();

    if (document.createEvent) {
        e.initCustomEvent(type, bubbles, cancelable, detail);
    }
    else {
        e.type = type;
        e.bubbles = bubbles;
        e.cancelable = cancelable;
        e.detail = detail;
    }

    return e;
}

const CustomEvent = canUseNative() ? NativeCustomEvent : CustomEventPolyfill;

export default CustomEvent;