var { useEffect, useState }=window.Snowball._React;
import { APP_ENV } from '../../env';
import { get } from '../../core/util';
import { getAppConfigList } from '../../core/api/gumiho';

export default function useAppConfig() {
    const [appConfig, setAppConfig] = useState({});

    useEffect(() => {
        getAppConfigList({
            type: 7
        })
            .then((res) => {
                if (res.success) {
                    const { value: appConfigLists = [] } = res.data;
                    const config = appConfigLists.find((item) => {
                        const prop = JSON.parse(item.prop);

                        return prop.channel === APP_ENV;
                    });
                    const configValue = JSON.parse(get(config, 'prop') || '{}');

                    configValue.imgs = (configValue.imgs || []).filter((item) => {
                        return !!item.src;
                    });

                    setAppConfig(configValue);
                }
            });
    }, []);

    return appConfig;
}