var React=window.Snowball._React;var { useState, useEffect, useLayoutEffect }=window.Snowball._React;
var { $ }=window.Snowball;
var { app }=window.Snowball._app;

export default function RichTextDetail({ details }) {
    const [detailHtml, setDetailHtml] = useState('');

    useEffect(() => {
        if (details) {
            fetch(app.env.TFS + details)
                .then(res => res.text())
                .then(html => {
                    setDetailHtml(formatHtml(html));
                });
        }
    }, [details]);

    useEffect(() => {
        function onScroll() {
        }
        $(window).on('scroll', onScroll);
        return () => $(window).off('scroll', onScroll);
    }, []);

    useLayoutEffect(() => {
        $(window).trigger('detectImageLazyLoad');
    }, [detailHtml]);

    return (
        <div
            className="it_details_rich_text bg_fff"
        >
            <div
                className="_html"
                dangerouslySetInnerHTML={{ __html: detailHtml }}
            />
        </div>
    );
}

function formatHtml(html) {
    return html
        .replace(
            /(?:https?:)?\/\/(?:static\.jk\.cn|static\.(?:dev|test)\.pajkdc\.com|jkcdn\.pajk\.com\.cn)\/(?:image\/)?([\w.]{18})([^"']+)?/gim,
            (match, tfsUrl) => {
                return app.tfs.transformTFSImageUrl(tfsUrl);
            }
        )
        .replace(/<img([^>]+)src=/gim, (match, attrs, index, input) => {
            return '<img' + attrs + ' src=';
        });
}
