export const AFTER_SALE_STATUS_TEXT_LIST = [
    '待审核',
    '受理通过',
    '审核不通过',
    '申请已取消',
    '商家已收货',
    '退款中',
    '退款成功',
    '退款中',
    '退款中',
    '退款成功',
    '寄回退货',
];

export const AFTER_SALE_TOP_TEXT_MAP = {
    1: '您的订单支持运费险，请填写收款账号',
    2: '银行卡信息错误，请更新收款账户信息',
    3: '运费险理赔中，点击查看',
    4: '本单支持运费险，请在商家确认退款后点此处发起理赔！'
};

// 运费类型
export const DELIVERY_TYPE = 50;

export const REFUND_TYPE_CNY_TEXT_MAP = {
    1: '仅退款',
    2: '退货退款',
    3: '取消订单',
    4: '退差价'
};

export const REFUND_TYPE_VALUE_MAP = {
    REFUND: 1,
    REFUND_ANG_GOODS: 2
};

export const REFUND_TYPE_KEY_MAP = {
    [REFUND_TYPE_VALUE_MAP.REFUND]: 'refund',
    [REFUND_TYPE_VALUE_MAP.REFUND_ANG_GOODS]: 'refundAndGoods'
};

export const REFUND_TYPE_DEFAULT_KEY = REFUND_TYPE_KEY_MAP[REFUND_TYPE_VALUE_MAP.REFUND];

export const REFUND_SUCCESS_STATUS_LIST = [6, 9];