var React=window.Snowball._React;var { useCallback, useState, useEffect }=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
import Form from "../../../shared/components/Form";
import Radio from '../../../shared/components/Radio';
const FormItem = Form.Item;
const sexyOptions = [{ name: '男', val: 1 }, { name: '女', val: 2 }];

const RadioGroup = ({ options, selectedVal, onChange }) => {
    const [checkedVal, setItemSelected] = useState(selectedVal);
    useEffect(()=> {
        setItemSelected(selectedVal);
    }, [selectedVal]);
    const selectedItem = useCallback((val) => {
        setItemSelected(val);
        onChange && onChange(val);
    }, [setItemSelected, onChange]);

    return (
        <div>
            {
                options.map((item) =>
                    (<div
                        className="fl_l mr_l"
                        onClick={() => selectedItem(item.val)}>
                        <Radio selected={checkedVal == item.val} />{item.name}
                    </div>))
            }
        </div>
    );
};


function PatientForm({
    formData,
    errors,
    onFieldsChange
}) {

    return (
        <Form>
            <FormItem
                label="真实姓名"
                required
                error={errors.patientName}
            >
                <input
                    name="name"
                    type="text"
                    className="app-form-input"
                    placeholder="请输入姓名"
                    value={formData.patientName}
                    onChange={(event) => {
                        onFieldsChange({ patientName: event.target.value });
                    }}
                />
            </FormItem>
            <FormItem
                label="性别"
                required
            >
                <RadioGroup
                    options={sexyOptions}
                    selectedVal={formData.gender || 1}
                    onChange={(val) => {
                        onFieldsChange({ gender: val });
                    }} />
            </FormItem>
            <FormItem
                label="年龄"
                required
                error={errors.monthAge}
            >
                <input
                    className="app-form-input tight"
                    name="age"
                    type="number"
                    placeholder="年龄"
                    value={formData.monthAge ? Math.floor(formData.monthAge / 12) : ''}
                    onChange={(event) => {
                        onFieldsChange({ monthAge: event.target.value * 12 });
                    }}
                /><span className="ml_l">岁</span>
            </FormItem>
            <FormItem
                label="手机号码"
                required
                error={errors.mobile}
            >
                <input
                    name="phone"
                    type="text"
                    className="app-form-input"
                    placeholder="请输入手机号"
                    value={formData.mobile}
                    onChange={(event) => {
                        onFieldsChange({ mobile: event.target.value });
                    }}
                />
            </FormItem>
        </Form>
    );
};
export default inject(mapViewModelToProps('patientFormViewModel'))(PatientForm);