var React=window.Snowball._React;var { useState, useEffect, useCallback }=window.Snowball._React;
var ReactDOM=window.Snowball._ReactDOM;
var { $ }=window.Snowball;
var { app }=window.Snowball._app;
var { throttle }=window.Snowball.util;

export const HomeFixedBar = ({ goConsult }) => {

    const [backvisible, setBackVisible] = useState(false);

    const onScroll = useCallback(throttle(() => {
        const screenH = $(window).height();
        const scrollTop = $(window).scrollTop();
        setBackVisible(scrollTop > screenH);
    }, 500),[]);

    useEffect(() => {
        $(window).on('scroll', onScroll);
        return () => $(window).off('scroll', onScroll);
    }, [onScroll]);

    const backtop = useCallback(()=> {
        window.scrollTo(0,0);
    }, []);

    const goCart = useCallback(()=> {
        app.navigation.forward('/cart');
    }, []);

    return ReactDOM.createPortal(
        <div className='cm_home_fixed__bar'>
            <div className="bar_box" onClick={goConsult}>
                <div className="icons-consult" />
                在线咨询
            </div>
            <div className="bar_box" onClick={goCart}>
                <div className="icons-cart" />
                购物车
            </div>
            {
                backvisible && <div className="bar_box" onclick={backtop}><div className="icons-backtop" />返回顶部</div>
            }
        </div>,
        document.body
    );
};