var React=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;

function SpecSelector({
    specKeys,
    skus,
    orderQtyMin,
    selectedSpecs,
    onSelectSpec
}) {


    return <div className="it_specs">
        {
            specKeys && specKeys.map((specKey, i) => {
                var availableSkus = skus.filter((sku) => {
                    return sku.specValues.every((specVal, j) => {
                        return !selectedSpecs[j] || specVal == selectedSpecs[j] || i == j;
                    });
                });

                const specs = [];
                skus.forEach(sku => {
                    if (sku.specValues[i]) {
                        const spec = {
                            index: i,
                            spec: sku.specValues[i],
                            available: sku.stockNum >= orderQtyMin && availableSkus.findIndex(aSku => aSku.skuId == sku.skuId) != -1
                        };
                        const oldSpec = specs.find((s) => s.spec == spec.spec);
                        if (oldSpec) {
                            oldSpec.available |= spec.available;
                        } else {
                            specs.push(spec);
                        }
                    }
                });

                return (
                    <div className="mt_m">
                        <span className="it_base_label">{specKey}</span>
                        <ul className="dp_ib">
                            {
                                specs.map(({ spec, available }) => {
                                    return <li
                                        className={"it_specs_item " + (selectedSpecs[i] == spec ? 'curr' : '') + (available ? '' : ' disabled')}
                                        onClick={available ? () => onSelectSpec({
                                            spec,
                                            index: i
                                        }) : undefined}
                                    >{spec}</li>;
                                })
                            }
                        </ul>
                    </div>
                );
            })
        }
    </div>;
}

export default inject(mapViewModelToProps('skuBuyViewModel'))(SpecSelector);
