
export default function validate(data, scheme) {
    const validate = (value, rules, key) => {
        for (let i = 0; i < rules.length; i++) {
            const rule = rules[i];
            if (rule.required) {
                if (!value) {
                    return { field: key, message: rule.message || `${key} required!` };
                }
            }
            if (rule.validator) {
                return rule.validator(value, key);
            }
            if (value != null && value !== '') {
                if (rule.pattern) {
                    if (!rule.pattern.test(value)) {
                        return { field: key, message: rule.message || `格式错误!` };
                    }
                } else if ('max' in rule || 'min' in rule) {
                    const len = typeof val === 'number' ? value : value.length;
                    if ('max' in rule && len > rule.max) {
                        return { field: key, message: rule.message || `max is ${rule.max}, now is ${len}` };
                    }
                    if ('min' in rule && len < rule.min) {
                        return { field: key, message: rule.message || `min is ${rule.max}, now is ${len}` };
                    }
                }
            }
        }
    };
    return Object.keys(scheme).reduce((errors, key) => {
        const val = data[key];
        let error = scheme[key] && validate(val, scheme[key], key);
        errors[key] = error ? error.message : null;
        return errors;
    }, {});
}