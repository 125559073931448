var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import { Header, Footer, Slogan, MyCart, SearchBar } from 'jkmall-frame';
import deliveryfeeImage from '../../../shared/images/deliveryfee.png';
import RefundProduct from '../components/RefundProduct';
import Main from '../../../shared/components/Main';
import Form from '../../../shared/components/Form';
import AfterSaleTopSteps from '../components/AfterSaleTopSteps';
import RefundRecordSteps from '../components/RefundRecordSteps';
import RefundExpressForm from '../components/RefundExpressForm';
import RefundOrderInfo from '../components/RefundOrderInfo';
import { DELIVERY_TYPE } from '../constants';
import '../styles/AfterSale.scss';

const AfterSale = inject(() => {
    const afterSaleViewModel = autowired('afterSaleViewModel');

    return {
        topTip: afterSaleViewModel.topTip,
        isTopTipClickable: afterSaleViewModel.isTopTipClickable,
        topSetpList: afterSaleViewModel.topSetpList,
        isRefundSuccess: afterSaleViewModel.isRefundSuccess,
        needUserSendProduct: afterSaleViewModel.needUserSendProduct,
        canCancelRefund: afterSaleViewModel.canCancelRefund,
        refundOrder: afterSaleViewModel.refundOrder,
        refundRecordList: afterSaleViewModel.refundRecordList,
        refundAddress: afterSaleViewModel.refundAddress,
        onTopTipClick: afterSaleViewModel.onTopTipClick,
        onRefundExpressFormSubmit: afterSaleViewModel.onRefundExpressFormSubmit,
        onCancelRefundBtnClick: afterSaleViewModel.onCancelRefundBtnClick
    };
})((props) => {
    const {
        topTip,
        topSetpList,
        refundOrder,
        refundRecordList,
        refundAddress,
        isTopTipClickable,
        isRefundSuccess,
        needUserSendProduct,
        canCancelRefund,
        onRefundExpressFormSubmit,
        onCancelRefundBtnClick,
        onTopTipClick
    } = props;

    return (<>
        <Header
            renderExtra={({ cartNum = 0 }) => {
                return <>
                    <Slogan />
                    <div className="usercenter-breadcrumb"><i className="triangle"></i>我的</div>
                    <SearchBar showHot={false} />
                    <MyCart count={cartNum} />
                </>;
            }}
        />
        <div className="app-wrapper-gray as__after_sale">
            <Main className="app-wrapper-fff as__after_sale_main">
                <AfterSaleTopSteps topSetpList={topSetpList} />
                {
                    topTip && (
                        <div className="as__top_tip">
                            <i className="icon"></i>
                            <strong>{topTip}</strong>
                            {isTopTipClickable && <span className="btn_check" onClick={onTopTipClick}>查看</span>}
                        </div>
                    )
                }
                {
                    isRefundSuccess && (
                        <div className="as__after_sale_success">
                            <div className="icon"></div>
                            <div className="info">
                                <p className="status">交易关闭</p>
                                <p className="desc">卖家取消订单，原因是{refundOrder.reason}</p>
                            </div>
                        </div>
                    )
                }
                {
                    refundOrder && (
                        <RefundOrderInfo
                            refundOrder={refundOrder}
                            needUserSendProduct={needUserSendProduct}
                        />
                    )
                }
                {
                    needUserSendProduct && (
                        <div className="as__refund_express_form">
                            <Form.Item label="退款商品">
                                {
                                    refundOrder.refundItems.map((item) => {
                                        return (
                                            <RefundProduct
                                                picture={item.type === DELIVERY_TYPE ? deliveryfeeImage : item.picture}
                                                title={item.title}
                                                specList={JSON.parse(item.specs || '[]')}
                                                style={{ paddingLeft: '0' }}
                                            />
                                        );
                                    })
                                }
                            </Form.Item>
                            <Form.Item label="退货地址" className="address">
                                <p style={{ width: '410px' }}>{ refundAddress }</p>
                            </Form.Item>
                            <RefundExpressForm />
                            <Form.Item>
                                <button
                                    className="address-save-btn"
                                    onClick={() => { onRefundExpressFormSubmit(); }}
                                >提交</button>
                            </Form.Item>
                        </div>
                    )
                }
                {
                    isRefundSuccess
                        ? null
                        : <RefundRecordSteps
                            refundRecordList={refundRecordList}
                            canCancelRefund={canCancelRefund}
                            onCancelRefundBtnClick={onCancelRefundBtnClick}
                        />
                }
            </Main>
        </div>
        <Footer />
    </>);
});

export default AfterSale;