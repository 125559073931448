export const CartTypes = {
    B2C: 1,
    O2O: 2,
    DemandList: 3
};

export const CartSourceNames = {
    [CartTypes.B2C]: 'jiangkangshangcheng',
    [CartTypes.O2O]: 'shandiansongyao',
    [CartTypes.DemandList]: 'xuqiuqingdan'
};

export const CartTypeNames = {
    [CartTypes.B2C]: 'b2c',
    [CartTypes.O2O]: 'o2o',
    [CartTypes.DemandList]: 'demand'
};

export const CartTypeCNames = {
    [CartTypes.B2C]: '网上商城',
    [CartTypes.O2O]: '闪电送药',
    [CartTypes.DemandList]: '需求清单'
};

export const CART_ROUTES = ['/cart','/cart', '/cart/o2o', '/cart/demandlist'];