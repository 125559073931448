import renderModules from "./renderModules";
var { inject, autowired }=window.Snowball._app;

const Modules = inject(() => {
    const decorationViewModel = autowired('decorationViewModel');
    return {
        templates: decorationViewModel.templates,
        modules: decorationViewModel.modules
    };
})(renderModules);

export default Modules;