var { controller, autowired, param }=window.Snowball._app;
import Item from '../containers/Item';
import { ItemConfiguration } from '../configuration';

@controller({
    component: Item,
    configuration: [ItemConfiguration],
})
export default class ItemController {
    @param('id')
    spuId: number;

    @autowired
    _itemViewModel;

    onInit() {
        // 无货商品
        // https://yao.test.pajkdc.com/shop/#/item/30577072
        this.ctx.logger.makeLog('H5web_pc_item', 'onload', {
            spuId: this.spuId,
        });
        this._itemViewModel.load(this.spuId);
    }
}
