var { configuration, singleton }=window.Snowball._app;

import UserCidViewModel from './view-models/UserCidViewModel';
import OrderCreateViewModel from './view-models/OrderCreateViewModel';
import OrderAppResourceService from './services/OrderAppResourceService';

import AddressViewModel from '../address/view-models/AddressViewModel';
import AddressEditorModalViewModel from '../address/view-models/AddressEditorModalViewModel';
import AddressFormViewModel from '../address/view-models/AddressFormViewModel';

export const OrderCreateConfiguration = configuration({
    modules: {
        userCidViewModel: singleton(UserCidViewModel),
        orderCreateViewModel: OrderCreateViewModel,
        orderAppResourceService: OrderAppResourceService,
        addressViewModel: AddressViewModel,
        addressEditorModalViewModel: AddressEditorModalViewModel,
        addressFormViewModel: AddressFormViewModel,
    }
});
