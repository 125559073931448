import ModuleBase from "../base/ModuleBase";
var { autowired, reactModule }=window.Snowball._app;
import UserService from "../../../shared/services/UserService";
var { toast, popup }=window.Snowball._widget;
import GumihoApiService from "../../../shared/apis/GumihoApiService";

@reactModule
export default class CouponImage extends ModuleBase {
    @autowired
    _gumihoApiService: GumihoApiService;
    @autowired
    _userService: UserService;

    patchData() {
        const { model, bizData } = this;
        const { coupons = [] } = bizData;
        const transformedCoupons = coupons.map((coupon) => ({
            ...coupon,
            src: this.context.app.tfs.transformTFSImageUrl(coupon.src),
        }));

        model
            .set({
                bizData: {
                    ...bizData,
                    coupons: transformedCoupons,
                },
                receiveCoupon: this.receiveCoupon,
            })
            .nextTick(() => {});
    }

    receiveCoupon = async (item) => {
        const { couponId } = item;

        if (await this._userService.ensureLogin()) {
            const result = await this._gumihoApiService.getCoupons({
                couponId,
                outBizType: this.app.env.OUT_BIZ_TYPE,
            });

            const errorMessage = result.message || "券已抢完";
            if (result.success) {
                if (result.data.value) {
                    popup.confirm({
                        title: "优惠券领取成功",
                        okText: "好的",
                    });
                } else {
                    toast.showToast(errorMessage);
                }
            } else {
                toast.showToast(errorMessage);
            }
        }
    };
}
