var React=window.Snowball._React;

function ErrorMessage({ msg = '', className }) {
    return (
        <div className={`error_container ${className ? className : ''}`}>
            <span className="error_icon">i</span>
            <span className="error_msg">{msg}</span>
        </div>
    );
}

export default ErrorMessage;