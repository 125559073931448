var { observable, util }=window.Snowball;
var { ViewModel, autowired }=window.Snowball._app;
var { toast }=window.Snowball._widget;
import UnicornApiService from '@/shared/apis/UnicornApiService';
import { LOGISTICS_STATUS_MAP } from '../constants';
import prescriptionImage from '../images/prescription.png';

export default class LogisticsViewModel extends ViewModel {
    @observable
    orderInfo = {
        productImage: '',
        productTotalCount: 0,
    }

    @observable
    logisticsInfo = {
        expressCompany: '',
        expressOrderId: '',
        currLogisticsStatusText: '',
        traceList: []
    }

    @autowired
    _unicornApiService: UnicornApiService;

    init(orderId) {
        this.loadOrder(orderId);
    }

    async loadOrder(orderId) {
        const res = await this._unicornApiService.queryOrderForBuyer(orderId);
        const { data: order = {}, success, message } = res;

        if (!success) {
            toast.showToast(message || '查询失败');
            return;
        }

        const { lgOrder = {}, tradeOrder: { items = [{}] } = {} } = order;
        const {
            lgTraces = [],
            logisticsCompanyName: expressCompany,
            logisticsExternalNo: expressOrderId
        } = lgOrder;
        const [firstTrace = { lgTraceStatus: 'PROCESSING' }] = lgTraces;
        const lastItem = items[items.length - 1];
        const productImage = this.ctx.location.query.recipe === 'true'
            ? prescriptionImage
            : this.app.tfs.transformTFSImageUrl(lastItem.itemPic, 50, 50);

        this.orderInfo = {
            productImage,
            productTotalCount: items.length
        };

        this.logisticsInfo = {
            expressCompany,
            expressOrderId,
            currLogisticsStatusText: LOGISTICS_STATUS_MAP[firstTrace.lgTraceStatus],
            traceList: lgTraces.map((item, index) => {
                return {
                    desc: item.content,
                    date: util.formatDate(item.occurTime * 1000, 'yyyy-MM-dd HH:mm:ss'),
                    actived: index === 0,
                    _item: item
                };
            })
        };

        return res;
    }
}