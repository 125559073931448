var React=window.Snowball._React;
import Steps from '../../../shared/components/Steps';

const RefundRecordSteps = (props) => {
    const {
        refundRecordList,
        canCancelRefund,
        onCancelRefundBtnClick
    } = props;

    return (
        <div className="as__refund_record">
            <p className="title">
                售后进度
                {
                    canCancelRefund && (
                        <div
                            className="btn_cancel"
                            onClick={onCancelRefundBtnClick}
                        >撤销申请</div>
                    )
                }
            </p>
            <div className="main">
                <Steps direction="vertical" className="as__refund_steps">
                    { refundRecordList.map((record) => {
                        return (
                            <Steps.Step
                                icon={<i></i>}
                            >
                                <p className="desc">{record.desc}</p>
                                <div className="additionalDesc">{
                                    record.additionalDescList.map((item) => {
                                        return (
                                            <p>{`${item.title}: ${item.content}`}</p>
                                        );
                                    })
                                }</div>
                                <p className="date">{record.date}</p>
                            </Steps.Step>
                        );
                    }) }
                </Steps>
            </div>
        </div>
    );
};

export default RefundRecordSteps;