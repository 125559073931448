var { Service }=window.Snowball._app;

export default class PromcenterApiService extends Service {

    getCouponSpecsWithChannel(spuId) {
        return this.app.gateway.request("promcenter.getCouponSpecsWithChannel", false,
            {
                spuId,
                channel: this.app.env.OUT_BIZ_TYPE
            },
            {
                isShowLoading: false,
                autoLogin: false
            }
        );
    }

    getPromotionsWithChannel(spuId) {
        return this.app.gateway.request("promcenter.getPromotionsWithChannel", false,
            {
                spuId,
                channel: this.app.env.OUT_BIZ_TYPE
            },
            {
                isShowLoading: false,
                autoLogin: false
            });
    }

    getPromotionsWithChannelV2(spuId) {
        return this.app.gateway.request("promcenter.getPromotionsWithChannelV2", false,
            {
                spuId,
                channel: this.app.env.OUT_BIZ_TYPE
            },
            {
                isShowLoading: false,
                autoLogin: false
            }
        );
    }

    /**
     * 根据模板ID给用户领券
     * @param {number} specId
     */
    receiveCouponBySpecId(specId) {
        return this.app.gateway.request("promcenter.receiveCouponBySpecId", true, {
            specId
        });
    }

    /**
     * 查询可领券和已领券列表
     * @param {number[]} spuIds
     * @param {number} specType 类型：1优惠券，4红包
     */
    queryAvailableAndReceivedSpecs(spuIds, specType) {
        return this.app.gateway.request("promcenter.queryAvailableAndReceivedSpecs", false, {
            channel: this.app.env.OUT_BIZ_TYPE,
            spuIds,
            specType
        });
    }

    /**
     * 查询优惠券列表
     * @param {string} status
     */
    queryUserCoupons({ status = "NOT_USED",pageNo = 1,pageSize = 10 }) {
        return this.app.gateway.request("promcenter.queryUserCoupons", true, {
            userCouponQuery: { status,pageNo,pageSize,appChannel: this.app.env.APP_ENV, code: 0, }
        });
    }

    /**
     * 优惠码激活
     * @param {string} code
     */
    activateCouponCode({ code = "" }) {
        return this.app.gateway.request("promcenter.activateCouponCode", true, {
            code
        });
    }
}