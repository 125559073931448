/* eslint-disable react-hooks/exhaustive-deps */
var React=window.Snowball._React;var { useEffect, useState }=window.Snowball._React;
import "../../sass/shared/carousel.scss";

const DEFAULT_OPTIONS = {
    interval: 3000
};

export default function Carousel(props) {
    const { list = [], options = DEFAULT_OPTIONS } = props;
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (this.timer) {
            setIndex(0);
            clearInterval(this.timer);
        }
        if (list.length) {
            this.timer = setInterval(() => {
                setIndex(index => (index + 1) % list.length);
            }, options.interval);
        }

        return () => this.timer && clearInterval(this.timer);
    }, [list]);

    if (!list.length) {
        return null;
    }

    return (
        <ul className="cm_slider">
            {list.map((item, idx) => (
                <li app-link={item.link} className={index == idx ? "visible" : ""}>
                    <img src={item.src} alt="slider_img" />
                </li>
            ))}
        </ul>
    );
}
