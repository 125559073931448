/**
 * app所在环境
 */

const ua = navigator.userAgent;

const PLATFORM = /(Android);?[\s/]+([\d.]+)?/i.test(ua)
    ? "android"
    : /(iPhone|iPad|iPod).*OS\s([\d_]+)/i.test(ua)
        ? 'iOS'
        : /(MSIE) (\d+)/i.test(ua)
            ? 'ie'
            : /Chrome\/(\d+)/i.test(ua)
                ? 'chrome'
                : 'pc';

const ov = RegExp.$2 ? RegExp.$2.split(/_|\./) : [0, 0];
const osVersion = parseFloat(ov[0] + '.' + ov[1]);
const iOS = PLATFORM === 'iOS';
const isAndroid = PLATFORM === 'android';
export const isIE = PLATFORM === 'ie';

const IS_SHOUXIAN_CORDOVA = /cordovashouxian/i.test(ua);
export const IS_WX = /MicroMessenger/.test(ua);

export const IS_PREVIEW = /(\?|&)preview=1\b/.test(location.search);
/**
 * 是否是预发环境
 */
export const IS_PRE = location.host.indexOf('pre.') !== -1;

export const APP_ENV = IS_SHOUXIAN_CORDOVA
    ? "SHOUXIAN"
    : /(?:\?|&)app=([^=&]+)/i.test(location.search)
        ? decodeURIComponent(RegExp.$1).toUpperCase()
        : "HFLPCZ";

export const IS_PC = true;
export const IS_SHOUXIAN = APP_ENV === "SHOUXIAN";
// 是否是微信小程序
export const IS_WX_MINIPROGRAM = APP_ENV === "WXXCX";

export const OUT_BIZ_TYPE = /(?:\?|&)outBizType=([^=&]+)/i.test(location.search)
    ? decodeURIComponent(RegExp.$1)
    : APP_ENV;

export const ENV = /(www|yao)\.jk\.cn/.test(location.host)
    ? 'prod'
    : /(www|yao)\.pre\.jk\.cn/.test(location.host)
        ? 'pre'
        : /(www|yao)\.test\.pajk(dc\.com|\.cn)/.test(location.host)
            ? 'test'
            : 'dev';

export { PLATFORM, osVersion, iOS };

export const android = isAndroid;

export const IS_FROM_IFRAME = /(\?|&)from=iframe\b/.test(location.search);

// 是否显示健康金
export const IS_SHOW_JKJ = false;

export const GATEWAY_URL = "/m.api";

export const APP_ID = 9224152;

export const PROJECTS = {
};

/**
 * m.api、tfs等地址
 */
export const autoconfig = {
    APP_API: process.env.REACT_APP_API,
    TFS: process.env.REACT_APP_TFS,
    FILEGW: process.env.REACT_APP_FILEGW,
    BEACON: process.env.REACT_APP_BEACON,
    SPM: process.env.REACT_APP_SPM_URL,
    LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
    LANDING_TIKET_URL: process.env.REACT_APP_LANDING_TIKET,
    AD_URL: process.env.REACT_APP_AD_URL,
    IM_CONSULT_URL: process.env.REACT_APP_IM_CONSULT_URL,
};

if (process.env.NODE_ENV !== 'production' || !process.env.REACT_APP_API || process.env.REACT_APP_API === '/m.api') {
    if (location.host.indexOf('.dev.') !== -1) {
        // 开发环境
        autoconfig.APP_API = "//api.dev.pajkdc.com/m.api";
        autoconfig.TFS = "//static.dev.pajkdc.com/";
        autoconfig.FILEGW = "//filegw.dev.pajkdc.com/";
        autoconfig.BEACON = "//beacon.dev.pajkdc.com/js/beacon.js";
        autoconfig.SPM = "//beacon.dev.pajkdc.com/js/spm.js";
        autoconfig.LOGIN_URL = "//www.dev.pajkdc.com/daisy/#/pclogin?appId=9224152";
        autoconfig.LANDING_TIKET_URL = "//dev.pajkdc.com/landing_smspassword";
        autoconfig.AD_URL = '//www.dev.pajkdc.com/adweb/adweb.js';
        autoconfig.IM_CONSULT_URL = '//www.test.pajkdc.com/im-cs-m/#/im-pc/20335450708?status=1';
    } else if (location.host.indexOf('.test.') !== -1) {
        // 测试环境
        autoconfig.APP_API = "//api.test.pajkdc.com/m.api";
        autoconfig.TFS = "//static.test.pajkdc.com/";
        autoconfig.FILEGW = "//filegw.test.pajkdc.com/";
        autoconfig.BEACON = "//beacon.test.pajkdc.com/js/beacon.js";
        autoconfig.SPM = "//beacon.test.pajkdc.com/js/spm.js";
        autoconfig.LOGIN_URL = "//www.test.pajkdc.com/daisy/#/pclogin?appId=9224152";
        autoconfig.LANDING_TIKET_URL = "//test.pajkdc.com/landing_smspassword";
        autoconfig.AD_URL = '//www.test.pajkdc.com/adweb/adweb.js';
        autoconfig.IM_CONSULT_URL = '//www.test.pajkdc.com/im-cs-m/#/im-pc/20335450708?status=1';
    } else if (IS_PRE) {
        // 预发环境
        autoconfig.APP_API = "//api.pre.jk.cn/m.api";
        autoconfig.TFS = "//jkcdn.pajk.com.cn/";
        autoconfig.FILEGW = "//filegw.pre.jk.cn/";
        autoconfig.BEACON = "//beacon.pre.jk.cn/js/beacon.js";
        autoconfig.SPM = "//beacon.pre.jk.cn/js/spm.js";
        autoconfig.LOGIN_URL = "//www.pre.jk.cn/daisy/#/pclogin?appId=9224152";
        autoconfig.LANDING_TIKET_URL = "//pre.jk.cn/landing_smspassword";
        autoconfig.AD_URL = '//www.pre.jk.cn/adweb/adweb.js';
        autoconfig.IM_CONSULT_URL = '//www.pre.jk.cn/im-cs-m/#/im-pc/24472980403?status=1';
    } else if (location.host.indexOf('.jk.cn') !== -1) {
        // 生产环境
        autoconfig.APP_API = "//api.jk.cn/m.api";
        autoconfig.TFS = "//jkcdn.pajk.com.cn/";
        autoconfig.FILEGW = "//filegw.jk.cn/";
        autoconfig.BEACON = "//beacon.jk.cn/js/beacon.js";
        autoconfig.SPM = "//beacon.jk.cn/js/spm.js";
        autoconfig.LOGIN_URL = "//www.jk.cn/daisy/#/pclogin?appId=9224152";
        autoconfig.LANDING_TIKET_URL = "//jk.cn/landing_smspassword";
        autoconfig.AD_URL = '//www.jk.cn/adweb/adweb.js';
        autoconfig.IM_CONSULT_URL = 'https://www.jk.cn/im-cs-m/#/im-pc/24472980403?status=1';
    } else {
        // 本地环境
        autoconfig.APP_API = process.env.REACT_APP_API || "/m.api";
        autoconfig.TFS = process.env.REACT_APP_TFS || "//static.dev.pajkdc.com/";
        autoconfig.FILEGW = process.env.REACT_APP_FILEGW || "//filegw.dev.pajkdc.com/";
        autoconfig.BEACON = process.env.REACT_APP_BEACON || "//beacon.dev.pajkdc.com/js/beacon.js";
        autoconfig.SPM = process.env.REACT_APP_SPM_URL || "//beacon.dev.pajkdc.com/js/spm.js";
        autoconfig.LOGIN_URL = process.env.REACT_APP_LOGIN_URL || '//www.dev.pajkdc.com/daisy/#/pclogin?appId=9224152';
        autoconfig.LANDING_TIKET_URL = process.env.REACT_APP_LANDING_TIKET || "//api.dev.pajkdc.com/landing_smspassword";
        autoconfig.AD_URL = process.env.REACT_APP_AD_URL || "//www.dev.pajkdc.com/adweb/adweb.js";
        autoconfig.IM_CONSULT_URL = process.env.REACT_APP_IM_CONSULT_URL || '//www.test.pajkdc.com/im-cs-m/#/im-pc/20335450708?status=1';
    }
}

if (location.host.indexOf('.pajk.cn') !== -1) {
    Object.keys(autoconfig).forEach((key) => {
        autoconfig[key] = autoconfig[key].replace('pajkdc.com', 'pajk.cn');
    });
    Object.keys(PROJECTS).forEach((key) => {
        PROJECTS[key] = PROJECTS[key].replace('pajkdc.com', 'pajk.cn');
    });
}

export const {
    APP_API,
    TFS,
    FILEGW,
    LANDING_TIKET_URL,
    AD_URL,
    IM_CONSULT_URL,
    LOGIN_URL,
    BEACON
} = autoconfig;

export const MALL_URL = `${location.protocol}//${location.host}${location.pathname.replace(/index\.html$/, '')}`;

export const API_CHANNAL_PARAM = PLATFORM + '|' + APP_ENV;
export const API_CHANNAL = APP_ENV;

export const APP_VERSION = /pajkAppVersion\/(\d+)/.test(ua) ? parseInt(RegExp.$1, 10) : 0;

export const TUAN_HOME = '';
