var React=window.Snowball._React;var { useEffect }=window.Snowball._React;
import AddressForm from "./AddressForm";
import Form from "../../../shared/components/Form";
var { inject, autowired }=window.Snowball._app;

function AddressEditor({
    onSave
}) {
    return (
        <div className="pt_l">
            <AddressForm />
            <Form.Item>
                <button
                    className="address-save-btn"
                    onClick={onSave}
                >保存</button>
            </Form.Item>
        </div>
    );
}

export default inject(() => {
    const addressFormViewModel = autowired('addressFormViewModel');
    useEffect(() => {
        addressFormViewModel.setFormData({});
    }, [addressFormViewModel]);

    return {
        onSave: addressFormViewModel.onSave
    };
})(AddressEditor);
