var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import mixPhone from '../../../shared/utils/mixPhone';
import getBlockStatus from '../../../shared/utils/getBlockStatus';

const BLOCK_ID = 3041201;

const BLOCK_STATUS = {
    NORMAL_DISABLE: 10,
    NORMAL_ENABLE: 11,
    MED_DISABLE: 20,
    MED_ENABLE: 21
};

function Bottom({
    totalPiece,
    cash,
    coupon,
    redPacket,
    promotionPrice,
    address,
    blockStatus,
    onCreateOrderClick,
}) {
    const enabled = blockStatus == BLOCK_STATUS.NORMAL_ENABLE
        || blockStatus == BLOCK_STATUS.MED_ENABLE;

    return (
        <div className="co_bottom fs_m">
            <div className="line ta_r">
                <span className="left">促销：</span>
                <span className="right">-￥{(promotionPrice / 100).toFixed(2)}</span>
            </div>
            <div className="line ta_r">
                <span className="left">优惠券：</span>
                <span className="right">-￥{coupon ? (coupon.discountPrice / 100).toFixed(2) : '0.00'}</span>
            </div>
            <div className="line ta_r">
                <span className="left">红包：</span>
                <span className="right">-￥{redPacket ? (redPacket.discountPrice / 100).toFixed(2) : '0.00'}</span>
            </div>
            <div className="total ta_r mt_xxl mb_xl">
                <div className="price fw_b">
                    <span>共<span className="count">{totalPiece}</span>件商品，应付总额:</span>
                    <span>
                        <span className="symbol">￥</span>
                        <span className="money">{cash !== undefined && (cash / 100).toFixed(2)}</span>
                    </span>
                </div>
                {
                    address.id ? (
                        <div className="address">
                            <span className="fw_b">寄送至：</span>
                            <span className="mr_l">{`${address.province == address.city ? '' : address.province}${address.city}${address.district}${address.streetAddress + address.referAddr}`}</span>
                            <span className="fw_b">收货人：</span>
                            <span>{address.recipientName} {mixPhone(address.recipientPhone)}</span>
                        </div>
                    ) : null
                }
            </div>
            <div className="clearfix">
                <div
                    className={`btn_submit fl_r ta_c cl_fff br_4 cs_p ${!enabled ? 'disabled' : ''}`}
                    onClick={enabled ? onCreateOrderClick : null}
                >
                    提交订单
                </div>
            </div>
        </div>
    );
}

export default inject(() => {
    const {
        fundModel,
        sellerCollection,
        addressModel,
        lml,
        onCreateOrderClick,
    } = autowired('orderCreateViewModel');

    const {
        cash,
        redCouponCode,
        redCoupons = [],
        couponCode,
        coupons = [],
        promotionPrice = 0,
    } = fundModel;


    const totalPiece = sellerCollection.reduce((prev, seller) => {
        const skuAmount = seller.items.reduce(
            (sum, cur) => Number(sum) + Number(cur.status ? 0 : cur.piece),
            0,
        );
        const bundleItemsAmount = seller.bundleItems.reduce((total, bundle) => {
            let skuSum = bundle.items.reduce(
                (sum, cur) => Number(sum) + Number(cur.status ? 0 : cur.piece),
                0,
            );
            return Number(total) + Number(bundle.status ? 0 : bundle.count * skuSum);
        }, 0);
        return prev + skuAmount + bundleItemsAmount;
    }, 0);

    return {
        totalPiece,
        cash,
        coupon: coupons.find(({ code }) => code == couponCode),
        redPacket: redCoupons.find(({ code }) => code == redCouponCode),
        promotionPrice,
        address: addressModel,
        onCreateOrderClick,
        blockStatus: getBlockStatus(lml, BLOCK_ID),
    };
})(Bottom);
