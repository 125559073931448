var React=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
import PayResult from '../components/PayResult';
import TradeOrderList from '../components/TradeOrderList';
import ProgressList from '../../../shared/components/ProgressList';
import Main from '../../../shared/components/Main';
import { Header, Logo } from 'jkmall-frame';

function PayResultContainer(props) {
    const {
        waiting,
        orders,
        payInfo,
        onViewOrder,
        onFinishPurchase,
        onRepay
    } = props;
    const order = orders && orders.length && orders[0];

    const isPaid = order && order.tradeOrder.payMode == 'PAYONLINE' && order.tradeOrder.orderStatus == "PAID";

    return (
        <div className="app-wrapper">
            <Header
                renderExtra={() => {
                    return <div className="clearfix">
                        <Logo name="收银台" className="mt_8" />
                        <ProgressList
                            selectedIndex={isPaid ? 3 : 2}
                        ></ProgressList>
                    </div>;
                }}
            />
            <Main className="opr_main">
                <PayResult
                    {...order}
                    waiting={waiting}
                    payInfo={payInfo}
                    onViewOrder={onViewOrder}
                    onFinishPurchase={onFinishPurchase}
                    onRepay={onRepay} >
                </PayResult>
                {
                    order && (
                        <TradeOrderList orders={orders}></TradeOrderList>
                    )
                }
            </Main>
        </div>
    );
}

export default inject(mapViewModelToProps('payResultViewModel'))(PayResultContainer);