var { controller, autowired, param }=window.Snowball._app;
import AfterSaleList from '../containers/AfterSaleList';
import { AfterSaleListConfiguration } from '../configuration';

@controller({
    component: AfterSaleList,
    configuration: [AfterSaleListConfiguration],
})
export default class AfterSaleListController {
    @param('orderId')
    orderId: number;

    @autowired
    _afterSaleListViewModel;

    onInit() {
        this.ctx.logger.makeLog('H5web_pc_after_sale_from_orderinfo', 'onload', {
            orderId: this.orderId,
        });
        this._afterSaleListViewModel.init(this.orderId);
    }
}
