var React=window.Snowball._React;var { Fragment, useEffect, useRef }=window.Snowball._React;
import Loading from '../../../shared/components/Loading';
import QRCode from 'qrcode';
import { QR_CODE_STATUS } from '../constants';

export default function QrCodePanel({
    icon,
    title,
    titleBgColor,
    tips,
    qrCode,
    qrCodeStatus,
    qrCodeRestTime = 0,
    money = 0,
    onRefreshClick,
}) {
    const qrCodeImgRef = useRef(null);

    useEffect(() => {
        QRCode.toDataURL(qrCode, { margin: 0, width: 174 }, function (error, url) {
            if (error) {
                console.error(error);
                return;
            }
            qrCodeImgRef.current.src = url;
        });
        return () => { };
    }, [qrCode]);

    return (
        <div className="qrcode_panel">
            <div className="header ps_r" style={{ backgroundColor: titleBgColor }}>
                {icon}
                <span className="va_m">{title}</span>
                <div className="triangle" style={{ backgroundColor: titleBgColor }}></div>
            </div>
            <div className="body">
                {tips && <p className="tips">{tips}</p>}
                <div className="qr_code_wrap ps_r">
                    <img className="qr_code va_t" ref={qrCodeImgRef} alt="qrCode" />
                    {
                        qrCodeStatus != QR_CODE_STATUS.NORMAL && (
                            <div className="mask">
                                <div className="mask_body">
                                    {
                                        qrCodeStatus == QR_CODE_STATUS.OVERDUE && (
                                            <Fragment>
                                                <p className="text_overdue">付款码已过期</p>
                                                <div>
                                                    <div className="btn_refresh cs_p" onClick={onRefreshClick}>刷新二维码</div>
                                                </div>
                                            </Fragment>
                                        )
                                    }
                                    {qrCodeStatus == QR_CODE_STATUS.LOADING && <Loading />}
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="total">
                    <span>应付金额：</span>
                    <span className="money">￥{money ? (money / 100).toFixed(2) : ''}</span>
                </div>
                {
                    qrCodeStatus == QR_CODE_STATUS.NORMAL && qrCodeRestTime
                        ? <div className="status">距离二维码过期还剩<span className="countdown">{(qrCodeRestTime / 1000).toFixed()}</span>秒</div>
                        : qrCodeStatus == QR_CODE_STATUS.OVERDUE
                            ? <div className="status">请刷新后重试</div>
                            : null
                }
            </div>
        </div>
    );
}