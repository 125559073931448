var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import Title from './Title';
import Promotion from './Promotion';

function CategoryCoupons({
    coupons,
    onCouponChange,
}) {
    if (!coupons.length) {
        return null;
    }

    return (
        <div className="co_cc">
            <Title title="优惠券" />
            {Array.isArray(coupons) && coupons.map((coupon, i) => {
                return (
                    <Promotion
                        key={i}
                        coupon={coupon}
                        noUseBtnText="不使用优惠"
                        onClick={() => onCouponChange(coupon.code)}
                        onNoUseBtnClick={() => onCouponChange('')}
                    />
                );
            })}
        </div>
    );
}

export default inject(() => {
    const {
        fundModel,
        onCouponChange,
    } = autowired('orderCreateViewModel');

    const { couponCode, coupons } = fundModel;

    const couponList = Array.isArray(coupons)
        ? coupons.reduce((result, coupon) => {
            result.push({
                ...coupon,
                selected: coupon.code == couponCode,
            });
            return result;
        }, [])
        : [];

    return {
        coupons: couponList,
        onCouponChange,
    };
})(CategoryCoupons);
