var { observable, asObservable, util }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
var { toast }=window.Snowball._widget;
import UnicornApiService from '@/shared/apis/UnicornApiService';
import AppResourceService from "@/shared/services/AppResourceService";

const rules = {
    expressCompanyId: [{
        required: true,
        message: '请选择物流公司'
    }],
    expressOrderId: [{
        required: true,
        message: '请输入物流订单号'
    }]
};

export default class RefundExpressFormViewModel extends ViewModel {
    @observable
    formData = {};

    @observable
    errors = {};

    @observable
    expressCompanyList = [];

    @autowired
    _unicornApiService: UnicornApiService;

    @autowired
    _appResourceService: AppResourceService;

    _requestNo = '';

    init(requestNo) {
        this._requestNo = requestNo;
        this.fetchExpressCompanyList();
    }

    async fetchExpressCompanyList() {
        const res = await this._appResourceService.getAppResource({
            system: 'plugin',
            channel: 'all',
            type: 'text',
            source: 'chooseExpress'
        });
        const { values = [] } = res;

        values.forEach((item) => {
            const { keyName, val = "[]" } = item;

            if (keyName === 'expressList') {
                this.expressCompanyList = JSON.parse(val).map((item) => {
                    const [id, name] = item.split('|');

                    return { id, name };
                });
            }
        });
    }

    save(onSuccess, onError?) {
        this.submit(async (data) => {
            const res = await this._unicornApiService.submitAfterSalesLogisticsInfo({
                afterSalesOrderId: this._requestNo,
                afterSalesOrderLogisticsArgs: {
                    logisticCompanyCode: data.expressCompanyId,
                    logisticOrderId: data.expressOrderId
                }
            });

            if (res.success) {
                toast.showToast('保存成功');
                onSuccess && onSuccess();
            }
            else {
                toast.showToast(res.message || '保存失败');
                onError && onError();
            }
        });
    }

    @emitter
    onFieldsChange(data) {
        asObservable(this.errors)
            .set(this.app.validate(data, util.pick(rules, Object.keys(data))));

        asObservable(this.formData)
            .set(data);
    }

    async submit(onSuccess, onError?) {
        this.errors = this.app.validate(this.formData, rules);
        if (Object.keys(this.errors).some((key) => this.errors[key])) {
            onError && onError(this.errors);
        } else {
            onSuccess({ ...this.formData });
        }
    }
}