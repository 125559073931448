var React=window.Snowball._React;
var { popup }=window.Snowball._widget;

const showCouponHelp = () => {
    popup.alert({
        title: '优惠券使用规则',
        content: (
            <div style={{ 'white-space': 'pre-line' }}>
                {`1、好福利PC商城发放的优惠券，仅能在本商城提交订单时抵减应支付商品金额（不能抵运费），不能进行兑现或其他用途； \n
        2、使用优惠券提交的订单，若订单未拆分，则订单取消后，系统自动返回相应的红包或优惠券；若订单拆分，取消全部子单，系统自动返回相应的优惠券； \n
        3、使用优惠券，若订单未拆分且订单全部商品发生售后退货，系统将在您订单最后一个商品退回后，为您返回优惠券；若订单拆分，发生售后退货，优惠券不予返还，请您慎重考虑；\n 
        4、本商城发放的所有优惠券严禁出售，如经发现并证实的，该券将予以作废处理； \n
        5、本规则由好福利PC商城依据国家相关法律法规及规章制度予以解释。\n
        
        注意：优惠券退回时，可能已超出优惠券的有效期，将无法再使用。您可以在“我的优惠券 - 已过期”中找到已过期的优惠券。`}
            </div>
        ),
        okText: '关闭'
    });
};

function CouponHelp() {
    return (
        <div className="coupon_help" onClick={() => showCouponHelp()}>
            <span className="icon">i</span>
            <span className="help">优惠券帮助说明</span>
        </div>
    );
}

export default CouponHelp;
