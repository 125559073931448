import "../styles/order-modal.scss";
var React=window.Snowball._React;
var { autowired, ViewModel }=window.Snowball._app;
var { popup, toast }=window.Snowball._widget;
import OrderAppResourceService from "../services/OrderAppResourceService";
import MasterApiService from "../../../shared/apis/MasterApiService";
import UnicornApiService from "../../../shared/apis/UnicornApiService";
import RefundModalViewModel from "./RefundModalViewModel";
import Reasons from "../components/Reasons";

export default class OrderOperationHandlers extends ViewModel {
    onOrderStatusChange = this.ctx.createEmitter();

    onGotoSelfService = this.ctx.createEmitter();
    onConsult = this.ctx.createEmitter();

    onPay = this.ctx.createEmitter();
    onRePurchase = this.ctx.createEmitter();
    onRefund = this.ctx.createEmitter();
    onDelOrder = this.ctx.createEmitter();
    onDidDeleteOrder = this.ctx.createEmitter();
    onConfirmReceipt = this.ctx.createEmitter();
    onDidConfirmReceipt = this.ctx.createEmitter();
    onGoEvaluating = this.ctx.createEmitter();
    onCancelOrder = this.ctx.createEmitter();
    onGoToOrderStatus = this.ctx.createEmitter();
    onGoToAfterSaleList = this.ctx.createEmitter();
    onApplyOrderAfterSale = this.ctx.createEmitter();
    onApplyItemAfterSale = this.ctx.createEmitter();
    onGoCheckLogistics = this.ctx.createEmitter();
    onDzcfBtnClick = this.ctx.createEmitter();

    @autowired
    _orderAppResourceService: OrderAppResourceService;

    @autowired
    _masterApiService: MasterApiService;

    @autowired
    _unicornApiService: UnicornApiService;

    @autowired
    _refundModalViewModel: RefundModalViewModel;

    constructor() {
        super();

        // TODO
        this.isRecipeFlagForDelivery = false;

        this.onGotoSelfService(() => this.goToSelfService());
        this.onConsult((tradeOrder) => this.consult(tradeOrder));

        this.onPay((tradeOrder) => this.pay(tradeOrder));
        this.onRePurchase((orderId) => this.rePurchase(orderId));
        // 整单退走取消订单逻辑
        this.onRefund((data) => this.cancelOrder(data));
        this.onDelOrder((tradeId) => this.delOrder(tradeId));
        this.onConfirmReceipt((tradeId) => this.confirmReceipt(tradeId));
        this.onGoEvaluating((tradeId) => this.goEvaluating(tradeId));
        this.onCancelOrder((data) => this.cancelOrder(data));
        this.onGoToOrderStatus(() => this.goToOrderStatus());
        this.onGoToAfterSaleList((tradeOrder) => this.goToAfterSaleList(tradeOrder));
        this.onApplyOrderAfterSale((data) => this.applyOrderAfterSale(data));
        this.onApplyItemAfterSale((data) => this.applyItemAfterSale(data));
        this.onGoCheckLogistics((tradeId) => this.checkLogistics(tradeId));
        this.onDzcfBtnClick(({ buttonStatus }) => {
            if (buttonStatus.healthPresButton && buttonStatus.healthPresButtonUrl) {
                const url = buttonStatus.healthPresButtonUrl;
                this._openPage(url);
            }
        });
    }

    /**
     * 自助服务
     */
    goToSelfService() {
        const { APP_ENV } = this.app.env;
        let url = (this._orderAppResourceService.documents.find(doc => doc.keyName == 'yao-consult-url') || {}).val;
        if (!url) {
            // return this._openPage(EXCHAGE_RULE_URL);
        } else if (APP_ENV == "PAJK") {
            return this._openPage(url + '?source=pajk&download=0&topbar=0');
        } else if (APP_ENV == "SHOUXIAN") {
            return this._openPage(url + '?source=shouxian&download=0&topbar=1');
        } else {
            return this._openPage(url + '?source=' + APP_ENV + '&download=0&topbar=1');
        }
    }

    /**
     * 客服咨询
     */
    consult(tradeOrder) {
        const { items = [] } = tradeOrder;
        const { spuTags = [] } = items[0] || {};
        const configAftersale = this._orderAppResourceService.documents.find(doc => doc.keyName == 'ymk-aftersale') || {};
        let aftersaleUrl = configAftersale.val || '';

        if (spuTags.indexOf('医美卡客服') !== -1) {
            return this._goToCustomerService(tradeOrder, aftersaleUrl);
        }

        // 货到付款-参数名:customerServiceNumber;
        let serviceTel = tradeOrder.customerServiceNumber || '';
        let customerServiceUrl = tradeOrder.customerServiceUrl || '';
        if (!customerServiceUrl && serviceTel) {
            this.callServiceTel(serviceTel);
        } else {
            this._goToCustomerService(tradeOrder, customerServiceUrl);
        }
    }

    callServiceTel(tel) {
        popup.confirm({
            content: "要打给" + tel + "吗？",
            okText: "拨打",
            cancelText: "取消",
            onOk: () => {
                let a = document.createElement("a");
                a.id = 'call-service';
                a.href = "tel:" + tel;
                document.body.appendChild(a);
                // 解决safari下a标签默认没有事件绑定
                let e = document.createEvent('HTMLEvents');
                e.initEvent('click', false, false);
                a.dispatchEvent(e);
            }
        });
    }

    _goToCustomerService(tradeOrder, customerServiceUrl) {
        const { APP_ENV, OUT_BIZ_TYPE, API_CHANNAL_PARAM, IM_CONSULT_URL } = this.app.env;

        let orderId = tradeOrder.tradeId;
        let bizData = {
            "serviceenter": "order",
            "source": APP_ENV,
            "orderid": orderId,
            "outBizType": OUT_BIZ_TYPE
        };

        // 传给客服工作台增加feature字段，内容为 设备|渠道（为代客下单服务）
        const feature = { source: API_CHANNAL_PARAM };
        const queryString = '&channel=' + this.app.env.APP_ENV + '&bizdata=' + JSON.stringify(bizData) + '&feature=' + JSON.stringify(feature);
        if (customerServiceUrl.length) {
            return this._openPage(customerServiceUrl.replace('#/im/', '#/im-pc/') + '&bizdata=' + JSON.stringify(bizData));
        }

        const url = IM_CONSULT_URL + queryString;
        this._openPage(url);
    }

    /**
     * 支付订单
     *
     * @param {*} tradeOrder 订单信息
     */
    async pay(tradeOrder) {
        let {
            tradeId,
        } = tradeOrder;

        const callBackUrl = `${location.protocol}//${location.hostname}${location.pathname}${location.search}#/order/payresult/${tradeId}`;

        const res = await this._masterApiService.getPayUrl({
            orderIds: [tradeId],
            outBizType: this.app.env.OUT_BIZ_TYPE,
            callBackUrl
        });

        if (res.success) {
            this.app.navigation.forward('/checkout?tradeIds=' + tradeId);
        } else {
            popup.alert({
                content:
                    res.stat.stateList[0].code == 38000076
                        ? '您提交的支付订单已过期，请重新下单后再操作!'
                        : res.message
            });
        }
    }

    /**
     * 再次购买
     *
     * @param {number} orderId 订单ID
     */
    async rePurchase(orderId) {
        const res = await this._masterApiService.rePurchase(orderId);
        if (res.success) {
            const {
                type,
                prescId = '',
                storeId,
                warn,
                chiefComplaint = '',
            } = res.data;

            const page_source = this.app.logger.getCurrentPageId();

            const prescUrl = parasiteUrl(`RP.PLUSONE.DRUG.SUGGESTION@${prescId}`, chiefComplaint, page_source);
            const prescStoreUrl = parasiteUrl(`RP.PLUSONE.CART@${storeId}`, chiefComplaint, page_source);

            // type: 1.电子处方 2.处方订单 3.B2C购物车 4.O2O购物车 5.处方药需求清单购物车
            const url = type === 1
                ? prescUrl
                : type === 2
                    ? prescStoreUrl
                    : '/cart';

            if (warn) {
                popup.confirm({
                    content: warn,
                    cancelText: "取消",
                    okText: type === 2 ? '去开药门诊' : '去购物车',
                    onOk: () => {
                        this.app.navigation.forward(url);
                    }
                });
            } else {
                this.app.navigation.forward(url);
            }
        } else {
            popup.alert({
                content: res.message
            });
        }
    }

    confirmReceipt(tradeId) {
        popup.confirm({
            title: '确认收货',
            content: '您确认收到订单商品了吗？',
            okText: "确定",
            onOk: async () => {
                try {
                    await this._unicornApiService.confirmReceipt(tradeId);
                    toast.showToast('已确认收货');
                    this.onDidConfirmReceipt.emit(tradeId);
                } catch (error) {
                    toast.showToast('系统繁忙，请稍后重试');
                }
            },
            cancelText: "取消"
        });
    }

    goEvaluating(tradeId) {
        // TODO: 放到二期
        this.app.navigation.forward(`/yao-comment/${tradeId}`);
    }

    /**
     * 查看物流
     */
    checkLogistics(tradeId) {
        this.app.navigation.forward(
            this.isRecipeFlagForDelivery
                ? `/logistics/${tradeId}?recipe=true`
                : `/logistics/${tradeId}`
        );
    }

    /**
     *取消订单
     *
     * @param {*} {
     *         tradeOrder,
     *         buttonStatus
     *     }
     */
    cancelOrder({
        tradeOrder,
        buttonStatus
    }) {
        if (buttonStatus.closeButton) {
            // 非20商品调用
            this.closeOrder(tradeOrder.tradeId);
        } else if (buttonStatus.cancelOrderButton) {
            // 20商品
            if (tradeOrder.payMode == 'CASHONDELIVERY') {
                // 货到付款
                if (tradeOrder.storeTel) {
                    popup.confirm({
                        content: '货到付款商品无法进行线上取消订单操作，取消订单请拨打商家电话:' + tradeOrder.storeTel
                    });
                } else {
                    popup.alert({
                        content: '您好，若您真的不想要了，等待快递送达时，直接拒签即可。'
                    });
                }
            } else {
                this.cancelUnpaidOrder(tradeOrder.tradeId);
            }
        } else if (buttonStatus.applyRefundButton) {
            if (!this.validAfterSalesExpired(buttonStatus)) {
                return;
            }
            // 已支付，未发货，整单取消
            this.wholeOrderRefund(tradeOrder.tradeId);
        } else {
            toast.showToast('订单不可取消!');
        }
    }

    async closeOrder(orderId) {
        try {
            await this._unicornApiService.closeOrder(orderId);
            toast.showToast('订单取消成功');
            this.onOrderStatusChange.emit({
                type: 'closeOrder',
                orderId
            });
        } catch (error) {
            toast.showToast('订单取消失败!');
        }
    }

    cancelUnpaidOrder(orderId) {
        this.selectCancelOrderReason({
            onSelect: async (reason) => {
                try {
                    await this._unicornApiService.cancelOrder(orderId, reason);
                    toast.showToast('订单取消成功');
                    this.onOrderStatusChange.emit({
                        type: 'cancelOrder',
                        orderId
                    });
                } catch (error) {
                    toast.showToast('订单取消失败!');
                }
            }
        });
    }

    wholeOrderRefund(orderId) {
        this.selectCancelOrderReason({
            cancelType: 'wholeOrderRefund',
            onSelect: async (reason) => {
                try {
                    const res = await this._unicornApiService.wholeOrderRefund(orderId, reason);
                    if (res.success) {
                        toast.showToast('请等待商家审核');
                        this.onOrderStatusChange.emit({
                            type: 'wholeOrderRefund',
                            orderId
                        });
                    } else if (res.code == 38000016) {
                        toast.showToast('订单状态已变更，请刷新重试');
                    } else {
                        throw new Error(res.message);
                    }
                } catch (error) {
                    toast.showToast('系统错误，请刷新重试!');
                }
            }
        });
    }

    selectCancelOrderReason({
        cancelType,
        onSelect,
        onCancel
    }) {
        const doc = this._orderAppResourceService.documents.find(item => item.keyName == 'cancelOrder');
        const reasons = JSON.parse(doc.val);
        const modal = popup.popup({
            className: "oi_reason_modal",
            content: <Reasons
                title={cancelType == 'refund'
                    ? "您确定要退款或退款退货吗？"
                    : cancelType == 'wholeOrderRefund'
                        ? '您确定要退款吗？'
                        : "您确定要取消订单吗？取消订单后，不能恢复。"
                }
                reasons={reasons.map((reason) => ({
                    type: reason,
                    title: reason
                }))}
                onError={() => {
                    toast.showToast('请选择' + (
                        cancelType == 'refund'
                            ? '退货或退款退货'
                            : cancelType == 'wholeOrderRefund'
                                ? '退款'
                                : '取消') + '原因！');
                }}
                onCancel={() => {
                    onCancel && onCancel();
                    modal.hide();
                }}
                onSelect={(reason) => {
                    onSelect(reason.title);
                    modal.hide();
                }}
            />
        });
    }

    /**
     * 整单售后
     *
     * @param {*} params { tradeId, orderStatus }
     */
    applyOrderAfterSale({ tradeId, orderStatus }) {
        this.selectRefundType({
            refundType: [1, 2],
            onSelect: (refundType) => {
                this.selectCancelOrderReason({
                    cancelType: 'refund',
                    onSelect: (reason) => {
                        this._unicornApiService.createAfterSalesOrderAll({
                            orderId: tradeId,
                            orderStatus,
                            refundType,
                            factor: reason
                        });
                    }
                });
            }
        });
    }

    selectRefundType({
        refundTypes = [1, 2],
        onSelect,
        onCancel
    }) {
        const mapper = {
            1: {
                type: 1,
                title: '我要退款(无需退货)',
                subTitle: '没收到货或与卖家协商同意不用退货只退款'
            },
            2: {
                type: 2,
                title: '我要退款退货',
                subTitle: '已收到货，需要退换收到的货物'
            }
        };
        const modal = popup.popup({
            className: "oi_reason_modal oi_reason_refund",
            content:
                <Reasons
                    title="您确定要退款或退款退货吗？"
                    reasons={refundTypes.map((refundType) => mapper[refundType])}
                    onError={() => {
                        toast.showToast('请选择退货退款类型！');
                    }}
                    onCancel={() => {
                        onCancel && onCancel();
                        modal.hide();
                    }}
                    onSelect={(reason) => {
                        onSelect(reason.type);
                        modal.hide();
                    }}
                />
        });
    }

    /**
     * 去订单审核状态页
     */
    goToOrderStatus() {
    }

    goToAfterSaleList(tradeId) {
        this.app.navigation.forward(`/aftersale/${tradeId}`);
    }

    /**
     * 单品售后
     *
     * @param {*} params {
     *         tradeOrder,
     *         buttonStatus,
     *         item
     *     }
     * @returns
     */
    applyItemAfterSale({
        tradeOrder,
        buttonStatus,
        item
    }) {
        if (!this.validAfterSalesExpired(buttonStatus)) {
            return;
        }

        if (typeof buttonStatus.applyOrderAfterSaleButtonUrl === 'string') {
            let targetUrl = buttonStatus.applyOrderAfterSaleButtonUrl;
            let subType = tradeOrder.orderSourceValue;
            if (subType == 36) {
                let orderId = tradeOrder.tradeId;
                let bizdata = {
                    orderid: orderId,
                    serviceenter: 'order',
                    source: this.app.env.APP_ENV,
                    outBizType: this.app.env.OUT_BIZ_TYPE
                };
                targetUrl = `${targetUrl}&channel=${this.app.env.APP_ENV}&bizdata=${encodeURIComponent(JSON.stringify(bizdata))}`;
            }
            return this._openPage(targetUrl);
        }

        this.selectRefundType({
            refundTypes: item.refundTypes,
            onSelect: (refundType) => {
                this._refundModalViewModel.refund({
                    refundType,
                    orderId: tradeOrder.tradeId,
                    skuId: item.itemSkuId,
                    amount: item.amount
                });
            }
        });
    }

    validAfterSalesExpired(buttonStatus) {
        if (buttonStatus.orderAfterSalesExpired) {
            toast.showToast({ message: '该商品已超过售后期' });
            return false;
        }
        return true;
    }

    delOrder(tradeId) {
        if (this._isDeleteingOrder) {
            return;
        }
        this._isDeleteingOrder = true;

        popup.confirm({
            title: '删除确认',
            content: '订单删除后，将无法查询和恢复，确认删除吗?',
            onOk: async () => {
                try {
                    await this._unicornApiService.hideOrder(tradeId);
                    this.onDidDeleteOrder.emit(tradeId);
                } catch (error) {
                    toast.showToast('订单删除失败，请重试！');
                }
                this._isDeleteingOrder = false;
            },
            onCancel: () => {
                this._isDeleteingOrder = false;
            }
        });
    }

    _openPage(url) {
        this.app.navigation.forward(url);
    }
}

function parasiteUrl(entrance, appeal, page_source) {
    return `https://${window.location.host}/parasite/consultcard/index.html?entrance=${entrance}&expertin=302&needbailout=0&paydoctorfirst=0&mainsuit=${encodeURIComponent(appeal)}&page_source=${page_source}`;
}
