var { useEffect, useState, useCallback }=window.Snowball._React;
import { debounce, get } from '../../../core/util';
import { APP_ENV } from '../../../env';
import { getSearchTags, mallThinkingWordSearch } from '../../../core/api/skydive';

export default function useSearchTags() {
    const [tags, setTags] = useState({});

    useEffect(() => {
        getSearchTags({
            // 固定值
            uid: 'PAJK_30404_PcHomePage'
        })
            .then((res) => {
                if (res.success) {
                    const { hotWords, searchTips } = res.data;
                    setTags({ hotWords, searchTips });
                }
            });
    }, []);

    return tags;
}

export function useThinkingList(keyWord) {
    const [thinkingList, setThinkingList] = useState([]);

    function requestThinkingList(keyWord = '') {
        keyWord = keyWord.trim();

        if (keyWord === '') {
            setThinkingList([]);
            return;
        }

        mallThinkingWordSearch({
            serverVersion: 'V5',
            position: 14000,
            sourcePosotion: 30404,
            inputWord: keyWord,
            pageSize: 15,
            appChannel: APP_ENV
        }).then((res) => {
            let list = get(res, 'data.mallThinkingWordTip.mallThinkingWords') || [];

            list = list.map((item) => {
                return {
                    ...item,
                    keyWordWithHtmlTag: item.keyword.replace(keyWord, `<strong>${keyWord}</strong>`)
                };
            });

            setThinkingList(list || []);
        });
    }

    const debouncedRequestThinkingList = useCallback(
        debounce(requestThinkingList, 500),
        []
    );

    useEffect(() => {
        debouncedRequestThinkingList(keyWord);
    }, [keyWord, debouncedRequestThinkingList]);

    return thinkingList;
}