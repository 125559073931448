var React=window.Snowball._React;

function DrugPermit({
    permitNo
}) {
    if (permitNo == null) {
        return null;
    };

    return (
        <div className="it_drug_permit flex">
            <p>国药准字&nbsp;</p>
            {permitNo}
            <a
                className="it_drug_permit_link"
                href="http://www.nmpa.gov.cn/WS04/CL2043/"
            >查看批准文号</a>
        </div>
    );
}

export default DrugPermit;