/**
 * 解析规则文档
 * http://doc.pajk-ent.com/pages/viewpage.action?pageId=51020329
 */
var { util }=window.Snowball;
var { app }=window.Snowball._app;

type SkuParam = {
    // 购物车过来需要传递cartKey
    cartKey?: number,
    skuId: number,
    quantity: number,
    trace: string,
    spm: string
};
type BundleItem = {
    // 购物车过来需要传递cartKey
    cartKey?: number,
    // 套餐ID
    comboId: number,
    // 套餐数量
    count: number,
    // 套餐来源
    spm: string,
    // 购物车商品浏览跟踪路径
    trace: string,
    // 商品参数
    skus: [
        {
            // sku id
            skuId: number,
            // 商品数量
            amount: number
        }
    ]
};
type OrderParam = {
    sellerId: number,
    groupId?: number,
    skus: SkuParam[],
    bundleItems: BundleItem[]
};

/**
 * 转换订单参数
 * @param {*} orderParam
 */
export function convertOrderParamsToString(orderParam: OrderParam[]) {
    orderParam.map(seller => {
        const { skus = [] } = seller;
        seller.skus = skus.map(item => {
            item.amount = item.quantity;
            delete item.quantity;
            return item;
        });
        return seller;
    });
    return JSON.stringify(orderParam);
}

// 0:默认正常来源, 1：立即购买来源, 2: B2C购物车, 3: O2O购物车, 4: 需求清单购物车
// 从购物车进下单，下完单后会清空购物车 且不可+ -修改商品数量
export const CREATE_ORDER_FROM = {
    NORMAL: 0,
    IMMEDIATE: 1,
    B2C_CART: 2,
    O2O_CART: 3,
    NEED_LIST: 4
};

/**
 *
 * @param {OrderParam[]} orderParam
 * @param {string} [pageSource]
 * @param {number} [from]
 * @param {string} [jkCode]
 */
export default function getOrderCreatePageLink(
    orderParam: OrderParam[],
    from?: number = CREATE_ORDER_FROM.NORMAL,
    pageSource?: string = ''
) {
    const qs = util.hashQs(location.href);
    app.navigation.forward(
        `/order/create?json_params=${encodeURIComponent(
            convertOrderParamsToString(orderParam)
        )}&from=${from}&useFlowLimited=${qs.useFlowLimited || 0}`,
        pageSource
    );
}
