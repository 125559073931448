var { observable }=window.Snowball;
var { Service, autowired }=window.Snowball._app;

export default class OrderAppResourceService extends Service {

    @autowired
    _appResourceService;

    @observable
    promotions

    fetchOrderCreateAppResources = async () => {
        const res = await this._appResourceService.getAppResource({
            system: 'mall-trade',
            channel: 'all',
            type: 'text',
            source: 'order_create',
            // keyName: '',
        });
        if (res.success) {
            const appResources = res.data.value;
            const orderCreatePromotion = appResources.find(({ keyName }) => keyName == 'order_create_promotion');
            if (orderCreatePromotion) {
                this.appResourcesModel.promotions = JSON.parse(orderCreatePromotion.val || '[]');
            }
        }
    }
}