var React=window.Snowball._React;
import { forward } from '../../../core/navigation';

const MyCart = (props) => {
    const { count } = props;

    return (
        <div
            className="mall_common__header__cart"
            onClick={() => { forward('/cart'); }}
        >
            <span className="mall_common__header__cart_icon"></span>
            我的购物车
            {
                count
                    ? <span className="mall_common__header__cart_count">{count}</span>
                    : null
            }
        </div>
    );
};

export default MyCart;