const ua = navigator.userAgent;
const location = window.location;

const PLATFORM = /(MSIE) (\d+)/i.test(ua)
    ? 'ie'
    : /Chrome\/(\d+)/i.test(ua)
        ? 'chrome'
        : 'pc';

export const APP_ENV = /(?:\?|&)app=([^=&]+)/i.test(location.search)
    ? decodeURIComponent(RegExp.$1).toUpperCase()
    : "HFLPCZ";

export const OUT_BIZ_TYPE = /(?:\?|&)outBizType=([^=&]+)/i.test(location.search)
    ? decodeURIComponent(RegExp.$1)
    : APP_ENV;

export const IS_PC = true;

export const APP_ID = 9224152;

export const API_CHANNAL_PARAM = PLATFORM + '|' + APP_ENV;
export const API_CHANNAL = APP_ENV;
export const JKMALL_PROJECT_NAME = 'jkmall';
export const JKMALL_FRAME_PROJECT_NAME = 'jkmall-frame';

const autoconfig = {
    APP_API: process.env.REACT_APP_API,
    FRAME_URL: process.env.REACT_APP_FRAME_URL,
    MALL_URL: process.env.REACT_APP_MALL_URL,
    COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
    LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
    TFS: process.env.REACT_APP_TFS,
    BEACON: process.env.REACT_APP_BEACON,
    SPM: process.env.REACT_APP_SPM_URL,
};

// 该判断条件里面的代码 只是本地开发的mock，真实的这些地址是走autoConfig
if (process.env.NODE_ENV !== 'production') {
    if (location.host.indexOf('.dev.') > -1) {
        // 开发环境
        autoconfig.APP_API = '//api.dev.pajkdc.com/m.api';
        autoconfig.FRAME_URL = '//www.dev.pajkdc.com/jkmall-frame/';
        autoconfig.MALL_URL = '//www.dev.pajkdc.com/jkmall/';
        autoconfig.COOKIE_DOMAIN = '.dev.pajkdc.com';
        autoconfig.LOGIN_URL = '//www.dev.pajkdc.com/daisy/#/pclogin?appId=9224152';
        autoconfig.TFS = "//static.dev.pajkdc.com/";
    } else if (location.host.indexOf('.test.') > -1) {
        // 测试环境
        autoconfig.APP_API = '//api.test.pajkdc.com/m.api';
        autoconfig.FRAME_URL = '//www.test.pajkdc.com/jkmall-frame/';
        autoconfig.MALL_URL = '//www.test.pajkdc.com/jkmall/';
        autoconfig.COOKIE_DOMAIN = '.test.pajkdc.com';
        autoconfig.LOGIN_URL = '//www.test.pajkdc.com/daisy/#/pclogin?appId=9224152';
        autoconfig.TFS = "//static.test.pajkdc.com/";
    } else if (location.host.indexOf('.pre.jk.cn') > -1) {
        // 预发环境
        autoconfig.APP_API = "//api.pre.jk.cn/m.api";
        autoconfig.FRAME_URL = '//www.pre.jk.cn/jkmall-frame/';
        autoconfig.MALL_URL = '//www.pre.jk.cn/jkmall/';
        autoconfig.COOKIE_DOMAIN = '.pre.jk.cn';
        autoconfig.LOGIN_URL = '//www.pre.jk.cn/daisy/#/pclogin?appId=9224152';
        autoconfig.TFS = "//jkcdn.pajk.com.cn/";
    } else if (location.host.indexOf('.jk.cn') > -1) {
        // 生产环境
        autoconfig.APP_API = "//api.jk.cn/m.api";
        autoconfig.FRAME_URL = '//www.jk.cn/jkmall-frame/';
        autoconfig.MALL_URL = '//www.jk.cn/jkmall/';
        autoconfig.COOKIE_DOMAIN = '.jk.cn';
        autoconfig.LOGIN_URL = '//www.jk.cn/daisy/#/pclogin?appId=9224152';
        autoconfig.TFS = "//jkcdn.pajk.com.cn/";
    } else {
        // 本地环境
        autoconfig.APP_API = '//api.dev.pajkdc.com/m.api';
        autoconfig.FRAME_URL = '//www.dev.pajkdc.com/jkmall-frame/';
        autoconfig.MALL_URL = 'http://localhost:3002/';
        autoconfig.COOKIE_DOMAIN = '.dev.pajkdc.com';
        autoconfig.LOGIN_URL = '//www.dev.pajkdc.com/daisy/#/pclogin?appId=9224152';
        autoconfig.TFS = "//static.dev.pajkdc.com/";
    }
}

if (location.host.indexOf('.pajk.cn') !== -1) {
    Object.keys(autoconfig).forEach((key) => {
        autoconfig[key] = autoconfig[key].replace('pajkdc.com', 'pajk.cn');
    });
}

if (!autoconfig.COOKIE_DOMAIN) {
    autoconfig.COOKIE_DOMAIN = location.origin.replace(/^(https?:)?\/\/([^/.]+\.)/, '.');
}

export const {
    APP_API,
    FRAME_URL,
    MALL_URL,
    LOGIN_URL,
    COOKIE_DOMAIN,
    HTTP_SERVER,
    TFS
} = autoconfig;