var { controller, autowired }=window.Snowball._app;
import MyAfterSale from "../containers/MyAfterSale";
import { MyAfterSaleConfiguration } from "../configuration";

@controller({
    component: MyAfterSale,
    configuration: [MyAfterSaleConfiguration]
})
export default class MyAfterSaleController {
    @autowired
    _myAfterSaleViewModel;

    onInit() {
        this.ctx.logger.makeLog('H5web_pc_my_after_sale', 'onload');
        this._myAfterSaleViewModel.init();
    }
}