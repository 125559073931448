export function isString(str) {
    return typeof str === 'string' || toString.call(str) == "[object String]";
}

export function isUndefined(val) {
    return val === undefined;
}

export function isPlainObject(obj) {
    if (typeof obj !== 'object' || obj === null) return false;

    return Object.getPrototypeOf(obj) === Object.prototype;
}

export function isFunction(fn) {
    return typeof fn === 'function';
}

export function isEmptyObject(obj) {
    if (!obj) return false;

    for (var name in obj) {
        return false;
    }
    return true;
}

export function is(x, y) {
    if (x === y) {
        return x !== 0 || y !== 0 || 1 / x === 1 / y;
    } else {
        // Step 6.a: NaN == NaN
        // eslint-disable-next-line no-self-compare
        return x !== x && y !== y;
    }
}

/**
 * 判断两个 Object/Array 是否相等
 *
 * @param {any} a
 * @param {any} b
 * @param {boolean} [eqeqeq] 是否全等`===`
 */
export function equals(a, b, eqeqeq = false) {
    if (eqeqeq ? is(a, b) : a == b) return true;

    var typeA = toString.call(a);
    var typeB = toString.call(b);
    var i;

    if (typeA !== typeB) return false;

    switch (typeA) {
        case '[object Object]':
            var keysA = Object.keys(a);
            if (keysA.length != Object.keys(b).length) {
                return false;
            }

            var key;
            for (i = keysA.length - 1; i >= 0; i--) {
                key = keysA[i];
                if (
                    !hasOwnProperty.call(b, key) || !equals(a[key], b[key], eqeqeq)
                ) {
                    return false;
                }
            }
            break;
        case '[object Array]':
            if (a.length !== b.length) {
                return false;
            }
            for (i = a.length - 1; i >= 0; i--) {
                if (!equals(a[i], b[i], eqeqeq)) {
                    return false;
                }
            }
            break;
        case '[object Date]':
            return +a === +b;
        case '[object RegExp]':
            return ('' + a) === ('' + b);
        default:
            if (eqeqeq ? !is(a, b) : a != b) return false;
    }

    return true;
}

export function same(a, b) {
    return equals(a, b, true);
}