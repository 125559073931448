var { util }=window.Snowball;
var React=window.Snowball._React;var { useLayoutEffect }=window.Snowball._React;

export default function ReactModule(props) {
    const { template = {}, data } = props;
    const { tempCss } = template;
    useLayoutEffect(() => {
        if (tempCss) {
            util.style(template.id + "_" + template.version, tempCss);
        }
    }, [tempCss, template.id, template.version]);

    if (!data || !template.id) {
        return null;
    }

    return (
        <div
            app-anchor-name={"@" + data.logicId}
            className={template.extProps.className || ''}
        >{props.children}</div>
    );
}