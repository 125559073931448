var React=window.Snowball._React;
import '../styles/personinfo.scss';
import Radio from '../../../shared/components/Radio';
import PhysicalityItem from '../components/PhysicalityItem';
import ErrorMessage from '../components/ErrorMessage';
import { NICK_NAME, GENDER, HEIGHT, WEIGHT, AGE, MALE, FEMALE } from '../constants/index';
var { app }=window.Snowball._app;

function PersonInfomation({
    save,
    onFieldsChange,
    onGenderClicked,
    error,
    personInfo,
    isSaving
}) {
    const {
        nickname = null,
        weight = null,
        height = null,
        age = null,
        gender = null,
        id = '',
        avatar = null
    } = personInfo || {};

    let avatarUrl;
    if (avatar) {
        avatarUrl = app.env.TFS + avatar;
    }
    return (
        <form className="person_info">
            <table>
                <tr>
                    <td>
                        <img src={avatarUrl} alt=""></img>
                    </td>
                    <td><span className="hint left_margin">当前头像</span></td>
                </tr>
                <tr>
                    <td className="hint"><span className="star name_prefix_width">*</span>昵称：</td>
                    <td>
                        <input
                            className="name left_margin"
                            type="text"
                            value={nickname}
                            placeholder="请输入昵称"
                            onChange={(event) => {
                                onFieldsChange({ [NICK_NAME]: event.target.value });
                            }}
                        />
                        <ErrorMessage className={`nickname_left_margin ${error[NICK_NAME] ? '' : 'hide_error'}`} display={error[NICK_NAME] ? true : 'none'} msg={error[NICK_NAME]}/>
                    </td>
                </tr>
                <tr>
                    <td className="hint">
                        <span className="name_prefix_width"></span><span>健康号：</span>
                    </td>
                    <td><span className="value left_margin">{ id }</span></td>
                </tr>
                <tr>
                    <td className="hint">
                        <span className="name_prefix_width"></span>性别：
                    </td>
                    <td className="value">
                        <Radio
                            className="left_margin"
                            selected={gender === MALE}
                            onClick={() => {
                                onGenderClicked('MALE');
                            }}
                        />男
                        <Radio
                            className="female_left_margin"
                            selected={gender === FEMALE}
                            onClick={() => {
                                onGenderClicked('FEMALE');
                            }}
                        />女
                        <ErrorMessage className={`nickname_left_margin ${error[GENDER] ? '' : 'hide_error'}`} msg="请选择性别"/>
                    </td>
                </tr>
                <tr>
                    <td className="hint">
                        <span className="name_prefix_width"></span>体征：
                    </td>
                    <td>
                        <PhysicalityItem
                            className="left_margin"
                            name="身高"
                            value={height}
                            unit="cm"
                            onChange={(event) => {
                                onFieldsChange({ height: event.target.value });
                            }}
                            errorMsg={error[HEIGHT] ? error[HEIGHT] : null}
                        />
                        <PhysicalityItem
                            name="体重"
                            value={weight}
                            unit="kg"
                            onChange={(event) => {
                                onFieldsChange({ weight: event.target.value });
                            }}
                        />
                        <PhysicalityItem
                            name="年龄"
                            value={age}
                            unit="岁"
                            onChange={(event) => {
                                onFieldsChange({ age: event.target.value });
                            }}
                        />
                    </td>
                </tr>
                {
                    (error[AGE] || error[WEIGHT] || error[HEIGHT]) &&
                    <tr>
                        <td></td>
                        <td className="relative_position">
                            <ErrorMessage className={`error_block height_error ${error[HEIGHT] ? '' : 'hide_error'}`} msg={error[HEIGHT]}/>
                            <ErrorMessage className={`error_block weight_error ${error[WEIGHT] ? '' : 'hide_error'}`} msg={error[WEIGHT]}/>
                            <ErrorMessage className={`error_block age_error ${error[AGE] ? '' : 'hide_error'}`} msg={error[AGE]}/>
                        </td>
                    </tr>
                }
                <tr>
                    <td></td>
                    <td>
                        <input
                            className={`save_btn left_margin ${isSaving ? 'unable_btn' : ''}`}
                            type="button"
                            value="保存"
                            onClick={()=>save()}
                        />
                    </td>
                </tr>
            </table>
        </form>
    );
}

export default PersonInfomation;