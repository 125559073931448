import '../../sass/pagination.scss';
var React=window.Snowball._React;var { useState, memo, useEffect, useRef, useCallback }=window.Snowball._React;

const BUFFER_SIZE = 2;
const STEP_LENGTH = 5;

const PaginationWrap = ({ children, className }) => {
    const classNames = ['md_pagination', 'clearfix'];
    if (className) {
        classNames.push(className);
    }
    return <div className={classNames.join(' ')}>{children}</div>;
};

const Total = ({ showTotal, total, pagiLength }) => {
    if (showTotal) {
        return <div className="show_total">{showTotal(total, pagiLength)}</div>;
    }
    return null;
};

const Child = ({ value = '', className = '', onChange = (noop) => noop, ...otherProps }) => {
    return (
        <li className={className} onClick={onChange} {...otherProps}>
            {value}
        </li>
    );
};

const Pagination = ({
    showTotal,
    total = 0,
    pageSize = 5,
    current = 1,
    onChange = (noop) => noop,
    nextText = '下一页',
    previousText = '上一页',
    className,
}) => {
    pageSize = parseInt(pageSize);

    const [currPage, setCurrPage] = useState(Number(current));
    const changeRef = useRef(null);

    const list = [];
    const allPages = Number(Math.ceil(total / pageSize));
    const prevPageClassNames = ['previos'];
    const nextPageClassNames = ['next'];

    const changeCurrPage = useCallback(
        (page) => {
            changeRef.current = page;
            setCurrPage(page);
        },
        [setCurrPage]
    );

    useEffect(() => {
        changeCurrPage(current);
    }, [current, changeCurrPage]);

    const onPrevPage = () => {
        changeCurrPage(Math.max(1, currPage - 1));
    };

    const onNextPage = () => {
        changeCurrPage(Math.min(allPages, currPage + 1));
    };

    const firstPage = (
        <Child
            value={1}
            onChange={() => changeCurrPage(1)}
            className={1 === currPage ? 'active' : ''}
        />
    );

    const lastPage = (
        <Child
            value={allPages}
            onChange={() => changeCurrPage(allPages)}
            className={allPages === currPage ? 'active' : ''}
        />
    );

    let left = Math.max(1, currPage - BUFFER_SIZE);
    let right = Math.min(currPage + BUFFER_SIZE, allPages);

    if (currPage - 1 <= BUFFER_SIZE) {
        right = Math.min(1 + BUFFER_SIZE * 2, allPages);
    }

    if (allPages - currPage <= BUFFER_SIZE) {
        left = Math.max(1, allPages - BUFFER_SIZE * 2);
    }

    for (let i = left; i <= right; i += 1) {
        const isCurrentPage = i === currPage;
        list.push(
            <Child
                value={i}
                onChange={() => changeCurrPage(i)}
                className={isCurrentPage ? 'active' : ''}
            />
        );
    }

    if (currPage - 1 >= BUFFER_SIZE * 2 && currPage !== 1 + 2) {
        list.splice(
            0,
            0,
            <Child
                value={'...'}
                title={`向前${STEP_LENGTH}页`}
                onChange={() => changeCurrPage(Math.max(1, currPage - STEP_LENGTH))}
            />
        );
    }

    if (allPages - currPage >= BUFFER_SIZE * 2 && currPage !== allPages - 2) {
        list.splice(
            list.length,
            0,
            <Child
                value={'...'}
                title={`向后${STEP_LENGTH}页`}
                onChange={() => changeCurrPage(Math.min(allPages, currPage + STEP_LENGTH))}
            />
        );
    }

    if (left !== 1) {
        list.unshift(firstPage);
    }

    if (right !== allPages) {
        list.push(lastPage);
    }

    // next and prev page class names
    currPage <= 1 && prevPageClassNames.push('disable');
    currPage >= allPages && nextPageClassNames.push('disable');

    useEffect(() => {
        changeRef.current && onChange(currPage, allPages);
        // eslint-disable-next-line
    }, [changeRef.current]);

    return (
        <PaginationWrap className={className}>
            <Total showTotal={showTotal} total={total} pagiLength={allPages} />
            <ul className="container">
                <li className={prevPageClassNames.join(' ')} onClick={onPrevPage}>
                    {previousText}
                </li>
                {list}
                <li className={nextPageClassNames.join(' ')} onClick={onNextPage}>
                    {nextText}
                </li>
            </ul>
        </PaginationWrap>
    );
};

export default memo(Pagination);
