var React=window.Snowball._React;

export const ProgressType = {
    trade: ['选择商品','填写订单信息','订单提交成功','支付成功'],
    refund: ['买家申请退款', '支付成功', '商家发货']
};

const ProgressItem = ({ title, selected, number }) => {
    return (
        <div className={`progress_item ${selected ? 'selected' : ''}`}>
            <div className='number'>
                { number }
            </div>
            <p>{title}</p>
        </div>
    );
};


export default function ProgressList({ selectedIndex, className, list = ProgressType.trade }) {
    return (
        <div className={`cm_progress__list ${className}`}>
            {
                list.map((val, idx) => {
                    return (
                        <ProgressItem number={idx + 1} title={val} selected={idx == selectedIndex} />
                    );
                })
            }
        </div>
    );
}