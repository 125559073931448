var React=window.Snowball._React;
import { forward } from '../../../core/navigation';

const CategoryDetail = (props) => {
    const {
        isShow,
        secondCategoryList
    } = props;

    const handleCategoryThirdItemClick = (item) => {
        if (item.actType === 1) {
            forward(item.url);
        }
        else {
            forward('/search', { categoryId: item.id });
        }
    };

    const renderThirdCategory = (list) => {
        return (
            <ul className="mall_common__category_third_list">
                {
                    list.map((item) => {
                        return (
                            <li
                                onClick={() => { handleCategoryThirdItemClick(item); }}
                            >{item.name}</li>
                        );
                    })
                }
            </ul>
        );
    };

    return (
        <div
            className="mall_common__category_second"
            style={{ display: isShow ? 'block' : 'none' }}
        >
            <ul className="mall_common__category_second_list">
                {
                    secondCategoryList.map((item) => {
                        return (
                            <li>
                                <div
                                    className="mall_common__category_second_list_item_title"
                                >
                                    {item.name}
                                </div>
                                { renderThirdCategory(item.children) }
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

export default CategoryDetail;