import { TFS } from '../env';

// 根据网络环境 决定图片质量
const IMAGE_DEFAULT_QUALITY = 80;
// 是否系统版本大于4.4的安卓机
const isAdvancedAndroid = false;

// 根据屏幕像素 缩放图片比例
const scaleRatio = 1;

export function transformTFSImageUrl(src, width, height, quality = IMAGE_DEFAULT_QUALITY) {
    if (!src) {
        console.error('src is undefined!!');
        return null;
    }
    if (/^(https?:)?\/\/|^data:/.test(src)) return src;

    if (/(.+?)\.(jpeg|jpg|png|gif)$/i.test(src)) {
        src = RegExp.$1;
    }

    if (arguments.length == 2) {
        quality = width;
        width = 0;
    }

    src += '?img=';

    if (width && height) {
        src += `/rs,w_${Math.round(width * scaleRatio)},h_${Math.round(height * scaleRatio)}`;
    }

    if (
        (quality || isAdvancedAndroid)
        && quality != 100
    ) {
        src += `/tf${quality ? ',q_' + quality : ''}${isAdvancedAndroid ? ',d_webp' : ''}`;
    }
    return `${TFS.startsWith('//') ? 'https:' : ''}${TFS}image/${src}`;
}

export function transformTFSFileUrl(src) {
    if (!src) {
        console.error('src is undefined!!');
        return null;
    }
    if (/^(https?:)?\/\/|^data:/.test(src)) return src;
    return `${TFS.startsWith('//') ? 'https:' : ''}${TFS}${src}`;
}