var { Service }=window.Snowball._app;

export default class PiggyApiService extends Service {
    get request() {
        return this.app.gateway.request;
    }

    /**
     * 获取 B2C 购物车内数据
     */
    getB2CCart(params: {
        prvCode: string,
        ctyCode: string,
        type: number,
        outBizType: string
    }) {
        return this.request('piggy.getB2CCart', true, params, { isShowLoading: true });
    }

    /**
     * 获取 O2O 购物车内数据
     */
    getO2OCart(params: {
        longitude: string,
        latitude: string,
        city: number,
        outBizType: string
    }) {
        return this.request('piggy.getO2OCart', true, params, { isShowLoading: true });
    }

    /**
     * 删除 购物车 数据
     */
    delCartItem(params: {
        cartSkus: { storeId: number, skuId: number }[],
        type: number
    }) {
        return this.request('piggy.delCartItemV2', true, params);
    }

    /**
     * 删除 购物车 数据 V3
     */
    delCartItemV3(params: {
        ids: number[],
        type: number
    }) {
        return this.request('piggy.delCartItemV3', true, params);
    }

    /**
     * 修改 购物车 商品数量
     */
    modifyCartItem(params: {
        skuId: number,
        num: number,
        storeId: number,
        type: number
    }) {
        return this.request('piggy.modifyCartItemV2', true, params);
    }

    /**
     * 修改 购物车 商品数量 V3
     */
    modifyCartItemV3(params: {
        cartRecordDTO: { storeId: number, id: number, num: number },
        type: number
    }) {
        return this.request('piggy.modifyCartItemV3', true, params);
    }

    /**
     * 购物车 算价格接口
     */
    cartCalc(params: {
        skuIds: number[],
        // 1:商城,2:闪电购药,3:需求清单
        type: number,
        // 0.列表|1.包邮凑单|2.优惠券,默认为0
        reqType: number,
        prvCode: string,
        ctyCode: string
    }) {
        return this.request('piggy.cartCalc', true, params);
    }

    /**
     *  购物车 去结算接口
     */
    toBalance(params: {
        skuIds: number[],
        // 1:商城,2:闪电购药,3:需求清单
        type: number,
        prvCode: string,
        ctyCode: string
    }) {
        return this.request('piggy.toBalance', true, params);
    }

    /**
     *  购物车 去结算接口
     */
    toBalanceV2(params: {
        ids: number[],
        // 1:商城,2:闪电购药,3:需求清单
        type: number,
        prvCode: string,
        ctyCode: string
    }) {
        return this.request('piggy.toBalanceV2', true, params);
    }
    /**
     * 购物车 切换规格
     */
    changeSpec(params: {
        origSkuId: number,
        skuId: number,
        num: number,
        storeId: number,
        type: number
    }) {
        return this.request('piggy.changeSpecV2', true, params);
    }

    /**
     * 购物车 切换规格V3
     */
    changeSpecV3(params: {
        origCartRecordDTO: any,
        cartRecordDTO: any,
        type: number
    }) {
        return this.request('piggy.changeSpecV3', true, params);
    }

    /**
     * 包邮凑单 算价格接口
     */
    postageCalc(params: {
        storeId: number,
        type: number,
        prvCode: string,
        ctyCode: string
    }) {
        return this.request('piggy.postageCalcV2', true, params);
    }

    /**
     * 获取咨询清单商品数量
     */
    countCart(
        params: {
            storeId: number,
            type: number,
            outBizType: string,
        },
        autoLogin: boolean,
    ) {
        return this.request(
            'piggy.countCart',
            true,
            params,
            { autoLogin: !!autoLogin }
        );
    }

    /**
     * 获取咨询清单
     */
    getCounseCart(params: {
        storeId: number,
        type: number,
        prvCode: number,
        ctyCode: number,
        outBizType: string,
    }) {
        return this.request(
            'piggy.getCounseCart',
            true,
            params,
            { isShowLoading: true }
        );
    }

    /**
     * 清空咨询清单
     */
    clearCartItems(params: {
        storeId: number,
        type: number,
    }) {
        return this.request('piggy.clearCartItems', true, params);
    }

    /**
     * 商品选中/取消选中
     */
    selectCartItem(params: {
        cartSkus: { storeId: number, skuId: number }[],
        operateType: number,
        type: number,
    }) {
        return this.request('piggy.selectCartItemV2', true, params);
    }

    /**
     * 商品选中/取消选中 V3
     */
    selectCartItemV3(params: {
        ids: number[],
        operateType: number,
        type: number,
    }) {
        return this.request('piggy.selectCartItemV3', true, params);
    }
    /**
     * 加车
     */

    addToCartV2({
        skuId,
        storeId,
        num,
        source = '',
        type,
    }) {
        return this.request('piggy.addToCartV2', true, {
            skuId,
            storeId,
            num,
            source,
            type,
            outBizType: this.app.env.OUT_BIZ_TYPE
        });
    }

    // 套餐加车
    addToCartV3({
        type,
        cartRecordDTO,
        dotInfoDTO,
    }) {
        return this.request('piggy.addToCartV3', true, {
            type,
            outBizType: this.app.env.OUT_BIZ_TYPE,
            cartRecordDTO,
            dotInfoDTO,
        });
    }

    getBizCartNum({ autoLogin }) {
        return this.request(
            'piggy.getBizCartNum',
            true,
            { outBizType: this.app.env.OUT_BIZ_TYPE },
            { autoLogin }
        );
    }

}
