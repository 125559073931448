var React=window.Snowball._React;var { useMemo }=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
import DistrictDropDown from "../../../shared/components/DistrictDropDown";
import Form from "../../../shared/components/Form";
import Checkbox from "../../../shared/components/Checkbox";

const FormItem = Form.Item;

export default inject(mapViewModelToProps('addressFormViewModel'))(function AddressForm({
    className,
    formData = {},
    errors,
    isSuggestAddressesVisible,
    suggestAddresses,
    onSelectStreetAddress,
    onFieldsChange,
    onSubmit
}) {
    const togglePreferred = () => {
        onFieldsChange({ preferred: !formData.preferred });
    };
    const district = useMemo(() => [formData.provinceCode, formData.cityCode, formData.districtCode], [formData.cityCode, formData.districtCode, formData.provinceCode]);

    return (
        <Form className={className} onSubmit={onSubmit}>
            <FormItem
                label="收货人"
                required
                error={errors.recipientName}
            >
                <input
                    name="recipientName"
                    type="text"
                    className="app-form-input"
                    placeholder="请输入收货人姓名"
                    value={formData.recipientName}
                    onChange={(event) => {
                        onFieldsChange({ recipientName: event.target.value });
                    }}
                />
            </FormItem>
            <FormItem
                label="手机号码"
                required
                error={errors.recipientPhone}
            >
                <input
                    name="recipientPhone"
                    type="text"
                    className="app-form-input"
                    placeholder="请输入手机号"
                    value={formData.recipientPhone}
                    onChange={(event) => {
                        onFieldsChange({ recipientPhone: event.target.value });
                    }}
                />
            </FormItem>
            <FormItem
                label="所在地区"
                required
                error={errors.district}
            >
                <DistrictDropDown
                    value={district}
                />
            </FormItem>
            <FormItem
                label="详细地址"
                required
                error={errors.streetAddress}
            >
                <input
                    className="app-form-input wide"
                    name="streetAddress"
                    type="text"
                    placeholder="如XX路XX号、XX小区、XX办公楼等"
                    value={formData.streetAddress}
                    autocomplete="off"
                    onChange={(event) => {
                        onFieldsChange({ streetAddress: event.target.value });
                    }}
                />
                {
                    isSuggestAddressesVisible && !!suggestAddresses.length && (
                        <ul className="address-suggest-addresses">
                            {suggestAddresses.map(item => (
                                <li
                                    className="item bd_b"
                                    onClick={() => {
                                        onSelectStreetAddress(item);
                                    }}>
                                    <p className="name to_e">{highlight(item.name, formData.streetAddress)}</p>
                                    <p className="detail to_e">{item.city + item.district}</p>
                                </li>
                            ))}
                        </ul>
                    )
                }
            </FormItem>
            <FormItem
                label="楼层门牌"
                required
                error={errors.referAddr}
            >
                <input
                    className="app-form-input wide"
                    name="referAddr"
                    type="text"
                    placeholder="单元号/楼层/门牌号等"
                    value={formData.referAddr}
                    onChange={(event) => {
                        onFieldsChange({ referAddr: event.target.value });
                    }}
                />
            </FormItem>
            <FormItem
                label="标签"
                className="address-form-tags"
            >
                <button
                    type="button"
                    className={formData.addressLabel == '家' ? 'tag checked' : 'tag'}
                    onClick={() => { onFieldsChange({ addressLabel: '家' }); }}
                >
                    家
                </button>
                <button
                    type="button"
                    className={formData.addressLabel == '公司' ? 'tag checked' : 'tag'}
                    onClick={() => { onFieldsChange({ addressLabel: '公司' }); }}
                >
                    公司
                </button>
                <button
                    type="button"
                    className={formData.addressLabel == '学校' ? 'tag checked' : 'tag'}
                    onClick={() => { onFieldsChange({ addressLabel: '学校' }); }}
                >
                    学校
                </button>
            </FormItem>
            <FormItem className="address-set-default">
                <Checkbox
                    checked={formData.preferred}
                    onChange={togglePreferred}
                />
                <label onClick={togglePreferred}>设置为默认收货地址</label>
            </FormItem>
        </Form>
    );
});

function highlight(str, key) {
    const arr = str.split(key || '').map(part => <span>{part}</span>);
    return arr.reduce((result, child, index) => {
        if (index < arr.length - 1) {
            return result.concat([child, <span className="red">{key}</span>]);
        }
        return result.concat(child);
    }, []);
}