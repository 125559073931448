var { controller, autowired, param }=window.Snowball._app;
import Logistics from '../containers/Logistics';
import { LogisticsConfiguration } from '../configuration';

@controller({
    component: Logistics,
    configuration: [LogisticsConfiguration],
})
export default class LogisticsController {
    @param('orderId')
    orderId: number;

    @autowired
    _logisticsViewModel;

    onInit() {
        this.ctx.logger.makeLog(`H5web_pc_logistics`, 'onload', {
            orderId: this.orderId,
        });
        this._logisticsViewModel.init(this.orderId);
    }
}
