import { isPlainObject } from './is';

export function params(args) {
    return Object.keys(args)
        .reduce(function (result, key) {
            var val = args[key];
            if (val !== undefined && val !== null) {
                result.push(key + "=" + encodeURIComponent(Array.isArray(val) || isPlainObject(val)
                    ? JSON.stringify(val)
                    : val));
            }
            return result;
        }, [])
        .join('&');
}

export function hashQs(url, append) {
    var indexOfHash = url.indexOf('#');
    var hash;
    if (indexOfHash === -1) {
        hash = "";
    } else {
        hash = url.slice(indexOfHash + 1);
        url = url.slice(0, indexOfHash);
    }
    return append ? url + '#' + qs(hash, append) : qs(hash);
}

export function qs(url, append) {
    !url && (url = '');

    var indexOfHash = url.indexOf('#');
    var hash;
    if (indexOfHash === -1) {
        hash = '';
    } else {
        hash = url.slice(indexOfHash);
        url = url.slice(0, indexOfHash);
    }

    var indexOfQuery = url.lastIndexOf('?');
    var path = indexOfQuery === -1 ? url : url.slice(0, indexOfQuery);
    var search = indexOfQuery === -1 ? '' : url.slice(indexOfQuery);
    if (indexOfQuery === -1) {
        path = url;
        search = '';
    } else {
        path = url.slice(0, indexOfQuery);
        search = url.slice(indexOfQuery);
    }

    const query = {};
    search && search.replace(/(?:\?|^|&)([^=&]+)=([^&#=?]*)/g, function (match, name, val) {
        query[decodeURIComponent(name)] = decodeURIComponent(val);
        return '';
    });

    if (!append) {
        return query;
    }

    return appendQueryString(path, Object.assign(query, append)) + hash;
}

export function appendQueryString(url, args) {
    return url + (url.indexOf('?') === -1 ? '?' : '&') + params(args);
}

export function queryString(search, name) {
    if (name === undefined) {
        name = search;
        search = window.location.search;
    }
    var reg = new RegExp("(^|&)" + name + "=([^&#=?]*)(&|$)");
    var r = search.substr(1).match(reg);

    return r ? decodeURIComponent(r[2]) : null;
}
