var { util }=window.Snowball;
var { app }=window.Snowball._app;

const { session } = util;

const { navigation } = app;

/**
 * 记录当前history位置并跳转至外部，以供返回时够过正常跳转
 * @param {string} url 跳转地址
 */
export function markAndRedirect(url) {
    setTimeout(function () {
        location.href = 'redirect.html?go=-2&recordhistory=1&time=' + Date.now() + '&url=' + encodeURIComponent(url);
    }, 0);
}

/**
 * history.go() 跳转到指定历史位置后，再跳转到指定地址
 * @param {*} goNumber history.go()的数量
 * @param {*} url 跳转地址
 */
function go(goNumber, url) {
    if (goNumber == 0) {
        url && navigation.replace(url);
    } else {
        if (!/^(https?:)?\/\//.test(url)) {
            url = `${location.protocol}//${location.host}${location.pathname}${location.search}#${url}`;
        }
        url && session('snowball_navigation_url', { expire: Date.now() + 30000, url });
        setTimeout(() => {
            history.go(goNumber);
        }, 0);
    }
    return this;
}

/**
 * 回到最后一次调用markAndRedirect的页面，再跳转到指定页面
 * 保证history不会错乱
 */
export function redirectFromMark(url) {
    setTimeout(() => {
        const recordHistoryLength = session('snowball_history_length');
        if (recordHistoryLength) {
            session('snowball_history_length', null);
            go(recordHistoryLength - history.length, url);
        } else {
            navigation.forward(url);
        }
    }, 0);
}