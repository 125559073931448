var { configuration }=window.Snowball._app;

import AfterSaleViewModel from './view-models/AfterSaleViewModel';
import AfterSaleListViewModel from './view-models/AfterSaleListViewModel';
import MyAfterSaleViewModel from './view-models/MyAfterSaleViewModel';
import RefundExpressFormViewModel from './view-models/RefundExpressFormViewModel';

export const AfterSaleConfiguration = configuration({
    modules: {
        afterSaleViewModel: AfterSaleViewModel,
        refundExpressFormViewModel: RefundExpressFormViewModel
    }
});

export const AfterSaleListConfiguration = configuration({
    modules: {
        afterSaleListViewModel: AfterSaleListViewModel,
        myAfterSaleViewModel: MyAfterSaleViewModel
    }
});

export const MyAfterSaleConfiguration = configuration({
    modules: {
        myAfterSaleViewModel: MyAfterSaleViewModel
    }
});