var { observable }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
import DsrCenterApiService from "../../../shared/apis/DsrCenterApiService";

export default class CommentViewModel extends ViewModel {
    @observable
    counts = {};

    @observable
    currentFilter = 0;

    @observable
    data = [];

    @observable
    total = 0;

    @observable
    pageNo = 1;

    @observable
    pageSize = 10;

    @autowired
    _dsrCenterApiService: DsrCenterApiService;

    constructor() {
        super();

        this._baseParamsHaveBeenSet = new Promise((resolve) => {
            this._resolveBaseParams = resolve;
        });
    }

    setBaseParams(spuId, sellerId) {
        this.spuId = spuId;
        this.sellerId = sellerId;
        this._resolveBaseParams();
        return this;
    }

    async init() {
        await this._baseParamsHaveBeenSet;

        this.loadComments();
        this._dsrCenterApiService.queryCommentCountToApp()
            .then((res) => {
                this.counts = res.data;
            });
    }

    async loadComments() {
        const result = await this._dsrCenterApiService.queryCommentsToApp({
            spuId: this.spuId,
            sellerId: this.sellerId,
            tabType: this.currentFilter,
            pageNo: this.pageNo,
            pageSize: this.pageSize
        });
        this.data = result.data ? result.data.itemComments || [] : [];
        this.total = result.count;
        return result;
    }

    @emitter
    onFilter(filter) {
        this.currentFilter = filter;
        this.pageNo = 1;
        this.loadComments();
    }

    @emitter
    onPageChange(pageNo) {
        this.pageNo = pageNo;
        this.loadComments();
    }
}