var { observable, util, asObservable }=window.Snowball;
var {
    toast,
    popup,
}=window.Snowball._widget;
var { ViewModel, autowired, emitter }=window.Snowball._app;
import * as env from '../../../env';
import {
    // getNormalSkus,
    // getNormalBundleItems,
    // getSubSellerSkus,
    // getSkus,
    // getSubSellerBBS,
    getFund,
    getBiz,
    getSeller,
    initSellers,
    getCurrentSeller,
} from '../utils/orderCreateUtils';
import popupServiceDescription from '../actions/popupServiceDescription';
import { MAX_BBS_LENGTH } from "../constants";
import { markAndRedirect } from '../../../shared/utils/externalNavigation';
import errorHandler from '../utils/errorHandler';
import hasEmoji from '../../../shared/utils/hasEmoji';

const { debounce } = util;

export default class OrderCreateViewModel extends ViewModel {
    @autowired
    _userService

    @autowired
    _userCidViewModel

    @autowired
    _appResourceService

    @autowired
    _masterApiService

    @autowired
    _globalAddressService

    @autowired
    _orderAppResourceService

    @autowired
    _orderPrescriptionViewModel

    @autowired
    _addressViewModel

    @autowired
    _addressEditorModalViewModel

    @observable
    error;

    // 资金域
    @observable
    fundModel = {};

    // 商户
    @observable.collection
    sellerCollection = [];

    // 扩展域
    @observable
    orderModel = {};

    @observable
    invoiceModel = {};

    @observable
    addressModel = {};

    @observable
    hasInitialized = false

    @observable
    lml = [];

    get userCidModel() {
        return this._userCidViewModel.userCid;
    }

    get promotions() {
        return this._orderAppResourceService.promotions;
    }

    get addressList() {
        return this._addressViewModel.addressList;
    }

    @emitter
    onAddressSelect(address) {
        this.addressModel = address;
        this.renewOrderInfo();
    }

    @emitter
    onProductsCountChange(id, skuId, count) {
        this.modifyProductsCount(id, skuId, count);
    }

    @emitter
    onStoreBBSChange({ id, bbs }) {
        const { sellerCollection } = this;
        const currentSeller = getCurrentSeller(sellerCollection, id);

        if (currentSeller) {
            asObservable(currentSeller)
                .set({ bbs: bbs.substr(0, MAX_BBS_LENGTH) });
        }
    }

    @emitter
    onPaymentTypeChange(type) {
        asObservable(this.fundModel)
            .set({ paymentType: type });
        this.renewOrderInfo();
    }

    @emitter
    onCouponChange(code) {
        asObservable(this.fundModel)
            .set({ couponCode: code });
        this.renewOrderInfo();
    }

    @emitter
    onRedPacketChange(code) {
        asObservable(this.fundModel)
            .set({ redCouponCode: code });
        this.renewOrderInfo();
    }

    getLogParams() {
        const sellerId: number = this.sellerCollection[0].sellerId;
        const storeId: number = this.sellerCollection[0].storeId;
        const skuIds: string = this.urlParams.map(store => store.skus.map(sku => sku.skuId).join(',')).join(',');
        const spuIds: number = this.sellerCollection
            .map(seller => seller.items.map(item => item.spuId).join(','))
            .join(',');
        const tuanGroupId: number = util.get(this.urlParams, [0, 'groupId']);
        const tagInstId: number = this.orderModel.tagInstId;
        const groupType: number = this.orderModel.groupType || 0;
        const hcodeInfo: string = this.orderModel.hcodeInfo || {};
        const { code = '' }: { code: string } = hcodeInfo;

        return {
            skuIds,
            spuIds,
            // tuanSkuId: 0,
            tuanGroupId,
            sellerId,
            storeId,
            tagInstId,
            promotionCode: code,
            groupType,
        };
    }

    constructor() {
        super();

        this.ctx.autorun(() => {
            this.user = this._userService.user;
        });
        this._addressEditorModalViewModel.onDidSave = this.onAddressSave;
    }

    async load({
        urlParams,
        from,
        jkCode,
        tcode,
        useFlowLimited
    }) {
        if (await this._userService.ensureLogin()) {
            this.urlParams = urlParams;
            this.from = from;
            this.jkCode = jkCode;
            this.tcode = tcode;
            this.useFlowLimited = useFlowLimited;

            this.initPage(urlParams);

            this.initAndFetch();
            this._addressViewModel.loadAllAddresses();
        }
    }

    initPage(urlParams) {
        this.sellerCollection = urlParams.map(store => {
            const { skus = [], bundleItems = [] } = store;
            return {
                groupId: store.groupId,
                sellerId: store.sellerId,
                items: skus.map(sku => ({
                    skuId: sku.skuId,
                    piece: sku.amount,
                    trace: sku.trace,
                    cartKey: sku.cartKey || '',
                    spm: sku.spm
                })),
                bundleItems: bundleItems.map((item) => ({
                    comboId: item.comboId,
                    count: item.count,
                    trace: item.trace,
                    cartKey: item.cartKey || '',
                    spm: item.spm,
                    items: item.skus.map((sku) => ({
                        skuId: sku.skuId,
                        piece: sku.amount
                    }))
                }))
            };
        });
        this.addressModel = {
            addressVisible: true
        };
        this.fundModel = {
            healthGold: { healthGoldSwitch: !!env.IS_WX_MINIPROGRAM },
            healthLife: { healthLifeSwitch: true },
            healthStar: { healthStarSwitch: true },
        };
        this.orderModel = {
            rechargeTel: '',
            userId: ''
        };
    }

    async initAndFetch() {
        let lastUserInfo = localStorage.getItem('ORDER_RECHARGE_TEL') || '';
        if (lastUserInfo) {
            await this._userService.fetchUser();
            let curId = this.userService.getUserId();
            let lastUserInfoArr = lastUserInfo.split('_');
            let lastId = lastUserInfoArr[0] || '';
            let lastTel = lastUserInfoArr[1] || '';
            if (curId == lastId) {
                this.order.rechargeTel = lastTel;
            }
        }

        await this.fetchGlobalAddress();

        // 获取order_create的所有文案中心配置
        await this._orderAppResourceService.fetchOrderCreateAppResources();
        const res = await this.fetchOrderPage();
        this.hasInitialized = true;
        if (res.success) {
            if (res.data.orderExt.isDiabetes) {
                this.popupServiceDescription();
            }
        }
    }

    async fetchOrderPage() {
        var res = await this.fetchOrderPageData({
            isFirst: !this.hasInitialized,
        });
        this.validateOrderInfo(res);
        if (this.orderModel.rechargeMobile) {
            this.validateTelForRecharge(this.orderModel.rechargeTel);
        }
        return res;
    }

    validateOrderInfo(res) {
        if (res.success) {
            const { warn = '' } = res.data.orderExt;
            if (warn) {
                popup.confirm({
                    content: warn,
                    className: 'ta_c',
                    cancelText: '返回',
                    onCancel: () => {
                        history.back();
                    },
                    onOk: () => {
                        popup.hidePopup();
                    },
                });
            }
        } else {
            if (this.hasInitialized) {
                popup.alert({
                    content: res.message || '未知错误',
                    className: 'ta_c',
                });
            } else {
                this.ctx.navigation.replace(`/error/notfound?error=${res.message || '未知错误'}`);
            }
        }
    }

    async fetchGlobalAddress() {
        let res = await this._globalAddressService.fetch();
        this.addressModel = { ...res };
        return res;
    }

    async fetchOrderPageData({ isFirst = false, withCoupon = true } = {}) {
        const params = this.assembleParams({
            excludeInvalidItems: false,
            withCoupon,
            isFirst
        });

        let res = await this._masterApiService.orderCreatePage(params);
        // let res = require('../mocks/master.createOrderPage')(params);

        if (res.success) {
            let { lml, user, fund, lg, sellers = [], orderExt } = res.data;
            if (!fund.invoice) {
                fund.invoice = {};
            }
            this.addressModel = lg.defaultAddress
                ? lg.defaultAddress
                : {
                    ...this.addressModel,
                    id: 0
                };

            this.sellerCollection = initSellers(sellers, this.sellerCollection);

            this.orderModel = {
                ...orderExt,
                distributor: lg.distributor,
                distributors: lg.distributors,
                validAddr: lg.validAddr,
                rechargeTel: (lg.defaultAddress && lg.defaultAddress.recipientPhone) || '',
                hcodeInfo: user.hcodeInfo
            };

            this.invoiceModel = {
                modes: fund.invoice.modes || [],
                types: fund.invoice.types || [],
                msg: fund.invoice.msg
            };

            this.fundModel = {
                coupons: [],
                redCoupons: [],
                ...fund,
                healthGoldAmount: user.healthGold,
                healthStarAmount: user.healthStar,
                healthLifeAmount: user.healthLife,
                healthLifeStatus: user.healthLifeStatus,
            };


            if (orderExt.haiTao) {
                this.fetchUserCid();
            }

            this.lml = JSON.parse(lml);
        }

        return res;
    }

    assembleParams = ({
        callbackUrl,
        withCoupon = true,
        excludeInvalidItems = false,
        isFirst = false,
    }) => {
        const {
            addressModel,
            orderModel,
            fundModel,
            userCidModel,
            sellerCollection,
            invoiceModel,
            groupId,
        } = this;

        let enableInvoice = false;

        const lg = {
            addressId: addressModel.id || 0,
            prvCode: addressModel.provinceCode,
            ctyCode: addressModel.cityCode,
            distributor: orderModel.distributor
        };
        const foundParams = {
            fundAttrs: fundModel,
            withCoupon,
            enableInvoice,
            invoiceAttrs: invoiceModel,
        };
        const sellerParams = {
            sellerCollection,
            excludeInvalidItems,
            withCoupon,
            urlParams: this.urlParams
        };
        const bizParams = {
            orderAttrs: orderModel,
            groupId,
            callbackUrl,
            isFirst,
            from: this.from,
            jkCode: this.jkCode,
            tcode: this.tcode,
            flowLimited: this.useFlowLimited,
        };

        const userParams = { cid: userCidModel.cid };

        return {
            outBizType: env.OUT_BIZ_TYPE,
            user: userParams,
            fund: getFund(foundParams),
            lg,
            seller: getSeller(sellerParams),
            biz: getBiz(bizParams),
        };
    }

    validateTelForRecharge(tel) {
        let telArr = tel.split('');
        telArr[3] == ' ' && telArr.splice(3, 1);
        telArr[7] == ' ' && telArr.splice(7, 1);
        tel = telArr.join('');

        let validTelNums = JSON.parse(this.orderModel.rechargeTelSection);
        let rechargeTelInfo = /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(tel)
            ? validTelNums.indexOf(tel.substring(0, 3)) > -1
                ? ''
                : '该号段手机号码暂且不支持在线充值'
            : '请输入正确的手机号码';
        let isTelValid = rechargeTelInfo == '';
        if (tel == '') {
            rechargeTelInfo = '';
        }
        this.orderModel = {
            isTelValid,
            rechargeTel: tel,
            rechargeTelInfo,
        };
    }

    async popupServiceDescription() {
        const { skuIds } = this.getLogParams();
        const json = (await this._appResourceService.getAppResource({
            system: 'mall-trade',
            channel: 'all',
            type: 'json',
            source: 'order_create',
            keyName: 'order_create_service_description'
        })).val;

        if (!json) {
            return;
        }
        let info = {};
        try {
            info = JSON.parse(json);
            popupServiceDescription({
                skuId: skuIds,
                title: info.title,
                brief: info.brief,
                url: info.url,
                onOk(modal) {
                    modal.hide();
                },
                onCancel: () => {
                    this.ctx.navigation.back();
                },
            });
        } catch (error) {
            console.error(error);
        }
    }

    async renewOrderInfo({ withCoupon } = {}) {
        var res = await this.fetchOrderPageData({
            withCoupon,
            isFirst: false,
        });
        this.validateOrderInfo(res);
        return res;
    }

    modifyProductsCount = debounce(({ id, skuId, count }) => {
        this.modifyProductsPiece({ id, skuId, count });
        this.renewOrderInfo({ withCoupon: false });
    }, 300)

    modifyProductsPiece({ id, skuId, count }) {
        const { sellerCollection } = this;
        const currentSeller = getCurrentSeller(sellerCollection, id);

        if (currentSeller) {
            asObservable(currentSeller)
                .collection('items')
                .updateBy('skuId', { skuId, piece: count });
        }
    }

    onManageAddressClick() {
        window.open(location.origin + location.pathname + location.search + '#/address');
    }

    @emitter
    onAddAddressClick() {
        this._addressEditorModalViewModel.show({});
    }

    @emitter
    onEditAddressClick(address) {
        this._addressEditorModalViewModel.show(address);
    }

    @emitter
    onAddressSave(res) {
        if (!res.success) {
            toast.showToast(res.message);
            return;
        }
        setTimeout(() => {
            this._addressViewModel.loadAllAddresses();
            this.onAddressSelect(res.data);
        }, 100);
    }

    @emitter
    onBackToCartClick() {
        history.back();
    }

    @emitter
    onCreateOrderClick() {
        this.createOrder();
    }

    async createOrder() {
        const sellers = this.sellerCollection;

        if (!this.validateForm()) {
            return;
        }
        if (this.isCreatingOrder) {
            return;
        } else {
            this.isCreatingOrder = true;
        }

        const params = this.assembleParams({
            callbackUrl: `{0}`,
            excludeInvalidItems: true,
        });

        const res = await this._masterApiService.createOrder(params);

        if (res.success) {
            const {
                payUrl,
                orderCompleteUrl,
            } = res.data;

            const payResultUrl = `${location.origin}${location.pathname}${location.search}#/order/payresult/${orderCompleteUrl}`;

            if (payUrl) {
                const checkoutUrl = `${location.origin}${location.pathname}${location.search}` + util.appendQueryString('#/checkout', {
                    tradeIds: orderCompleteUrl,
                    callBackUrl: payResultUrl,
                });
                markAndRedirect(checkoutUrl);
            } else {
                markAndRedirect(payResultUrl);
            }
        } else {
            const { code, message } = res;
            // const {
            //     tuanSkuId,
            //     tuanGroupId,
            //     tagInstId
            // } = this.logParams;
            errorHandler(code, message, {
                spuId: util.get(sellers, [0, 'items', 0, 'spuId']),
                storeId: util.get(sellers, [0, 'storeId']),
                verifyOrderInfo: this.renewOrderInfo,
                // tuanSkuId,
                // tuanGroupId,
                // tagInstId,
                requestOnCashChange: this.requestOnCashChange.bind(this),
            });
        }
        this.isCreatingOrder = false;
        if (this.orderModel.rechargeMobile) {
            let curId = this._userService.getUserId();
            let userInfo = curId + '_' + this.orderModel.rechargeTel;
            localStorage.setItem('ORDER_RECHARGE_TEL', userInfo);
        }
        return res;
    }

    validateForm() {
        const orderModel = this.orderModel;
        const address = this.addressModel;
        const fund = this.fundModel;
        const sellerCollection = this.sellerCollection;
        const { haiTao: isHaiTao, codRx: isCOD, validAddr: isValidAddr = true, agreeDeduct, isNewPrescriptionModel, isVirtual } = orderModel;
        const { id } = address;
        const { paymentType } = fund;
        const tel = this.getExtTel();
        if (sellerCollection.map(seller => seller.bbs || '').some(bbs => hasEmoji(bbs))) {
            toast.showToast('买家留言不能用emoji表情');
            return false;
        }
        if (!id && isValidAddr) {
            if (this.addressList.length > 0) {
                toast.showToast('请设置收货地址');
            } else {
                popup.confirm({
                    title: '您还未选择收货人信息',
                    content: '新增地址即可正常提交订单。',
                    okText: '新增收货地址',
                    onOk: () => {
                        this._addressEditorModalViewModel.show({});
                    },
                });
            }
            return false;
        }
        if (isCOD) {
            if (tel) {
                if (!/^(\+[\d]{2,4})?[0-9]{11}$/.test(tel)) {
                    toast.showToast('用药人手机号码错误');
                    return false;
                }
            } else {
                if (isNewPrescriptionModel === true) {
                    toast.showToast('请填写用药人手机号');
                } else {
                    toast.showToast('用药人手机号码必填');
                }
                return false;
            }
        }

        if (isVirtual) {
            if (tel) {
                if (!/^[0-9]{11}$/.test(tel)) {
                    toast.showToast('请填写正确的短信接收手机号');
                    return false;
                }
            } else {
                toast.showToast('请填写短信接收手机号');
                return false;
            }
        }

        if (isHaiTao) {
            const cidModel = this.userCidModel;
            const cid = cidModel.cid;
            if (!cid) {
                toast.showToast('实名认证信息必填，请完善后提交');
                return false;
            }
            if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(cid)) {
                toast.showToast('请输入正确的身份证号码');
                return false;
            }
        }

        if (paymentType == 'dk' && !agreeDeduct) {
            toast.showToast('请阅读并同意自动续费协议');
            return false;
        }

        return true;
    }

    getExtTel() {
        return this.orderModel.tel;
    }

    requestOnCashChange() {
        this.clearCash();
        this.renewOrderInfo();
    }

    clearCash() {
        asObservable(this.fundModel)
            .set({ cash: undefined });
    }

}

