var React=window.Snowball._React;
import emptyCartImg from '../styles/imgs/empty-cart.png';

export const EmptyCart = ({ link }) => (
    <div className="cart_list_err">
        <img src={emptyCartImg} alt=""/>
        <p className="fs_xl cl_666 ta_c">购物车还是空的~</p>
        {!!link && (
            <button
                app-link={link}
                app-track="guangguang_click"
            >
                去逛逛
            </button>
        )}
    </div>
);
