var React=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
import CouponHelp from "../components/CouponHelp";
import CouponList from "../components/CouponList";
import "../styles/my-coupon.scss";
import { Tabs, useTabState, usePanelState } from "../components/Tab";
import { STATUS_MAP } from "../constants";

const tabs = Array.from(STATUS_MAP.keys());

const Tab = ({ children,onChangeTab,type }) => {
    const { isActive, onClick } = useTabState();
    return (
        <li className={`${isActive ? "selected" : ""}`} onClick={()=>{onClick(); onChangeTab({ type });}}>
            {children}
        </li>
    );
};

const Panel = ({ children }) => {
    const isActive = usePanelState();

    return isActive ? children : null;
};

function MyCouponTabs({ showCouponHelp, onActivateCoupon, data,onChangeTab,formatCouponDate,type, loading }) {
    return (
        <Tabs>
            <div className="tabs_coupon_help_container">
                <ul className={`my_coupon_tabs ${type}`}>
                    {tabs.map(tab => (
                        <Tab onChangeTab={onChangeTab} type={STATUS_MAP.get(tab)}>{tab}</Tab>
                    ))}
                </ul>
                <CouponHelp showCouponHelp={showCouponHelp} />
            </div>
            {tabs.map(tab => (
                <Panel>
                    <CouponList data={data} loading={loading} type={STATUS_MAP.get(tab)} formatCouponDate={formatCouponDate}/>
                </Panel>
            ))}
        </Tabs>
    );
}

export default inject(() => {
    return {
        ...mapViewModelToProps("myCouponViewModel")
    };
})(MyCouponTabs);
