import { MALL_URL, LOGIN_URL, APP_ENV, OUT_BIZ_TYPE } from '../env';
import { hashQs, isPlainObject } from './util';

// 公共部分被各业务团队调用，故目前不会主动从url上面取 qs 或者 hashQs 拼接
export const forward = (url, props = {}) => {
    const { location } = window;

    if (!isPlainObject(props)) {
        throw new Error('props must be object type');
    }

    if (/^(https?:)?\/\//.test(url)) {
        location.href = url;
        return;
    }

    const shortUrl = url;

    if (shortUrl === '/login') {
        location.href = hashQs(LOGIN_URL, { returnUrl: location.href });
    }
    else if (shortUrl === '/search') {
        location.href = hashQs(
            `${location.origin}/p-search/index.html#/searchresult/index`,
            {
                source: 'search',
                app: APP_ENV,
                outBizType: OUT_BIZ_TYPE,
                positionCode: 30404,
                categoryId: props.categoryId,
                keyword: props.keyword
            }
        );
    }
    else if (/^\/jkmall\//.test(location.pathname)) {
        location.hash = shortUrl;
    }
    else {
        let fullUrl = `${MALL_URL}#${shortUrl}`;
        // 外部参数定义与商城不同，不要带过来
        // const urlQs = qs(location.href);
        // fullUrl = qs(fullUrl, urlQs);
        // fullUrl = isEmptyObject(urlQs) ? fullUrl : qs(fullUrl, urlQs);
        location.href = fullUrl;
    }
};

export const reload = () => {
    const { location } = window;

    location.reload();
};