var React=window.Snowball._React;

export default function Empty({ text = '暂无优惠券' }) {
    return (
        <div className="empty">
            <p className="img" />
            <p>{text}</p>
        </div>
    );
}
