import "../styles/order-info.scss";
var React=window.Snowball._React;
import { Header, Footer } from "jkmall-frame";
import TopTips from '../components/TopTips';
import OrderStatus from '../components/OrderStatus';
import BasicInfo from "../components/BasicInfo";
import ProductList from '../components/ProductList';
import GoodGlass from '../components/GoodGlass';
import Settlement from '../components/Settlement';
import OrderButtons from '../components/OrderButtons';
import RefundModal from "../components/RefundModal";
import Main from "../../../shared/components/Main";

export default function OrderInfo() {
    return <>
        <Header />
        <Main className="mb_100">
            <TopTips />
            <OrderStatus>
                <OrderButtons />
            </OrderStatus>
            <BasicInfo />
            <ProductList />
            <GoodGlass />
            <Settlement />
        </Main>
        <RefundModal />
        <Footer></Footer>
    </>;
}