import ModuleBase from "../base/ModuleBase";

export default class FloorBrands extends ModuleBase {
    patchData() {
        const { model, bizData } = this;

        model.set({
            isShow: true,
            bizData: {
                ...bizData,
                images: bizData.images.map(img => ({
                    ...img,
                    src: this.context.app.tfs.transformTFSImageUrl(img.src)
                }))
            }
        }).nextTick(() => { });
    }
}