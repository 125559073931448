var { observable }=window.Snowball;
var { Service, autowired }=window.Snowball._app;
import HestiaApiService from "../../../shared/apis/HestiaApiService";
var { toast }=window.Snowball._widget;

export default class TradeStatusDetailService extends Service {
    @observable
    statusDetails = [];

    @autowired
    _hestiaApiService: HestiaApiService;

    async loadTradeStatusDetails(tradeId) {
        this.tradeId = tradeId;
        const res = await this._hestiaApiService.getTradeStatusDetail(tradeId);
        if (res.success) {
            this.statusDetails = res.data;
        } else {
            toast.showToast(res.message);
        }
    }
}
