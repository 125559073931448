var { observable }=window.Snowball;
var { ViewModel, autowired }=window.Snowball._app;
import UnicornApiService from '@/shared/apis/UnicornApiService';
import MyAfterSaleViewModel from '../view-models/MyAfterSaleViewModel';

export default class AfterSaleListViewModel extends ViewModel {
    @observable
    orders = [];

    @autowired
    _unicornApiService: UnicornApiService;

    @autowired
    _myAfterSaleViewModel: MyAfterSaleViewModel;

    init(orderId) {
        this.loadOrders(orderId);
    }

    async loadOrders(orderId) {
        const res = await this._unicornApiService.getAfterSalesOrdersByTradeId(orderId);

        if (res.success) {
            const { value = [] } = res.data;

            this.orders = value.map(order => this._myAfterSaleViewModel.transformRefundOrder(order));
        }
        else {
            this.orders = [];
        }

        return res;
    }
}