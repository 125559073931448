import '../styles/my-coupon.scss';
var React=window.Snowball._React;var { useState }=window.Snowball._React;
var { Modal }=window.Snowball._components;
var { inject, mapViewModelToProps }=window.Snowball._app;

const AddCouponModal = ({ visible, addCoupon, switchAddCouponModal,clearErrMsg,addCouponErrMsg }) => {
    const [code,changeCode] = useState('');
    return (
        <Modal className="add-coupon-modal" visible={visible}>
            <div className="add-coupon-modal-container ps_r">
                <h1 className="add-coupon-modal-hd">激活优惠券</h1>
                <div className="add-coupon-modal-label">优惠券券号：</div>
                <input
                    type="text"
                    className="add-coupon-modal-input"
                    placeholder="请输入优惠券的券号"
                    value={code}
                    onChange={(event) => {
                        clearErrMsg();
                        changeCode(event.target.value);
                    }}
                />
                <button className="add-coupon-modal-btn ta_c" onClick={()=>addCoupon(code)}>
                    立即激活
                </button>
                {addCouponErrMsg ? <div className="errmsg ps_a"><i class="icon iconfont icon-warn mr_s"></i>{addCouponErrMsg}</div> : null}
                <div className="close ps_a" onClick={()=>{switchAddCouponModal(false); changeCode(''); clearErrMsg();}}>x</div>
            </div>
        </Modal>
    );
};

export default inject(mapViewModelToProps('myCouponViewModel'))(AddCouponModal);
