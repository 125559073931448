var { observable }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
import GlobalAddressService from "../services/GlobalAddressService";
import AddressApiService from "../apis/AddressApiService";
import AreaSelectorViewModel from "../../app/address/view-models/AreaSelectorViewModel";
import UserService from "../services/UserService";
var { toast }=window.Snowball._widget;

export default class GlobalAddressSelectorViewModel extends ViewModel {
    @observable
    isModalVisible = false;

    @observable
    addressList;

    _loaded = false;

    @autowired
    _globalAddressService: GlobalAddressService;

    @autowired
    _userService: UserService;

    @autowired
    _addressApiService: AddressApiService;

    @autowired
    _areaSelectorViewModel: AreaSelectorViewModel;

    constructor() {
        super();

        this._areaSelectorViewModel.onChange(async () => {
            const address = this._areaSelectorViewModel.currentArea;
            let res = await this._addressApiService.resolve(
                address.city || '',
                (address.province || '') +
                (address.city || '') +
                (address.district || ''),
                'bd09ll'
            );
            if (res.success && res.data && res.data.lat && res.data.lng) {
                const { lat, lng } = res.data;
                this._globalAddressService.update({
                    ...address,
                    longitude: lng,
                    latitude: lat
                });
                this.isModalVisible = false;
            } else {
                toast.showToast('地址选择失败，请重新选择');
            }
        });
    }

    get address() {
        return this._globalAddressService.address;
    }

    @emitter
    onSelectAddress(address) {
        this._globalAddressService.update(address);
        this.isModalVisible = false;
    }

    @emitter
    onShow() {
        this.show();
    }

    @emitter
    onHide() {
        this.isModalVisible = false;
    }

    async show() {
        this.isModalVisible = true;
        if (!this._loaded) {
            if (await this._userService.isLogin()) {
                this._loaded = true;
                const res = await this._addressApiService.getAllAddressesByBiz('mall', false);
                let addressList = res.success ? res.data.value || [] : [];
                this.addressList = addressList.filter(item => item.latitude && item.longitude);
            }
        }
        const { address } = this;
        this._areaSelectorViewModel.initWithValue([address.provinceCode, address.cityCode, address.districtCode]);
    }
}