var React=window.Snowball._React;var { Fragment }=window.Snowball._React;
import Info from '../components/Info';
import Tabs from '../components/Tabs';
import { Header, Footer, Logo } from 'jkmall-frame';
import ProgressList from '../../../shared/components/ProgressList';


export default function Checkout() {
    return (
        <Fragment>
            <Header renderExtra={() => {
                return (
                    <div className="clearfix">
                        <Logo name="收银台" className="mt_8" />
                        <ProgressList selectedIndex={2} />
                    </div>
                );
            }} />
            <div className="checkout">
                <div className="body">
                    <Info />
                    <Tabs />
                </div>
            </div>
            <Footer />
        </Fragment>

    );
}
