var React=window.Snowball._React;var { Component }=window.Snowball._React;
import Checkbox from '../../../shared/components/Checkbox';
import { ShopProps } from '../constant/types';
var { mapViewModelToProps, inject }=window.Snowball._app;
import { CartItem } from './';
import { CartActTypes } from '../constant/ActType';

/**
 *
 * 渲染商户栏选中的check
 * 如果是非编辑状态下，能否购买>库存状况>是否选中(不能购买或者全部库存为0的时候显示disabled)
 * 如果是编辑状态下，只有是否选中
 *
 */
const CheckItem = ({ onSellerCheck, shopInfo }) => {
    const { disabled, isAllItemsChecked } = shopInfo;
    const checked = isAllItemsChecked;
    return (
        <div className="fl_l mt_2">
            <Checkbox
                disabled={disabled}
                checked={checked}
                onChange={() => {
                    onSellerCheck(shopInfo);
                }}
            />
        </div>
    );
};

type CartListProps = {
    // 有效购物车列表
    list: ShopProps[],
    // 是否处于编辑态
    isEditMode: boolean,
    onShopCheck: (shop: ShopProps, checked: boolean) => void,
    // 规格被改变触发的事件
    onSpecChange: (
        spuId: number,
        skuId: number,
        storeId: number,
        count: number
    ) => void,
    onCouponChoose: (shop: ShopProps) => void
};

export class CartList extends Component<CartListProps, {}> {
    render() {
        const {
            list,
            deleteItems,
            changeItemNum,
            // cartType,
            // hasMemberPrice,
            // cartInfo,
            // isShowPostageBar,
            toggleShopChecked,
            checkItem,
            // onStoreClick,
            // onCouponChoose,
            // onRoundOrder,
            // onItemCountChange,
            // getActivityList,
            // onSpecChange,
            // onComboSpecChange
        } = this.props;
        // const isDepreciable = cartInfo.depreciableProducts && cartInfo.depreciableProducts.length > 0 && cartInfo.isShowPromotionTip;

        return (
            <div>
                {list.map((store, i) => {
                    const {
                        storeId,
                        // sellerId = 0,
                        // cartSellerDTO = {},
                        // userAddressDelivered
                    } = store;
                    if (store.cartSkuDTOs.length === 0) {
                        return null;
                    }
                    // PC端额外逻辑，全部都是套餐的话不展示这个商户
                    const isAllComboInTheStore = store.cartSkuDTOs.every((item)=>item.cartSubType === CartActTypes.COMBO);
                    if (isAllComboInTheStore) {
                        return null;
                    }
                    return (
                        <div
                            className="mb_m ct_cart__store"
                            key={storeId + '-' + i}
                        >
                            <div className="store_line">
                                <CheckItem
                                    onSellerCheck={toggleShopChecked}
                                    shopInfo={store}
                                />
                                <p
                                    className="store_name"
                                >
                                    {store.displayName}
                                </p>
                            </div>
                            {
                                store.cartSkuDTOs.map((item) => {
                                    if (item.cartSubType === CartActTypes.COMBO) {
                                        return null;
                                    }
                                    return (
                                        <CartItem
                                            key={'' + item.id + item.skuId}
                                            skuData={item}
                                            onCartItemCheck={checkItem}
                                            deleteItems={deleteItems}
                                            changeItemNum={changeItemNum}
                                        />
                                    );
                                })
                            }
                        </div>
                    );
                })}
            </div>
        );
    }
}


export const CartListInjector = inject(({ deleteItems, changeItemNum }) => ({
    ...mapViewModelToProps('CartViewModel'),
    deleteItems,
    changeItemNum,
}))(CartList);