var { observable, asObservable }=window.Snowball;
var { ViewModel, autowired }=window.Snowball._app;
import GumihoApiService from "../../shared/apis/GumihoApiService";

export default class DecorationViewModel extends ViewModel {
    pageId = 0;
    currentIndex = 0;
    pageSize = 15;

    @observable
    loading = false;

    @observable
    isNoMoreData = false;

    @observable
    pageData = {};

    @observable
    templates = [];

    @observable
    modules = [];

    @autowired
    _gumihoApiService: GumihoApiService;

    loadHomePage(pageType = 14) {
        this.currentIndex = 0;
        return this._promise = this._loadPage(() => {
            return this._gumihoApiService.getMallPage(this.app.env.APP_ENV, pageType, null, this.currentIndex, this.currentIndex + this.pageSize);
        });
    }

    loadHomeMore() {
        if (this.loading) {
            return this._promise;
        }
        return this._loadHomePageById(this.pageId, false);
    }

    previewHomePageById(pageId) {
        this.currentIndex = 0;
        return this._promise = this._loadPage(() => {
            return this._gumihoApiService.previewMallPageById(pageId, null, this.currentIndex, this.currentIndex + this.pageSize);
        });
    }

    previewHomeMore() {
        if (this.loading) {
            return this._promise;
        }
        return this._promise = this._loadPage(() => {
            return this._gumihoApiService.previewMallPageById(this.pageId, null, this.currentIndex, this.currentIndex + this.pageSize);
        }, false);
    }

    _loadHomePageById(pageId, isRenewData) {
        return this._promise = this._loadPage(() => {
            return this._gumihoApiService.getMallPageById(pageId, null, this.currentIndex, this.currentIndex + this.pageSize);
        }, isRenewData);
    }

    loadCmsPage(pageLogicId) {
        this.currentIndex = 0;
        return this._promise = this._loadPage(() => {
            return this._gumihoApiService.getCmsPage(pageLogicId, this.currentIndex, this.currentIndex + this.pageSize);
        });
    }

    loadCmsMore() {
        if (this.loading) {
            return this._promise;
        }
        return this._loadCmsPageById(this.pageId, false);
    }

    previewCmsPageById(pageId) {
        this.currentIndex = 0;
        return this._promise = this._loadPage(() => {
            return this._gumihoApiService.previewCmsPageById(pageId, this.currentIndex, this.currentIndex + this.pageSize);
        });
    }

    previewCmsMore() {
        if (this.loading) {
            return this._promise;
        }
        return this._promise = this._loadPage(() => {
            return this._gumihoApiService.previewCmsPageById(this.pageId, this.currentIndex, this.currentIndex + this.pageSize);
        }, false);
    }

    _loadCmsPageById(pageId, isRenewData) {
        return this._promise = this._loadPage(() => {
            return this._gumihoApiService.getCmsPageById(pageId, this.currentIndex, this.currentIndex + this.pageSize);
        }, isRenewData);
    }

    async _loadPage(request, isRenewData) {
        let res;
        this.loading = true;
        try {
            res = await request();
            this._handleRemoteResult(res, isRenewData);
        } catch (error) {
            console.error(error);
            res = this.error = error;
            this.noMoreData();
        }
        this.loading = false;
        this._promise = null;
        return res;
    }

    _handleRemoteResult(res, isRenewData) {
        if (!res.success || res.stat.stateList[0].code == 50220001) {
            this.error = res;
            this.noMoreData();
        } else {
            this.setPage(res.data, isRenewData);
            this.error = null;
            if (res.data.isEnd) {
                this.error = null;
                this.noMoreData();
            }
        }
        if (res.data.end) {
            this.currentIndex = res.data.end + 1;
        } else {
            this.currentIndex += this.pageSize + 1;
        }
    }

    noMoreData() {
        this.isNoMoreData = true;
    }

    setPage(data, isRenew = true) {
        if (data.cmsPageDTO) {
            this.updatePageData(data.cmsPageDTO);
        }
        this.updateTemplates(data.cmsTemplateDTOs);
        this.updateTemplateData(data.cmsTemplateDataDTOs, isRenew);
    }

    updatePageData(mallPage) {
        if (mallPage.logicId == 0) delete mallPage.logicId;
        if (!mallPage.pageId) mallPage.pageId = mallPage.id;

        this.pageId = mallPage.pageId;

        mallPage.attrs = mallPage.attrs ? JSON.parse(mallPage.attrs) : {};
        let { pageBgImage } = mallPage.attrs;
        if (pageBgImage) {
            mallPage.attrs.pageBgImage = this.app.tfs.transformTFSImageUrl(pageBgImage);
        }
        this.pageData = mallPage;
    }

    updateTemplateData(cmsTemplateData, isRenew) {
        let modules = [];
        if (cmsTemplateData) {
            for (var index = 0; index < cmsTemplateData.length; index++) {
                var templatData = cmsTemplateData[index];
                modules.push({
                    ...templatData,
                    template: asObservable(this.templates).find('id', templatData.templateId),
                    bizData: templatData.bizData
                        ? JSON.parse(templatData.bizData)
                        : {},
                    propsVal: templatData.propsVal
                        ? JSON.parse(templatData.propsVal)
                        : {},
                });
            }
        }
        if (isRenew) {
            this.modules = modules;
        } else {
            asObservable(this.modules).update(modules, 'logicId');
        }
        asObservable(this.modules).sort((a, b) => {
            return (b.template.extProps.sort || 0) - (a.template.extProps.sort || 0);
        });
    }

    updateTemplates(cmsTemplatesDTO) {
        if (cmsTemplatesDTO) {
            var templates = cmsTemplatesDTO.map(item => {
                return {
                    ...item,
                    extProps: item.extProps ? JSON.parse(item.extProps) : {}
                };
            });
            asObservable(this.templates).update(templates, 'id');
        }
    }
}