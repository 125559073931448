var { configuration }=window.Snowball._app;

import OrderAppResourceService from './services/OrderAppResourceService';
import OrderInfoViewModel from './view-models/OrderInfoViewModel';
import OrderOperationHandlers from './view-models/OrderOperationHandlers';
import RefundService from './services/RefundService';
import RefundFormViewModel from './view-models/RefundFormViewModel';
import RefundModalViewModel from './view-models/RefundModalViewModel';
import TradeStatusDetailService from './services/TradeStatusDetailService';

export const OrderInfoConfiguration = configuration({
    modules: {
        orderAppResourceService: OrderAppResourceService,
        orderInfoViewModel: OrderInfoViewModel,
        orderOperationHandlers: OrderOperationHandlers,
        refundService: RefundService,
        refundFormViewModel: RefundFormViewModel,
        refundModalViewModel: RefundModalViewModel,
        tradeStatusDetailService: TradeStatusDetailService,
    }
});

export const TradeStatusDetailConfiguration = configuration({
    modules: {
        tradeStatusDetailService: TradeStatusDetailService,
    }
});
