var React=window.Snowball._React;
import '../styles/pay-result.scss';
import PaySuccessImg from '../images/pay-result-pay-success.png';
import PayFailedImg from '../images/pay-result-pay-failed.png';
import Loading from '../../../shared/components/Loading';

function PayResult(props) {
    const {
        waiting,
        payInfo,
        buttonStatus,
        onViewOrder,
        onFinishPurchase,
        onRepay,
        tradeOrder = {}
    } = props || {};

    const { activePrivateDoctor } = buttonStatus || {};
    const { payMode } = tradeOrder;

    return (
        <div className="pay_result_container">
            <div>
                {
                    waiting
                        ? <Loading className="pay_result_loading" />
                        : <img
                            className="pay_result_icon"
                            src={payInfo.success ? PaySuccessImg : PayFailedImg}
                            alt=""
                        />
                }
            </div>
            <div className={ `${waiting ? 'pb_xl' : ''}` }>
                <p className="pay_success_text">{payInfo.payStatusText}</p>
                <p className="pay_success_desc">{payInfo.payStatusDesc}</p>
            </div>
            {
                waiting
                    ? null
                    : payInfo.success
                        ? <div className="ta_c">
                            {
                                (activePrivateDoctor === 0) &&
                                <button className="pay_success_finish_buy_btn"
                                    onClick={onFinishPurchase}>
                                    完成购买
                                </button>
                            }
                            <button className={`pay_success_look_orders_btn ${activePrivateDoctor === 0 ? 'btn_left' : ''}`}
                                onClick={onViewOrder}>
                                查看订单
                            </button>
                        </div>
                        : <div className="ta_c">
                            <button
                                className="pay_success_finish_buy_btn"
                                onClick={onViewOrder}>
                                查看订单
                            </button>
                            {
                                payMode == 'PAYONLINE' &&
                                <button
                                    className="pay_success_look_orders_btn btn_left"
                                    onClick={onRepay}>
                                    重新支付
                                </button>
                            }
                        </div>
            }
        </div>
    );
}

export default PayResult;