var { observable, util }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
import UnicornApiService from '@/shared/apis/UnicornApiService';
import { AFTER_SALE_STATUS_TEXT_LIST, REFUND_TYPE_CNY_TEXT_MAP } from "../constants";

export default class MyAfterSaleViewModel extends ViewModel {
    @observable
    orders = [];

    @observable
    total = 0;

    @observable
    pageNo = 1;

    @observable
    pageSize = 4;

    @autowired
    _unicornApiService: UnicornApiService;

    async init() {
        this.loadOrders();
    }

    async loadOrders() {
        const res = await this._unicornApiService.getAfterSalesOrdersByBuyerId({
            pageNo: this.pageNo,
            pageSize: this.pageSize
        });

        if (res.success) {
            const { totalCount, afterSalesOrders = [] } = res.data;

            this.orders = afterSalesOrders.map(order => this.transformRefundOrder(order));
            this.total = totalCount;
        }
        else {
            this.orders = [];
            this.total = 0;
        }

        return res;
    }

    @emitter
    onPageChange(pageNo) {
        this.pageNo = pageNo;
        this.loadOrders();
    }

    transformRefundOrder(orderInfo) {
        const {
            applyTime,
            status,
            refundType,
            cashRefundAmount,
            healthGoldRefundAmount,
            shoppingCardRefundAmount,
            healthBeanRefundAmount,
            healthStarRefundCount
        } = orderInfo;
        const formatAmount = (amount = 0) => { return (amount / 100).toFixed(2); };
        const refundAmountTextList = [
            { desc: '现金:', unit: '￥', placeholder: '', amount: cashRefundAmount, format: formatAmount },
            { desc: '健康金:', unit: '', placeholder: '', amount: healthGoldRefundAmount, format: formatAmount },
            { desc: '健康生活通:', unit: '￥', placeholder: '', amount: shoppingCardRefundAmount, format: formatAmount },
            { desc: '健康豆:', unit: '', placeholder: '', amount: healthBeanRefundAmount },
            { desc: '健康星:', unit: '', placeholder: '', amount: healthStarRefundCount, format: (amount = 0) => amount / 100 }
        ]
            .map((item) => {
                const { desc, unit, placeholder, amount, format } = item;

                return amount > 0 ? `${desc}${unit}${format ? format(amount) : amount}` : placeholder;
            })
            .filter((text) => text !== '');

        return {
            ...orderInfo,
            statusText: AFTER_SALE_STATUS_TEXT_LIST[status],
            applyTime: util.formatDate(applyTime * 1000, 'yyyy-MM-dd HH:mm:ss'),
            refundAmountTextList,
            refundTypeText: REFUND_TYPE_CNY_TEXT_MAP[refundType]
        };
    }
}