import "../styles/address.scss";
var React=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;

function AddressList({
    addressList,
    modifyAddress,
    setDefaultAddress,
    deleteAddress
}) {
    return (
        <div className="address-list">
            {
                addressList.map(item => {
                    return (
                        <div className="address-list-item clearfix of_h">
                            <ul>
                                <li>
                                    <label>收货人：</label>
                                    <p className="name">{item.recipientName}</p>
                                </li>
                                <li>
                                    <label>手机：</label>
                                    <p>{item.recipientPhone}</p>
                                </li>
                                <li>
                                    <label>所在地区：</label>
                                    <p>{`${item.province} ${item.city} ${item.district}`}</p>
                                </li>
                                <li>
                                    <label>详细地址：</label>
                                    <p>{`${item.streetAddress}${item.referAddr}`}</p>
                                </li>
                            </ul>
                            <div className="tags">
                                {item.addressLabel && <span className="tag">{item.addressLabel}</span>}
                                {item.preferred && <span className="tag">默认地址</span>}
                            </div>
                            <div className="actions">
                                {!item.preferred &&
                                    <button onClick={() => { setDefaultAddress(item); }} >设为默认</button>
                                }
                                {!item.preferred &&
                                    <span className="seperator">|</span>
                                }
                                <button onClick={() => { modifyAddress(item); }}>修改</button>
                                <span className="seperator">|</span>
                                <button onClick={() => { deleteAddress(item); }}>删除</button>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default inject(mapViewModelToProps('addressViewModel'))(AddressList);
