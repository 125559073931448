var React=window.Snowball._React;var { Component }=window.Snowball._React;
var { app }=window.Snowball._app;

function setImageSrc(img, src) {
    const preactAttr = img['__preactattr_'];
    if (preactAttr) {
        delete preactAttr.src;
    }
    img.removeAttribute('src');
    if (src) {
        img.src = src;
    }
}

export default class TFSImage extends Component {
    // 修复preact复用img节点导致先展示老src再展示新src
    imageRef = (ref) => {
        if (ref) {
            const {
                src,
                width,
                height,
                quality
            } = this.props;

            const tfsUrl = app.tfs.transformTFSImageUrl(src, width, height, quality);
            if (ref.src != tfsUrl) {
                setImageSrc(ref, tfsUrl);
            }
        }
    }

    render() {
        const {
            src,
            className,
            style,
            alt,
            width,
            height,
            quality,
            ...moreProps
        } = this.props;

        var props = src
            ? { src: app.tfs.transformTFSImageUrl(src, width, height, quality) }
            : null;
        return <img ref={this.imageRef} {...props} className={className} style={style} alt={alt} {...moreProps} />;
    }
}
