var React=window.Snowball._React;
import fea1Image from '../../sass/images/fea1.png';
import fea2Image from '../../sass/images/fea2.png';
import fea3Image from '../../sass/images/fea3.png';
import fea4Image from '../../sass/images/fea4.png';

const Footer = (props) => {
    // type = white | grey
    const { className, type = 'white' } = props;

    return (
        <div className={`mall_common__footer mall_common__footer_${type} ${className || ''}`}>
            <div className="mall_common__footer_container">
                <ul className="mall_common__footer__feature_list">
                    <li>
                        <img alt="" src={fea1Image} />
                        <div>平安质保</div>
                    </li>
                    <li>
                        <img alt="" src={fea2Image} />
                        <div>资质齐全</div>
                    </li>
                    <li>
                        <img alt="" src={fea3Image} />
                        <div>正品低价</div>
                    </li>
                    <li>
                        <img alt="" src={fea4Image} />
                        <div>假一赔十</div>
                    </li>
                </ul>
                <div className="mall_common__footer__links">
                    <p className="mall-common__footer__links_pingan">
                        <div className="mall_common__footer__links_title">集团链接:</div>
                        <div className="mall_common__footer__links_content">
                            <a href="https://www.pingan.com/" target="_blank">中国平安</a>
                            <a href="https://one.pingan.com.cn/paone/index.html#/one" target="_blank">一账通</a>
                            <a href="http://stock.pingan.com/" target="_blank">平安证券</a>
                            <a href="https://www.yqb.com/" target="_blank">壹钱包</a>
                            <a href="https://www.lu.com/" target="_blank">陆金所</a>
                        </div>
                    </p>
                    {/* <p className="mall-common__footer__links_other">
                        <div className="mall_common__footer__links_title">友情链接:</div>
                        <div className="mall_common__footer__links_content">
                            <a href="https://m.jk.cn" target="_blank">手机版</a>
                            <a href="https://m.jk.cn/atlanta/dept" target="_blank">疾病索引</a>
                            <a href="https://m.jk.cn/atlanta/cases/1001456/1" target="_blank">咳嗽</a>
                            <a href="https://m.jk.cn/atlanta/cases/1000949/1" target="_blank">消化不良</a>
                            <a href="https://m.jk.cn/atlanta/cases/1024276/1" target="_blank">腹痛</a>
                            <a href="https://m.jk.cn/atlanta/cases/1018630/1" target="_blank">腹泻</a>
                            <a href="https://m.jk.cn/atlanta/cases/1016651/1" target="_blank">高血压</a>
                            <a href="https://m.jk.cn/atlanta/cases/1004397/1" target="_blank">早泄</a>
                            <a href="https://m.jk.cn/atlanta/cases/1001043/1" target="_blank">青春痘</a>
                            <a href="https://m.jk.cn/atlanta/cases/1017458/1" target="_blank">感冒</a>
                            <a href="https://m.jk.cn/atlanta/cases/1014560/1" target="_blank">1型糖尿病</a>
                            <a href="http://www.doctorjob.com.cn" target="_blank">中国医疗人才网</a>
                            <a href="http://www.vodjk.com/" target="_blank">健康一线</a>
                        </div>
                    </p> */}
                </div>
                <div className="mall_common__footer__copyright">
                    <p>
                        Copyright © PING AN HEALTH CLOUD COMPANY LIMITED. All Rights Reserved 版权所有 © 平安健康互联网股份有限公司
                    </p>
                    <p>
                        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502000566" target="_blank">粤公网安备44030502000566号</a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="http://www.beian.miit.gov.cn/" target="_blank">粤ICP备17022768号</a> <br/>
                        深圳市前海深港合作区前湾一路1号A栋201室 021-38647044&nbsp;&nbsp;&nbsp;&nbsp;   <a href="https://szcert.ebs.org.cn/58858ea4-fb93-42aa-b36b-4ae75a35bae8" target="_blank">工商电子标识</a>
                        &nbsp;&nbsp;&nbsp;&nbsp;   <a href="https://www.jk.cn/common-protocol/index.html?code=pc_private" target="_blank">隐私政策</a><br/>
                        医疗器械网络交易服务第三方平台备案凭证：（粤）网械平台备字[2018]第00009号 <br/>
                        互联网药品信息服务资格证书：（粤）-经营性-2020-0250  |  广播电视节目制作经营许可证：（粤）字第03243号  |  食品经营许可证：JY14403040209138 <br/>
                        药品经营许可证：粤DA7550161  |  药品经营质量管理规范认证证书：C-GD-018-SZ-0375  |  网络文化经营许可证：粤网文[2019]3973-970号<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
