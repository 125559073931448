var React=window.Snowball._React;
import ReactModule from "../base/ReactModule";
var { reactModule }=window.Snowball._app;
import Anchor from "../../../shared/components/Anchor";

@reactModule
export default class AnchorModule extends React.Component {
    render() {
        const { bizData } = this.props.data;
        const { bg, color, activeColor, anchors = [] } = bizData;

        const items = anchors.map((anchor) => {
            const item = { text: anchor.text };
            if (anchor.link.startsWith("@")) {
                item.tag = {
                    name: "app-anchor-name",
                    value: anchor.link,
                };
            } else {
                item.link = anchor.link;
            }
            return item;
        });

        return (
            <ReactModule {...this.props}>
                <Anchor bgColor={bg} textColor={color} activeColor={activeColor} items={items} />
            </ReactModule>
        );
    }
}
