var React=window.Snowball._React;

const Steps = (props) => {
    const {
        children,
        direction = 'horizontal',
        className
    } = props;

    return (
        <div className={`cm_steps cm_steps_${direction} ${className}`}>
            { React.Children.map(children, (child, index) => {
                const length = React.Children.count(children);
                const isFirst = index === 0;
                const isLast = index + 1 === length;

                return React.cloneElement(
                    child, { index: index + 1, isFirst, isLast }
                );
            }) }
        </div>
    );
};

Steps.Step = (props) => {
    const { icon, actived, index, isFirst, isLast } = props;
    const activedClassName = actived ? 'active' : '';
    const firstItemClassName = isFirst ? 'first' : '';
    const lastItemClassName = isLast ? 'last' : '';

    return (
        <div
            className={`item ${activedClassName} ${firstItemClassName} ${lastItemClassName}`}
        >
            <div className="head">
                <div className="inner">
                    { icon ? icon : <i>{index}</i> }
                </div>
            </div>
            <div className="main">
                { props.children }
            </div>
        </div>
    );
};

export default Steps;