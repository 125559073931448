var React=window.Snowball._React;var { useEffect }=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import DistrictSelector from "./DistrictSelector";

function GlobalAddressSelector({
    isModalVisible,
    className,
    address,
    addressList,
    onSelectAddress,
    onShow,
    onHide
}) {
    useEffect(() => {
        const onClick = (e) => {
            let target = e.target;
            let isClickGlobalAddress = false;
            while (target && target != document.body) {
                if (target.className) {
                    if (target.className.indexOf('app-global-address-select-modal') !== -1 || target.className.indexOf('app-global-address-select-con') !== -1) {
                        isClickGlobalAddress = true;
                        break;
                    }
                }
                target = target.parentNode;
            }
            if (isModalVisible && !isClickGlobalAddress) {
                onHide();
            }
        };
        document.body.addEventListener('click', onClick, true);
        return () => document.body.removeEventListener('click', onClick, true);
    }, [isModalVisible, onHide]);

    return <div className={"app-global-address-select " + (className || '')}>
        <button
            className="app-global-address-select-con"
            onClick={() => {
                isModalVisible ? onHide() : onShow();
            }}
        >{address.fullAddress}</button>
        {
            !!isModalVisible && <GlobalAddressSelectorModal
                onSelectAddress={onSelectAddress}
                address={address}
                addressList={addressList}
            />
        }
    </div>;
}

function GlobalAddressSelectorModal({ className, address, addressList, onSelectAddress }) {
    return <div className={"app-global-address-select-modal " + (className || '')}>
        {
            addressList && addressList.length
                ? <>
                    <h5>常用地址</h5>
                    <ul className="addressList">
                        {
                            addressList.map((item) => {
                                const {
                                    id,
                                    recipientName,
                                    province = '',
                                    city = '',
                                    district = '',
                                    streetAddress = '',
                                    referAddr = ''
                                } = item;

                                return <li
                                    key={id}
                                    className={address && address.id == id ? 'curr' : ''}
                                    onClick={() => onSelectAddress(item)}
                                >
                                    {recipientName + ' '}
                                    {province == city ? '' : province}
                                    {city}
                                    {district}
                                    {streetAddress}
                                    {referAddr}</li>;
                            })
                        }
                    </ul>
                </>
                : <p className="no-address">
                    您暂无收货地址，请选择其他区域
                </p>
        }
        <h5>选择新地址</h5>
        <DistrictSelector />
    </div>;
}


export default inject(() => {
    const globalAddressSelectorViewModel = autowired('globalAddressSelectorViewModel');
    return {
        address: globalAddressSelectorViewModel.address,
        addressList: globalAddressSelectorViewModel.addressList,
        isModalVisible: globalAddressSelectorViewModel.isModalVisible,
        onSelectAddress: globalAddressSelectorViewModel.onSelectAddress,
        onShow: globalAddressSelectorViewModel.onShow,
        onHide: globalAddressSelectorViewModel.onHide,
    };
})(GlobalAddressSelector);