
var React=window.Snowball._React;
import TFSImage from "../../../shared/components/TFSImage";
var { inject, autowired }=window.Snowball._app;

function RefundItem({ skuPicture, skuPrice, skuTitle, specs, skuNum }) {
    let specsText = '';
    specs && specs.forEach((obj) => {
        specsText += obj.sk + ': ' + obj.sv + ' ';
    });

    return <div className="oi_refund_item clearfix">
        <TFSImage
            className="img"
            src={skuPicture}
            width={120}
            height={120}
        />
        <div className="con">
            <p className="tit to_e2">{skuTitle}</p>
            <p className="spec to_e2">{specsText}</p>
            <p>
                <span className="price">{skuPrice > 0 && `￥${formatPrice(skuPrice)}`}</span>
                <span className="cl_333"> ×{skuNum}</span>
            </p>
        </div>
    </div>;
};

export default inject(() => {
    const refundFormViewModel = autowired('refundFormViewModel');
    const { refundInfo } = refundFormViewModel;
    return {
        skuPicture: refundInfo.skuPicture,
        skuPrice: refundInfo.skuPrice,
        skuTitle: refundInfo.skuTitle,
        skuNum: refundInfo.amount,
        specs: JSON.parse(refundInfo.spec || '[]')
    };
})(RefundItem);

function formatPrice(price) {
    price = (parseInt(price, 0) || 0) + "";
    if (price.length > 2) {
        return price.substring(0, price.length - 2) + "." + price.substring(price.length - 2, price.length);
    } else if (price.length === 1) {
        return "0.0" + price;
    } else {
        return "0." + price;
    }
}
