var React=window.Snowball._React;
var { popup }=window.Snowball._widget;
import { CPaymentPopup } from '../components';
export default function checkout(
    groups: Array<{
        title: string,
        total: number,
        cashPrice: number,
        sellers: any[]
    }>,
    toOrder
) {
    // 所有商户都允许合并支付的情况
    if (groups.length === 1) {
        // log.makeLog('settle_click',{
        //     isCombined: true
        // });
        toOrder(groups[0].sellers);
        return;
    }

    // log.makeLog('settle_click',{
    //     isCombined: false
    // });

    const modal = popup.popup({
        animate: 'scale',
        className: 'checkout_pop',
        clickMaskToHide: true,
        content: (
            <CPaymentPopup
                groups={groups}
                onComplete={(result, index) => {
                    toOrder(result);
                    modal.hide();
                }}
                onHide={() => {
                    modal.hide();
                }}
            />
        ),
        onHide: () => {}
    });
}
