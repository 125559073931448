var React=window.Snowball._React;var { useState }=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
import UserCenterMain from "@/shared/components/UserCenterMain";
import UserCenterTitle from "@/shared/components/UserCenterTitle";
import MyCouponTabs from "../components/MyCouponTabs";
import "../styles/my-coupon.scss";
import CouponPagination from '../components/CouponPagination';
import AddCouponModal from '../components/AddCouponModal';


function MyCoupon({
    tabs,
    showCouponHelp,
    onActivateCoupon,
    data
}) {
    const [addCouponModal, switchAddCouponModal] = useState(false);
    return (
        <UserCenterMain name={'coupon'}>
            <UserCenterTitle
                title={'我的优惠券'}
                toolbar={
                    <button onClick={() => switchAddCouponModal(true)}>
                        激活优惠券
                    </button>
                }
            />
            <MyCouponTabs />
            <CouponPagination />
            <AddCouponModal visible={addCouponModal} switchAddCouponModal={switchAddCouponModal} />
        </UserCenterMain>
    );
}

export default inject(() => {
    return {
        ...mapViewModelToProps("myCouponViewModel")
    };
})(MyCoupon);
