var React=window.Snowball._React;

function Radio({ selected, className, ...otherProps }) {
    return (
        <div
            className={`cm_radio dp_ib mr_s ${selected ? 'selected' : ''} ${className ? className : ''}`}
            {...otherProps}
        ></div>
    );
}

export default Radio;
