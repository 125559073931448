var { observable }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
var { toast }=window.Snowball._widget;
import PromcenterApiService from "../../../shared/apis/PromcenterApiService";
import { formatCoupon } from "./CouponViewModel";

export default class RedPacketViewModel extends ViewModel {
    @observable
    redPackets = [];

    @autowired
    _promcenterApiService: PromcenterApiService;

    async loadRedPackets(spuIds) {
        this.spuIds = spuIds;
        const result = await this._promcenterApiService.queryAvailableAndReceivedSpecs(spuIds, 4);
        const redPackets = result.data ? result.data.specs || [] : [];
        this.redPackets = redPackets.filter(item => item.type == 1)
            .concat(redPackets.filter(item => item.type == 2))
            .map(formatCoupon);
    }

    @emitter
    onReceiveRedPacket(specId) {
        this.receiveRedPacket(specId);
    }

    async receiveRedPacket(specId) {
        const res = await this._promcenterApiService.receiveCouponBySpecId(specId);
        if (res.success) {
            toast.showToast('领取成功');
        } else {
            toast.showToast(res.message || '领取失败');
        }
        this.loadRedPackets(this.spuIds);
    }
}