var { controller, autowired }=window.Snowball._app;
import codeUtil from '../../../shared/utils/codeUtil';
import OrderCreate from "../containers/OrderCreate";
import { OrderCreateConfiguration } from "../configuration";
import { parseOrderJSONParams } from '../../../shared/utils/parseOrderParams';

@controller({
    component: OrderCreate,
    configuration: [OrderCreateConfiguration]
})
export default class OrderCreateController {

    @autowired
    _orderCreateViewModel;

    onInit() {
        const { location } = this.ctx;
        const from = location.query.from || '0';
        const jkCode = codeUtil.get(codeUtil.CODES.JK_CODE);
        const tcode = codeUtil.get(codeUtil.CODES.T_CODE);
        const useFlowLimited = Number(location.query.useFlowLimited) || 0;

        this.ctx.logger.makeLog('H5web_pc_order_create', 'onload');
        this._orderCreateViewModel.load({
            urlParams: parseOrderJSONParams(location.query.json_params),
            from,
            jkCode,
            tcode,
            useFlowLimited
        });
    }
}