var React=window.Snowball._React;
import { forward } from '../../../core/navigation';
import SLOGAN_IMAGE from '../../../sass/images/logo.png';

const Slogan = (props) => {
    return (
        <div
            className="mall_common__header__slogan"
            onClick={() => { forward('/'); }}
        >
            <img src={SLOGAN_IMAGE} alt="平安健康APP" />
        </div>
    );
};

export { SLOGAN_IMAGE };

export default Slogan;