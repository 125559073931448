import ModuleBase from "../base/ModuleBase";

export default class FloorTopic extends ModuleBase {
    patchData() {
        const { model, bizData } = this;

        model.set({
            isShow: true,
            bizData: {
                ...bizData,
                backgroundImgSrc: this.context.app.tfs.transformTFSImageUrl(bizData.backgroundImgSrc)
            }
        }).nextTick(() => {
        });
    }
}