var { util }=window.Snowball;

const codeUtil = {
    CODES: {
        JK_CODE: 'jkCode',
        T_CODE: 'tcode',
    },
    get(code) {
        return util.queryString(code) || '';
    },
};

export default codeUtil;
