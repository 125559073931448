var React=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
import PatientForm from "./PatientForm";
var { Modal }=window.Snowball._components;
import PatientList from "./PatientList";
function PatientModal({
    list,
    status,
    save,
    cancel
}) {
    const { visible } = status;
    const isEmpty = list.length == 0;
    return (
        <Modal visible={visible} className="patient-modal">
            <div className="modal_head">
                <p className="fl_l">就诊人信息</p>
                <span>请如实填写真实个人身份信息</span>
                <i
                    className="iconfont icon-close cl_999 fs_l fl_r"
                    onClick={cancel}
                />
            </div>
            <div className="patient-main">
                <PatientList />
                <PatientForm />
            </div>
            <div className="modal_bottom">
                <button className="confirm" onClick={save}>{isEmpty ? '保存并使用' : '确定'}</button>
                <button className="cancel" onClick={cancel}>取消</button>
            </div>
        </Modal>
    );
};
export default inject(mapViewModelToProps('patientViewModel'))(PatientModal);