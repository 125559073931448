var React=window.Snowball._React;var { useState }=window.Snowball._React;
import UsedImg from '../images/my-coupon-used.png';
import ExpiredImg from '../images/my-coupon-expired.png';
import useClickOutside from '@/shared/hooks/useClickOutside';
import NewImg from '../images/my-coupon-new.png';
// import ExpiredImg from '../images/my-coupon-expired.png';

function Coupon({ coupon, type = 'NOT_USED', formatCouponDate }) {
    const [detailmodal, changeDetailModal] = useState(false);
    const _ref = useClickOutside(() => changeDetailModal(false));
    const {
        price,
        ruleDescription,
        name,
        startTimeNum,
        endTimeNum,
        remainDays,
        dateDesc,
        isNewCoupon,
        specInstType,
        detailInfo
    } = coupon;
    const isRedPacket = specInstType === 3 || specInstType === 4;
    const notUsed = type === 'NOT_USED';
    const showRuleTag = notUsed && remainDays < 6;
    return (
        <div className={`coupon_container ps_r ${notUsed ? 'notused' : ''}`}>
            <div
                className={`used_and_expired_coupon_container ${
                    notUsed ? 'notused' : ''
                }`}
            >
                <div className="top">
                    <p className="price">￥<span className="priceNo">{price / 100}</span></p>
                    <p className="threshold to_e" title={ruleDescription}>{showRuleTag ? <span className='ruleTag'>{remainDays > 1 ? `仅剩${remainDays}天` : "今天到期"}</span> : null}{ruleDescription}</p>
                </div>
                <div className="dash_line" />
                <div className="tag_title">
                    <span className={`tag ${isRedPacket ? 'redpack' : ''}`}>
                        {isRedPacket ? '红包' : '优惠券'}
                    </span>
                    <span className="title">{name}</span>
                </div>
                <p className="valid_date">
                    有效期:
                    {formatCouponDate({
                        startDate: startTimeNum,
                        endDate: endTimeNum,
                        remainDays: remainDays,
                        dateDesc: dateDesc
                    })}
                </p>
                <p
                    className="use_instruction"
                    onClick={() => changeDetailModal(!detailmodal)}
                >
                    使用说明:
                    <span className="detail dp_ib to_e">{detailInfo}</span>
                    <span className="help" ref={_ref}>
                        i
                    </span>
                </p>
                {!notUsed ? (
                    <img
                        className="used_or_expired_img"
                        src={type === 'USED' ? UsedImg : ExpiredImg}
                        alt=""
                    />
                ) : (
                    <div className="activityBtn ta_c" app-link={`/`}>立即使用</div>
                )}
            </div>
            {isNewCoupon && notUsed ? <img
                className="new_img ps_a"
                src={NewImg}
                alt=""
            /> : null}
            <span className="left_arc_line"></span>
            <span className="right_arc_line"></span>
            <div className={`detailModal ps_a ${detailmodal ? '' : 'dp_n'}`}>
                <div className='to_e3'>{detailInfo}</div>
                <div className="triangle ps_a"></div>
            </div>
        </div>
    );
}

export default Coupon;
