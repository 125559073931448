var { controller, autowired }=window.Snowball._app;
import PersonInfo from '../containers/PersonInfo';
import { PersonInfoConfiguration } from '../configuration';

@controller({
    component: PersonInfo,
    configuration: PersonInfoConfiguration
})
export default class PersonInfoController {
    @autowired
    _personInfoViewModel;

    onInit() {
        this.ctx.logger.makeLog('H5web_pc_person_info', 'onload');
        this._personInfoViewModel.getUserInfo();
    }
}