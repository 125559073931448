var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import TFSImage from "../../../shared/components/TFSImage";

function DrugProcess({ image }) {
    if (!image) {
        return null;
    }

    return (
        <div className="it_drug_process">
            <h4 className="hd">药品需求处理流程</h4>
            <TFSImage
                className="mt_m"
                src={image}
            />
        </div>
    );
}

export default inject(() => {
    const itemViewModel = autowired('itemViewModel');
    let { drugProcess } = itemViewModel.itemConfigs;
    let { purchaseProcessSpec } = itemViewModel.pres;

    return {
        image: drugProcess || purchaseProcessSpec,
    };
})(DrugProcess);
