import "../styles/item.scss";
var React=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
import Pictures from "../components/Pictures";
import ItemBase from "../components/ItemBase";
import RichTextDetail from "../components/RichTextDetail";
import Announcement from "../components/Announcement";
import DrugProcess from "../components/DrugProcess";
import DetailTab from "../components/DetailTab";
import ItemSpecs from "../components/ItemSpecs";
import Instructions from "../components/Instructions";
// import { CommentList } from "../components/comments";
import PriceDescription from "../components/PriceDescription";
import { Header, Menu, Footer } from "jkmall-frame";

function Item({
    item,
    seller,
    fund,
    pres,
    control,
    goCustomService
}) {
    return (
        <>
            <Header />
            <Menu />
            <div className="app-wrapper-fff">
                <div className="app-breadcrumbs it_breadcrumbs">
                    <a className="app-breadcrumbs-item" href="/">首页</a>
                    <span className="app-breadcrumbs-arrow"></span>
                    <p className="current to_e">{item.detailTitle}</p>
                    <a className="customerService fl_r" onClick={goCustomService}>
                        <i className="iconfont icon-service"></i>
                        <em>咨询客服</em>
                    </a>
                    <p className="fl_r mr_xl">{seller.name}</p>
                </div>
                <div className="app-main it_base_wrap clearfix">
                    <Pictures
                        className="fl_l"
                    />
                    <ItemBase />
                </div>
                <div className="app-main">
                    <Announcement />
                    <DrugProcess />
                    <DetailTab>
                        <div className="it_details_main">
                            <ItemSpecs />
                            <Instructions />
                        </div>
                        <RichTextDetail details={item.details} />
                        <PriceDescription />
                        {/* <CommentList /> */}
                    </DetailTab>
                </div>
            </div>
            <Footer type="grey"></Footer>
        </>
    );
}

export default inject(mapViewModelToProps('itemViewModel'))(Item);
