var React=window.Snowball._React;

const CustomContainer = React.memo(() => {
    return (
        <div
            class="mall_common__header__custom"
            id="mall_common__header__custom"
        ></div>
    );
}, () => {
    return false;
});

CustomContainer.getId = () => {
    return '#mall_common__header__custom';
};

export default CustomContainer;