var { util, observable }=window.Snowball;
var { Service, autowired }=window.Snowball._app;
import * as GIS from '../../utils/gis';

import UserService from './UserService';
import AddressApiService from '../apis/AddressApiService';

type TAddress = {
    // 地址id, 大于0代表修改,否则新增
    id: number,
    // 用户id, 必填
    userId: number,
    // 业务场景,预分配, 必填
    bizType: string,
    // 使用人id
    personId: number,
    // 业务场景细分,各业务自定义, 选填
    bizSubType: string,
    // 省级行政区名称
    province: string,
    // 省级行政区编码
    provinceCode: string,
    // 市级行政区名称
    city: string,
    // 市级行政区编码
    cityCode: string,
    // 区县级行政区名称
    district: string,
    // 区县级行政区编码
    districtCode: string,
    // 详细街道地址
    streetAddress: string,
    // 收件人姓名
    recipientName: string,
    // 收件人号码
    recipientPhone: string,
    // 固定电话
    telephone: string,
    // 收件人邮政编码
    zipCode: string,
    // 是否默认收货地址
    preferred: boolean,
    // 经度
    longitude: string,
    // 纬度
    latitude: string,
    // 参考地址
    referAddr: string,
    // 地址标签
    addressLabel: string
};

export default class GlobalAddressService extends Service {

    @observable
    address = {};

    @autowired
    _userService: UserService;

    @autowired
    _addressApiService: AddressApiService;

    async fetch(): Promise<TAddress> {
        var globalStore = util.store('store');
        var globalAddress = util.get(globalStore, [
            'yao_address',
            'yao_o2oAddress',
            0
        ]);
        var defaultAddress = {
            latitude: 31.189135861272053,
            longitude: 121.46327199999992,
            id: 0,
            province: '上海市',
            provinceCode: '310000',
            city: '上海市',
            cityCode: '310100',
            district: '徐汇区',
            districtCode: '310104',
            streetAddress: '凯滨路166号',
            outerType: 'default'
        };

        // 全局地址中如果不含经纬度，当做空处理
        if (globalAddress && (!globalAddress.longitude || !globalAddress.latitude)) {
            globalAddress = null;
        }

        // 如果全局地址内存储了用户id,判断和当前用户的id是否一致，
        if (globalAddress && globalAddress.userId) {
            const { _userService } = this;
            const isLogin = await _userService.isLogin();
            if (!isLogin || _userService.getUserId() != globalAddress.userId) {
                globalAddress = null;
            }
        }
        // 有全局地址，无省市code，附加省市code
        if (
            globalAddress &&
            (!globalAddress.provinceCode || !globalAddress.cityCode)
        ) {
            const res = await this._addressApiService.estimateAddress({
                latitude: globalAddress.latitude,
                longitude: globalAddress.longitude
            });
            if (res.success && res.data.provinceCode && res.data.cityCode && res.data.districtCode) {
                globalAddress = {
                    ...globalAddress,
                    provinceCode: res.data.provinceCode,
                    cityCode: res.data.cityCode,
                    districtCode: res.data.districtCode
                };
            } else {
                globalAddress = defaultAddress;
            }
            this.store(globalAddress);
        }

        // 如果无全局地址、或全局地址为自动定位地址，则重新获取用户当前定位地址
        if (!globalAddress || globalAddress.outerType) {
            var result;
            let seconds = 1000;
            try {
                var loc = await Promise.race([
                    // 一秒后超时
                    new Promise((resolve, reject) => setTimeout(reject, seconds)),
                    GIS.getLocation()
                ]);
                if (loc && loc.latitude && loc.longitude) {
                    // 如果本次与上次定位的地址变化不超过100平方米则不更换全局地址
                    // 防止频繁请求
                    if (
                        verifyGlobalAddress(globalAddress) &&
                        near(globalAddress, loc)
                    ) {
                        result = globalAddress;
                    } else {
                        var res = await this._addressApiService.estimateAddress(loc);
                        if (res.success) {
                            result = {
                                ...res.data,
                                ...loc,
                                outerType: 'native'
                            };
                        }
                    }
                }
            } catch (e) { }

            // 若获取定位地址失败则使用用户默认地址
            if (!result) {
                var userDefaultAddress = await this._addressApiService.getDefaultAddressBiz('mall');
                if (userDefaultAddress.success && userDefaultAddress.data.id) {
                    result = userDefaultAddress.data;
                }
            }
            // 用户无默认地址，取平安大厦兜底地址
            if (!result) {
                result = defaultAddress;
            }
            // 默认地址无经纬度则取根据详细地址查出经纬度
            if (!result.longitude || !result.latitude) {
                var lgRes = await this._addressApiService.resolveAddress(
                    result.city,
                    (result.province || '') +
                    (result.city || '') +
                    (result.district || '') +
                    (result.streetAddress || ''),
                    'bd09ll'
                );
                if (lgRes.success) {
                    const { lat, lng } = lgRes.data;
                    result = {
                        ...result,
                        longitude: lng,
                        latitude: lat
                    };
                } else {
                    result = defaultAddress;
                }
            }
            globalAddress = result;
            this.store(globalAddress);
        }
        this.address = globalAddress = locationMixin(globalAddress);
        return globalAddress;
    }

    update(address: TAddress) {
        this.address = address = locationMixin(address);
        this.store(address);
    }

    store(address: TAddress) {
        var globalStore = util.store('store');
        if (!globalStore) globalStore = {};
        if (!globalStore.yao_address) globalStore['yao_address'] = {};
        if (!globalStore.yao_address['yao_o2oAddress']) {
            globalStore.yao_address['yao_o2oAddress'] = [];
        }
        globalStore.yao_address.yao_o2oAddress[0] = address.fullAddress
            ? address
            : locationMixin(address);
        util.store('store', globalStore);
    }
}

function locationMixin(address: TAddress) {
    return {
        district: address.area,
        ...address,
        location: address.streetAddress || address.city || '',
        fullAddress:
            (address.province == address.city ? '' : address.province || '') +
            (address.city || '') +
            (address.district || address.area || '') +
            (address.streetAddress || '') +
            (address.referAddr || '')
    };
}

function near(a, b) {
    return (
        inHundredMeter(a.latitude, a.latitude) &&
        inHundredMeter(b.longitude, b.longitude)
    );
}

function inHundredMeter(a, b) {
    return Math.abs(a - b) < 0.0009;
}

function verifyGlobalAddress(address) {
    return !!(address && address.cityCode && address.provinceCode);
}
