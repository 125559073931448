import ModuleBase from "../base/ModuleBase";

export default class Images extends ModuleBase {
    patchData() {
        const { model, bizData } = this;

        model.set({
            isShow: bizData.isShow !== false &&
                bizData.images &&
                bizData.images.length != 0,
            bizData: {
                ...bizData,
                images: bizData.images && bizData.images.map((img) => {
                    return {
                        ...img,
                        src: this.context.app.tfs.transformTFSImageUrl(img.src)
                    };
                })
            },
        })
            .nextTick(() => {
            });
    }
}