import { isString } from './is';
import { castPath } from './castPath';

export function get(data, path) {
    if (isString(path)) {
        path = castPath(path);
    }

    for (var i = 0, len = path.length; i < len; i++) {
        if (data == null)
            return undefined;
        data = data[path[i]];
    }

    return data;
}