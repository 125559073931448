export const TAB_TPYE = {
    PAY_PLATFORM: 1,
    HEALTH_CARD: 2,
};

// 二维码过期时间
export const QRCODE_OVERDUE = 120 * 1000;

export const QR_CODE_STATUS = {
    NORMAL: 1,
    OVERDUE: 2,
    LOADING: 3,
};

export const PAY_METHOD_KEY = {
    WECHAT_PAY: 1,
    ALI_PAY: 2,
    HEALTH_CARD: 3,
    MOCK: 4,
};