var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import Pagination from '../../../shared/components/Pagination';
import UserCenterMain from '../../../shared/components/UserCenterMain';
import UserCenterTitle from '../../../shared/components/UserCenterTitle';
import RefundOrderTable from '../components/RefundOrderTable';
import '../styles/MyAfterSale.scss';

const MyAfterSale = inject(() => {
    const myAfterSaleViewModel = autowired('myAfterSaleViewModel');

    return {
        orders: myAfterSaleViewModel.orders,
        pageNo: myAfterSaleViewModel.pageNo,
        pageSize: myAfterSaleViewModel.pageSize,
        total: myAfterSaleViewModel.total,
        onPageChange: myAfterSaleViewModel.onPageChange
    };
})((props) => {
    const {
        orders,
        total,
        pageNo,
        pageSize,
        onPageChange
    } = props;

    return (
        <UserCenterMain name="aftersale">
            <UserCenterTitle
                title={'我的售后'}
            />
            <div className="as__my_after_sale">
                <RefundOrderTable orders={orders} />
                <div className="clearfix">
                    <Pagination
                        total={total}
                        pageSize={pageSize}
                        current={pageNo}
                        onChange={(pageNo) => {
                            onPageChange(pageNo);
                        }}
                        showTotal={(count, total) => (
                            <div>
                                共: {count} 条，{total}页
                            </div>
                        )}
                    />
                </div>
            </div>
        </UserCenterMain>
    );
});

export default MyAfterSale;