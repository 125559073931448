var { app }=window.Snowball._app;
var { loadJs, cookie, $, store }=window.Snowball.util;
var { util }=window.Snowball;
import { md5 } from "../utils/md5";
import { base64 } from "../utils/base64";

export class Logger {
    constructor(ctx) {
        this.ctx = ctx;
        this.app = ctx.app;
    }

    init() {
        if (this._initializer) return this._initializer;
        return this._initializer = this._init();
    }

    async _init() {
        if (!window.__windowLoaded) {
            await new Promise(resolve => {
                $(window).one("load", () => {
                    resolve();
                });
            });
        }

        await loadJs(app.env.BEACON);

        let beaconScript;
        const scripts = document.getElementsByTagName("script");
        for (let i = 0, len = scripts.length; i < len; i++) {
            if (/\/beacon-/.test(scripts[i].src)) {
                beaconScript = scripts[i];
                break;
            }
        }

        if (beaconScript) {
            await new Promise(fullfil => {
                const dispose = () => beaconScript.removeEventListener("load", onBeaconScriptLoad);
                const timer = setTimeout(() => {
                    fullfil();
                    dispose();
                }, 500);
                function onBeaconScriptLoad() {
                    clearTimeout(timer);
                    fullfil();
                    dispose();
                }
                beaconScript.addEventListener("load", onBeaconScriptLoad);
            });
        }

        const hasDtk = () => !!cookie("_dtk4399com");
        if (!hasDtk()) {
            const event = document.createEvent("HTMLEvents");
            event.initEvent("load", true, true);
            window.dispatchEvent(event);

            await new Promise(fullfil => {
                let times = 0;
                const isRegisterDevice = () => {
                    times++;
                    if (hasDtk() || times > 20) {
                        fullfil();
                    } else {
                        requestAnimationFrame(isRegisterDevice);
                    }
                };
                requestAnimationFrame(isRegisterDevice);
            });
        }
    }

    /**
     * 老打点，不推荐使用，尽量使用spm打点
     * conf地址:
     * http://doc.pajk-ent.com/pages/viewpage.action?pageId=19663959
     * 自动化打点:
     * http://doc.pajk-ent.com/pages/viewpage.action?pageId=47715163
     *
     * @param {String} pageId 页面Id
     * @param {String} eventName 事件名
     * @param {Object} eventParams 事件参数
     * @param {String} eventParams.source 页面来源
     * @param {Object} [extData] 扩展数据
     */
    async makeLog(pageId, eventName, eventParams, extData?) {
        if (process.env.NODE_ENV === "test") {
            return;
        }

        const { page } = this.ctx;
        if (page && page.isDestroyed()) {
            return;
        }

        const userService = app.autowired('userService');

        await Promise.all([this.init(), userService.isLogin()]);

        return new Promise(resolve => {
            var tryTimes = 3;
            function log() {
                if (window.report && window.report.logger) {
                    try {
                        const { businessData, ...more } = extData || {};
                        const logData = {
                            page_id: pageId,
                            evt: eventName,
                            event_type: {
                                channel: app.env.APP_ENV,
                                bizchannel: app.env.OUT_BIZ_TYPE,
                                outBizInfo: util.qs(location.search).outBizInfo || "",
                                ...eventParams
                            },
                            url: page.location.url,
                            refer: page.location.referrer,
                            user_id: userService.getUserId(),
                            channel: app.env.API_CHANNAL,
                            businessData: {
                                wap_userid: getUUID(),
                                ...businessData
                            },
                            ...more
                        };
                        window.report.logger.makelog(logData);
                    } catch (e) {
                        console.error("打点出错: " + e.message);
                    }
                    resolve();
                } else if (tryTimes-- > 0) {
                    setTimeout(log, 1000);
                }
            }
            log();
        });
    }

    /**
     * SPM打点: http://doc.pajk-ent.com/pages/viewpage.action?pageId=55900572
     * @param {*} data 打点数据
     * @param {*} data.spm spm信息
     * @param {*} data.spmCnt 坑位内容信息
     * @param {*} [eventType] 手动打点event类型: 'load/touch'
     */
    async log(data, eventType?) {
        if (process.env.NODE_ENV === "test") {
            return;
        }

        await this.init();

        return new Promise(resolve => {
            var tryTimes = 3;
            function log() {
                if (window.report && window.report.logger) {
                    try {
                        let spmStr = "";
                        if (typeof data.spm == "string") {
                            spmStr = `${data.spm}.${Date.now()}`;
                        } else {
                            spmStr = `${data.spm.A || ""}.${data.spm.B || ""}.${data.spm.C || ""}${data.spm.C1 ? "-" + data.spm.C1 : ""}${data.spm.C2 ? "-" + data.spm.C2 : ""}.${Date.now()}`;
                        }
                        eventType
                            ? window.report.logger.spmLog(
                                {
                                    spm: spmStr,
                                    spmCnt: JSON.stringify({
                                        pageType: "H5",
                                        channel: app.env.API_CHANNAL,
                                        outBizType: app.env.OUT_BIZ_TYPE,
                                        ...data.spmCnt
                                    }),
                                    eventType
                                },
                                "act"
                            )
                            : window.report.logger.spmLog(data);
                    } catch (e) {
                        console.error("打点出错: " + e.message);
                    }
                    resolve();
                } else if (tryTimes-- > 0) {
                    setTimeout(log, 1000);
                }
            }
            log();
        });
    }

    error(msg, line?, col?, stack?) {
        return error(msg, this.ctx.location.url, line, col, stack);
    }

    assert(condition, message) {
        return assert(condition, message, this.ctx.location.url);
    }

    getPreSpm() {
        return (window.pajkSpmCore && window.pajkSpmCore.preSpm()) || this.getSpm();
    }

    getSpm() {
        return (
            (window.pajkSpmCore && window.pajkSpmCore.lastSpm()) ||
            (store("MALL_CURRENT_SPM") || {}).spm ||
            ""
        );
    }
}

document.body.addEventListener(
    "click",
    e => {
        let node = e.target;
        while (node && node != document.body) {
            let spm = node.getAttribute("data-spm-data");
            if (spm) {
                const timestamp = Date.now();
                spm += timestamp + randomStr();
                store("MALL_CURRENT_SPM", {
                    spm,
                    timestamp
                });
                break;
            }
            node = node.parentNode;
        }
    },
    true
);

function randomStr(length = 7) {
    let char = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return Array.from(Array(length))
        .map(t => char[parseInt(Math.random() * char.length, 10)])
        .join("");
};

var reportURL;
if (location.host.indexOf("dev.pajkdc.com") !== -1) {
    // 开发环境
    reportURL = "https://srv.dev.pajk.cn";
} else if (
    location.host.indexOf("localhost") !== -1 ||
    location.host.indexOf("test.pajkdc.com") !== -1 ||
    location.host.indexOf("test.pajk.cn") !== -1
) {
    // 测试环境
    reportURL = "https://srv.test.pajk.cn";
} else {
    // 正式线上环境
    reportURL = "https://srv.jk.cn";
}
const batchReportURL = reportURL + "/log-collector/log/batch/put";

function getLogTime() {
    function pad(number) {
        if (number < 10) {
            return "0" + number;
        }
        return number;
    }
    var t = new Date();
    return (
        t.getUTCFullYear() +
        "-" +
        pad(t.getMonth() + 1) +
        "-" +
        pad(t.getDate()) +
        " " +
        pad(t.getHours()) +
        ":" +
        pad(t.getMinutes()) +
        ":" +
        pad(t.getSeconds()) +
        "." +
        (t.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
        ""
    );
}

function serialize(obj, prefix) {
    var str = [];
    for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + '[" + p + "]' : p,
                v = obj[p];
            str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

export function error(msg, url?, line?, col?, stack?, cnslerr = true) {
    var data = {};
    data.logMessage = msg || "";
    data.errorUrl = url || location.href;
    data.errorLine = line || 0;
    data.errorCol = col || 0;
    data.type = "fault";

    const userService = app.autowired('userService');

    data.errorStack = encodeURI(
        [app.env.APP_ENV + " " + app.env.OUT_BIZ_TYPE, userService.getUserId()]
            .concat(JSON.stringify(app.paths))
            .concat(stack || '')
            .join("\n")
    );
    data.logTime = getLogTime();
    data.hash = location.hash;

    data.userAgent = navigator.userAgent;
    data.v = "0.0.1";
    data.appName = "jkmall";

    const errors = store("MALL_ERROR_STORAGE") || [];
    errors.push(data);

    store("MALL_ERROR_STORAGE", errors);

    const params = encodeURI(base64(JSON.stringify(errors)));
    const xhr = new XMLHttpRequest();

    xhr.open("POST", batchReportURL, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    xhr.send(
        serialize({
            data: params
        })
    );
    xhr.onload = () => {
        store(
            "MALL_ERROR_STORAGE",
            (store("MALL_ERROR_STORAGE") || []).filter(err => {
                return errors.findIndex(item => item.logTime == err.logTime) === -1;
            })
        );
    };

    cnslerr && console.error("log error:", data);
}

export function assert(condition, message, url?) {
    if (!condition) {
        error(message, url);
    }
}

function getUUID() {
    var uuid = localStorage.getItem("WAP_USERID");
    if (!uuid) {
        uuid = util.uuid();
        uuid = md5(uuid);
        localStorage.setItem("WAP_USERID", uuid);
    }
    return uuid;
}

export function startErrorReporter() {
    window.addEventListener('error', (e) => {
        error(e.message, location.href, e.lineno, e.colno, e.error && e.error.stack, false);
    });
}