var React=window.Snowball._React;
import Category, { CategoryPlaceholder } from '../category';
import { forward } from '../../core/navigation';
import useAppConfig from '../../shared/hooks/useAppConfig';

const Menu = ({ categoryVisible = true }) => {
    const { categoryId, menus = [] } = useAppConfig();

    return (
        <div className="mall_common__menu">
            <div className="mall_common__menu_container">
                {
                    categoryVisible && categoryId
                        ? <Category categoryId={categoryId} />
                        : <CategoryPlaceholder />
                }
                <ul className="mall_common__menu_list">
                    { menus.map((menu) => {
                        return (
                            <li
                                className={menu.important ? 'mall_common__menu_list_item_important' : ''}
                                onClick={() => { forward(menu.link); }}
                            >{menu.title}</li>
                        );
                    }) }
                </ul>
            </div>
        </div>
    );
};

export default Menu;