var { Service }=window.Snowball._app;

interface IUserAddress {
    // 地址id
    id: number;
    // 业务场景,预分配
    bizType: string;
    // 业务场景细分,各业务自定义
    bizSubType: string;
    // 用户id(非普通用户下单 请传入要
    userId: string;
    // 省级行政区名称
    province: string;
    // 省级行政区编码
    provinceCode: string;
    // 市级行政区名称
    city: string;
    // 市级行政区编码
    cityCode: string;
    // 区县级行政区名称
    district: string;
    // 区县级行政区编码
    districtCode: string;
    // 详细街道地址
    streetAddress: string;
    // 收件人姓名
    recipientName: string;
    // 收件人号码
    recipientPhone: string;
    // 固定电话
    telephone: string;
    // 收件人邮政编码
    zipCode: string;
    // 是否默认收货地址
    preferred: string;
    // 经度
    longitude: string;
    // 维度
    latitude: string;
    // 参考地址
    referAddr: string;
    // 自定义字段0
    custom0: string;
    // 自定义字段1
    custom1: string;
    // 自定义字段2
    custom2: string;
    // 自定义字段3
    custom3: string;
}

export default class AddressApiService extends Service {

    request(mt, needLogin, data, options) {
        return this.app.gateway.request(mt, needLogin, data, options)
            .then(res => {
                const errorMap = {
                    42000000: '获取所有省级行政区信息出错',
                    42000001: '通过省级行政区编码获取所有市级行政区信息出错',
                    42000002: '通过市级行政区编码获取所有区县级行政区信息出错',
                    42000003: '获取我的收货地址信息出错',
                    42000006: '更新我的收货地址信息出错',
                    42000007: '新增我的收货地址信息出错',
                    42000008: '删除我的收货地址信息出错',
                    42000009: '经纬度数据有误,无法根据经纬度获取所区县编码',
                    42000010: '经纬度数据有误,无法根据经纬度获取所区县编码',
                    42000011: '新增我的地址缺少参数',
                    42000012: '新增地址超过上限',
                    42000013: '行政编码转码失败',
                    42000014: '地址中存在非法字符',
                    42000015: '无法获取省市区',
                    42000016: '固话格式无效',
                    42000017: '手机号码格式无效'
                };
                if (!res.success && errorMap[res.code]) {
                    res.message = errorMap[res.code]; ;
                }
                return res;
            });
    }

    getAllProvinces() {
        return this.request('address.getAllProvinces', false, null, {
            isShowLoading: true,
            autoLogin: false
        });
    }

    getAllCitiesByProvinceCode(provinceCode: string) {
        return this.request(
            'address.getAllCitiesByProvinceCode',
            false,
            { provinceCode },
            {
                isShowLoading: true,
                autoLogin: false
            }
        );
    }

    getAllDistrictsByCityCode(cityCode: string) {
        return this.request(
            'address.getAllDistrictsByCityCode',
            false,
            { cityCode },
            {
                isShowLoading: true,
                autoLogin: false
            }
        );
    }

    /**
     * 获取当前用户的所有地址
     *
     * @export
     * @param {string} bizType
     * @returns
     */
    getAllAddressesByBiz(bizType: string, autoLogin: boolean = true) {
        return this.request(
            'address.getAllAddressesByBizV2',
            true,
            { bizType },
            {
                isShowLoading: true,
                autoLogin
            }
        );
    }

    /**
     * 保存或更新地址
     *
     * @export
     * @param {IUserAddress} userAddressDTO
     * @returns
     */
    saveOrUpdateAddress(address: IUserAddress) {
        const IGNORE_PARAMETERS = ['longitude', 'latitude', 'userId', 'preferred', 'id'];
        const myAddressReq = {
            ...address
        };
        for (let param of IGNORE_PARAMETERS) {
            if (param in myAddressReq && myAddressReq[param] == null) {
                delete myAddressReq[param];
            }
        }

        if (myAddressReq.latitude && myAddressReq.longitude) {
            myAddressReq.bizSubType = 'o2o';
        } else {
            myAddressReq.bizSubType = 'b2c';
        }
        myAddressReq.bizType = 'mall';

        return this.request(
            'address.saveOrUpdateAddressV2',
            true,
            { myAddressReq },
            {
                isShowLoading: true,
                autoLogin: true
            }
        );
    }

    /**
     * 根据地址ID删除
     *
     * @export
     * @param {number} addressId
     * @returns
     */
    deleteAddressById(id: number) {
        return this.request(
            'address.deleteAddressByIdV2',
            true,
            { id },
            {
                isShowLoading: true,
                autoLogin: true
            }
        );
    }

    /**
     * 根据经纬度获取省市区
     *
     * @export
     * @param {number} id
     * @returns
     */
    estimate(latitude: number, longitude: number) {
        return this.request(
            'address.estimate',
            false,
            { latitude, longitude },
            {
                isShowLoading: true,
                autoLogin: false
            }
        );
    }

    estimateAddress({ latitude, longitude }) {
        return this.request(
            'address.estimate',
            false,
            {
                latitude,
                longitude
            },
            {
                isShowLoading: false,
                autoLogin: false,
                showErrorToast: false
            }
        );
    }

    /**
     *
     * @param {string} city 城市名
     * @param {string} address 详细地址
     * @param {‘wgs84ll’|‘gcj02ll’|‘bd09ll’} coordType 坐标类型
     */
    resolve(
        city: string,
        address: string,
        coordType: 'wgs84ll' | 'gcj02ll' | 'bd09ll' = 'bd09ll'
    ) {
        return this.resolveAddress(
            city,
            address,
            coordType
        );
    }

    /**
     *
     * @param {string} city 城市名
     * @param {string} address 省市区详细地址
     * @param {‘wgs84ll’|‘gcj02ll’|‘bd09ll’} coordType 坐标类型
     */
    resolveAddress(
        city: string,
        address: string,
        coordType: 'wgs84ll' | 'gcj02ll' | 'bd09ll' = 'bd09ll'
    ) {
        return this.request(
            'address.resolve',
            false,
            {
                geocoderAddressVO: {
                    city,
                    address
                },
                coordType
            },
            {
                isShowLoading: true,
                autoLogin: false
            }
        );
    }

    getDefaultAddressBiz(type) {
        return this.request(
            'address.getDefaultAddressBiz',
            true,
            {
                bizType: type
            },
            {
                isShowLoading: false,
                autoLogin: false,
                showErrorToast: false
            }
        );
    }
}