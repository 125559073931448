var { configuration }=window.Snowball._app;

import AddressViewModel from './view-models/AddressViewModel';
import AddressFormViewModel from './view-models/AddressFormViewModel';
import AddressEditorModalViewModel from './view-models/AddressEditorModalViewModel';

export const AddressConfiguration = configuration({
    modules: {
        addressViewModel: AddressViewModel,
        addressFormViewModel: AddressFormViewModel,
        addressEditorModalViewModel: AddressEditorModalViewModel
    }
});