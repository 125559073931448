var React=window.Snowball._React;
var { isNumber }=window.Snowball.util;

export default function DiscountList({
    fullDiscountRules,
    fullCutRules,
    onClose
}) {
    return (
        <div className="it_discount_list">
            <div className="it_discount_list_title">
                满减
                <button
                    className="ps_a it_discount_list_close"
                    onClick={onClose}
                >收起</button>
            </div>
            {
                fullDiscountRules.map((fullDiscountRule, index) => {
                    const { fullDiscounts, description } = fullDiscountRule;
                    return <div className="it_discount_item" key={index}>
                        <div className="discountTitle">
                            {
                                Array.isArray(fullDiscounts)
                                    ? fullDiscounts.map((fullDiscount) => getFullDiscount(fullDiscount)).join('、')
                                    : null
                            }
                        </div>
                        {description ? <div className="discountDesc">{description}</div> : null}
                    </div>;
                })
            }
            {
                fullCutRules.map((fullCutRule, index) => {
                    const { fullCuts, description } = fullCutRule;
                    return (
                        <div className="it_discount_item" key={index}>
                            <div className="discountTitle">
                                {
                                    Array.isArray(fullCuts)
                                        ? fullCuts.map((fullCut) => getFullCutBreif(fullCut)).join('、')
                                        : null
                                }
                            </div>
                            {description ? <div className="discountDesc fs_s cl_666 mt_s">{description}</div> : null}
                        </div>
                    );
                })
            }
        </div>
    );
}

const getFullDiscount = ({ amountThreshold, discount }) => {
    return `满${amountThreshold}件打${discount / 10}折`;
};

const getFullCutBreif = ({ priceThreshold, cut }) => {
    return priceThreshold > 0
        ? `满${convertCentToYuan(priceThreshold)}元减${convertCentToYuan(cut)}元`
        : `立减${convertCentToYuan(cut)}元`;
};

const convertCentToYuan = (price: number): number => isNumber(price) ? price / 100 : 0;
