var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;

function TopTips({ text, visible, onClose }) {
    return visible
        ? (
            <div className="oi_toptips dp_f ps_r">
                <i className="iconfont icon-tip"></i>
                <div className="fx_1 con pl_s">
                    {text}
                </div>
                <div
                    onClick={onClose}
                    className="iconfont icon-close"
                ></div>
            </div>
        )
        : null;
}

export default inject(() => {
    const orderInfoViewModel = autowired('orderInfoViewModel');
    return {
        text: orderInfoViewModel.topTips,
        visible: orderInfoViewModel.isTopTipsVisible,
        onClose: orderInfoViewModel.onCloseTopTips.emit,
    };
})(TopTips);