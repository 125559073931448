var React=window.Snowball._React;
var { autowired, inject }=window.Snowball._app;
import PayMethod from './PayMethod';
import QrCodePanel from './QrCodePanel';
import { TAB_TPYE, PAY_METHOD_KEY } from '../constants';

function PayPlatform({
    payPlatformTab,
    money,
    onPayMethodClick,
    onRefreshClick,
}) {
    return (
        <div className="pay_platform_container">
            <div>
                {
                    payPlatformTab.payMethods.map((payMethod, index) => {
                        switch (payMethod.key) {
                            case PAY_METHOD_KEY.WECHAT_PAY:
                                return (
                                    <PayMethod
                                        key={index}
                                        icon={<img className="va_m mr_m" src={require('../images/wechat_pay.png')} alt="" />}
                                        text="微信支付"
                                        recommend={payMethod.recommend}
                                        selected={payMethod.selected}
                                        qrCodePanelVisible={payMethod.selected}
                                        qrCodePanel={(
                                            <QrCodePanel
                                                icon={<img className="va_m mr_m" src={require('../images/wechat_pay_white.png')} alt="" />}
                                                title="微信支付"
                                                titleBgColor="#0dbb06"
                                                tips="使用微信App扫码完成支付"
                                                money={money}
                                                qrCode={payMethod.qrCode}
                                                qrCodeStatus={payMethod.qrCodeStatus}
                                                qrCodeRestTime={payMethod.qrCodeRestTime}
                                                onRefreshClick={onRefreshClick}
                                            />
                                        )}
                                        onClick={() => onPayMethodClick({ tabType: payPlatformTab.tabType, payMethod })}
                                    />
                                );

                            case PAY_METHOD_KEY.ALI_PAY:
                                return (
                                    <PayMethod
                                        key={index}
                                        icon={<img className="va_m mr_m" src={require('../images/alipay.png')} alt="" />}
                                        text="支付宝"
                                        recommend={payMethod.recommend}
                                        selected={payMethod.selected}
                                        qrCodePanelVisible={payMethod.selected}
                                        qrCodePanel={(
                                            <QrCodePanel
                                                icon={<img className="va_m mr_m" src={require('../images/alipay_white.png')} alt="" />}
                                                title="支付宝支付"
                                                titleBgColor="#1777ff"
                                                tips="使用支付宝App扫码完成支付"
                                                money={money}
                                                qrCode={payMethod.qrCode}
                                                qrCodeStatus={payMethod.qrCodeStatus}
                                                qrCodeRestTime={payMethod.qrCodeRestTime}
                                                onRefreshClick={onRefreshClick}
                                            />
                                        )}
                                        onClick={() => onPayMethodClick({ tabType: payPlatformTab.tabType, payMethod })}
                                    />
                                );

                            default:
                                return null;
                        }
                    })
                }
            </div>
        </div>
    );
}

export default inject(() => {
    const {
        money,
        tabs,
        onPayMethodClick,
        onRefreshClick
    } = autowired('checkoutViewModel');
    const payPlatformTab = tabs.find(({ tabType }) => tabType == TAB_TPYE.PAY_PLATFORM);
    return {
        payPlatformTab,
        money,
        onPayMethodClick,
        onRefreshClick,
    };
})(PayPlatform);
