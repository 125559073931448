var React=window.Snowball._React;
import SelectedImg from '../../../shared/images/selected.png';

export default function PayMethod({
    icon,
    text,
    selected,
    recommend,
    qrCodePanelVisible,
    qrCodePanel,
    onClick,
}) {
    return (
        <div className={`pay_method ps_r dp_ib ${selected ? 'selected' : ''}`}>
            <div className="cs_p" onClick={onClick}>
                {icon}
                <span className="text va_m">{text}</span>
                {recommend && <div className="recommend">推荐</div>}
                {selected && <img className="img_selected" src={SelectedImg} alt="" />}
            </div>
            {qrCodePanelVisible && qrCodePanel}
        </div>
    );
}