var { configuration, singleton }=window.Snowball._app;
import UserService from "./services/UserService";
import LoginService from "./services/LoginService";
import AddressApiService from "./apis/AddressApiService";
import MasterApiService from "./apis/MasterApiService";
import GlobalAddressService from "./services/GlobalAddressService";
import GumihoApiService from "./apis/GumihoApiService";
import PiggyApiService from "./apis/PiggyApiService";
import HeadlineApiService from "./apis/HeadlineApiService";
import PromcenterApiService from "./apis/PromcenterApiService";
import GlobalAddressSelectorViewModel from "./view-models/GlobalAddressSelectorViewModel";
import DsrCenterApiService from "./apis/DsrCenterApiService";
import AreaSelectorViewModel from "../app/address/view-models/AreaSelectorViewModel";
import AppResourceService from "./services/AppResourceService";
import UnicornApiService from "./apis/UnicornApiService";
import ConsultAuditApiService from './apis/ConsultAuditApiService';
import HestiaApiService from "./apis/HestiaApiService";

export const AppConfiguration = configuration({
    modules: {
        userService: singleton(UserService),
        loginService: singleton(LoginService),
        globalAddressService: singleton(GlobalAddressService),
        globalAddressSelectorViewModel: GlobalAddressSelectorViewModel,
        areaSelectorViewModel: AreaSelectorViewModel,
        addressApiService: AddressApiService,
        appResourceService: AppResourceService,
        masterApiService: MasterApiService,
        hestiaApiService: HestiaApiService,
        gumihoApiService: GumihoApiService,
        piggyApiService: PiggyApiService,
        headlineApiService: HeadlineApiService,
        promcenterApiService: PromcenterApiService,
        dsrCenterApiService: DsrCenterApiService,
        unicornApiService: UnicornApiService,
        consultAuditApiService: ConsultAuditApiService,
    }
});