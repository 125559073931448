var React=window.Snowball._React;

function PhysicalityItem({ name = '', value, unit = '', className, onChange, errorMsg }) {
    return (
        <div className={`physicality_container ${className ? className : ''}`}>
            <div>
                <span>{ name }:</span>
                <input type="text" value={ value } placeholder="请输入" onChange={(event) => onChange(event)}/>
                <span>{ unit }</span>
            </div>
        </div>
    );
}

export default PhysicalityItem;