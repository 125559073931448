var { observable, asObservable }=window.Snowball;
var { ViewModel, autowired }=window.Snowball._app;
import { AGE, GENDER, HEIGHT, WEIGHT, NICK_NAME, BIRTHDAY } from '../constants/index';
var { toast }=window.Snowball._widget;
import UserService from '../../../shared/services/UserService';

export default class PersonInfoViewModel extends ViewModel {
    @observable
    inputError = {};

    @observable
    personInfo = null;

    @observable
    isSaving = false;

    @autowired
    _userService: UserService;

    constructor() {
        super();

        this.onSave(() => this.save());
        this.nicknameChanged = false;
        this.basicInfoChanged = false;
    }

    onSave = this.ctx.createEmitter();

    save = async () => {
        let nickValid = this.validateNickname();
        let genderValid = this.validateGender();
        let height = this.validateHeight();
        let weight = this.validateWeight();
        let age = this.validateAge();

        if (nickValid && genderValid && height && weight && age) {
            if (this.nicknameChanged || this.basicInfoChanged) {
                let responses = await this.updatePersonInfo();
                this.isSaving = false;
                let errMsg = '';
                let allRequestSuccess = responses.every(res => {
                    if (!errMsg && !res.success) {
                        errMsg = res.message;
                    }
                    return res.success;
                });

                if (allRequestSuccess) {
                    this.getUserInfo();
                    toast.showToast('修改成功！');
                } else {
                    toast.showToast(errMsg.length > 0 ? errMsg : '修改失败，请稍后试！');
                }
            }
        }
    }

    validateNickname = () => {
        let nick = this.personInfo[NICK_NAME];
        if (!nick) {
            asObservable(this.inputError).set({ [NICK_NAME]: '请输入昵称' });
            return false;
        } else if (!(nick.length <= 10)) {
            asObservable(this.inputError).set({ [NICK_NAME]: '昵称不超过10个字' });
            return false;
        } else {
            asObservable(this.inputError).set({ [NICK_NAME]: null });
            return true;
        }
    }

    validateGender = () => {
        let gender = this.personInfo && this.personInfo[GENDER];

        if (!gender) {
            asObservable(this.inputError).set({ [GENDER]: '请选择性别' });
            return false;
        } else {
            asObservable(this.inputError).set({ [GENDER]: null });
            return true;
        }
    }

    validateHeight = () => {
        let height = this.personInfo && this.personInfo[HEIGHT];

        if (!height) {
            asObservable(this.inputError).set({ [HEIGHT]: '身高信息不能为空' });
            return false;
        } else if (!this.isNumber(height) || !(this.isNumber(height) && parseInt(height) <= 240 && parseInt(height) >= 60)) {
            asObservable(this.inputError).set({ [HEIGHT]: '身高60-240cm' });
            return false;
        } else {
            asObservable(this.inputError).set({ [HEIGHT]: null });
            return true;
        }
    }

    validateWeight = () => {
        let weight = this.personInfo && this.personInfo[WEIGHT];
        if (!weight || !this.isNumber(weight) || !(this.isNumber(weight) && parseInt(weight) >= 25 && parseInt(weight) <= 200)) {
            asObservable(this.inputError).set({ [WEIGHT]: '体重25-200kg' });
            return false;
        } else {
            asObservable(this.inputError).set({ [WEIGHT]: null });
            return true;
        }
    }

    validateAge = () => {
        let age = this.personInfo && this.personInfo[AGE];
        if (!age || !this.isNumber(age) || !(this.isNumber(age) && parseInt(age) >= 1 && parseInt(age) <= 100)) {
            asObservable(this.inputError).set({ [AGE]: '年龄1-100岁' });
            return false;
        } else {
            asObservable(this.inputError).set({ [AGE]: null });
            return true;
        }
    }

    isNumber = (toCheckStr) => {
        let req = /^\d+$/;
        if (req.test(toCheckStr)) {
            return true;
        } else {
            return false;
        }
    }
    onGenderClicked = (gender) => {
        this.basicInfoChanged = true;
        asObservable(this.personInfo).set({ [GENDER]: gender });
    }

    onFieldsChange = (checkKeyValue) => {
        let keys = Object.keys(checkKeyValue);
        for (let key of keys) {
            asObservable(this.personInfo).set({ [key]: checkKeyValue[key] });

            if (key === NICK_NAME) {
                this.nicknameChanged = true;
            } else {
                this.basicInfoChanged = true;
            }
        }
    }

    getUserInfo = async () => {
        let res = await this._userService.fetchUser();
        if (res.success) {
            this.personInfo = res.data;
            if (this.personInfo[BIRTHDAY]) {
                let age = parseInt((new Date().getTime() - this.personInfo[BIRTHDAY]) / (1000 * 365 * 24 * 3600));
                asObservable(this.personInfo).set({ [AGE]: age });
            }
        } else {
            let isLogin = await this._userService.isLogin();
            isLogin && toast.showToast('获取个人信息失败');
        }
    }

    updatePersonInfo = () => {
        if (this.isSaving) {
            return;
        }
        this.isSaving = true;
        let promises = [];
        if (this.nicknameChanged) {
            promises.push(this._userService.updateNickname(this.personInfo[NICK_NAME]));
        }

        if (this.basicInfoChanged) {
            let param = {
                [AGE]: Number(this.personInfo[AGE]),
                [HEIGHT]: Number(this.personInfo[HEIGHT]),
                [WEIGHT]: Number(this.personInfo[WEIGHT]),
                [GENDER]: this.personInfo[GENDER]
            };
            promises.push(this._userService.updateUserBasicInfo(param));
        }
        return Promise.all(promises);
    }
}
