var React=window.Snowball._React;
var { inject, mapViewModelToProps, autowired }=window.Snowball._app;
import NumberModifier from "../../../shared/components/NumberModifier";
import BuyButton from "./BuyButton";

function BuyButtons({
    pres,
    orderQtyMin,
    buyNum,
    onBuyNumChange,
}) {
    return (
        <div className="it_buy_btns mt_m">
            <div>
                <span className="it_base_label">数量</span>
                <NumberModifier
                    min={orderQtyMin}
                    className="it_buy_num"
                    value={buyNum}
                    onChange={(num) => onBuyNumChange(num)}
                />
                {
                    orderQtyMin > 1 && <span className="qtyMin">
                        {orderQtyMin}件起购
                    </span>
                }
            </div>
            {
                !!pres.buttonReason && (
                    <div className="mt_l it_buy_button_reason">{pres.buttonReason}</div>
                )
            }
            <BuyButton className="it_buy_btn" />
        </div>
    );
}

export default inject(() => {
    const itemViewModel = autowired('itemViewModel');
    return {
        pres: itemViewModel.pres,
        ...mapViewModelToProps('skuBuyViewModel')
    };
})(BuyButtons);
