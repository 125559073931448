import * as util from '../util';
import { APP_API, API_CHANNAL_PARAM } from '../../env';

function encrypt(token, params) {
    params = { ...params };
    params._st = +new Date();
    params._sm = "md5";
    params._chl = API_CHANNAL_PARAM;

    var _sv = util.cookie('projId');
    if (_sv) params._sv = _sv;

    var keys = Object.keys(params);
    keys.sort();

    var s = keys.reduce((prev, key, i) => {
        var val = params[key];
        if (val === undefined || val === null) return prev;
        return prev + key + "=" + (Array.isArray(val) || util.isPlainObject(val)
            ? JSON.stringify(val)
            : val);
    }, "");
    s += token;
    params._sig = util.md5(s);

    return params;
}

function getToken(needLogin, wtk) {
    if (needLogin === false) {
        return "jk.pingan.com";
    } else {
        if (wtk) {
            return wtk;
        } else {
            return null;
        }
    }
}

function checkResponse(content, _wtk, _st, sig) {
    _wtk = _wtk || '';
    _st = _st || '';
    return util.md5(content + _wtk + _st) === sig;
}

export const RESULT_FROM_CACHE = 304;
export const API_RESULT_RESPSIGN_ERROR = 5001;
export const API_RESULT_UNAVAILABLE_DATA = 5002;

export const API_RESULT_SUCCESS = 0;
export const API_RESULT_GUEST_NOAUTH = -310;
export const API_RESULT_RISK_USER_LOCKED = -380;
export const API_RESULT_NO_ACTIVE_DEVICE = -340;
export const API_RESULT_NO_TRUSTED_DEVICE = -320;
export const API_RESULT_SIGNATURE_ERROR = -180;
export const API_RESULT_USER_LOCKED = -370;
export const API_RESULT_TOKEN_ERROR = -360;
export const API_RESULT_TOKEN_EXPIRE = -300;

export const API_RESULT_OTHER_ERROR = -1;

export const ERROR_TYPES = {
    AUTH_ERROR: 'AUTH_ERROR'
};

const AUTH_ERROR_MESSAGE = '授权无效';

export const http = {
    _request: function (type = 'POST', url, params) {
        return new Promise((success, error) => {
            var xhr = new XMLHttpRequest();
            xhr.addEventListener('load', () => {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    success(JSON.parse(xhr.responseText));
                }
            });
            xhr.addEventListener('error', (e) => {
                if (xhr.status === 0) {
                    // 网络被页面跳转中断时等待600ms
                    setTimeout(() => {
                        error(e);
                    }, 600);
                } else {
                    error(e);
                }
            });

            xhr.open(type, url, true);
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.withCredentials = true;

            xhr.send(JSON.stringify(params));
        });
    },
    post: function (url, params) {
        return http._request('POST', url, params);
    },
    get: function (url, params) {
        return http._request('GET', url, params);
    }
};

function requestGateway(payload, needLogin, options) {
    var _wtk = util.cookie("_wtk");
    // _aid = util.cookie("v_aid");

    var { _mt, ...params } = encrypt(getToken(needLogin, _wtk), payload.data);
    var url = APP_API + '?_mt=' + _mt;

    try {
        return new Promise((success, error) => {
            var xhr = new XMLHttpRequest();
            xhr.addEventListener('load', () => {
                success(JSON.parse(xhr.responseText));
            });
            xhr.addEventListener('error', (e) => {
                if (xhr.status === 0) {
                    // 网络被页面跳转中断时等待600ms
                    setTimeout(() => {
                        error(e);
                    }, 600);
                } else {
                    error(e);
                }
            });

            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
            xhr.withCredentials = true;

            xhr.send(util.params(params));
        }).then((res) => {
            // 返回结果验签
            let resOK = checkResponse(JSON.stringify(res.content), _wtk, params._st, res.stat.sig);

            if (!resOK && res.stat && res.stat.code === API_RESULT_SUCCESS) {
                return {
                    success: true,
                    code: API_RESULT_RESPSIGN_ERROR,
                    message: 'response sign failure!',
                    stat: res.stat,
                    data: res.content && res.content.length > 1 ? res.content : res.content[0]
                };
            } else if (!res || !res.stat || !res.stat.stateList || res.stat.stateList.length < 0 || !res.content) {
                console.error("[api error] unavailable data:", res);

                return {
                    success: false,
                    code: API_RESULT_UNAVAILABLE_DATA,
                    message: "无效数据"
                };
            }

            let desc = util.get(res, 'stat.stateList[0].desc');

            switch (res.stat.code) {
                case API_RESULT_GUEST_NOAUTH:
                case API_RESULT_RISK_USER_LOCKED:
                case API_RESULT_NO_ACTIVE_DEVICE:
                case API_RESULT_NO_TRUSTED_DEVICE:
                case API_RESULT_SIGNATURE_ERROR:
                    util.store("MALL_EXPIRED_WTK", _wtk);

                    return {
                        success: false,
                        code: res.stat.code,
                        type: ERROR_TYPES.AUTH_ERROR,
                        message: AUTH_ERROR_MESSAGE
                    };

                case API_RESULT_USER_LOCKED:
                case API_RESULT_TOKEN_ERROR:
                case API_RESULT_TOKEN_EXPIRE:
                    util.store("MALL_EXPIRED_WTK", _wtk);

                    return {
                        success: false,
                        code: res.stat.code,
                        type: ERROR_TYPES.AUTH_ERROR,
                        message: AUTH_ERROR_MESSAGE
                    };

                case API_RESULT_SUCCESS:
                    const errCode = res.stat.stateList[0].code;
                    const content = res.content && res.content.length > 1 ? res.content : res.content[0];
                    if (errCode == 0) {
                        return {
                            success: true,
                            code: API_RESULT_SUCCESS,
                            stat: res.stat,
                            data: content
                        };
                    } else {
                        return {
                            success: false,
                            code: errCode,
                            stat: res.stat,
                            data: content,
                            message: desc,
                            desc
                        };
                    }
                default:
                    return {
                        success: false,
                        code: res.stat.code,
                        stat: res.stat,
                        message: desc || '服务端请求返回结果错误'
                    };
            }
        });
    } catch (error) {
        return {
            success: false,
            code: API_RESULT_OTHER_ERROR,
            message: '网络错误'
        };
    }
};

/**
 * 请求后端gateway api
 * @param {string} mt
 * @param {boolean} [needLogin] 是否要求登录
 * @param {object} [data] 请求数据
 * @param {object} [options] 选项
 * @param {boolean} [options.isShowLoading] 是否显示loading
 * @param {boolean} [options.autoLogin] 未是否自动登录
 */
export function request(mt, needLogin, data, options) {
    if (typeof needLogin !== 'boolean') {
        options = data;
        data = needLogin;
        needLogin = true;
    }

    if (!options) options = {
        isShowLoading: false,
        autoLogin: true
    };

    // var timeStart = Date.now();
    var payload = typeof mt === 'string' ? { data: data } : mt;
    payload.data = Object.assign({ _mt: mt }, payload.data);

    return requestGateway(payload, needLogin, options);
};
