var { controller, autowired }=window.Snowball._app;
import Cart from "../containers/Cart";
import { CartConfiguration } from "../configuration";
import CartController from './CartController';
var { toast }=window.Snowball._widget;

@controller({
    component: Cart,
    configuration: CartConfiguration
})
export default class B2CCartController extends CartController {

    cartType = 1;

    emptyLink = "/";

    @autowired
    _cartRequester;

    onInit() {
        this.request = this._cartRequester.b2c;
        this.ctx.logger.makeLog('H5web_pc_b2c_cart', 'onload');
        super.onInit();
    }

    async checkOut() {
        const groups = await this.checkoutValid();
        if (!groups.length) {
            toast.showToast(
                `请先选择需要结算的商品`
            );
            return;
        }
        this.checkoutbase(groups);
    }
}