var React=window.Snowball._React;var { Fragment, useState }=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import mixPhone from '../../../shared/utils/mixPhone';
import Title from './Title';
import SelectedImg from '../../../shared/images/selected.png';

function Actions({ onAddAddressClick, onManageAddressClick }) {
    return (
        <Fragment>
            <span className="mr_l cs_p" onClick={onAddAddressClick}>新增收货地址</span>
            <span className="cs_p" onClick={onManageAddressClick}>管理收货地址</span>
        </Fragment>
    );
}

function Receiver({ address, onClick, onEditAddressClick }) {
    return (
        <div
            className={`receiver ps_r fs_s mb_m br_4 cs_p fl_l ${address.selected ? 'selected' : ''}`}
            onClick={() => onClick(address)}
        >
            <div className="title_wrap">
                <div className="title fw_b clearfix">
                    <div className="fl_l fs_m">
                        <span className="mr_m">{address.recipientName.length > 5 ? address.recipientName.slice(0, 5) + '...' : address.recipientName}</span>
                        <span>{mixPhone(address.recipientPhone)}</span>
                    </div>
                    {address.isDefault && <span className=" fl_r cl_666">默认地址</span>}
                </div>
            </div>
            <div className="address cl_333 to_e2 mt_m mb_m">
                {`${address.province == address.city ? '' : address.province}${address.city}${address.district}${address.streetAddress + address.referAddr}`}
            </div>
            <div className="bottom ps_a pl_l pr_l w_1x">
                <span
                    className="btn_modify cs_p"
                    onClick={(event) => {
                        event.stopPropagation();
                        onEditAddressClick(address);
                    }}>修改</span>
            </div>
            {address.selected && <img className="img_selected" src={SelectedImg} alt="" />}
        </div>
    );
}

function Receivers({
    addressList,
    onAddressSelect,
    onAddAddressClick,
    onEditAddressClick,
    onManageAddressClick,
}) {
    const visibleCount = 4;
    const [expand, setExpand] = useState(false);
    const visibleAddressList = expand
        ? addressList
        : addressList.slice(0, visibleCount);

    return (
        <div className="co_receivers">
            <Title
                title="选择收货人信息"
                actions={<Actions onAddAddressClick={onAddAddressClick} onManageAddressClick={onManageAddressClick} />}
            />
            <div>
                {
                    visibleAddressList.length == 0
                        ? (
                            <div className="btn_add fs_s dp_ib ta_c br_4 cs_p" onClick={onAddAddressClick}>
                                <div className="content">
                                    <div className="icon_add">+</div>
                                    <div className="cl_666">新增收货地址</div>
                                </div>
                            </div>
                        )
                        : (
                            <Fragment>
                                <div className="receiver_list clearfix">
                                    {visibleAddressList.map((address, index) => {
                                        return (
                                            <Receiver
                                                key={index}
                                                address={address}
                                                onClick={onAddressSelect}
                                                onEditAddressClick={onEditAddressClick}
                                            />
                                        );
                                    })}
                                </div>
                                {
                                    addressList.length > visibleCount && !expand
                                        ? (
                                            <div>
                                                <div className="btn_show_all fs_m cs_p" onClick={() => setExpand(true)}>
                                                    <span>显示全部地址</span>
                                                    <i className="icon iconfont icon-down"></i>
                                                </div>
                                            </div>
                                        )
                                        : null
                                }
                            </Fragment>
                        )
                }
            </div>
        </div>
    );
}

export default inject(() => {
    const orderCreateViewModel = autowired('orderCreateViewModel');
    const {
        addressModel,
        addressList,
        onAddressSelect,
        onAddAddressClick,
        onEditAddressClick,
        onManageAddressClick,
    } = orderCreateViewModel;

    const list = addressList.reduce((list, address) => {
        if (addressModel.id == address.id) {
            const selectedAddress = {
                ...address,
                selected: true,
                isDefault: addressModel.isDefault,
            };
            list.push(selectedAddress);
        } else {
            list.push(address);
        }
        return list;
    }, []);

    return {
        addressList: list,
        onAddressSelect,
        onAddAddressClick,
        onEditAddressClick,
        onManageAddressClick,
    };
})(Receivers);
