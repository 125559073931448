var { Service, autowired }=window.Snowball._app;
var { util }=window.Snowball;

export default class CartRequester extends Service {

    @autowired
    _piggyApiService;

    b2c = async ({ provinceCode, cityCode }) => {
        const params = {
            prvCode: provinceCode,
            ctyCode: cityCode,
            type: 1,
            outBizType: this.app.env.OUT_BIZ_TYPE
        };
        var res = await this._piggyApiService.getB2CCart(params);
        return processRemoteData(res);
    }

    demand = async ({ provinceCode, cityCode }) => {
        const params = {
            prvCode: provinceCode,
            ctyCode: cityCode,
            type: 3,
            outBizType: this.app.env.OUT_BIZ_TYPE
        };
        var res = await this._piggyApiService.getB2CCart(params);
        return processRemoteData(res);
    }

    o2o = async ({ city, longitude, latitude }) => {
        const params = {
            longitude,
            latitude,
            city,
            outBizType: this.app.env.OUT_BIZ_TYPE
        };
        var res = await this._piggyApiService.getO2OCart(params);
        res = processRemoteData(res);
        if (res.success) {
            patchO2OItemsSelection(res.cartList);
        }
        return res;
    }
}

function processRemoteData(res) {
    return !res.success
        ? res
        : {
            success: true,
            hasMemberPrice:
                res.data.memberDTO && res.data.memberDTO.hasMemberPrice,
            cartList: res.data.validCartDTOs,
            invalidCartList: res.data.inValidCartSkuDTOs,
            cashPrice: res.data.cashPrice || 0,
            skuCount: res.data.count || 0,
            promPrice: res.data.promPrice || 0,
            systime: res.stat && res.stat.systime
        };
}

/**
 * O2O 中 需要把第一个商户下的所有商品都选中
 */
function patchO2OItemsSelection(cartList) {
    var firstItem =
        cartList &&
        cartList.find((item) =>
            util.get(item, ['cartStoreDTO', 'userAddressDelivered'])
        );
    if (firstItem) {
        firstItem.cartSkuDTOs.forEach((sku) => {
            sku.selected = 1;
        });
    }
}
