var React=window.Snowball._React;var { useState, useEffect, useRef }=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import BuyButton from "./BuyButton";

function DetailTab({
    commentCount,
    goCustomService,
    children
}) {
    return (
        <div className="it_details_tab">
            <TabBar
                commentCount={commentCount}
                goCustomService={goCustomService}
            />
            <div>
                {children}
            </div>
        </div>
    );
}

function TabBar({ commentCount, goCustomService }) {
    const [isFixed, setIsFixed] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const onScroll = (e) => {
            const { top } = ref.current.getBoundingClientRect();
            if (top <= 15) {
                if (!isFixed) {
                    setIsFixed(true);
                }
            } else {
                if (isFixed) {
                    setIsFixed(false);
                }
            }
        };
        window.addEventListener('scroll', onScroll, true);
        return () => window.removeEventListener('scroll', onScroll, true);
    }, [isFixed]);

    return <>
        <div ref={ref} className="clearfix it_detail_head"
            style={isFixed ? {} : { height: 0, visibility: "hidden" }}
        ></div>
        <div className={"clearfix it_detail_head " + (isFixed ? 'fixed' : '')}>
            <div className="tit">商品详情</div>
            {/* <div>商品评价({commentCount})</div> */}
            <div className="btns">
                <BuyButton className="btn_cart" />
                <button
                    onClick={goCustomService}
                    className="btn_service"
                >咨询客服</button>
            </div>
        </div>
    </>;
}

export default inject(() => {
    const itemViewModel = autowired('itemViewModel');
    const {
        comment = {},
        goCustomService
    } = itemViewModel;
    return {
        commentCount: comment.count,
        goCustomService,
    };
})(DetailTab);