var React=window.Snowball._React;

export default function Title({ title, titleAddition, actions }) {
    return (
        <div className="co_title mb_m clearfix">
            <div className="fl_l">
                <div className="title cl_333 fw_b">{title}</div>
                {titleAddition && <div className="title_addition">{titleAddition}</div>}
            </div>
            {actions && <div className="actions fs_m cl_666 fl_r">{actions}</div>}
        </div>
    );
}
