var React=window.Snowball._React;
import RefundOrderTableRow from './RefundOrderTableRow';
import emptyImage from '../../../shared/images/empty.png';
import '../styles/RefundOrderTable.scss';

const RefundOrderTable = (props) => {
    const { orders } = props;

    return (
        <table className="as__order_table">
            <thead className="hd">
                <tr>
                    <th>商品详情</th>
                    <th>退款金额</th>
                    <th>售后状态</th>
                    <th>操作</th>
                </tr>
            </thead>
            {
                orders && orders.length > 0
                    ? orders.map((order) => {
                        return <RefundOrderTableRow order={order} />;
                    })
                    : (
                        <tbody>
                            <tr>
                                <td colSpan={6}>
                                    <div className="empty_placeholder">
                                        <p><img alt="" src={emptyImage} /></p>
                                        <p>暂无数据</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    )
            }
        </table>
    );
};

export default RefundOrderTable;