var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;

function Services({
    serviceTagSpecs
}) {
    return (
        <div className="it_services clearfix">{
            serviceTagSpecs && serviceTagSpecs.map((serv) => {
                return <p className="fl_l ml_m"><i className="iconfont icon-check fs_m mr_s" />{serv.name}</p>;
            })
        }</div>
    );
}

export default inject(() => {
    const itemViewModel = autowired('itemViewModel');
    const { serviceTagSpecs } = itemViewModel.pres;
    return {
        serviceTagSpecs: serviceTagSpecs ? JSON.parse(serviceTagSpecs) : []
    };
})(Services);