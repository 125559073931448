import "../styles/order-info.scss";
var React=window.Snowball._React;
import { Header, Footer } from 'jkmall-frame';
import Main from '../../../shared/components/Main';

export default function TradeStatusDetails({ statusDetails }) {
    const statusList = [];
    if (statusDetails && statusDetails.length) {
        for (let i = statusDetails.length.length - 1; i >= 0; i--) {
            statusList.push(statusDetails[0]);
        }
    }
    return <>
        <Main>
            <Header />
            <Main className="mb_100">
                <ul className="tsd_status_list pt_l">
                    {statusList.map((status) => {
                        return <li className="tsd_status_list_item">{status}</li>;
                    })}
                </ul>
            </Main>
            <Footer />
        </Main>
    </>;
}