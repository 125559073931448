var React=window.Snowball._React;var { useEffect, useState, useCallback }=window.Snowball._React;
import "../../sass/shared/category.scss";
var { app, inject, autowired }=window.Snowball._app;

export default inject(() => {
    const unicornApiService = autowired("unicornApiService");
    return {
        unicornApiService,
    };
})(function Category(props) {
    const [category, setCategory] = useState([]);
    const [showPop, setShowPop] = useState(false);
    const [popList, setPopList] = useState([]);
    const { unicornApiService } = props;

    useEffect(() => {
        unicornApiService.getFrontCategory(props.id).then((res) => {
            if (res.success) {
                setCategory(res.data.children);
            }
        });
    }, [props.id, unicornApiService]);

    const onSelectCate = useCallback(
        async (cateId) => {
            const cate = category.find((item) => item.id == cateId);
            if (!cate.children) {
                const res = await unicornApiService.getFirstLevelFrontCategory({
                    id: cateId,
                    rootId: props.id,
                });
                if (res.success) {
                    cate.children = res.data.children;
                }
                setCategory(category);
            }

            setPopList(cate.children || []);
        },
        [category, props.id, unicornApiService]
    );

    const createJumpUrl = useCallback(
        (id, keyword) =>
            `${location.origin}/p-search/index.html#/searchresult/index?source=search&app=${app.env.APP_ENV}&outBizType=${app.env.OUT_BIZ_TYPE}&positionCode=30404&categoryId=${id}`,
        []
    );

    return (
        <div className="cm_category ps_r" onMouseEnter={() => setShowPop(true)} onMouseLeave={() => setShowPop(false)}>
            <ul className="level_1">
                {category.map((cat) => (
                    <li id={cat.id} className="flex" onMouseEnter={() => onSelectCate(cat.id)}>
                        <i className="round" style={{ backgroundImage: `url(${app.env.TFS}${cat.img})` }}></i>
                        <span className="to_e fx_1">{cat.name}</span>
                        <i class="icon iconfont icon-next"></i>
                    </li>
                ))}
            </ul>
            {showPop ? (
                <ul className="level_2">
                    {popList.map((second) => (
                        <li className="flex" id={second.id}>
                            <span className="second_name flex jc_sb">
                                {Array.from(second.name || "").map((word) => (
                                    <i>{word}</i>
                                ))}
                            </span>
                            <ul className="level_3">
                                {(second.children || []).map((third) => (
                                    <li
                                        onClick={() => {
                                            location.href = third.actType == 1 ? third.url : createJumpUrl(third.id, third.name);
                                        }}
                                        id={third.id}
                                    >
                                        {third.name}
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
});
