import "../styles/address.scss";
var React=window.Snowball._React;
import AddressForm from './AddressForm';
var { Modal }=window.Snowball._components;
var { inject, mapViewModelToProps }=window.Snowball._app;

const AddressEditorModal = ({
    title,
    visible,
    onSave,
    onCancel
}) => {
    return (
        <Modal
            className="address-editor-modal"
            visible={visible}
        >
            <div className="address-editor-modal-cont">
                <h1 className="address-editor-modal-hd">{title}</h1>
                <div className="address-editor-modal-con">
                    {visible && <AddressForm className="address-editor-modal-form" />}
                </div>
                <div className="address-editor-modal-ft clearfix">
                    <button className="fl_r btn_save" onClick={onSave}>保 存</button>
                    <button className="fl_r btn_cancel" onClick={onCancel}>取 消</button>
                </div>
            </div>
        </Modal>
    );
};

export default inject(mapViewModelToProps('addressEditorModalViewModel'))(AddressEditorModal);