var { Service }=window.Snowball._app;

export default class ConsultAuditApiService extends Service {
    get request() {
        return this.app.gateway.request;
    }

    /**
     * 获取 是否需要实名认证
     */
    getVerifyInfo(params) {
        return this.request('consultAudit.getVerifyInfo', true, params, { isShowLoading: true });
    }

    /**
     * 获取 常用病人信息列表
     */
    getUserPatientList() {
        return this.request('consultAudit.getUserPatientList', true, {}, { isShowLoading: true });
    }

    /**
     * 编辑/新增病人
     */
    savePatient(params) {
        return this.request('consultAudit.verifyPatient', true, params, { isShowLoading: true });
    }

}
