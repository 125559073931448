var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import Form from '../../../shared/components/Form';

const RefundExpressForm = inject(() => {
    const refundExpressFormViewModel = autowired('refundExpressFormViewModel');

    return {
        expressCompanyList: refundExpressFormViewModel.expressCompanyList,
        errors: refundExpressFormViewModel.errors,
        onFieldsChange: refundExpressFormViewModel.onFieldsChange
    };

})((props) => {
    const {
        expressCompanyList,
        errors,
        onFieldsChange
    } = props;

    return (
        <div className="as__refund_express_form">
            <Form>
                <Form.Item
                    label="物流公司"
                    required
                    error={errors.expressCompanyId}
                >
                    <select
                        className="app-select"
                        onChange={(event) => { onFieldsChange({ expressCompanyId: event.target.value }); }}
                    >
                        <option value="">请选择</option>
                        {
                            expressCompanyList.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                );
                            })
                        }
                    </select>
                </Form.Item>
                <Form.Item
                    label="物流订单"
                    required
                    error={errors.expressOrderId}
                >
                    <input
                        name="expressOrderId"
                        type="text"
                        className="app-form-input"
                        placeholder="请填写物流订单"
                        onChange={(event) => {
                            onFieldsChange({ expressOrderId: event.target.value });
                        }}
                    />
                </Form.Item>
            </Form>
        </div>
    );
});

export default RefundExpressForm;