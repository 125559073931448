var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import TFSImage from "../../../shared/components/TFSImage";

function PriceDescription({ image }) {
    if (!image) {
        return null;
    }

    return (
        <div className="it_price_description">
            <TFSImage
                className="mb_m"
                src={image}
            />
        </div>
    );
}

export default inject(({ pres }) => {
    const itemViewModel = autowired('itemViewModel');
    let { priceDescription } = itemViewModel.itemConfigs;
    let { priceSpec } = itemViewModel.pres;
    return {
        image: priceDescription || priceSpec,
    };
})(PriceDescription);
