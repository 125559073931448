var { controller,autowired }=window.Snowball._app;
import MyCoupon from '../containers/MyCoupon';
import { MyCouponConfiguration } from '../configuration';

@controller({
    component: MyCoupon,
    configuration: MyCouponConfiguration
})
export default class MyCouponController {
    @autowired
    _myCouponViewModel;

    onInit() {
        this.ctx.logger.makeLog('H5web_pc_my_coupon', 'onload');
        this._myCouponViewModel._fetch();
    }
}