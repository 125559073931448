var { controller, autowired }=window.Snowball._app;
import Address from "../containers/Address";
import { AddressConfiguration } from "../configuration";
import AddressViewModel from "../view-models/AddressViewModel";

@controller({
    component: Address,
    configuration: [AddressConfiguration]
})
export default class AddressController {

    @autowired
    _addressViewModel: AddressViewModel;

    onInit() {
        this.ctx.logger.makeLog('H5web_pc_address', 'onload');
        this._addressViewModel.loadAllAddresses();
    }
}