var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import Pagination from "@/shared/components/Pagination";

function CouponPagination({
    total = 0,
    pageSize = 10,
    pageNo = 1,
    onChange = noop => noop
}) {
    return (
        <div className='dp_ib fl_r'>
            <Pagination
                total={total}
                pageSize={pageSize}
                current={pageNo}
                onChange={pageNo => {
                    onChange({ pageNo });
                }}
                showTotal={(count, total) => (
                    <div>
                        共: {count} 条，{total}页
                    </div>
                )}
            />
        </div>
    );
}

export default inject(() => {
    const myCouponViewModel = autowired("myCouponViewModel");
    return {
        ...myCouponViewModel.pagination,
        onChange: myCouponViewModel.onPaginationChange
    };
})(CouponPagination);
