var React=window.Snowball._React;
import Coupon from './Coupon';
import Empty from './Empty';

const Spin = ({ loading = false, children }) => {
    if (loading) {
        return (
            <div className="spin">
                <span className="loading" />
            </div>
        );
    }
    return children;
};

function CouponList({ data = [], type, formatCouponDate, loading = false }) {

    return (
        <div style={{ minHeight: '640px' }} className="couponlist">
            <Spin loading={loading}>
                {data.length > 0 ? data.map(coupon=><Coupon coupon={coupon} type={type} formatCouponDate={formatCouponDate}/>) : <Empty />}
            </Spin>
        </div>
    );
}

export default CouponList;