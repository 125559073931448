var React=window.Snowball._React;
var { popup }=window.Snowball._widget;

type popupConfirmProps = {
    title?: string;
    content?: string | any;
    okText?: string;
    cancelText?: string;
    showCancelBtn?: Boolean,
    onOk?: () => any;
    onCancel?: () => any,
}

const ConfirmContent = ({
    title,
    content,
    onOk,
    onCancel,
    okText = '确定',
    cancelText = '取消',
    showCancelBtn = true,
}) => {
    if (!title && content) {
        title = content;
        content = '';
    }

    return (
        <div className="confirm_box">
            <div className="clearfix">
                <i class="icons-warn-rem"></i>
                <p className="title">{title}</p>
            </div>
            <div className="content">{content}</div>
            <div className="operates clearfix">
                <div className="operate_btn ok" onClick={onOk}>{okText}</div>
                {showCancelBtn && <div className="operate_btn cancel" onClick={onCancel}>{cancelText}</div>}
            </div>
        </div>
    );
};

export default function popupConfirm({
    title,
    content,
    okText,
    cancelText,
    onOk,
    onCancel,
}: popupConfirmProps) {
    const modal = popup.popup({
        className: 'cm_popup__confirm',
        animate: 'scale',
        clickMaskToHide: false,
        content: (
            <ConfirmContent
                title={title}
                content={content}
                onOk={() => {
                    onOk && onOk();
                    modal.hide();
                }}
                okText={okText}
                cancelText={cancelText}
                onCancel={() => {
                    modal.hide();
                    onCancel && onCancel();
                }}
            />
        ),
        onHide: () => { }
    });
    return modal;
}

export function popupAlert({
    title,
    content,
    okText,
    onOk,
}) {
    const modal = popup.popup({
        className: 'cm_popup__confirm',
        animate: 'scale',
        clickMaskToHide: false,
        content: (
            <ConfirmContent
                title={title}
                content={content}
                showCancelBtn={false}
                onOk={() => {
                    onOk && onOk();
                    modal.hide();
                }}
                okText={okText}
            />
        ),
        onHide: () => { }
    });
    return modal;
}
