import "../styles/order-modal.scss";
var React=window.Snowball._React;
var { Modal }=window.Snowball._components;
import RefundForm from "./RefundForm";
var { inject, mapViewModelToProps }=window.Snowball._app;

export default inject(mapViewModelToProps('refundModalViewModel'))(function RefundModal({
    visible,
    onCancel,
    refundType
}) {
    const title = refundType === 'refund' ? '填写退款信息' : '填写退货信息';
    return <Modal
        visible={visible}
        onCancel={onCancel}
        className="oi_refund_modal"
    >
        <div className="title">{title}</div>
        <RefundForm
            onCancel={onCancel}
        />
    </Modal>;
});