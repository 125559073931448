var React=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
import SelectedImg from '../../../shared/images/selected.png';

const Patient = ({ patient, isSelected, onSelect }) => {
    const { patientName, gender, monthAge, mobile } = patient;
    return (
        <div className={`patient ${isSelected ? 'selected' : ''}`} onClick={onSelect}>
            <p>
                <span className="mr_l">{patientName || '默认用户'}</span>
                <span className="mr_l">{gender == '1' ? '男' : '女'}</span>
                <span>{Math.floor(monthAge / 12)}岁</span>
            </p>
            <p>{mobile || '无'}</p>
            {isSelected && <img className="img_selected" src={SelectedImg} alt="" />}
        </div>
    );
};

const PatientList = ({ list, showMore, status, selectPatient, addPatient }) => {
    if (!list.length) {
        return null;
    };

    const { isEdit, isShowMore, selectedId } = status;

    const mapList = isShowMore ? list : list.slice(0, 3);

    const isLimited = list.length >= 20;

    return (
        <div className="patient_list">
            {
                isLimited
                &&
                (
                    <div className="full_info">
                        <i className="icon iconfont icon-warn mr_s"></i>
                        <span>最多可添加20条就诊人信息，您可以对已添加的就诊人信息进行修改。</span>
                    </div>
                )
            }
            <div className="clearfix mt_m">
                <p className="fl_l fs_l cl_333 fw_b">选择就诊人</p>
                {!isLimited && <p className="fl_r fs_m cl_666 add_patient" onClick={addPatient}>新增就诊人</p>}
            </div>
            <div className={`list clearfix ${isShowMore ? 'showMore' : ''}`}>
                {
                    mapList.map((item) => (<Patient onSelect={() => selectPatient(item)} isSelected={item.patientId === selectedId} patient={item} />))
                }
            </div>
            {
                list.length > 3 && !isShowMore && (<div className="btn_show_all fs_m mt_m" onClick={showMore}>
                    <span>显示全部</span>
                    <i className="icon iconfont icon-down"></i>
                </div>)
            }
            {
                <p className="cl_666 fs_l fw_b mt_l mb_m">{isEdit ? '编辑就诊人信息' : '新增就诊人信息'}</p>
            }
        </div>
    );
};
export default inject(mapViewModelToProps('patientViewModel'))(PatientList);