var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
var { CountDown }=window.Snowball._components;
import { ORDER_STATUS } from '../constants/ORDER_STATUS';

function OrderStatus({ tradeOrder, buttonStatus, children }) {
    const { isShowTimeout = false } = buttonStatus;
    const { orderStatus, expireTime } = tradeOrder;
    const STATUS = ORDER_STATUS[orderStatus] || '未知状态';
    const [statusText, subText] = Array.isArray(STATUS) ? STATUS : [STATUS];

    const statusClass = ['WAITING_DELIVERY', 'SHIPPING', 'ORDERED', 'PAID', 'APPROVE_SUCCESS', 'ORDER_SUCCESS'].includes(orderStatus)
        ? 'oi_status_success'
        : 'oi_status_waring';

    if (isShowTimeout && (orderStatus === "WAITING_PAY")) {
        return (
            <div className="oi_status oi_status_countdown">
                <div>
                    <i className={statusClass} />
                    <span>{statusText}</span>
                </div>
                <div
                    className="pt_m cl_666"
                    style={{ paddingLeft: 54 }}
                >
                    药品已锁定，请在&nbsp;
                    <CountDown endTime={expireTime}>{({ days, hours, minutes, seconds }) => <span>{days}天{hours}小时{minutes}分{seconds}秒</span>}</CountDown>
                    &nbsp;内完成支付
                </div>
                {children}
            </div>
        );
    }

    return (
        <div className="oi_status">
            <div className="oi_status_text">
                <i className={statusClass}></i>
                <div className="text dp_ib">{statusText}</div>
            </div>
            {
                subText && (
                    <div
                        className="pt_m cl_666"
                        style={{ paddingLeft: 54 }}
                    >{subText}</div>
                )
            }
            {children}
        </div>
    );
}

export default inject(() => {
    const orderInfoViewModel = autowired('orderInfoViewModel');
    return {
        tradeOrder: orderInfoViewModel.tradeOrder,
        buttonStatus: orderInfoViewModel.buttonStatus
    };
})(OrderStatus);