var React=window.Snowball._React;
// import { log } from 'mall-core';
import {
    EmptyCart,
    CartListInjector,
    InvalidCartListInjector
} from '../components';

export const CartListFrame = ({ showEmptyTip, link }) => {
    if (showEmptyTip) {
        return <EmptyCart link={link} />;
    }
    return (
        <div>
            <CartListInjector />
            <InvalidCartListInjector />
        </div>
    );
};