var { ViewModel, autowired }=window.Snowball._app;
import OrderAppResourceService from "./OrderAppResourceService";
var { observable }=window.Snowball;
import UnicornApiService from "../../../shared/apis/UnicornApiService";

export default class RefundService extends ViewModel {
    @autowired
    _orderAppResourceService: OrderAppResourceService;

    @autowired
    _unicornApiService: UnicornApiService;

    @observable
    refundInfo = {};

    @observable
    orderInfo = {};

    @observable
    reasons = [];

    onDidLoad = this.ctx.createEmitter();

    async loadRefundInfo({
        refundType,
        amount,
        orderId,
        skuId
    }) {
        this.refundType = refundType;
        const res = await this._unicornApiService.getAfterSalesOrderApplicationPage({
            amount,
            orderId,
            skuId,
        });
        this.refundInfo = {
            amount,
            orderId,
            skuId,
            ...res.data
        };
        this.loadRefundReasons(refundType);
        this.onDidLoad.emit();
    }

    refund({
        amount,
        orderId,
        skuId,
        refundCash,
        refundHealthStar,
        refundHealthGold,
        refundShopCard,
        factor,
        isLogisticFeeRefunded,
        description,
        pics = [],
    }) {
        const afterSalesOrderArgs = {
            orderStatus: 'PAID',
            // 售后单类型，单商品仅退款:1/单商品退货退款:2
            type: this.refundType,
            refundCash,
            refundHealthStar,
            refundHealthGold,
            refundGiftCard: refundShopCard,
            factor,
            isLogisticFeeRefunded,
            description,
            pics: pics.join(',')
        };
        return this._unicornApiService.createAfterSalesOrder({
            amount,
            orderId,
            skuId,
            afterSalesOrderArgs
        });
    }

    async loadRefundReasons(refundType) {
        await this._orderAppResourceService.loadCommonAppResources();
        const refundTypeName = refundType == 1 ? 'refund' : 'refundAndGoods';
        const resource = this._orderAppResourceService.documents.find(item => item.keyName == refundTypeName);
        return this.reasons = JSON.parse(resource.val);
    }
}