var { observable }=window.Snowball;
var { ViewModel, autowired }=window.Snowball._app;
var { toast }=window.Snowball._widget;
import OrderOperationHandlers from '../../order-info/view-models/OrderOperationHandlers';

const defaultPayInfo = {
    payStatusText: '正在确认支付结果中，请稍等…',
    payStatusDesc: '若长时间未返回结果，请手动点击按钮进行操作',
    success: false
};

export default class PayResultViewModel extends ViewModel {
    @observable
    orders;

    @observable
    orderIds = [];

    @observable
    payInfo = defaultPayInfo;

    @observable
    waiting = true;

    @autowired
    _unicornApiService;

    @autowired
    _orderOperationHandlers: OrderOperationHandlers;

    onViewOrder = this.ctx.createEmitter();
    onFinishPurchase = this.ctx.createEmitter();
    onRepay = this.ctx.createEmitter();

    constructor() {
        super();

        this.onViewOrder(() => this.viewOrder());
        this.onFinishPurchase(() => this.finishPurchase());
        this.onRepay(() => this.repay());

        this.orderIds = [];
        this.retryCount = 0;
        this.retryQueryOrderTimer = null;
    }

    async loadOrders(bizOrderIds) {
        this.orderIds = bizOrderIds;
        this.waiting = true;
        this.payInfo = defaultPayInfo;
        this.orderIds = [...bizOrderIds];

        let errMsg;
        let hasWaitingPayOrder = false;
        const resultList = await Promise.all(bizOrderIds.map(orderId =>
            this._unicornApiService.queryOrderForBuyer(orderId)
        ));
        const orders = resultList.filter((result) => {
            if (!result.success) {
                errMsg = result.message;
            }

            const { tradeOrder = {} } = result.data;
            const { orderStatus = 'WAITING_PAY' } = tradeOrder;
            if (orderStatus === 'WAITING_PAY') {
                hasWaitingPayOrder = true;
            }
            return result.success;
        }).map(result => result.data);

        // 尝试3次，每次请求回来如果还是支付中，隔1s再请求；
        if (hasWaitingPayOrder && this.retryCount < 3) {
            this.retryCount++;
            this.retryQueryOrderTimer = setTimeout(() => {
                this.loadOrders(this.orderIds);

                clearTimeout(this.retryQueryOrderTimer);
                this.retryQueryOrderTimer = null;
            }, 1000);

            return;
        }
        if (orders.length) {
            // this.orders = [...orders];
            this.retryCount = 0;
            this.orders = this.formatPersonAndAddress(orders);
            this.processOrders(this.orders);
        } else {
            this.retryCount = 0;
            toast.showToast(errMsg || '获取订单信息失败');
        }
    }

    processOrders(orders) {
        const order = orders[0];
        const { tradeOrder } = order;
        const { payMode, orderStatus } = tradeOrder;

        switch (orderStatus) {
            case 'PAID':
            case 'ORDERED':
            case 'FINISH':
                this.payInfo = {
                    payStatusText: !!payMode &&
                        (payMode == 'PAYONLINE'
                            ? '订单已支付成功!'
                            : '订单下单成功!'),
                    payStatusDesc: '您的订单已通知商家尽快发货，请耐心等待！',
                    success: true
                };
                this.waiting = false;
                break;
            case 'UNPAID':
            case 'WAITING_PAY':
                this.payInfo = {
                    payStatusText: '订单还未支付!',
                    payStatusDesc: '您需要请重新支付该笔订单',
                    success: false
                };
                this.waiting = false;
                break;

            case 'GROUPING':
            case 'WAITING_TO_APPROVE':
            default:
                this.viewOrder();
                break;
        }
    }

    viewOrder() {
        const order = this.orders[0];
        const { tradeOrder } = order;

        this.ctx.navigation.forward(
            this.orders.length > 1
                ? '/order-list/all'
                : `/order/${tradeOrder.tradeId}`
        );
    }

    finishPurchase() {
        this.ctx.navigation.forward(
            '/order-list/all'
        );
    }

    repay() {
        this._orderOperationHandlers.pay(this.orders[0].tradeOrder);
    }

    formatPersonAndAddress(orders) {
        return orders.map((item, index) => {
            return {
                ...item,
                address: {
                    ...item.address,
                    name: this.nameMixin(item.address.name),
                    detail: this.addressMixin(item.address.detail)
                },
            };
        });
    }
    addressMixin(address = '') {
        const STARS = '*****';
        const { length } = address;
        if (length == 5) {
            return STARS + address.slice(-2);
        } else if (length > 5 && length <= 10) {
            return address[0] + STARS + address[length - 1];
        } else if (length > 10) {
            return address.slice(0, 3) + STARS + address.slice(-4);
        }
    }

    nameMixin(name = '') {
        return name.replace(/^(.)(.*)(.)$/, function (match, p1, p2, p3) {
            return p2
                ? p1 + p2.replace(/./g, '*') + p3
                : p1 + '*';
        });
    }
}
