var { ViewModel, autowired, emitter }=window.Snowball._app;
var { observable, asObservable }=window.Snowball;
var { toast }=window.Snowball._widget;

// import showConsult from "../../consult/containers/Consult2";

export default class PatientViewModel extends ViewModel {

    @autowired
    _consultViewModel;

    @autowired
    _patientFormViewModel;

    @autowired
    _consultAuditApiService;

    @observable
    status = {};

    @observable
    list = [];

    async setCheoutInfo(seller) {
        this.seller = seller;
        await this.reuqestPatientList();
        asObservable(this.status).set({
            visible: true
        });
    }

    showMore() {
        asObservable(this.status).set({
            isShowMore: true
        });
    }

    addPatient() {
        this._patientFormViewModel.clearForm();
        asObservable(this.status).set({
            isEdit: false,
            selectedId: 0
        });
    }

    selectPatient(patient) {
        this._patientFormViewModel.setFormData(patient);
        asObservable(this.status).set({
            isEdit: true,
            selectedId: patient.patientId
        });
    }

    cancel() {
        this._patientFormViewModel.clearForm();
        this.status = {};
    }

    @emitter
    save() {
        if (!this.status.isEdit && this.list.length > 19) {
            toast.showToast('最多只能新增20个就诊人');
            return;
        }
        this._patientFormViewModel.submit((data) => {
            this.savePatient(data);
        },(err) => {
            console.log(err);
        });
    }

    async reuqestPatientList() {
        var res = await this._consultAuditApiService.getUserPatientList();
        if (res.success) {
            this.list = res.data.patientList;
            // this.list.length > 3 && this.selectPatient(this.list[0]);
        }
    }

    async savePatient(data) {
        const outbiztype = 'HFLPCDZCF_HEALTH_PRES';
        const entrance = `SKU.CART@${this.seller.storeId}.3.${outbiztype}`;
        const { patientName, monthAge, mobile, gender = 1, patientId } = data;
        const patientInfo = {
            patientName, monthAge, mobile, gender
        };
        this.status.isEdit && (patientInfo.patientId = patientId);
        var res = await this._consultAuditApiService.savePatient({
            param: {
                entrance,
                patientInfo
            }
        });

        if (!res.success) {
            return toast.showToast(res.message);
        }
        if (!res.data.baseResult.isSuccess) {
            return toast.showToast(res.data.baseResult.errorMessage);
        }

        const returnPatientId = this.status.isEdit ? patientId : res.data.patientId;
        const url = this.getConsultUrl({ entrance, data, patientId: returnPatientId });
        // 准备唤起iframe
        // showConsult(url);
        this._consultViewModel.setConsultInfo(url, returnPatientId);
        this.cancel();
    }

    getConsultUrl({ entrance, data: patientData, patientId }) {

        const { gender, monthAge } = patientData;
        const preSuitAI = `医生您好，我（｛${gender == '1' ? '男' : '女'}｝，｛${Math.floor(monthAge / 12)}｝岁）想开`;
        const preSuit = '医生您好，我想开';
        const titles = this.seller.skus.map(item => item.title).join('，');
        const mainSuitAI = preSuitAI + titles;
        const mainSuit = preSuit + titles;
        const prehref = `${location.origin}/chatcube/#/guide?biztype=12`;
        const href = prehref + `&entrance=${encodeURIComponent(entrance)}&mainSuitForAI=${encodeURIComponent(mainSuitAI)}&mainsuit=${encodeURIComponent(mainSuit)}&patientid=${patientId}`;
        return href;
    }

}