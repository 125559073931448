var { observable, util, asObservable }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
import MasterApiService from "../../../shared/apis/MasterApiService";
var { toast }=window.Snowball._widget;
import PiggyApiService from "../../../shared/apis/PiggyApiService";
import { BUY_TYPES } from "../constants";
import CustomEvent from "../../../shared/utils/customEvent";

export default class SkuBuyViewModel extends ViewModel {
    @observable
    selectedSpecs = [];

    @observable
    buyNum = 1;

    @observable
    skus = [];

    @observable
    specKeys = [];

    @observable
    orderQtyMin;

    @observable
    minPrice;

    @observable
    spuId;

    @observable
    maxPrice;

    @observable
    minHealthGold;

    @observable
    maxHealthGold;

    /**
     * 购买类型 - 0: 不可购买 1: 购物车, 2: 需求清单
     */
    @observable
    buyType = 1;

    storeId = 0;

    // 当前购买商品
    item;

    @observable
    error;

    @observable
    selectedSku;

    @autowired
    _masterApiService: MasterApiService;

    @autowired
    _piggyApiService: PiggyApiService;

    setBuyOptions({ buyType, storeId, item }) {
        this.buyType = buyType;
        this.storeId = storeId;
        this.item = item;
        this.buyNum = item.orderQtyMin || 1;
    }

    async getSkus(spuId, storeId?, skuId?, extParam?) {
        const res = await this._masterApiService.getItemSkuSelection(spuId, storeId, skuId, extParam);
        if (res.success) {
            asObservable(this)
                .set(util.pick(res.data, [
                    'skus',
                    'specKeys',
                    'orderQtyMin',
                    'maxHealthGold',
                    'minPrice',
                    'spuId',
                    'maxPrice',
                    'minHealthGold'
                ]));
            if (this.skus.length == 1) {
                this.selectedSpecs = this.skus[0].specValues;
            }
            this.error = null;
        } else {
            this.error = res;
        }
    }

    @emitter
    onSelectSpec({
        spec,
        index
    }) {
        const newSpecs = [];
        if (index != 0) {
            for (let i = 0; i < this.specKeys.length; i++) {
                newSpecs[i] = this.selectedSpecs[i];
            }
        } else {
            newSpecs[index] = this.selectedSpecs[0];
        }
        newSpecs[index] = newSpecs[index] == spec ? '' : spec;
        this.selectedSpecs = newSpecs;
        this.selectedSku = this.skus.find(sku => util.equals(sku.specValues, this.selectedSpecs));
    }

    @emitter
    onBuyNumChange(buyNum) {
        this.buyNum = buyNum;
    }

    @emitter
    onAddToCart() {
        const sku = this._selectSku();
        if (!sku || !this.validBuyNum(sku)) return;

        this._addToCart({
            skuId: sku.skuId,
            num: this.buyNum
        });
    }

    _selectSku() {
        if (this.selectedSpecs.length != this.specKeys.length || this.selectedSpecs.some(spec => !spec)) {
            toast.showToast(`请选择${this.specKeys.reduce((str, key, index) => {
                if (this.selectedSpecs[index]) return str;
                if (!key) return str;
                return str ? str + `,"${key}"` : `"${key}"`;
            }, '')}`);
            return null;
        }
        return this.skus.find(sku => util.equals(sku.specValues, this.selectedSpecs));
    }

    async _addToCart({
        skuId,
        num,
        storeId = this.storeId,
    }) {
        const res = await this._piggyApiService.addToCartV3({
            // 需求清单和普通购物车类型都是`B2C类型` - type: 1
            type: 1,
            cartRecordDTO: {
                storeId,
                objId: skuId,
                num: num || 1,
                subType: 0,
            },
            dotInfoDTO: {
                source: "pcsx",
                spm: this.ctx.logger.getSpm()
            }
        });
        const actionName = this.buyType == BUY_TYPES.ADD_TO_CART ? '加入购物车' : '提交需求清单';
        if (res.success) {
            toast.showToast(actionName + '成功!');

            window.dispatchEvent(new CustomEvent('jkmallAddCart', { detail: { addToCartNum: num } }));
        } else {
            toast.showToast(`${res.message}, 您无法${actionName}!`);
        }
    }

    buyNow() {
        const sku = this._selectSku();
        if (!sku || !this.validBuyNum(sku)) return;
        console.log(sku);

        toast.showToast(`加车sku:${sku.skuId},数量:${this.buyNum}`);
        // TODO: 去下单页
    }

    validBuyNum(sku) {
        let minNum = this.item.orderQtyMin || 1;
        if (this.buyNum < minNum) {
            toast.showToast(`最小限购${minNum}件`);
            this.buyNum = minNum;
            return false;
        }
        let max = Math.max(minNum, Math.min(sku.stockNum, sku.buyNum));
        if (this.buyNum > max) {
            toast.showToast('数量超过最大上限');
            this.buyNum = max;
            return false;
        }
        return true;
    }

    /**
     * 立即开药
     */
    async prescribeNow({
        skuId,
        num,
        storeId = this.storeId,
    }) {
        const res = await this._piggyApiService.addToCartV3({
            // 立即开药特殊类型200
            type: 200,
            cartRecordDTO: {
                storeId,
                num,
                objId: skuId,
                subType: 0
            },
            dotInfoDTO: {
                source: "pcsx",
                spm: this.ctx.logger.getSpm()
            }
        });
        if (!res.success) {
            toast.showToast("网络异常，请稍后重试");
            return;
        }
    }
}