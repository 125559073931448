var { Service }=window.Snowball._app;

export default class DsrCenterApiService extends Service {

    /**
     * 获取我的评价
     */
    queryUserCommentToApp(pageNo: number, pageSize: number) {
        return this.app.gateway.request('dsrcenter.queryUserCommentToApp', true, {
            searchQuery: {
                pageNo,
                pageSize
            }
        });
    }

    queryCommentCountToApp(spuId: string, sellerId: number) {
        return this.app.gateway.request('dsrcenter.queryCommentCountToApp', false, {
            countQuery: {
                spuId,
                channel: this.app.env.APP_ENV,
                sellerId
            }
        });
    }

    queryCommentsToApp(params: {
        spuId: string,
        sellerId: number,
        tabType: number,
        pageNo: number,
        pageSize: number
    }) {
        return this.app.gateway.request('dsrcenter.queryCommentsToApp', false, {
            commentQuery: {
                channel: this.app.env.APP_ENV,
                ...params
            }
        });
    }

    queryQuestionListForApp(params: {
        spuId: string,
        sellerId: number,
        pageNo: number,
        pageSize: number
    }) {
        return this.app.gateway.request('dsrcenter.queryQuestionListForApp', false, {
            ...params
        });
    }

    saveQuestion(params: {
        spuId: string,
        sellerId: number,
        content: string
    }) {
        return this.app.gateway.request('dsrcenter.saveQuestion', true, {
            question: params
        });
    }
}