var React=window.Snowball._React;
import { CartTypes } from '../constant/CartTypes';
import Checkbox from '../../../shared/components/Checkbox';

type BottomBarProps = {
    isChecked: boolean,
    disabled: boolean,
    // 结算数量
    skuNumber: number,
    // 小计金额
    totalMoney: number,
    promPrice: number,
    hasOversea?: boolean,
    checkoutButtonText?: string,

    // 选择全部
    onCheckAll: (checked: boolean) => void,
    // 点击删除按钮
    onDelete: () => void,
    // 结算按钮文案
    // 点击结算按钮
    onCheckout: () => void
};

const ERROR_CODE = -1;

const BottomBarWrapper = ({
    disabled,
    isChecked,
    skuNumber,
    checkoutButtonText = '去结算',
    hasOversea = false,
    promPrice,
    totalMoney = 0,
    onCheckout,
    onDelete,
    onCheckAll
}) => {
    return (
        <div className="cart_bottom_bar">
            <div className="check_all">
                <div className="cart-column t-checkbox">
                    <Checkbox
                        disabled={disabled}
                        checked={isChecked}
                        onChange={() => onCheckAll(!isChecked)}
                    >全选</Checkbox>
                </div>
                <div className="delete_text" onClick={onDelete}>
                    删除已选中的商品
                </div>
            </div>
            <div className="fl_r">
                <div className="choosen_info">
                    <p>已选择<span>{skuNumber}</span>商品</p>
                    <p>（不含运费）</p>
                </div>
                <div className="price_box">
                    <p className="price_area">
                        <span>合计：</span>
                        {
                            totalMoney !== ERROR_CODE && <span className="money"><span>￥</span>{totalMoney / 100}</span>
                        }
                        {
                            promPrice > 0 && <p className="promo">已优惠：￥{promPrice / 100}</p>
                        }
                    </p>
                </div>
                <div className="checkout_btn" onClick={onCheckout}>
                    {checkoutButtonText}
                </div>
            </div>
        </div>
    );
};

const BottomBar = (props: BottomBarProps) => {
    return (
        <BottomBarWrapper
            {...props}
        />
    );
};

const O2OBottomBar = (props: BottomBarProps) => {
    return (
        <BottomBarWrapper
            {...props}
        />
    );
};

const DemandListBottomBar = (props: BottomBarProps) => {
    const btnText = props.skuNumber > 0 ? '问诊开药' : '去结算';
    return (
        <BottomBar
            checkoutButtonText={btnText}
            {...props}
        />
    );
};

export function bottomBarFactory(cartType) {
    switch (cartType) {
        case CartTypes.O2O:
            return O2OBottomBar;
        case CartTypes.DemandList:
            return DemandListBottomBar;
        default:
            return BottomBar;
    }
}
