var React=window.Snowball._React;
import { transformTFSImageUrl } from '../../../core/tfs';

const HORIZONTAL_VALUE = 'horizontal';

const MobileApp = (props) => {
    const { type, appList = [] } = props;
    const isHorizontalType = type === HORIZONTAL_VALUE;
    const moduleDefaultClassName = 'mall_common__header__mobile_app';
    const list = isHorizontalType ? appList.slice(0, 2) : appList;

    return (
        <div
            className={`${moduleDefaultClassName} ${isHorizontalType ? moduleDefaultClassName + '_horizontal' : ''}`}
        >
            {
                isHorizontalType
                    ? null
                    : <span>
                        <i className="mall_common__header__mobile_app_phone_icon"></i>
                        手机版
                        <i className="mall_common__header__mobile_app_arrow_icon"></i>
                    </span>
            }
            <ul className="mall_common__header__mobile_app_dropdown_list">
                {
                    list.map((app) => {
                        return (
                            <li key={app.src}>
                                <div className="mall_common__header__mobile_app_qrcode">
                                    <img src={transformTFSImageUrl(app.src)} alt="" />
                                </div>
                                <div className="mall_common__header__mobile_app_info">
                                    <p className="mall_common__header__mobile_app_title">{app.txt}</p>
                                    <p className="mall_common__header__mobile_app_desc">{''}</p>
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

export default MobileApp;