var { useState, useEffect }=window.Snowball._React;
import CategoryService from '../services/CategoryService';

export default function useCategory(categoryId) {
    const categoryService = CategoryService.getInstance(categoryId);
    const [isShowCategory, setCategoryVisible] = useState(false);
    const [firstCategoryList, setFirstCategoryList] = useState([]);
    const [activedCategory, setActivedCategory] = useState({});

    useEffect(() => {
        categoryService.getFirstCategory().then((list) => {
            setFirstCategoryList(list);
        });
    }, [categoryService, categoryId]);

    const onCategoryNavItemMouseEnter = (id) => {
        setCategoryVisible(true);
        categoryService.getSecondAndThirdCategory(id).then((list) => {
            setActivedCategory({
                id,
                children: list
            });
        });
    };

    const onCategoryMouseLeave = () => {
        setCategoryVisible(false);
        setActivedCategory({});
    };

    return {
        isShowCategory,
        firstCategoryList,
        activedCategory,
        onCategoryMouseLeave,
        onCategoryNavItemMouseEnter
    };
}