var React=window.Snowball._React;
import ReactModule from "../base/ReactModule";
import Carousel from "../../../shared/components/Carousel";
var { reactModule }=window.Snowball._app;

@reactModule
export default class CarouselModule extends React.Component {
    render() {
        const { imgs = [] } = this.props.data.bizData;
        const list = imgs.map((item) => ({
            link: item.link,
            src: this.app.tfs.transformTFSImageUrl(item.src),
        }));

        return (
            <ReactModule {...this.props}>
                <Carousel list={list} />
            </ReactModule>
        );
    }
}
