var { controller, autowired }=window.Snowball._app;
import Checkout from "../containers/Checkout";
import { CheckoutConfiguration } from "../configuration";

@controller({
    component: Checkout,
    configuration: [CheckoutConfiguration]
})
export default class CheckoutController {

    @autowired
    _checkoutViewModel;

    onInit() {
        const { location } = this.ctx;
        const {
            tradeIds,
            callBackUrl,
        } = location.query;
        this.ctx.logger.makeLog('H5web_pc_checkout', 'onload');
        this._checkoutViewModel.load({
            tradeIds: tradeIds.split('__'),
            callBackUrl,
        });
    }

    onDestroy() {
        this._checkoutViewModel.clearTimer();
    }
}