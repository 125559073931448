var { useEffect, useState }=window.Snowball._React;
import { cookie } from '../../../core/util';
import { COOKIE_DOMAIN } from '../../../env';
import { reload } from '../../../core/navigation';
import { getUserInfo, logout } from '../../../core/api/user';
import { getCartNum } from '../../../core/api/piggy';
import { getMineWealth } from '../../../core/api/mine';

export default function useHeaderUser({
    isLogin,
    version
} = {}) {
    const wtk = cookie('_wtk');
    const [user, setUser] = useState({
        isLogin: isLogin === undefined ? !!wtk : isLogin,
        nickName: null,
    });

    useEffect(() => {
        fetchUser(setUser);
    }, [wtk, version, isLogin]);

    useEffect(() => {
        function addCart(event) {
            const { detail: { addToCartNum = 0 } = {} } = event;

            setUser(u => {
                const newCartNum = (u.cartNum || 0) + addToCartNum;
                return { ...u, cartNum: newCartNum < 0 ? 0 : newCartNum };
            });
        }

        function receiveCoupon(event) {
            const { detail: { receiveCouponNum = 0 } = {} } = event;

            setUser(u => {
                const newCouponNum = (u.couponNum || 0) + receiveCouponNum;
                return { ...u, couponNum: newCouponNum < 0 ? 0 : newCouponNum };
            });
        }

        window.addEventListener('jkmallAddCart', addCart);
        window.addEventListener('jkmallReceiveCoupon', receiveCoupon);

        return () => {
            window.removeEventListener('jkmallAddCart', addCart);
            window.removeEventListener('jkmallReceiveCoupon', receiveCoupon);
        };
    }, []);

    return { user, logout: logoutUser };
}

function fetchUser(callback) {
    getUserInfo()
        .then((res) => {
            if (res.success && res.data.id) {
                const user = {
                    isLogin: true,
                    userId: res.data.id,
                    nickName: res.data.nickname || res.data.nick || `用户${res.data.id}`
                };
                callback(user);

                Promise.all([
                    getCartNum(),
                    getMineWealth()
                ]).then(([cartRes, mineRes]) => {
                    const { bizCartNum } = cartRes.data;
                    const { couponNum } = mineRes.data;
                    callback({
                        ...user,
                        cartNum: bizCartNum,
                        couponNum
                    });
                });
            } else {
                callback({
                    isLogin: false,
                    nickName: null
                });
            }
        });
}

function logoutUser() {
    logout().then((res) => {
        if (res.success) {
            cookie('_wtk', '', undefined, undefined, COOKIE_DOMAIN);
            reload();
        }
    });
}