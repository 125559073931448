var React=window.Snowball._React;
import Steps from '../../../shared/components/Steps';

const AfterSaleTopSteps = (props) => {
    const { topSetpList } = props;

    return (
        <div className="as__top_steps_container">
            <Steps className="as__top_steps">
                {
                    topSetpList.map(item => {
                        return (
                            <Steps.Step actived={item.actived}>{item.desc}</Steps.Step>
                        );
                    })
                }
            </Steps>
        </div>
    );
};

export default AfterSaleTopSteps;