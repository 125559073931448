var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;

function Info({
    orderNo,
    money,
}) {
    return (
        <div className="checkout_info mb_xl">
            <div className="clearfix">
                <div className="fl_l mr_m">
                    <img className="img_status" src={require('../images/status_success.png')} alt="" />
                </div>
                <div className="fl_l pt_xs">
                    <div className="status">订单已提交，请于24小时内完成支付！</div>
                    <div className="line fs_m mt_m">
                        <span className="cl_666">订单号：</span>
                        <span className="cl_333">{orderNo}</span>
                    </div>
                    <div className="line fs_m mt_m">
                        <span className="cl_666">待支付金额：</span>
                        <span className="money fw_b">￥{money ? (money / 100).toFixed(2) : ''}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default inject(() => {
    const {
        orders,
        money,
    } = autowired('checkoutViewModel');

    return {
        orderNo: orders.map(({ tradeOrder }) => tradeOrder.tradeId).join(', '),
        money,
    };
})(Info);