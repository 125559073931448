var { observable, asObservable }=window.Snowball;
var { ViewModel, autowired }=window.Snowball._app;
var { toast }=window.Snowball._widget;
import { SHOP_ITEM, OVERSEA } from '../constant/ProductType';
import { CartActTypes } from '../constant/ActType';
var { debounce }=window.Snowball.util;
import { SellerTypes } from "../constant/SellerTypes";

export default class CartViewModel extends ViewModel {
    @autowired
    _userService;

    @autowired
    _piggyApiService;

    @autowired
    _globalAddressService;

    @observable
    error;

    @observable
    list = [];

    @observable
    invalidList = [];

    @observable
    cartInfo = {
    }

    @observable
    cartStatus = {
    }

    @observable
    statusInfo = {
        isLoading: true
    }

    setProps(props) {
        for (const key in props) {
            const element = props[key];
            this[key] = element;
        }
    }

    async fetch() {
        const globalAddress = await this._globalAddressService.fetch();
        const { city, provinceCode, cityCode, longitude, latitude } = globalAddress;
        const res = await this.request({
            city,
            provinceCode,
            cityCode,
            longitude,
            latitude
        });
        if (!res.success) {
            toast.showToast(res.message);
            this.error = res;
            return;
        }
        if (res.success) {
            this.processCartData(res);
        }
        const timeOffset = Date.now() - res.systime;
        asObservable(this.statusInfo).set({
            isLoading: false,
            isError: !res.success,
            hasMemberPrice: res.hasMemberPrice,
            timeOffset
        });
        return res;
    }

    getCartStatus() {
        return this.cartStatus;
    }

    getCartInfo() {
        return this.cartInfo;
    }

    getInvalidList() {
        return this.invalidList;
    }


    async fetchCartNum() {
        const res = await this._piggyApiService.getBizCartNum({ autoLogin: true });
        if (res.success) {
            let { b2cCartNum, o2oCartNum, bizCartNum, rxCartNum, latestCartType } = res.data;
            asObservable(this.cartInfo).set({
                demandCount: rxCartNum,
                b2cCount: b2cCartNum,
                o2oCount: o2oCartNum,
                cartTotal: bizCartNum,
                latestCartType
            });
            return res.data;
        }
        return {
            rxCartNum: 0
        };
    }

    reload() {
        this.fetch();
        this.fetchCartNum();
    }

    async calcCPaymentsPrice(groups) {
        const globalAddress = await this._globalAddressService.fetch();
        const { provinceCode, cityCode } = globalAddress;
        let errorMsg = '';
        let res = await Promise.all(
            groups.map(async (item) => {
                const ids = item.sellers.map((store) => store.idsForCalc);
                const requestIds = [].concat(...ids);
                const res = await this._piggyApiService.toBalanceV2({
                    type: 1,
                    ids: requestIds,
                    prvCode: provinceCode,
                    ctyCode: cityCode,
                    outBizType: this.app.env.OUT_BIZ_TYPE
                });
                if (!res.success || !res.data) {
                    errorMsg = res.message || '接口错误';
                    return;
                }
                const { cashPrice = 0, count = 0 } = res.data;
                return {
                    ...item,
                    total: count,
                    cashPrice
                };
            })
        );
        return errorMsg || res;
    }

    processCartData(result) {
        const {
            cartList,
            invalidCartList = [],
            promPrice = 0,
            cashPrice = 0,
            skuCount = 0
        } = result;
        const cartData = processCartData(cartList);
        const invalidList = processInvalidList(invalidCartList);
        const isEmpty = cartList.length === 0 && invalidList.length === 0;
        const hasOversea = cartData.hasOversea;

        this.list = cartData.cartList;
        this.invalidList = invalidList;
        asObservable(this.cartStatus).set({ hasOversea });
        asObservable(this.cartInfo).set({
            isEmpty,
            promPrice: promPrice,
            cashPrice: cashPrice,
            skuCount: skuCount,
            disabled: cartData.disabled,
            isAllItemsChecked: cartData.isAllItemsChecked
        });
    }

    async selectItems(ids, operateType) {
        const res = await this._piggyApiService.selectCartItemV3({
            ids,
            operateType,
            type: this.cartType
        });
        res.success && this.reload();
    }

    async updateItemNumber({ sku, num, id, storeId }) {
        this._setItemModeByUpdateNumber({ sku, num });
        this.modifyCartNum({ sku, num, id, storeId });
    }

    modifyCartNum = debounce(async ({ sku, num, id, storeId }) => {
        var res = await this._piggyApiService.modifyCartItemV3({
            cartRecordDTO: {
                id,
                num,
                storeId
            },
            type: this.cartType,
            outBizType: this.app.env.OUT_BIZ_TYPE
        });
        if (res.success) {
            this.reload();
        }
        else {
            toast.showToast(res.message);
        }
        return res;
    }, 500)

    async deleteItems(ids) {
        this._deleteItemsModel(ids);
        var res = await this._piggyApiService.delCartItemV3({
            type: this.cartType,
            ids
        });
        this.reload();
        if (!res.success) toast.showToast(res.message);
        return res;
    }

    async checkItem(skuItem) {
        this._setItemCheckedModel(skuItem, !skuItem.selected);
        this.selectItems([skuItem.id], +!skuItem.selected);
    }

    async toggleShopChecked(seller) {
        const operateType = seller.isAllItemsChecked ? 0 : 1;
        const ids = seller.cartSkuDTOs.map((sku) => sku.id);
        this._setShopCheckedModel(seller, !!operateType);
        this.selectItems(ids, operateType);
    }

    async setAllChecked(checked) {
        const ids = this.getListIds();
        if (!ids.length) {
            return false;
        }
        this._setAllCheckedModel(checked);
        this.selectItems(ids, +checked);
    }

    _setItemCheckedModel(sku, selected) {
        asObservable(sku).set({
            selected
        });
    }

    _deleteItemsModel(ids) {
        const shouldRemove = (item) =>
            ids.findIndex((id) => id == item.id) !== -1;
        this.list.forEach((item) => asObservable(item.cartSkuDTOs).remove(shouldRemove));
        asObservable(this.invalidList).remove(shouldRemove);
        const leftIds = this.getListIds();
        if (leftIds.length === 0) {
            asObservable(this.list).clear();
        }
    }

    _setShopCheckedModel(target, selected) {
        asObservable(target.cartSkuDTOs).updateAll({
            selected
        });
        asObservable(target).set({
            isAllItemsChecked: selected
        });
    }

    _setAllCheckedModel(selected) {
        this.list.forEach((item) => this._setShopCheckedModel(item, selected));
        this._setCartModelChecked(selected);
    }

    _setCartModelChecked(selected) {
        asObservable(this.cartInfo).set({
            isAllItemsChecked: selected
        });
    }

    _setItemModeByUpdateNumber({ sku, num }) {
        asObservable(sku).set({
            count: num
        });
    }

    getListIds() {
        return this.list.reduce((ids, item) =>
            ids.concat(item.cartSkuDTOs.map(sku => sku.id))
        ,[]);
    }

    getCheckedIds() {
        return this.list.reduce((ids, item) =>
            ids.concat(item.cartSkuDTOs.filter(sku => sku.selected).map(sku => sku.id))
        , []);
    }

    getSellerById(sellerId) {
        return this.list.find(
            (item) => item.cartSellerDTO.sellerId === sellerId
        );
    }

    /**
     * 分类支持合并支付和不支持合并支付的商品
     * 目前是只需要传递storeId
     */
    groupItemsByCPayment() {
        const groups = [];
        const merger = [];

        this.list.forEach((item) => {
            const { displayName, cartSellerDTO = {}, cartStoreDTO } = item;
            if (!cartStoreDTO.userAddressDelivered) return;
            const {
                idsForCalc,
                selectedSkuIds,
                selectedCombos
            } = item.cartSkuDTOs.reduce(
                (result, sku) => {
                    if (sku.stock > 0 && sku.selected) {
                        result.idsForCalc.push(sku.id);
                        if (sku.cartSubType === CartActTypes.COMBO) {
                            result.selectedCombos.push({
                                cartKey: sku.id,
                                comboId: sku.objId,
                                count: sku.count,
                                trace: sku.source || '',
                                spm: '?',
                                // getCartSpm(
                                //     sku.dotInfoDTO,
                                //     this.statusModel.attributes.timeOffset
                                // ),
                                skus: sku.cartSkuDTOs.map((subSku) => ({
                                    skuId: subSku.skuId,
                                    amount: subSku.count,
                                })),
                            });
                        } else {
                            result.selectedSkuIds.push({
                                cartKey: sku.id,
                                skuId: sku.skuId,
                                quantity: sku.count,
                                trace: sku.source || '',
                                // spm: getCartSpm(sku.dotInfoDTO, this.statusModel.attributes.timeOffset),
                                spn: '?',
                                //  处方药跳导诊需要传递商品名称
                                title: cartSellerDTO.isSellPrescr === SellerTypes.CLINICPRESCRIPTIONS ? sku.detailTitle : '',
                            });
                        }
                    }
                    return result;
                },
                {
                    idsForCalc: [],
                    selectedSkuIds: [],
                    selectedCombos: []
                }
            );
            if (idsForCalc.length) {
                const isOverseaSeller = cartSellerDTO.sellRange === 2;
                if (cartSellerDTO.isReceipt && !isOverseaSeller) {
                    merger.push({
                        idsForCalc,
                        title: displayName,
                        groupId: 0,
                        storeId: cartStoreDTO.storeId,
                        sellerId: cartSellerDTO.sellerId,
                        skus: selectedSkuIds,
                        bundleItems: selectedCombos
                    });
                } else {
                    groups.push({
                        title: displayName,
                        isOverseaSeller,
                        sellers: [
                            {
                                groupId: 0,
                                idsForCalc,
                                storeId: cartStoreDTO.storeId,
                                sellerId: cartSellerDTO.sellerId,
                                skus: selectedSkuIds,
                                bundleItems: selectedCombos
                            }
                        ]
                    });
                }
            }
        });
        merger.length &&
            groups.unshift({
                title: merger.map((item) => item.title).join('、'),
                sellers: merger
            });
        return groups;
    }
}

function processCartData(cartList) {
    return cartList.reduce(
        (result, item, i) => {
            if (!item.cartStoreDTO) return result;

            const userAddressDelivered = item.cartStoreDTO.userAddressDelivered;
            const shop = {
                ...item,
                userAddressDelivered,
                storeId: item.cartStoreDTO.storeId,
                sellerId: item.cartSellerDTO && item.cartSellerDTO.sellerId,
                displayName:
                    item.cartSellerDTO && item.cartSellerDTO.sellerName,
                disabled: true,
                isAllItemsChecked: true,
                isAllItemsCheckedForEdit: true,
                isShowPostageBar: false
            };
            shop.cartSkuDTOs = item.cartSkuDTOs.map((sku) => {
                // 计算套餐可添加库存
                const stock =
                    sku.cartSubType === CartActTypes.COMBO
                        // todo 本期套餐库存直接清0
                        // ? computeComboStock(sku.cartSkuDTOs)
                        ? 0
                        : sku.stock;
                const orderQtyMin = sku.orderQtyMin || 1;
                const itemDisabled =
                    !userAddressDelivered || stock <= 0 || stock < orderQtyMin;

                result.isAllItemsChecked &= itemDisabled || sku.selected;
                result.isAllItemsCheckedForEdit &= sku.editSelected;
                shop.isAllItemsChecked &= itemDisabled || sku.selected;
                shop.isAllItemsCheckedForEdit &= sku.editSelected;
                shop.isShowPostageBar |= sku.selected && !itemDisabled;
                shop.disabled && (shop.disabled = itemDisabled);
                result.disabled &= shop.disabled;
                sku.savePrice &&
                    sku.cartSubType === 0 &&
                    result.depreciableProducts.push({
                        id: sku.id,
                        sortId: sku.fullFoldId
                    });
                // 套餐品是否含有海外购品
                const comboHasOverSea =
                    (sku.cartSkuDTOs || []).findIndex(
                        (subSku) =>
                            subSku.type === SHOP_ITEM &&
                            subSku.subType === OVERSEA
                    ) !== -1;
                const skuOverSea =
                    comboHasOverSea ||
                    (sku.type === SHOP_ITEM && sku.subType === OVERSEA);
                sku.selected && (result.hasOversea |= skuOverSea);

                return {
                    ...sku,
                    dotInfoDTO: sku.dotInfoDTO || {},
                    stock,
                    hasOversea: skuOverSea,
                    userAddressDelivered,
                    disabled: itemDisabled
                };
            });

            result.cartList.push(shop);
            return result;
        },
        {
            cartList: [],
            disabled: true,
            isAllItemsChecked: true,
            depreciableProducts: [],
            isAllItemsCheckedForEdit: true,
            hasOversea: false
        }
    );
}

function processInvalidList(list) {
    return list.reduce((map, sku) => {
        if (sku.cartSubType === CartActTypes.COMBO) {
            // TODO 本期不做套餐品
            // map = map.concat(...(sku.cartSkuDTOs || []));
        } else {
            map.push(sku);
        }
        return map;
    }, []);
}

// function computeComboStock(list) {
//     if (!list.length) {
//         return 0;
//     }
//     const stocks = list.map((sku) => Math.floor(sku.stock / sku.count));
//     return Math.min(...stocks);
// }