var React=window.Snowball._React;
var { app }=window.Snowball._app;
var { popup, toast }=window.Snowball._widget;
import * as env from '../../../env';

export default function errorHandler(
    errCode,
    msg = '未知异常',
    {
        spuId,
        storeId,
        verifyOrderInfo,
        // tuanSkuId,
        // tuanGroupId,
        // tagInstId,
        requestOnCashChange,
    }
) {
    const popOptions = {};
    const JUMP_URL = {
        TUAN_INDEX: env.TUAN_HOME,
        PRODUCT_DETAIL: `/item/${spuId}/${storeId}`,
        ORDER_DETAIL: '/order-list/waiting-pay',
        MY_TUAN: `/tuan/mylist`,
        ERROR: `/error?t=${msg}`
    };

    switch (errCode) {
        case 50310002:
        case 50310004:
        case 50310008:
        case 50310011:
        case 50310100:
        case 50310103:
        case 50310104:
        case 50310106:
        case 50310108:
        case 50310110:
        case 50310120:
        case 50310121:
        case 50310122:
        case 50310128:
        case 50310162:
        case 50310180:
        case 50310201:
        case 50310202:
        case 50310204:
        case 50310607:
        case 50310611:
        case 50310616:
        case 50310009:
        case 50310625:
        case 50310641:
        case 50310642:
        case 50310643:
        case 50310644:
        case 50310645:
        case 50310646:
        case 50310647:
        case 50310648:
        case 50310649:
        case 50310650:
            popOptions.okText = '返回';
            popOptions.okUrl = -1;
            break;
        case 50310127:
        case 50310203:
        case 50310609:
        case 50310615:
            popOptions.okText = '查看更多团';
            popOptions.okUrl = JUMP_URL.TUAN_INDEX;
            break;
        case 50310161:
            popOptions.okText = '查看更多团';
            popOptions.okUrl = JUMP_URL.TUAN_INDEX;
            popOptions.cancelText = '关闭';
            break;
        case 50310200:
        case 50310610:
            popOptions.okText = '去支付';
            popOptions.okUrl = JUMP_URL.ORDER_DETAIL;
            break;
        case 50310207:
        case 50310129:
            popOptions.okText = '确认';
            popOptions.cancelText = '返回';
            popOptions.cancelUrl = -1;
            break;
        // 库存变动
        // 商品全部不可售
        case 50310617:
        case 50310111:
            verifyOrderInfo();
            return;
        case 50310608:
            popOptions.okText = '我来开团';
            popOptions.okUrl = JUMP_URL.PRODUCT_DETAIL;
            popOptions.cancelText = '查看更多团';
            popOptions.cancelUrl = JUMP_URL.TUAN_INDEX;
            break;
        case 50310614:
            popOptions.okText = '查看更多团';
            popOptions.okUrl = JUMP_URL.TUAN_INDEX;
            popOptions.cancelText = '我的团';
            popOptions.cancelUrl = JUMP_URL.MY_TUAN;
            break;
        case 50310140:
            return toast.showToast('请填写地址');
        case 50310107:
            return toast.showToast('用户实名认证信息必填，请完善后提交');
        case 50310626:
            return toast.showToast('请填写短信接收手机号');
        case 50310633:
        case 50310634:
            popOptions.okText = '我知道了';
            popOptions.okUrl = -1;
            break;
        case 50310638:
            popOptions.title = '健康生活通账号已冻结！';
            msg = (
                <React.Fragment>
                    <p className="mb_s ta_l">您的账号存在安全风险，健康生活通不可用。请到“<span style={{ color: '#ff6f00' }}>我的-我的健康生活通</span>”提交身份审核。</p>
                    <p className="cl_999 ta_l">请勿参与任何形式的刷单，谨防受骗</p>
                </React.Fragment>
            );
            popOptions.okText = '我知道了';
            break;
        default:
            break;
    }

    // 错误弹窗
    const { title, okText, okUrl, cancelText, cancelUrl } = popOptions;
    const modal = popup[cancelText ? 'confirm' : 'alert']({
        className: 'ta_c',
        title,
        content: msg,
        okText,
        onOk: () => {
            popup.hidePopup();
            if (okUrl) {
                if (okUrl == -1) {
                    app.navigation.back();
                } else {
                    app.navigation.replace(okUrl);
                }
            }

            if (
                // 价格出现变动，设置cash为空，重新调用接口。
                errCode == 50310207 || errCode == 50310129
                // 健康生活通被冻结，刷新页面数据
                || errCode == 50310638
            ) {
                requestOnCashChange();
            }
        },
        cancelText,
        onCancel: () => {
            modal.hide();
            if (cancelUrl) {
                if (okUrl == -1) {
                    app.navigation.back();
                } else {
                    app.navigation.replace(cancelUrl);
                }
            }
        }
    });
}
