import { FILEGW } from '../env';
var { loader }=window.Snowball._widget;

if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
            var canvas = this;
            setTimeout(function () {
                var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]);
                var len = binStr.length;
                var arr = new Uint8Array(len);

                for (var i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }

                callback(new Blob([arr], { type: type || 'image/png' }));
            });
        }
    });
}

if (!FileReader.prototype.readAsBinaryString) {
    FileReader.prototype.readAsBinaryString = function (fileData) {
        var binary = "";
        var pt = this;
        var reader = new FileReader();
        reader.onload = function (e) {
            var bytes = new Uint8Array(reader.result);
            var length = bytes.byteLength;
            for (var i = 0; i < length; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            pt.content = binary;
            pt.onload(pt);
        };
        reader.readAsArrayBuffer(fileData);
    };
}

export function updloadImageFile(imageFile, isPublic) {
    loader.showLoader();
    return new Promise((resolve, reject) => {
        compressImage(imageFile, 70, blob => {
            uploadImgV2(blob, 'CC_CMS', isPublic)
                .then(res => {
                    const values = Array.isArray(res) ? res : Object.values(res);
                    resolve(values ? values[0] : null);
                })
                .catch(reject)
                .then(() => {
                    loader.hideLoader();
                });
        });
    });
}

/**
 * 文件网关图片上传
 * @param {Blob} blobData blob
 * @param {string} subBizType 业务类型
 * @param {boolean} isPublic 是否是公有云，默认公有云
 */
export function uploadImgV2(blobData, subBizType = 'CC_CMS', isPublic = true) {
    loader.showLoader();

    return new Promise((resolve, reject) => {
        if (typeof blobData === 'string') {
            uploadData(blobData, resolve, reject, subBizType, isPublic);
        } else {
            var fr = new FileReader();
            fr.onload = function (e) {
                uploadData(e.target.result, resolve, reject, subBizType, isPublic);
            };
            fr.readAsBinaryString(blobData);
        }
    });
}

function uploadData(data, resolve, reject, subBizType = 'CC_CMS', isPublic = true) {
    const boundary = "WebKitFormBoundaryoJ0uuJghwGS3ADUL";

    const reqUrl = `${FILEGW}uploadImgV2?tfsGroupId=${isPublic ? 0 : 1}&bizType=MALL&bizSubType=${subBizType}`;

    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', function () {
        loader.hideLoader();
        resolve(JSON.parse(xhr.responseText));
    });
    xhr.addEventListener('error', (e) => {
        loader.hideLoader();
        console.log(e);
        reject(e);
    });

    xhr.open("POST", reqUrl, true);
    xhr.setRequestHeader("Content-Type", 'multipart/form-data; boundary=' + boundary);

    xhr.withCredentials = true;

    sendAsBinary(xhr, '--' + boundary + '\r\n' +
        'Content-Disposition: form-data; name="file"; filename="a.jpg"\r\nContent-Type: image/jpeg\r\n\r\n' +
        data + '\r\n' +
        '--' + boundary + '--\r\n');
}

function sendAsBinary(xhr, text) {
    var data = new ArrayBuffer(text.length);
    var uia = new Uint8Array(data);
    for (var i = 0; i < text.length; i++) {
        uia[i] = (text.charCodeAt(i));
    }
    xhr.send(uia.buffer);
}

function compressImage(imageFile, quality, cb) {
    var reader = new FileReader();
    reader.onload = async e => {
        var image = new Image();
        image.src = e.target.result;

        await new Promise((resolve, reject) => {
            if (!image.complete) {
                image.onload = () => setTimeout(resolve, 0);
                image.onerror = reject;
            } else {
                setTimeout(resolve, 0);
            }
        });

        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        // canvas.toBlob(cb, 'image/jpeg', quality);

        var binStr = atob(canvas.toDataURL('image/jpeg', quality).split(',')[1]);
        // var len = binStr.length;
        // var arr = new Uint8Array(len);

        // for (var i = 0; i < len; i++) {
        //     arr[i] = binStr.charCodeAt(i);
        // }

        cb(binStr);
    };
    reader.readAsDataURL(imageFile);
}
