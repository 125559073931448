var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import Title from './Title';
import SelectedImg from '../../../shared/images/selected.png';

const PAYMENT_METHODS = [
    {
        key: 'cash_on_delivery',
        text: '货到付款',
    },
    {
        key: 'online',
        text: '在线支付',
    },
    {
        key: 'dk',
        text: '续期代扣',
    },
];

function Payment({
    paymentTypes,
    paymentType,
    onPaymentTypeChange,
}) {
    return (
        <div className="co_payment">
            <Title title="支付方式" />
            <div className="clearfix">
                {Array.isArray(paymentTypes) && paymentTypes.map((type, index) => {
                    const method = PAYMENT_METHODS.find(
                        method => method.key == type
                    );
                    return method && (
                        <div
                            key={index}
                            className={`method fs_m ps_r cl_666 br_4 fl_l ta_c cs_p ${paymentType == method.key ? 'selected' : ''}`}
                            onClick={() => onPaymentTypeChange(method.key)}
                        >
                            {method.text}
                            {paymentType == method.key && <img className="img_selected" src={SelectedImg} alt="" />}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default inject(() => {
    const orderCreateViewModel = autowired('orderCreateViewModel');
    const { paymentTypes, paymentType } = orderCreateViewModel.fundModel;
    return {
        paymentTypes,
        paymentType,
        onPaymentTypeChange: orderCreateViewModel.onPaymentTypeChange,
    };
})(Payment);