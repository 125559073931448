var React=window.Snowball._React;
import { forward } from '../../../core/navigation';
import LOGO_IMAGE from '../../../sass/images/logo.png';

const Logo = ({ name, className }) => {
    return (
        <div
            className={`mall_common__header__logo ${className}`}
            onClick={() => { forward('/'); }}
        >
            <img src={LOGO_IMAGE} alt="平安健康APP" />
            { name && <span className="cl_666 fs_xl ml_m">{name}</span>}
        </div>
    );
};

export default Logo;