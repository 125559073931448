var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import { Header, Footer, Slogan, SearchBar, MyCart } from 'jkmall-frame';
import RefundOrderTable from '../components/RefundOrderTable';
import Main from '../../../shared/components/Main';
import '../styles/AfterSaleList.scss';

const AfterSaleList = inject(() => {
    const afterSaleListViewModel = autowired('afterSaleListViewModel');

    return {
        orders: afterSaleListViewModel.orders
    };
})((props) => {
    const {
        orders
    } = props;

    return (<>
        <Header
            renderExtra={({ cartNum = 0 }) => {
                return <>
                    <Slogan />
                    <div className="usercenter-breadcrumb"><i className="triangle"></i>我的</div>
                    <SearchBar showHot={false} />
                    <MyCart count={cartNum} />
                </>;
            }}
        />
        <div className="app-wrapper-gray as__after_sale_list">
            <Main className="app-wrapper-fff as__after_sale_list_main">
                <RefundOrderTable orders={orders} />
            </Main>
        </div>
        <Footer />
    </>);
});

export default AfterSaleList;