var { ViewModel, autowired, emitter }=window.Snowball._app;
import RefundService from "../services/RefundService";
var { observable, asObservable, util }=window.Snowball;
var { toast }=window.Snowball._widget;
import { updloadImageFile } from "../../../extentions/filegw";

export default class RefundFormViewModel extends ViewModel {
    @observable
    formData = {
        isLogisticFeeRefunded: true
    };

    @observable
    refundType;

    @observable
    maxRefundNum;

    @observable
    refundNum;

    @observable
    errors = {};

    @autowired
    _refundService: RefundService;

    scheme = {
        refundCash: [{
            required: true,
            message: '请输入退款金额'
        }, {
            validator: (value, key) => {
                if (value) {
                    if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                        return { field: key, message: '请输入正确的退款金额' };
                    }
                    const refundCash = Number(value);
                    if (refundCash <= 0) {
                        return { field: key, message: '请输入正确的退款金额' };
                    } else if (refundCash * 100 > this.refundInfo.refundCash) {
                        return { field: key, message: '退款金额不能超过' + (this.refundInfo.refundCash / 100) };
                    }
                }
            }
        }],
        factor: [{
            required: true,
            message: '请选择退货/退款原因'
        }],
        pics: [{
            validator: (value, key) => {
                console.log(value);
                if (value) {
                    if (value.length > 4) {
                        return { field: key, message: '最多4张' };
                    }
                }
            }
        }]
    }

    constructor() {
        super();

        this._refundService.onDidLoad(() => {
            this.setField('refundCash', this.refundInfo.refundCash / 100);
        });
    }

    get refundInfo() {
        return this._refundService.refundInfo;
    }

    get reasons() {
        return this._refundService.reasons;
    }

    @emitter
    async onPictureChange(e) {
        if (!e.target.files || !e.target.files.length) return;
        const images = await Promise.all([...e.target.files].map((imageFile) => {
            return updloadImageFile(imageFile);
        }));
        this.setField('pics', (this.formData.pics || []).concat(images));
        e.target.value = "";
    }

    @emitter
    async onDeletePic(pic) {
        this.setField('pics', util.remove((this.formData.pics || []).concat([]), pic));
    }

    @emitter
    onFieldsChange(data) {
        asObservable(this.errors)
            .set(this.app.validate(data, util.pick(this.scheme, Object.keys(data))));

        asObservable(this.formData)
            .set(data);
    }

    @emitter
    onSubmit() {
        this.submit((res) => {
            if (res.success) {
                this.app.navigation.replace('/aftersale/' + this.refundInfo.orderId);
            } else if (res.code == 38000204) {
                toast.showToast('该商品已超过售后期');
            } else {
                toast.showToast('服务器繁忙，请稍后重试');
            }
        });
    }

    @emitter
    onRefundNumChange(refundNum) {
        this.loadRefundInfo({
            amount: refundNum,
            orderId: this.orderId,
            skuId: this.skuId,
            refundType: this.refundType
        });
    }

    loadRefundInfo({
        amount,
        orderId,
        skuId,
        refundType
    }) {
        this.refundNum = amount;
        this.refundType = refundType;
        this.skuId = skuId;
        this.orderId = orderId;
        return this._refundService.loadRefundInfo({
            amount,
            orderId,
            skuId,
            refundType
        });
    }

    setFormData(formData) {
        this.formData = formData;
        this.errors = {};
        return this;
    }

    setField(key, value) {
        asObservable(this.formData)
            .set(key, value);
    }

    async submit(onSuccess, onError?) {
        this.errors = this.app.validate(this.formData, this.scheme);
        console.log(this.errors);
        if (Object.keys(this.errors).some((key) => this.errors[key])) {
            onError && onError(this.errors);
        } else {
            const res = await this._refundService.refund({
                ...this.refundInfo,
                ...this.formData,
                refundCash: Math.round(Number(this.formData.refundCash) * 100)
            });
            onSuccess(res);
        }
    }
}
