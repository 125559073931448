export default function getBlockStatus(blocks, id) {
    if (Array.isArray(blocks)) {
        for (let i = 0; i < blocks.length; i++) {
            const block = blocks[i];
            if (block.id == id) {
                return block.status;
            } else if (block.children) {
                const status = getBlockStatus(block.children, id);
                if (status != undefined) {
                    return status;
                }
            }
        }
    }
}