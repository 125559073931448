var { Service }=window.Snowball._app;

export default class GumihoApiService extends Service {
    get request() {
        return this.app.gateway.request;
    }

    getMallPage(
        channel,
        type,
        address: {
            addressId?: number,
            city?: string,
            longitude?: number,
            latitude?: number,
        } = null,
        start = 0,
        end = 5
    ) {
        var params = {
            channel: this.app.env.IS_PRE ? 'PRE_' + channel : channel,
            type,
            start,
            end,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };

        address && Object.assign(params, address);

        return this.request('gumiho.getMallPage', false, params, {
            isShowLoading: false,
        });
    }

    getMallPageById(
        pageId,
        address: {
            addressId?: number,
            city?: string,
            longitude?: number,
            latitude?: number,
        } = null,
        start = 0,
        end = 5
    ) {
        var params = {
            start,
            end,
            pageId,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };

        address && Object.assign(params, address);

        return this.request(
            'gumiho.getMallPageById',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    previewMallPageById(
        pageId,
        address: {
            addressId?: number,
            city?: string,
            longitude?: number,
            latitude?: number,
        } = null,
        start = 0,
        end = 5
    ) {
        var params = {
            start,
            end,
            pageId,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };

        address && Object.assign(params, address);

        return this.request(
            'gumiho.previewMallPageById',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    /**
     * 加车王、爆款王获取商品销量或加车数量
     * @param {number} templetType
     * @param {number[]} spuIds
     * @param {string} outBizType
     */
    getSpusSaleOrCartNum(templetType, spuIds, outBizType) {
        return this.request(
            'gumiho.getSpusSaleOrCartNum',
            false,
            {
                templetType,
                spuIds,
                outBizType,
            },
            {
                isShowLoading: false,
            }
        );
    }

    /**
     * 在cms页上，根据spu id批量获取spu信息
     * @param {Array} spuIds spu id列表
     */
    getCmsSpus(spuIds) {
        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.previewCmsSpus' : 'gumiho.getCmsSpusV2',
            false,
            {
                spuIds: JSON.stringify(spuIds),
                outBizType: this.app.env.OUT_BIZ_TYPE,
            },
            { isShowLoading: false }
        );
    }

    /**
     * 在cms页上，根据spu id批量获取spu信息 - 带会员价信息
     * @param {Array} spuIds spu id列表
     */

    getCmsMemberSpus(spuIds) {
        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.getCmsMemberSpus' : 'gumiho.getCmsMemberSpus',
            false,
            {
                spuIds: JSON.stringify(spuIds),
                outBizType: this.app.env.OUT_BIZ_TYPE,
            },
            { isShowLoading: false }
        );
    }

    getCmsPage(logicId, start = 0, end = 5) {
        return this.request('gumiho.getCmsPageV2', false, {
            logicId,
            start,
            end,
            outBizType: this.app.env.OUT_BIZ_TYPE,
            pageExtDTO: {
                theme: this.app.env.THEME
            }
        }, {
            isShowLoading: false,
        });
    }

    getCmsPageById(pageId, start = 0, end = 5) {
        var params = {
            pageId,
            start,
            end,
            outBizType: this.app.env.OUT_BIZ_TYPE,
            pageExtDTO: {
                theme: this.app.env.THEME
            }
        };

        return this.request(
            'gumiho.getCmsPageByIdV2',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    previewCmsPageById(pageId, start = 0, end = 5) {
        var params = {
            pageId,
            start,
            end,
            outBizType: this.app.env.OUT_BIZ_TYPE,
            pageExtDTO: {
                theme: this.app.env.THEME
            }
        };

        return this.request(
            'gumiho.previewCmsPageById',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    getFlashSaleSkuInfos(logicId) {
        var params = {
            logicId,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };

        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.previewFlashSaleSkuInfos' : 'gumiho.getFlashSaleSkuInfos',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    getSeckilSkuInfos(logicId, size) {
        var params = {
            logicId,
            size,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };

        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.previewSeckilSkuInfos' : 'gumiho.getSeckilSkuInfos',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    /**
     * 接口变动，logicId给正式用，templetDataid 给预览用
     */
    getMCTempletDataSpus(params: {
        templetDataLogicId?: number,
        templetDataId?: number,
        templetType: number,
        page: number,
        pageSize: number,
        group?: number,
        spuId?: number,
        type?: string,
    }) {
        params.outBizType = this.app.env.OUT_BIZ_TYPE;

        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.previewMCTempletDataSpusById' : 'gumiho.getMCTempletDataSpus',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }
    /**
     * 适用于本次改版的瀑布流模块
     */
    getMCTempletDataSpusV2(params: {
        templetDataLogicId?: number,
        templetDataId?: number,
        templetType: number,
        page: number,
        pageSize: number,
        group?: number,
        spuId?: number,
        type?: string,
    }) {
        params.outBizType = this.app.env.OUT_BIZ_TYPE;

        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.previewMCTempletDataSpusByIdV2' : 'gumiho.getMCTempletDataSpusV2',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    getMCTempletDataSpusV3(params: {
        tdLogicId?: number,
        templetDataId?: number,
        templetType: number,
        page: number,
        pageSize: number,
        group: number,
        type?: string,
        pageExtDTO?: string,
    }) {
        params.outBizType = this.app.env.OUT_BIZ_TYPE;

        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.previewMCTempletDataSpusByIdV3' : 'gumiho.getMCTempletDataSpusV3',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    getO2OTempletDataSpus(params: {
        templetDataLogicId: number,
        templetType: number,
        page: number,
        pageSize: number,
        group: number,
        tagId: number,
        storeIds: srring[],
        type?: string,
    }) {
        params.outBizType = this.app.env.OUT_BIZ_TYPE;

        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.previewO2OTempletDataSpus' : 'gumiho.getO2OTempletDataSpus',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    getSkuInfos(skuIds: any[], otherParams) {
        const params = {
            skuIds: JSON.stringify(skuIds),
            ...otherParams,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };

        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.previewSkuInfosV3' : 'gumiho.getSkuInfosV3',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    getCmsHcodeSpus(spuIds: any[]) {
        var params = {
            spuIds,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };

        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.previewCmsHcodeSpus' : 'gumiho.getCmsHcodeSpus',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    exchangeCoupon(dataPoolId: number) {
        var params = {
            dataPoolId,
        };
        return this.request('gumiho.exchangeCoupon', true, params, {
            isShowLoading: false,
        });
    }

    getStorePage(sellerId: number) {
        var params = {
            sellerId,
            start: 0,
            end: 60,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };
        return this.request('gumiho.getStorePage', false, params);
    }

    getO2OCmsPage(
        logicId,
        start = 0,
        end = 5,
        address: {
            addressId?: number,
            longitude?: number,
            latitude?: number,
        } = {}
    ) {
        const params = {
            logicId,
            start,
            end,
            outBizType: this.app.env.OUT_BIZ_TYPE,
            ...address,
        };

        return this.request('gumiho.getO2OCmsPage', false, params, {
            isShowLoading: false,
        });
    }

    getO2OCmsPageById(
        pageId,
        start = 0,
        end = 5,
        address: {
            addressId?: number,
            longitude?: number,
            latitude?: number,
        } = {}
    ) {
        const params = {
            pageId,
            start,
            end,
            outBizType: this.app.env.OUT_BIZ_TYPE,
            ...address,
        };

        return this.request(
            this.app.env.IS_PREVIEW ? 'gumiho.previewO2OCmsPageById' : 'gumiho.getO2OCmsPageById',
            false,
            params,
            {
                isShowLoading: false,
            }
        );
    }

    focusOn(params: {
        pageId: number,
        tabPageId: number,
        startTime: number,
        endTime: number,
        spuId: number,
        skuId: number,
        type: number,
        pageExtDTO: ?any,
    }) {
        // console.log(params);
        // return Promise.resolve({
        //     success: true,
        //     data: {
        //         value: true
        //     },
        //     stat: {
        //         systime: Date.now()
        //     }
        // });
        return this.request(
            'gumiho.focusOn',
            true,
            {
                ...params,
                outBizType: this.app.env.OUT_BIZ_TYPE,
            },
            {
                isShowLoading: false,
            }
        );
    }

    /**
     * 智能弹窗数据请求
     * @param {number} ruleId
     * @param {number} type
     */
    getRuleInfo(ruleId, type) {
        return this.request(
            'gumiho.getRuleInfo',
            false,
            {
                ruleId,
                type,
                outBizType: this.app.env.OUT_BIZ_TYPE,
            },
            {
                isShowLoading: false,
            }
        );
    }

    // 获取搜索商品
    getSpuInfoBySearch(params) {
        return this.request('gumiho.getSpuInfoBySearch', false, params, {
            isShowLoading: false,
        });
    }

    getRulePage(ruleId, start = 0, end = 5) {
        const params = {
            ruleId,
            start,
            end,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };

        return this.request('gumiho.getRulePage', false, params, {
            isShowLoading: false,
        });
    }

    getRuleItemData(ruleId, page = 1, pageSize = 10, ext) {
        const params = {
            ruleId,
            page,
            pageSize,
            ext,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };
        return this.request('gumiho.getRuleItemData', false, params, {
            isShowLoading: false,
        });
    }

    getItemDataById(params: {
        ruleId: String,
        bitControl: Number,
        pageNo: Number,
        pageSize: Number,
        customMadeDTO?: any,
    }) {
        params.outBizType = this.app.env.OUT_BIZ_TYPE;
        return this.request('gumiho.getItemDataById', false, params, {
            isShowLoading: false,
        });
    }

    /**
     * @param {*} customMadeDTO 扩展属性（prvCode, cityCode, cumtomItems定制商品）
     */
    getRuleItemDataById(
        ruleId,
        bitControl = 127,
        pageNo = 1,
        pageSize = 10,
        customMadeDTO
    ) {
        const params = {
            ruleId,
            bitControl,
            pageNo,
            pageSize,
            customMadeDTO,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        };
        return this.request('gumiho.getItemDataById', false, params, {
            isShowLoading: false,
        });
    }

    getCoupons(params: { couponId: number, outBizType?: string }) {
        return this.request('gumiho.getCoupons', true, params, {
            isShowLoading: true,
        });
    }

    getStoreDetail(sellerId) {
        return this.request(
            'gumiho.getStoreDetail',
            false,
            {
                sellerId,
                outBizType: this.app.env.OUT_BIZ_TYPE,
            },
            { isShowLoading: true }
        );
    }

    verifyCaptcha(key, value) {
        return this.request(
            'gumiho.verifyCaptcha',
            false,
            { key, value },
            {
                isShowLoading: true,
                autoLogin: false,
            }
        );
    }

    getShopBusinessLicenses(sellerId) {
        return this.request(
            'gumiho.getShopBusinessLicenses',
            false,
            { sellerId },
            {
                isShowLoading: true,
                autoLogin: false,
            }
        );
    }

    getAppConfig(id) {
        return this.request(
            "gumiho.getAppConfig",
            false,
            { configId: id, outBizType: this.app.env.OUT_BIZ_TYPE },
            {
                isShowLoading: false,
                autoLogin: false
            }
        );
    }
}