var React=window.Snowball._React;var { useEffect, useCallback }=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import { COUPON_BUTTON_STATUS } from "../constants";

function CouponList({
    className,
    spuId,
    coupons,
    onLoad,
    onClose,
    onReceiveCoupon
}) {
    useEffect(() => {
        if (spuId && onLoad) {
            onLoad(spuId);
        }
    }, [onLoad, spuId]);

    const unavaibleCoupons = coupons && coupons.filter(({ status }) => status != COUPON_BUTTON_STATUS.Avaible);

    return <div className={"it_coupon_list " + (className || '')}>
        <div className="it_coupon_list_title">
            优惠券
            <button
                className="ps_a it_coupon_list_close"
                onClick={onClose}
            >收起</button>
        </div>
        <div className="it_coupon_list_con">
            <List
                coupons={coupons && coupons.filter(({ status }) => status == COUPON_BUTTON_STATUS.Avaible)}
                onReceiveCoupon={onReceiveCoupon}
            />
            {
                !!(unavaibleCoupons && unavaibleCoupons.length) && <>
                    <h4 className="ml_m fs_m cl_333 mb_s">已领取优惠券<span className="cl_999">（满足条件后可用于当前商品）</span></h4>
                    <List
                        coupons={unavaibleCoupons}
                        onReceiveCoupon={onReceiveCoupon}
                    />
                </>
            }
        </div>
    </div>;
}

function List({
    coupons,
    onReceiveCoupon
}) {
    return <ul>{
        coupons && coupons.map((coupon) => {
            console.log(coupon);
            const {
                specId,
                discountPrice,
                description,
                effectiveTime,
                status,
            } = coupon;
            return <li className="it_coupon_list_item clearfix">
                <div className="fl_l price">
                    <div className="center">
                        <i>￥</i>
                        <span>{discountPrice}</span>
                    </div>
                </div>
                <div className="fl_l con">
                    <p className="desc to_e">{description}</p>
                    <p className="date to_e">{effectiveTime}</p>
                </div>
                <div className="fl_r">
                    {
                        status == COUPON_BUTTON_STATUS.Avaible
                            ? <button
                                className="btn"
                                type="button"
                                onClick={() => onReceiveCoupon(specId)}
                            >
                                领取
                            </button>
                            : status == COUPON_BUTTON_STATUS.Received
                                ? <i className="item-received-coupon" />
                                : status == COUPON_BUTTON_STATUS.Invalid
                                    ? <i className="item-invalid-coupon" />
                                    : status == COUPON_BUTTON_STATUS.Invalid
                                        ? <i className="item-sack-coupon" />
                                        : null
                    }
                </div>
            </li>;
        })
    }</ul>;
}

export default inject(() => {
    const couponViewModel = autowired('couponViewModel');
    return {
        coupons: couponViewModel.coupons,
        onReceiveCoupon: couponViewModel.onReceiveCoupon,
        onLoad: useCallback((spuId) => couponViewModel.loadCoupons([spuId]), [couponViewModel])
    };
})(CouponList);
