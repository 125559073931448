var { observable, util }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;

export default class AreaSelectorViewModel extends ViewModel {
    @autowired
    _addressApiService;

    @observable
    value;

    @observable
    currentArea = {};

    @observable
    provinces = [];

    @observable
    cities = [];

    @observable
    districts = [];

    initWithValue(value = []) {
        this.value = value.every(k=>!k) ? [] : value;
        const [provinceCode, cityCode] = this.value;
        const tasks = [this.loadAllProvinces()];
        if (provinceCode) {
            tasks.push(this.loadAllCitiesByProvinceCode(provinceCode));
            if (cityCode) {
                tasks.push(this.loadAllDistrictsByCityCode(cityCode));
            }
        }
        Promise.all(tasks)
            .then(() => {
                this._syncCurrentArea();
            });
    }

    _syncCurrentArea() {
        this.currentArea = this.getCurrentArea();
    }

    @emitter
    onProvinceChange(provinceCode) {
        this.loadAllCitiesByProvinceCode(provinceCode);
    }

    @emitter
    onCityChange(cityCode) {
        this.loadAllDistrictsByCityCode(cityCode);
    }

    @emitter
    onChange(value) {
        this.value = value;
        this._syncCurrentArea();
    }

    getCurrentArea() {
        const [provinceCode, cityCode, districtCode] = this.value || [];
        return {
            provinceCode,
            province: provinceCode && util.get(this.provinces.find(item => item.code == provinceCode), 'name'),
            cityCode,
            city: cityCode && util.get(this.cities.find(item => item.code == cityCode), 'name'),
            districtCode,
            district: districtCode && util.get(this.districts.find(item => item.code == districtCode), 'name'),
        };
    }

    async loadAllProvinces() {
        if (this._provincesLoaded)
            return;
        this._provincesLoaded = true;

        const result = await this._addressApiService.getAllProvinces();
        this.provinces = (util.get(result, 'data.value') || []).map(province => {
            return {
                code: province.provinceCode,
                name: province.provinceName
            };
        });
    }

    async loadAllCitiesByProvinceCode(provinceCode) {
        const result = await this._addressApiService.getAllCitiesByProvinceCode(provinceCode);
        this.cities = (util.get(result, 'data.value') || []).map(city => {
            return {
                code: city.cityCode,
                name: city.cityName
            };
        });
    }

    async loadAllDistrictsByCityCode(cityCode) {
        const result = await this._addressApiService.getAllDistrictsByCityCode(cityCode);
        this.districts = (util.get(result, 'data.value') || []).map(district => {
            return {
                code: district.districtCode,
                name: district.districtName
            };
        });
    }
}