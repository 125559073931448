var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import OrderOperationHandlers from "../view-models/OrderOperationHandlers";
import OrderInfoViewModel from "../view-models/OrderInfoViewModel";

function OrderButtons({
    buttonStatus,
    tradeOrder,
    isFromPaySuccess,
    onPay,
    onRePurchase,
    onRefund,
    onDelOrder,
    onConfirmReceipt,
    onGoEvaluating,
    onCancelOrder,
    onGoToOrderStatus,
    onGoToAfterSaleList,
    onApplyOrderAfterSale,
    onGoCheckLogistics
}) {
    // 处方2.0的'待审核处方单'状态下，不支持售后服务，没有操作订单状态按钮（hualin 2018.6.14）
    const isWaitToApproveHP = tradeOrder.orderStatus === 'WAITING_TO_APPROVE_HP';
    if (isWaitToApproveHP) {
        return null;
    }

    const buttons = [];

    let rePurchaseText = '再次购买';
    let source = tradeOrder.source || '';

    const hideRefundBtn = (source === 'JKX_HEALTH_PRES' || source === 'HFLKF_HEALTH_PRES');

    if (buttonStatus.buyAgainButton && tradeOrder.items.some(item => (item.itemTypeValue === 20 && item.itemSubTypeValue === 10))) {
        rePurchaseText = '再次预订';
    }

    if (tradeOrder.bizType == 'VIDEO_DIAGNOSIS' && tradeOrder.orderStatus == 'WAITING_PAY') {
        if (buttonStatus.payButton) {
            buttons.push(
                <button
                    onClick={() => onPay(tradeOrder)}
                    className="button btn-block-primary clickable"
                >立即付款</button>
            );
        }
    } else if (tradeOrder.orderStatus == 'WAITING_PAY' && (buttonStatus.cancelOrderButton || buttonStatus.closeButton)) {
        buttons.push(
            <button
                onClick={() => onCancelOrder({
                    tradeOrder,
                    buttonStatus
                })}
                className="button"
            >取消订单</button>
        );
        // 如果有去付款按钮
        if (buttonStatus.payButton) {
            buttons.push(
                <button
                    onClick={() => onPay(tradeOrder)}
                    className="button btn_solid clickable"
                >去付款</button>
            );
        }
    } else if (buttonStatus.cancelOrderButton || buttonStatus.closeButton) {
        // 货到付款
        // 有再次购买按钮
        if (buttonStatus.buyAgainButton) {
            buttons.push(
                <button
                    className="btn btn_solid clickable"
                    onClick={() => onRePurchase(tradeOrder.tradeId)}
                >{rePurchaseText}</button>
            );
        }
        buttons.push(
            <button
                className="btn clickable"
                onClick={() => onCancelOrder({
                    tradeOrder,
                    buttonStatus
                })}
            >取消订单</button>
        );
    } else if (buttonStatus.applyRefundButton && !hideRefundBtn) {
        // 已支付，未发货申请退款
        // 有再次购买按钮
        if (buttonStatus.buyAgainButton) {
            buttons.push(
                <button
                    className="btn btn_solid clickable"
                    onClick={() => onRePurchase(tradeOrder.tradeId)}
                >{rePurchaseText}</button>
            );
        }
        buttons.push(<button
            className="btn clickable"
            onClick={() => onRefund({
                tradeOrder,
                buttonStatus
            })}>申请退款</button>);
    } else {
        // 底部按钮
        if (buttonStatus.hideOrderButton && !isFromPaySuccess) {
            buttons.push(
                <button
                    key='1'
                    className="del-btn clickable"
                    onClick={() => onDelOrder(tradeOrder.tradeId)}
                >删除订单</button>
            );
        }
        if (buttonStatus.confirmReceiptButton) {
            buttons.push(
                <button
                    key='2'
                    className="btn btn_solid clickable"
                    onClick={() => onConfirmReceipt(tradeOrder.tradeId)}
                >确认收货</button>
            );
        }
        if (buttonStatus.evaluableButton && tradeOrder.evaluable) {
            buttons.push(
                <button
                    key='3'
                    className="btn btn_solid clickable"
                    onClick={onGoEvaluating}
                >评价</button>
            );
        }
        if (buttonStatus.buyAgainButton) {
            buttons.push(
                <button
                    key='4'
                    className="btn btn_solid clickable"
                    onClick={onRePurchase}
                >{rePurchaseText}</button>
            );
        }
        if (buttonStatus.orderAfterSaleRecordButton) {
            buttons.push(
                <button
                    key='5'
                    className="btn clickable"
                    onClick={() => onGoToAfterSaleList(tradeOrder.tradeId)}
                >售后记录</button>
            );
        }
        if (buttonStatus.applyOrderAfterSaleButton) {
            buttons.push(
                <button
                    key='6'
                    className="btn clickable"
                    onClick={onApplyOrderAfterSale}
                >申请售后</button>
            );
        }
        if (buttonStatus.statusListButton) {
            buttons.push(
                <button
                    key='7'
                    className="btn clickable"
                    onClick={onGoToOrderStatus}
                >审核状态</button>
            );
        }
        if (buttonStatus.buyerLgOrder) {
            buttons.push(
                <button
                    key='8'
                    className="btn clickable"
                    onClick={() => onGoCheckLogistics(tradeOrder.tradeId)}
                >物流详情</button>
            );
        }
    }

    if (!buttons.length) {
        return null;
    }

    return (
        <div className="oi_buttons">
            {buttons}
        </div>
    );
}

function mapOrderInfoToProps(orderInfoViewModel: OrderInfoViewModel) {
    return {
        tradeOrder: orderInfoViewModel.tradeOrder,
        buttonStatus: orderInfoViewModel.buttonStatus,
    };
}

function mapOperationsToProps(orderOperationHandlers: OrderOperationHandlers) {
    return {
        onPay: orderOperationHandlers.onPay.emit,
        onRePurchase: orderOperationHandlers.onRePurchase.emit,
        onRefund: orderOperationHandlers.onRefund.emit,
        onDelOrder: orderOperationHandlers.onDelOrder.emit,
        onConfirmReceipt: orderOperationHandlers.onConfirmReceipt.emit,
        onGoEvaluating: orderOperationHandlers.onGoEvaluating.emit,
        onCancelOrder: orderOperationHandlers.onCancelOrder.emit,
        onGoToOrderStatus: orderOperationHandlers.onGoToOrderStatus.emit,
        onGoToAfterSaleList: orderOperationHandlers.onGoToAfterSaleList.emit,
        onApplyOrderAfterSale: orderOperationHandlers.onApplyOrderAfterSale,
        onGoCheckLogistics: orderOperationHandlers.onGoCheckLogistics
    };
}

export default inject(() => {
    return {
        ...mapOrderInfoToProps(autowired('orderInfoViewModel')),
        ...mapOperationsToProps(autowired('orderOperationHandlers')),
    };
})(OrderButtons);

