var React=window.Snowball._React;
import TFSImage from '../../../shared/components/TFSImage';
import '../styles/RefundProduct.scss';

const RefundProduct = (props) => {
    const {
        picture,
        title,
        specList,
        cashAmount,
        count,
        style = {}
    } = props;

    return (
        <div className="as__refund_item clearfix" style={style}>
            <div className="col">
                <div className="image">
                    <TFSImage src={picture} width="60" height="60" />
                </div>
            </div>
            <div className="col" style={{ width: '350px', verticalAlign: 'top' }}>
                <p className="title">{title}</p>
                <p className="spec">
                    {
                        specList.map((spec) => {
                            return (
                                <span>{`${spec.sk}: ${spec.sv}`}</span>
                            );
                        })
                    }
                </p>
            </div>
            <div className="col" style={{ marginLeft: '30px' }}>
                {cashAmount != undefined && <div className="price">{`￥${(cashAmount / 100).toFixed(2)}`}</div>}
                {count != undefined && <div className="count">{`x${count}`}</div>}
            </div>
        </div>
    );
};

export default RefundProduct;