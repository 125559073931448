import { request } from './request';

export function getSearchTags(params) {
    return request("skydive.queryDataForWapSearchBox", false, params, {
        isShowLoading: false,
        autoLogin: false
    });
}

export function mallThinkingWordSearch(params) {
    return request("skydive.mallThinkingWordSearch", false, params, {
        isShowLoading: false,
        autoLogin: false
    });
}