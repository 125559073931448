var React=window.Snowball._React;var { useEffect, useCallback }=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import { COUPON_BUTTON_STATUS } from "../constants";

function RedPacketList({
    className,
    spuId,
    redPackets,
    onLoad,
    onClose,
    onReceiveRedPacket
}) {
    useEffect(() => {
        if (spuId && onLoad) {
            onLoad(spuId);
        }
    }, [onLoad, spuId]);

    const unavaibleRedPackets = redPackets && redPackets.filter(({ status }) => status != COUPON_BUTTON_STATUS.Avaible);

    return <div className={"it_coupon_list it_redpacket_list " + (className || '')}>
        <div className="it_coupon_list_title">
            红包
            <button
                className="ps_a it_coupon_list_close"
                onClick={onClose}
            >收起</button>
        </div>
        <div className="it_coupon_list_con">
            <List
                redPackets={redPackets && redPackets.filter(({ status }) => status == COUPON_BUTTON_STATUS.Avaible)}
                onReceiveRedPacket={onReceiveRedPacket}
            />
            {
                !!(unavaibleRedPackets && unavaibleRedPackets.length) && <>
                    <h4 className="ml_m fs_m cl_333 mb_s">已领取红包<span className="cl_999">（满足条件后可用于当前商品）</span></h4>
                    <List
                        redPackets={unavaibleRedPackets}
                        onReceiveRedPacket={onReceiveRedPacket}
                    />
                </>
            }
        </div>
    </div>;
}

function List({
    redPackets,
    onReceiveRedPacket
}) {
    return <ul>{
        redPackets && redPackets.map((item) => {
            const {
                specId,
                discountPrice,
                description,
                effectiveTime,
                status,
            } = item;
            return <li className="it_coupon_list_item clearfix">
                <div className="fl_l price">
                    <div className="center">
                        <i>￥</i>
                        <span>{discountPrice}</span>
                    </div>
                </div>
                <div className="fl_l con">
                    <p className="desc to_e">{description}</p>
                    <p className="date to_e">{effectiveTime}</p>
                </div>
                <div className="fl_r">
                    {
                        status == COUPON_BUTTON_STATUS.Avaible
                            ? <button
                                className="btn"
                                type="button"
                                onClick={() => onReceiveRedPacket(specId)}
                            >
                                领取
                            </button>
                            : status == COUPON_BUTTON_STATUS.Received
                                ? <i className="item-received-redpacket" />
                                : status == COUPON_BUTTON_STATUS.Invalid
                                    ? <i className="item-invalid-redpacket" />
                                    : status == COUPON_BUTTON_STATUS.Sack
                                        ? <i className="item-sack-redpacket" />
                                        : null
                    }
                </div>
            </li>;
        })
    }</ul>;
}

export default inject(() => {
    const redPacketViewModel = autowired('redPacketViewModel');
    return {
        redPackets: redPacketViewModel.redPackets,
        onReceiveRedPacket: redPacketViewModel.onReceiveRedPacket,
        onLoad: useCallback((spuId) => redPacketViewModel.loadRedPackets([spuId]), [redPacketViewModel])
    };
})(RedPacketList);
