var React=window.Snowball._React;
import deliveryfeeImage from '../../../shared/images/deliveryfee.png';
import RefundProduct from './RefundProduct';
import { DELIVERY_TYPE } from '../constants';

const RefundOrderTableRow = (props) => {
    const { order } = props;
    const commonStyle = { whiteSpace: 'nowrap', padding: '0 5px', textAlign: 'center' };

    return (
        <tbody className="as__order_row">
            <tr className="sub_hd">
                <td colSpan="6">
                    <span>申请编号: {order.requestNo}</span>
                    <span style={{ marginLeft: '20px' }}>订单号: {order.orderId}</span>
                </td>
            </tr>
            <tr className="sub_bd">
                <td>
                    {
                        order.refundItems.map((item) => {
                            return (
                                <RefundProduct
                                    picture={item.type === DELIVERY_TYPE ? deliveryfeeImage : item.picture}
                                    title={item.title}
                                    specList={JSON.parse(item.specs || '[]')}
                                    cashAmount={item.amount}
                                    count={item.count}
                                />
                            );
                        })
                    }
                </td>
                <td style={{ ...commonStyle, color: '#ff4500', fontWeight: 'bold' }}>
                    {
                        order.refundAmountTextList.map((item) => {
                            return <p>{`${item}`}</p>;
                        })
                    }
                </td>
                <td style={commonStyle}>
                    <p>{order.refundTypeText}</p>
                    <p>{order.statusText}</p>
                </td>
                <td style={commonStyle}>
                    <div className="btn_detail" app-link={`/aftersale-detail/${order.requestNo}`}>售后记录</div>
                </td>
            </tr>
        </tbody>
    );
};

export default RefundOrderTableRow;