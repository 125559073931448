var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import Product from './Product';

function ProductList({
    showSelfService,
    tradeOrder,
    buttonStatus,
    onClickSelfService,
    onClickConsult
}) {
    if (!tradeOrder || !tradeOrder.tradeId) return null;
    const {
        items,
    } = tradeOrder;

    return (
        <div className="oi_store">
            <table className="oi_items">
                <tr>
                    <th className="ta_l">订单详情</th>
                    <th>价格</th>
                    <th>数量</th>
                    <th></th>
                    <th>金额</th>
                    <th>订单操作</th>
                </tr>
                {
                    items.map((item, i) => {
                        const isLastItem = i == items.length - 1;
                        return (
                            <Product
                                key={item.itemId}
                                isLastItem={isLastItem}
                                item={item}
                            ></Product>
                        );
                    })
                }
            </table>
        </div>
    );
}

export default inject(() => {
    const orderInfoViewModel = autowired('orderInfoViewModel');
    return {
        tradeOrder: orderInfoViewModel.tradeOrder,
        buttonStatus: orderInfoViewModel.buttonStatus,
        showSelfService: orderInfoViewModel.showSelfService,
    };
})(ProductList);