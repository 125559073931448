var { controller, autowired, param }=window.Snowball._app;
import Marketing from '../containers/Marketing';
import { MarketingConfiguration } from '../configuration';
import DecorationViewModel from '../../modules/DecorationViewModel';

@controller({
    component: Marketing,
    configuration: MarketingConfiguration,
})
class MarketingController {
    @param('logicId')
    logicId: number;

    @param('pageId')
    pageId: number;

    @param('preview')
    isPreview;

    @autowired
    _decorationViewModel: DecorationViewModel;

    async onInit() {
        if (this.app.env.IS_PREVIEW && this.pageId) {
            await this._decorationViewModel.previewCmsPageById(this.pageId);
            while (!this._decorationViewModel.isNoMoreData) {
                await this._decorationViewModel.previewCmsMore();
            }
        } else {
            this.ctx.logger.makeLog(`H5web_pc_cms_${this.logicId}`, 'onload');
            await this._decorationViewModel.loadCmsPage(this.logicId);
            while (!this._decorationViewModel.isNoMoreData) {
                await this._decorationViewModel.loadCmsMore();
            }
        }
    }
}

export default MarketingController;
