var { observable, asObservable, $ }=window.Snowball;
var { ViewModel, autowired, emitter }=window.Snowball._app;
import { OUT_BIZ_TYPE } from '../../../env';
import { TAB_TPYE, QRCODE_OVERDUE, QR_CODE_STATUS, PAY_METHOD_KEY } from '../constants';

export default class OrderCreateViewModel extends ViewModel {

    @autowired
    _userService

    @autowired
    _unicornApiService

    @observable
    orders = []

    @observable
    tabs = []

    qrCodeTimer

    checkOrderStatusTimer

    get money() {
        return this.orders.reduce((money, order) => {
            const { tradeOrder } = order;
            money += tradeOrder.actualTotalFee;
            return money;
        }, 0);
    }

    constructor() {
        super();

        this.ctx.autorun(() => {
            this.user = this._userService.user;
        });
    }

    async load({
        tradeIds,
        callBackUrl,
    }) {
        if (await this._userService.ensureLogin()) {
            this.tradeIds = tradeIds;
            this.callBackUrl = callBackUrl || `${location.protocol}//${location.hostname}${location.pathname}${location.search}#/order/payresult/${tradeIds.join('__')}`;
            this.initPay();
            this.fetchOrders();
            this.checkOrderStatus();
        }
    }

    async getPayData() {
        const result = await this._unicornApiService.getPayUrl({
            orderIds: this.tradeIds,
            callBackUrl: this.callBackUrl,
            outBizType: OUT_BIZ_TYPE,
        });

        if (result.success) {
            let payData;
            try {
                payData = JSON.parse(result.data.url);
            } catch (error) {
                console.error(error);
            }
            return payData;
        }
    }

    async initPay() {
        const payData = await this.getPayData();
        if (payData) {
            const tabs = [];
            if (
                payData.wechatScanPA
                || payData.alipayScanPA
            ) {
                const tab = {
                    title: '支付平台',
                    selected: false,
                    tabType: TAB_TPYE.PAY_PLATFORM,
                    payMethods: [],
                };
                if (payData.wechatScanPA) {
                    tab.payMethods.push({
                        key: PAY_METHOD_KEY.WECHAT_PAY,
                        recommend: true,
                        selected: false,
                        qrCode: payData.wechatScanPA,
                        qrCodeStatus: QR_CODE_STATUS.NORMAL,
                        qrCodeRefreshTime: Date.now(),
                        qrCodeRestTime: this.getQrCodeRestTime(Date.now()),
                    });
                }
                if (payData.alipayScanPA) {
                    tab.payMethods.push({
                        key: PAY_METHOD_KEY.ALI_PAY,
                        recommend: false,
                        selected: false,
                        qrCode: payData.alipayScanPA,
                        qrCodeStatus: QR_CODE_STATUS.NORMAL,
                        qrCodeRefreshTime: Date.now(),
                        qrCodeRestTime: this.getQrCodeRestTime(Date.now()),
                    });
                }
                tabs.push(tab);
            }
            if (this.tradeIds.length == 1 && payData.jkCardPA) {
                const tab = {
                    title: '健康卡',
                    selected: false,
                    tabType: TAB_TPYE.HEALTH_CARD,
                    payMethods: [
                        {
                            key: PAY_METHOD_KEY.HEALTH_CARD,
                            recommend: false,
                            selected: true,
                            data: payData.jkCardPA,
                        }
                    ],
                };
                tabs.push(tab);
            }
            tabs[0].selected = true;
            tabs[0].payMethods[0].selected = true;
            this.tabs = tabs;
            this.startQrCodeTimer();
        }
    }

    @emitter
    onTabClick(tab) {
        const { tabs } = this;
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].tabType == tab.tabType) {
                asObservable(tabs[i])
                    .set({ selected: true })
                    .nextTick(async () => {
                        await this.refreshQrCode();
                        this.startQrCodeTimer();
                    });
            } else {
                asObservable(tabs[i])
                    .set({ selected: false });
            }
        }
    }

    @emitter
    onPayMethodClick({ tabType, payMethod }) {
        const selectedTab = this.findTab(tabType);
        for (let i = 0; i < selectedTab.payMethods.length; i++) {
            if (selectedTab.payMethods[i].key == payMethod.key) {
                asObservable(selectedTab.payMethods[i])
                    .set({
                        selected: true,
                    })
                    .nextTick(async () => {
                        await this.refreshQrCode();
                        this.startQrCodeTimer();
                    });
            } else {
                asObservable(selectedTab.payMethods[i])
                    .set({ selected: false });
            }
        }
    }

    @emitter
    async onRefreshClick() {
        await this.refreshQrCode();
        this.startQrCodeTimer();
    }

    @emitter
    onImmediatelyPay() {
        const selectedPayMethod = this.findSelectedPayMethod();
        if (!selectedPayMethod) {
            return;
        }
        if (selectedPayMethod.key == PAY_METHOD_KEY.HEALTH_CARD) {
            const url = selectedPayMethod.data.url;
            const params = selectedPayMethod.data.parameters;

            const $postForm = $('<form>', document)
                .attr('id', 'post_form')
                .attr('action', url)
                .attr('method', 'POST');

            for (const k in params) {
                const $input = $(`<input type="hidden" name="${k}" />`);
                $input.val(params[k]);
                $postForm.append($input);
            }
            $(document.body).append($postForm);
            $postForm[0].submit();
        }
    }

    findTab(tabType) {
        return this.tabs.find((tab) => tab.tabType == tabType);
    }

    findSelectedTab() {
        return this.tabs.find(({ selected }) => !!selected);
    }

    findPayMethod({ tabType, payMethodKey }) {
        const tab = this.findTab(tabType);
        if (!tab) {
            return;
        }
        return tab.payMethods.find(({ key }) => key == payMethodKey);
    }

    findSelectedPayMethod() {
        const selectedTab = this.findSelectedTab();
        if (!selectedTab) {
            return;
        }
        return selectedTab.payMethods.find(({ selected }) => !!selected);
    }

    startQrCodeTimer() {
        if (this.qrCodeTimer) {
            clearInterval(this.qrCodeTimer);
            this.qrCodeTimer = null;
        }
        const selectedPayMethod = this.findSelectedPayMethod();
        if (!selectedPayMethod) {
            return;
        }
        if (selectedPayMethod.qrCode) {
            this.qrCodeTimer = setInterval(() => {
                const qrCodeRestTime = this.getQrCodeRestTime(selectedPayMethod.qrCodeRefreshTime);
                if (qrCodeRestTime < 0) {
                    asObservable(selectedPayMethod)
                        .set({ qrCodeStatus: QR_CODE_STATUS.OVERDUE });
                    clearInterval(this.qrCodeTimer);
                    this.qrCodeTimer = null;
                } else {
                    asObservable(selectedPayMethod)
                        .set({ qrCodeRestTime });
                }
            }, 300);
        }
    }

    async refreshQrCode() {
        const selectedPayMethod = this.findSelectedPayMethod();
        if (!selectedPayMethod) {
            return;
        }
        if (this.getQrCodeRestTime(selectedPayMethod.qrCodeRefreshTime) > 0) {
            return;
        }
        asObservable(selectedPayMethod)
            .set({
                qrCodeStatus: QR_CODE_STATUS.LOADING,
                qrCodeRestTime: 0,
            });
        const payData = await this.getPayData();
        if (payData) {
            let qrCode;
            switch (selectedPayMethod.key) {
                case PAY_METHOD_KEY.WECHAT_PAY:
                    qrCode = payData.wechatScanPA;
                    break;

                case PAY_METHOD_KEY.ALI_PAY:
                    qrCode = payData.alipayScanPA;
                    break;
            }
            asObservable(selectedPayMethod)
                .set({
                    qrCode,
                    qrCodeStatus: QR_CODE_STATUS.NORMAL,
                    qrCodeRefreshTime: Date.now(),
                    qrCodeRestTime: this.getQrCodeRestTime(Date.now()),
                });
        }
    }

    checkOrderStatus() {
        this.checkOrderStatusTimer = setInterval(async () => {
            const result = await Promise.race(this.tradeIds.map((tradeId) => this._unicornApiService.queryOrderForBuyer(tradeId)));
            if (result.success) {
                const { tradeOrder } = result.data;
                if (tradeOrder.orderStatus != 'WAITING_PAY') {
                    clearInterval(this.checkOrderStatusTimer);
                    this.redirect();
                }
            }
        }, 3000);
    }

    async fetchOrders() {
        const resultArr = await Promise.all(this.tradeIds.map((tradeId) => this._unicornApiService.queryOrderForBuyer(tradeId)));
        if (resultArr.every(({ success }) => success)) {
            this.orders = resultArr.map(({ data }) => data);
        }
    }

    redirect() {
        console.log(this.callBackUrl);
        location.href = this.callBackUrl;
    }

    getQrCodeRestTime(qrCodeRefreshTime) {
        return qrCodeRefreshTime + QRCODE_OVERDUE - Date.now();
    }

    clearTimer() {
        if (this.qrCodeTimer) {
            clearInterval(this.qrCodeTimer);
        }
        if (this.checkOrderStatusTimer) {
            clearInterval(this.checkOrderStatusTimer);
        }
    }
}

