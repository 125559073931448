var { lazy }=window.Snowball._app;
import HomeController from './home/controllers/HomeController';
import Error from './home/containers/Error';
import MarketingController from './cms/controllers/MarketingController';
import ItemController from './item/controllers/ItemController';
import B2CCartController from './cart/controllers/B2CCartController';
import OrderCreateController from './order-create/controllers/OrderCreateController';
import AddressController from './address/controllers/AddressController';
import OrderInfoController from './order-info/controllers/OrderInfoController';
import DemandCartController from './cart/controllers/DemandCartController';
import PayResultController from './pay-result/controllers/PayResultController';
import MyCouponController from './my-coupon/controllers/MyCouponController';
import PersonInfoController from './person-info/controllers/PersonInfoController';
import BeforeCheckoutController from './checkout/controllers/BeforeCheckoutController';
import CheckoutController from './checkout/controllers/CheckoutController';
import AfterSaleController from './after-sale/controllers/AfterSaleController';
import AfterSaleListController from './after-sale/controllers/AfterSaleListController';
import MyAfterSaleController from './after-sale/controllers/MyAfterSaleController';
import TradeStatusDetailController from './order-info/controllers/TradeStatusDetailController';
import LogisticsController from './logistics/controllers/LogisticsController';

export default {
    '/': HomeController,
    '/market/\\d+:logicId': MarketingController,
    '/item/\\d+:id': ItemController,
    '/test': lazy(() => import('./Test')),
    '/template': lazy(() => import('./home/controllers/TemplateController')),
    '/cart': B2CCartController,
    '/cart/demandlist': DemandCartController,
    '/order/create': OrderCreateController,
    '/order/payresult/:orderId': PayResultController,
    '/order-list': lazy(() => import('./order-list/controllers/OrderListController')),
    '/order-list/:orderType': lazy(() => import('./order-list/controllers/OrderListController')),
    '/order/\\d+:orderId': OrderInfoController,
    '/orderstatus/\\d+:orderId': TradeStatusDetailController,
    '/aftersale/:orderId': AfterSaleListController,
    '/aftersale-detail/:requestNo': AfterSaleController,
    '/my-aftersale': MyAfterSaleController,
    '/address': AddressController,
    '/my-coupon': MyCouponController,
    '/person-info': PersonInfoController,
    '/checkout': BeforeCheckoutController,
    '/pay': CheckoutController,
    '/my-comment': lazy(() => import('./my-comment/controllers/MyCommentController')),
    '/logistics/\\d+:orderId': LogisticsController,
    '/error': Error
};
