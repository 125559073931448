var React=window.Snowball._React;
var { ViewModel, autowired, }=window.Snowball._app;
var { formatDate }=window.Snowball.util;
var { observable, asObservable }=window.Snowball;
import PromcenterApiService from "@/shared/apis/PromcenterApiService";
import { DEFAULT_PAGINATION, } from "../constants";
var { toast }=window.Snowball._widget;

const TIME_FORMAT = "yyyy.MM.dd";

export default class MyCouponViewModel extends ViewModel {
    @observable
    type = "NOT_USED";

    @observable
    loading = false;

    @observable
    data = [];

    @observable
    addCouponErrMsg = '';

    @observable
    pagination = DEFAULT_PAGINATION;

    @autowired
    _promcenterApiService: PromcenterApiService;

    constructor() {
        super();
        this.onChangeTab(({ type }) => this.changeTab({ type }));
        this.onPaginationChange(({ pageNo }) => this.paginationChange(pageNo));
    }

    async _fetch(pageNo = 1) {
        this.loading = true;
        const { pageSize } = this.pagination;
        const result = await this._promcenterApiService.queryUserCoupons({
            status: this.type,
            pageNo,
            pageSize
        });
        const res = result.data || { couponList: [], count: 0 };
        this._setPagination({ total: res.count,pageNo });
        this.data = res.couponList;
        this.loading = false;
    }

    async addCoupon(code = '') {
        const result = await this._promcenterApiService.activateCouponCode({
            code
        });
        const { success, desc } = result;
        console.log('addCoupon: ',result);
        if (!success) {
            this.addCouponErrMsg = desc;
        } else {
            toast.showToast('激活成功');
        }
    }

    clearErrMsg() {
        this.addCouponErrMsg = '';
    }

    onPaginationChange = this.ctx.createEmitter();
    onShowCouponHelp = this.ctx.createEmitter();
    onActivateCoupon = this.ctx.createEmitter();
    onChangeTab = this.ctx.createEmitter();

    paginationChange(pageNo) {
        if (this.pagination.pageNo !== pageNo) {
            this._setPagination({
                pageNo
            });
            this._fetch(pageNo);
        }
    }

    async load({ type }) {
        this._fetch();
    }

    changeTab({ type }) {
        if (!this._makeCouponType(type)) return;
        this._reset();
        this.load({ type });
    }

    _reset() {
        this.data = [];
        this.pagination = DEFAULT_PAGINATION;
    }

    _makeCouponType(type = "") {
        if (type !== this.type) {
            this.type = type;
            return true;
        }
        return false;
    }

    _setPagination(values = {}) {
        asObservable(this.pagination).set(values);
    }

    showCouponHelp() {
        console.log("*** show coupon help");
    }

    activateCoupon(couponNo) {
        console.log("*** activate coupon");
    }

    formatCouponDate = ({ startDate, endDate, remainDays, dateDesc = "" }) => {
        if (dateDesc && dateDesc.length > 0) {
            return dateDesc;
        } else if (!startDate || !endDate) {
            return "";
        } else if (remainDays === 1) {
            return "今天到期";
        } else if (remainDays > 1 && remainDays < 6) {
            return (
                <span>
                    {formatDate(endDate, TIME_FORMAT)}到期{" "}
                    <span className="cl_red">(仅剩{remainDays}天)</span>
                </span>
            );
        } else {
            return `${formatDate(
                startDate,
                TIME_FORMAT
            )}至${formatDate(endDate, TIME_FORMAT)}`;
        }
    };
}
