export const COUPON_BUTTON_STATUS = {
    /** @description 不显示 */
    Null: 1,
    /** @description 立即领取 */
    Avaible: 2,
    /** @description 已领取 */
    Received: 6,
    /** @description 去凑单/可用商品 */
    RoundOrder: 3,
    /** @description 已失效 */
    Invalid: 4,
    /** @description 已抢光 */
    Sack: 5,
};