var { Service }=window.Snowball._app;

export default class MasterApiService extends Service {

    getItemDetail({
        spuId,
        prCode,
        cyCode,
        storeId = 0,
        skuId = 0,
        // 限流开关 0关 1开
        flowLimited = 0
    }) {
        return this.app.gateway.request('master.getItemDetailV2', false, {
            spuId,
            storeId,
            skuId,
            outBizType: this.app.env.OUT_BIZ_TYPE,
            extParam: {
                prCode,
                cyCode,
                flowLimited,
                isWX: false
            },
        });
    }

    getItemSkuSelection(spuId, storeId = 0, skuId = 0, extParam = null) {
        return this.app.gateway.request('master.getItemSkuSelectionV2', false, {
            spuId,
            storeId,
            skuId,
            outBizType: this.app.env.OUT_BIZ_TYPE,
            extParam
        });
    }

    orderCreatePage(params) {
        return this.app.gateway.request('master.createOrderPage', true, params, {
            isShowLoading: true,
            autoLogin: true,
        });
    }

    createOrder(params) {
        return this.app.gateway.request('master.createOrder', true, params, {
            isShowLoading: true,
            autoLogin: true,
        });
    }

    getBarrage(spuId?: number) {
        return this.app.gateway.request(
            'master.getBarrage',
            false,
            {
                spuId,
            },
            {
                isShowLoading: false,
                autoLogin: false,
            },
        );
    }

    getMyGroups(status, pageNo, pageSize) {
        return this.app.gateway.request('master.getMyGroups', true, {
            groupStatus: status,
            pageNo,
            pageSize,
        });
    }

    getRecommendItems(pageNo, pageSize) {
        return this.app.gateway.request('master.getRecommendItems', false, {
            pageNo,
            pageSize,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        });
    }

    getGroupDetail(groupId) {
        return this.app.gateway.request('master.getGroupDetail', false, {
            groupId,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        });
    }

    getItemGroups(spuId: number) {
        return this.app.gateway.request('master.getItemGroupsV2', false, {
            spuId,
            outBizType: this.app.env.OUT_BIZ_TYPE
        });
    }

    getUserGroups(spuId: number, size = 3) {
        return this.app.gateway.request('master.getUserGroups', false, {
            spuId,
            size,
        });
    }

    groupRightCheck(spuId: number, groupId = 0) {
        return this.app.gateway.request('master.groupRightCheck', true, {
            spuId,
            groupId,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        });
    }

    getCustomerServiceUrl(sellerId: number, spuType: number, source: string) {
        return this.app.gateway.request('master.getCustomerServiceUrl', false, {
            customerServiceParam: {
                sellerId,
                spuType,
                source,
            },
        });
    }

    querySellerSlide(sellerId: number) {
        return this.app.gateway.request('master.querySellerSlide', false, {
            sellerId,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        });
    }

    guessYouLike(spuIds = [], business = 'item_detail', pageNo = 1, pageSize = 20) {
        return this.app.gateway.request('master.guessYouLike', false, {
            spuIds,
            business,
            pageNo,
            pageSize,
            outBizType: this.app.env.OUT_BIZ_TYPE,
        });
    }

    querySellerRecommends({ sellerId, spuId }) {
        return this.app.gateway.request('master.querySellerRecommends', false, {
            outBizType: this.app.env.OUT_BIZ_TYPE,
            sellerId,
            spuId,
        });
    }

    updateHCode(hcode: string) {
        return this.app.gateway.request('master.updateHCode', true, {
            hCode: hcode,
        });
    }

    getItemDetailPreview(spuId) {
        return this.app.gateway.request('master.getItemDetailPreview', false, {
            outBizType: this.app.env.OUT_BIZ_TYPE,
            spuId,
        });
    }

    userVerify(addressId, user) {
        return this.app.gateway.request('master.userVerify', true, {
            addressId,
            user: { cid: user }
        });
    }

    // 查询佣金金额和佣金订单
    salesCommissionTotal() {
        return this.app.gateway.request('master.salesCommissionTotal', true, {
            outBizType: this.app.env.OUT_BIZ_TYPE
        });
    }

    // 代理人销售订单列表
    salesCommissionDetail(date, pageNo = 1) {
        return this.app.gateway.request('master.salesCommissionDetail', false, {
            outBizType: this.app.env.OUT_BIZ_TYPE,
            date,
            pageNo
        });
    }

    getLiveInfo(spuId, sellerId) {
        return this.app.gateway.request('master.getLiveInfo', false, {
            platform: this.app.env.APP_ENV,
            sellerId,
            spuId
        });
    }

    // 下单 保险卡实名信息换skuId
    getSkuIdByAge({ skuId, age }) {
        return this.app.gateway.request('master.getSkuIdByAge', true, {
            skuId,
            age,
        });
    }

    // 商详 发新人券接口
    fetchNewCoupon({ spuId, sellerId, skuIds, categoryId }) {
        return this.app.gateway.request('master.fetchNewCoupon', true, {
            spuId,
            sellerId,
            skuIds,
            categoryId,
            outBizType: this.app.env.OUT_BIZ_TYPE
        });
        // return Promise.resolve({
        //     success: true,
        //     data: {
        //         coupons: [{
        //             discountPrice: 10,
        //             thresholdPrice: 100
        //         }]
        //     }
        // });
    }

    getPayUrl({ orderIds, callBackUrl }) {
        return this.app.gateway.request(
            'master.getPayUrl', true, {
                orderIds,
                callBackUrl,
                outBizType: this.app.env.OUT_BIZ_TYPE
            }, {
                isShowLoading: true,
                autoLogin: true,
            }
        );
    }

    rePurchase(orderId) {
        return this.app.gateway.request('master.rePurchase', true, {
            orderId,
            outBizType: this.app.env.OUT_BIZ_TYPE
        });
    }
}