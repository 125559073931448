var React=window.Snowball._React;
import deliveryfeeImage from '../../../shared/images/deliveryfee.png';
import RefundProduct from '../components/RefundProduct';
import { DELIVERY_TYPE } from '../constants';

const RefundOrderInfo = (props) => {
    const { refundOrder, needUserSendProduct } = props;

    return (
        <div className="as__order_info">
            <p className="title">订单信息</p>
            <div className="main">
                {
                    !needUserSendProduct && (
                        <div className="row">
                            <div className="label" style={{ marginTop: '35px' }}>退款商品: </div>
                            <div className="cont">
                                {
                                    renderRefundProductList(refundOrder.refundItems)
                                }
                            </div>
                        </div>
                    )
                }
                <div className="row">
                    <div className="label">订单编号: </div>
                    <div className="cont">{refundOrder.orderId}</div>
                </div>
                <div className="row">
                    <div className="label">申请编号: </div>
                    <div className="cont">{refundOrder.requestNo}</div>
                </div>
                <div className="row">
                    <div className="label">申请时间: </div>
                    <div className="cont">{refundOrder.applyTime}</div>
                </div>
                {
                    refundOrder.reason && (
                        <div className="row">
                            <div className="label">退货原因: </div>
                            <div className="cont">{refundOrder.reason}</div>
                        </div>
                    )
                }
                <div className="row">
                    <div className="label">退款金额: </div>
                    <div className="cont" style={{ color: '#ff6f00', fontWeight: 'bold' }}>{
                        refundOrder.refundAmountTextList.map((item) => {
                            return <span style={{ marginRight: '10px' }}>{item}</span>;
                        })
                    }</div>
                </div>
                <div className="row">
                    <div className="label">售后进度: </div>
                    <div className="cont">{refundOrder.statusText}</div>
                </div>
            </div>
        </div>
    );
};

function renderRefundProductList(refundItems) {
    return refundItems.map((item) => {
        return (
            <RefundProduct
                picture={item.type === DELIVERY_TYPE ? deliveryfeeImage : item.picture}
                title={item.title}
                specList={JSON.parse(item.specs || '[]')}
            />
        );
    });
}

export default RefundOrderInfo;