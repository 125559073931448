var { controller, autowired, param }=window.Snowball._app;
import OrderInfo from '../containers/OrderInfo';
import { OrderInfoConfiguration } from '../configuration';
import OrderInfoViewModel from '../view-models/OrderInfoViewModel';

@controller({
    component: OrderInfo,
    configuration: OrderInfoConfiguration,
})
export default class OrderInfoController {
    @param
    orderId;

    @autowired
    _orderInfoViewModel: OrderInfoViewModel;

    onInit() {
        this.ctx.logger.makeLog('H5web_pc_orderinfo', 'onload', { orderId: this.orderId });
        this._orderInfoViewModel.init(this.orderId);
    }
}
