var React=window.Snowball._React;
import Main from "./Main";
import ProfileNav from './ProfileNav';
import { Header, Footer, SearchBar, MyCart, Slogan } from 'jkmall-frame';

export default function UserCenterMain({
    className,
    name,
    children
}) {
    return <>
        <Header
            renderExtra={({ cartNum = 0 }) => {
                return <>
                    <Slogan />
                    <div className="usercenter-breadcrumb"><i className="triangle"></i>我的</div>
                    <SearchBar showHot={false} />
                    <MyCart count={cartNum} />
                </>;
            }}
        />
        <div className="app-wrapper-gray pt_xl" style={{ paddingBottom: 100 }}>
            <Main className={"usercenter-wrapper " + (className || '')}>
                <ProfileNav current={name} />
                <div className="usercenter-content-wrapper">
                    <div className="usercenter-content">
                        {children}
                    </div>
                </div>
            </Main>
        </div>
        <Footer></Footer>
    </>;
}