var React=window.Snowball._React;

export default function ProfileNav({ current }) {
    return (
        <nav className="usercenter-nav">
            <section>
                <h1>订单中心</h1>
                <button app-link={`/order-list/all`} className={current == 'order' ? 'active' : ''}>我的订单</button>
                {/* <button app-link={`/my-comment`} className={current == 'mycomment' ? 'active' : ''}>商品评价</button> */}
                <button app-link={`/my-aftersale`} className={current == 'aftersale' ? 'active' : ''}>我的售后</button>
                {/* <button app-link={`/home`} className={current == 'favorite' ? 'active' : ''}>我的收藏夹</button> */}
            </section>
            <section>
                <h1>个人信息</h1>
                <button app-link={`/person-info`} className={current == 'profile' ? 'active' : ''}>个人资料</button>
                <button app-link={`/my-coupon`} className={current == 'coupon' ? 'active' : ''}>我的优惠券</button>
                <button app-link={`/address`} className={current == 'address' ? 'active' : ''}>收货地址</button>
            </section>
        </nav>
    );
}