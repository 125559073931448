var React=window.Snowball._React;
var { inject, autowired }=window.Snowball._app;
import GlobalAddressSelector from "../../../shared/components/GlobalAddressSelector";

function Delivery({
    spuId,
    address,
    hasStock,
    freightText
}) {
    return (
        <div className="it_delivery">
            <span className="it_base_label">送至</span>
            <GlobalAddressSelector />
            <span className="hasStock">{hasStock ? '有货' : '无货'} </span>
            <span className="freight">{freightText}</span>
        </div>
    );
}

export default inject(() => {
    const itemViewModel = autowired('itemViewModel');
    const globalAddressSelectorViewModel = autowired('globalAddressSelectorViewModel');

    return {
        hasStock: itemViewModel.pres.buttonReasonId == 0,
        freightText: itemViewModel.freightText,
        address: globalAddressSelectorViewModel.address,
        sellerId: itemViewModel.seller.sellerId,
    };
})(Delivery);