var React=window.Snowball._React;
var { inject, mapViewModelToProps }=window.Snowball._app;
import AddressEditor from "../components/AddressEditor";
import AddressList from "../components/AddressList";
import AddressEditorModal from "../components/AddressEditorModal";
import UserCenterMain from "../../../shared/components/UserCenterMain";
import UserCenterTitle from "../../../shared/components/UserCenterTitle";

function Address({
    addressList,
    onAddAddress,
}) {
    return (
        <UserCenterMain name={'address'}>
            <UserCenterTitle
                title={'收货地址'}
                desc={`您已添加${addressList.length}个收货地址，最多可以添加20个`}
                toolbar={!!addressList.length &&
                    <button onClick={onAddAddress}>
                        新增收货地址
                    </button>
                }
            />
            {
                addressList.length
                    ? <AddressList />
                    : <AddressEditor />
            }
            <AddressEditorModal />
        </UserCenterMain>
    );
}

export default inject(mapViewModelToProps('addressViewModel'))(Address);