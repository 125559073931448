import './sass/index.scss';
import Header, { Slogan, Logo, SearchBar, MyCart, MobileApp } from './components/header';
import Footer from './components/footer';
import Menu from './components/menu';

export {
    Header,
    Footer,
    Menu,
    Slogan,
    Logo,
    SearchBar,
    MyCart,
    MobileApp
};
